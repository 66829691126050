function MIN(a,b){return (a<b)?a:b;}
function MAX(a,b){return (a>b)?a:b;}

function checkPointFromPoligon(polygon,point){
  var i=1,N=polygon.length,isIn=false,
    p1=polygon[0],p2;

  for(;i<=N;i++)
  {
    p2 = polygon[i % N];
    if (point.lng > MIN(p1.lng,p2.lng))
    {
      if (point.lng <= MAX(p1.lng,p2.lng))
      {
        if (point.lat <= MAX(p1.lat,p2.lat))
        {
          if (p1.lng != p2.lng)
          {
            var xinters = (point.lng-p1.lng)*(p2.lat-p1.lat)/(p2.lng-p1.lng)+p1.lat;
            if (p1.lat == p2.lat || point.lat <= xinters)
              isIn=!isIn;
          }
        }
      }
    }
    p1 = p2;
  }
  return isIn;
}

export { checkPointFromPoligon };
