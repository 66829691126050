import React from "react";
import clsx from "clsx";

const StatusCheckbox = ({ on, off }) => {
	return (
		<div
			className={clsx({
				'tm-round-info': true,
				'--on': on,
				'--off': off,
			})}
		/>
	)
};

export default React.memo(StatusCheckbox)
