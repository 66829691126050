import palette from '../palette';

export default {
  root: {
    cursor: "pointer",

    "&:hover": {
      "& .MuiListItemText-primary": {
        color: palette.primary.main
      }
    }
  }
};
