import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Map,
  TileLayer,
  FeatureGroup
} from 'react-leaflet'
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import {EditControl} from 'react-leaflet-draw';
import PolylineDecorator from './PolylineDecorator';
import Points from "./Points";

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: 250
  },
}));

const MapObject = (props) => {
  const classes = useStyles();
  const {position, points} = props;

  return (
    <Map
      className={classes.leafletContainer}
      center={position}
      length={4}
      zoom={12}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      <Points
        points={points}
      />
    </Map>
  );
};

export default MapObject;
