const urls = {
    "web-socket": process.env.REACT_APP_WWS_MOBILE_URL,
    "image-url": process.env.REACT_APP_DOWNLOAD_URL,
    "mobile-image-url": process.env.REACT_APP_IMAGE_MOBILE_URL,

    // report-overlay
    'get-report-overlay': '/operator/report/overlay',

    // mobile
    'get-mobile-list-wanted': '/mobile/list/get-all',
    'create-mobile-list-wanted': '/mobile/list/create',
    'edit-mobile-list-wanted': '/mobile/list/edit',
    'delete-mobile-list-wanted': '/mobile/list/delete',
    'upload-mobile-list-wanted': '/mobile/list/upload',
    "mobile-get-filter": "/mobile/user/get-filter",
    "mobile-sensor-get-list": "/mobile/sensor/get-list",
    "mobile-list-get-all": "/mobile/list/get-all",
    "mobile-filter-set-filter": "/mobile/user/set-filter",

    // users
    'user-get-list': '/user/get-list',
    'user-get': '/user/get',
    'user-create': '/user/create',
    'user-edit': '/user/edit',
    'user-delete': '/user/delete',

    // alarms
    "alarms-get-list": "/alarms/get-list",

    // sensors
    "sensor-find-cameras": "/operator/sensor/find-cameras",
    "sensor-get-cameras": "/operator/sensor/get-cameras",
    "sensor-create-camera": "/operator/sensor-camera/create",
    "sensor-edit-camera": "/operator/sensor-camera/edit",
    "sensor-delete-camera": "/operator/sensor-camera/delete",

    // archive
    "mobile-archive-get": "/mobile/result/get",

    // help
    "help-get-list": "/help/list",
    "help-get-item": "/help/get",
    "help-get-item-slug": "/help/get-slug",
    "help-create": "/help/create",
    "help-edit": "/help/edit",
    "help-delete": "/help/delete",

    // Метки на карте
    "map-item-get": "/map-item/list",
    "map-item-create": "/map-item/create",
    "map-item-edit": "/map-item/edit",
    "map-item-delete": "/map-item/delete",

    // Поиск наиболее частых маршрутов ТС
    "frequent-routes-get": "/operator/report/frequent-routes",


    // Поиск наиболее частых мест стоянки ТС
    "frequent-parkings-get": "/operator/report/frequent-parkings"
}

export default urls
