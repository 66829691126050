import React from "react";
import {
	Box,
	Button,
	Typography
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import clsx from "clsx";
import palette from "../../../../../theme/palette";

const BlockNotification = (props) => {
	const {
		type,
		title,
		message,

		onCancel
	} = props;
	const classes = useStyles();

	return (
		<Box
			className={clsx({
				[classes.root]: true,
				'--error': Boolean(type === 'error')
			})}
		>
			{Boolean(title) && <Typography className={classes.title}>{ title }</Typography>}
			{Boolean(message) && <Typography className={classes.message}>{ message }</Typography>}
			{Boolean(onCancel) && <Button color="secondary" variant="contained" size="small" onClick={onCancel}>Отменить</Button>}
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		borderRadius: "10px",
		background: palette.primary.main,
		padding: "20px 32px",
		boxSizing: "border-box",
		marginBottom: 16,

		"&.--error": {
			background: palette.error.main,
		}
	},
	title: {
		marginBottom: 12,

		color: "#152435",
		fontSize: "18px",
		fontWeight: "700",
		lineHeight: "100%",
		letterSpacing: "-0.36px",
	},
	message: {
		marginBottom: 12,

		color: "#152435",
		fontSize: "16px",
		lineHeight: "100%",
		letterSpacing: "-0.36px",
	},
}));

export default React.memo(BlockNotification)
