import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  makeStyles,
  Grid,
  Tooltip, Select, MenuItem, FormControl, TableContainer
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  Search as SearchIcon
} from 'react-feather';
import {
  CustomCheckbox
} from '../../../index';
import clsx from 'clsx';
import palette from "../../../../theme/palette";
const StationaryTypeIcon = require("../../../../assets/icons/sensor/stationary-type.svg");
const MobileTypeIcon = require("../../../../assets/icons/sensor/mobile-type.svg");


const TableComponents = props => {
  const {
    rows,
    filter,
    selected,

    onFilter,
    onChangeSelected
  } = props;
  const classes = useStyles();

  const handleSelectAllCustomers = () => {
    let newSelected = [];
    if (selected.length !== rows.length) {
      newSelected = rows.map((t) => t.id)
    }

    onChangeSelected(newSelected)
  };
  const handleSelectOneSensor = (id) => {
    let newSelected = [...selected]
    if (newSelected.indexOf(id) > -1) {
      newSelected.splice(newSelected.indexOf(id), 1)
    } else {
      newSelected.push(id)
    }
    onChangeSelected(newSelected)
  };
  const handleChangeFilter = (event) => {
    let newFilter = {...filter};

    let item = event.target;
    let name = item.name;
    let value = item.value;

    newFilter[name] = value;

    onFilter(newFilter)
  }
  const handleClearFilter = (name, value) => {
    let newFilter = {...filter};

    newFilter[name] = value;

    onFilter(newFilter)
  };

  let selectedSomeSensor = selected.length > 0 && selected.length < rows.length;
  let selectedAllSensor = selected.length === rows.length;

  return (
    <PerfectScrollbar>
      <Table stickyHeader className="MuiTable-list-modal">
        <TableHead className="MuiTableHead-list-modal">
          <TableRow className={classes.tableRowHeader}>
            <TableCell width={45}>
              <CustomCheckbox
                checked={selectedAllSensor}
                indeterminate={selectedSomeSensor}
                onChange={handleSelectAllCustomers}
                type="circle"
              />
            </TableCell>
            <TableCell className={classes.rowAddress} align={'left'}>
              <TextField
                value={filter.address}
                name={'address'}
                placeholder={'Адрес'}
                variant={'outlined'}
                size={'small'}

                fullWidth
                onChange={handleChangeFilter}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Grid container spacing={1} wrap={'nowrap'} style={{paddingRight: 10}} alignItems={'center'}>
                        <Grid item style={{display: 'flex'}}>
                          {

                            (filter.address) && (
                              <IconButton onClick={() => handleClearFilter('address', '')}>
                                <HighlightOffIcon color={'rgba(134, 152, 177, 0.6)'} size={18}/>
                              </IconButton>
                            )
                          }
                        </Grid>
                        <Grid item style={{display: 'flex'}}>
                          <SearchIcon color={'rgba(134, 152, 177, 0.6)'} size={18}/>
                        </Grid>
                      </Grid>

                    </InputAdornment>
                  )
                }}
              />
            </TableCell>
            <TableCell className={classes.rowDirection} align={'left'}>
              <TextField
                fullWidth
                placeholder={'Направление'}
                variant={'outlined'}
                size={'small'}
                value={filter.direction}
                name={'direction'}

                onChange={handleChangeFilter}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Grid container spacing={1} wrap={'nowrap'} style={{paddingRight: 10}} alignItems={'center'}>
                        <Grid item style={{display: 'flex'}}>
                          {
                            (filter.direction) && (
                              <IconButton onClick={() => handleClearFilter('direction', '')}>
                                <HighlightOffIcon/>
                              </IconButton>
                            )
                          }
                        </Grid>
                        <Grid item style={{display: 'flex'}}>
                          <SearchIcon color={'rgba(134, 152, 177, 0.6)'} size={18}/>
                        </Grid>
                      </Grid>
                    </InputAdornment>
                  )
                }}
              />
            </TableCell>
            <TableCell className={classes.rowVendor} align={'left'}>
              <TextField
                placeholder={'Вендор'}
                variant={'outlined'}
                size={'small'}

                value={filter.vendor}
                name={'vendor'}

                onChange={handleChangeFilter}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Grid container spacing={1} wrap={'nowrap'} style={{paddingRight: 10}} alignItems={'center'}>
                        <Grid item style={{display: 'flex'}}>
                          {
                            (filter.vendor) && (
                              <IconButton onClick={() => handleClearFilter('vendor', '')}>
                                <HighlightOffIcon/>
                              </IconButton>
                            )
                          }
                        </Grid>
                        <Grid item style={{display: 'flex'}}>
                          <SearchIcon color={'rgba(134, 152, 177, 0.6)'} size={18}/>
                        </Grid>
                      </Grid>
                    </InputAdornment>
                  )
                }}
              />
            </TableCell>
            <TableCell className={classes.rowSerial} align={'left'}>
              <TextField
                variant={'outlined'}
                size={'small'}
                placeholder={'Серия'}

                value={filter.serial}
                name={'serial'}

                onChange={handleChangeFilter}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Grid container spacing={1} wrap={'nowrap'} style={{paddingRight: 10}} alignItems={'center'}>
                        <Grid item style={{display: 'flex'}}>
                          {
                            (filter.serial) && (
                              <IconButton onClick={() => handleClearFilter('serial', '')}>
                                <HighlightOffIcon/>
                              </IconButton>
                            )
                          }
                        </Grid>
                        <Grid item style={{display: 'flex'}}>
                          <SearchIcon color={'rgba(134, 152, 177, 0.6)'} size={18}/>
                        </Grid>
                      </Grid>
                    </InputAdornment>
                  )
                }}
              />
            </TableCell>
            <TableCell className={classes.rowType} align={'left'}>
              <FormControl
                className={classes.filterSelect}
                variant='outlined'
                size='small'
              >
                <Select
                  name="type"
                  value={filter.type}
                  placeholder={'Тип устройства'}
                  onChange={handleChangeFilter}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {
                        (filter.type) && (
                          <Tooltip title="Очистить">
                            <IconButton onClick={() => handleClearFilter('type', undefined)}>
                              <HighlightOffIcon/>
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    </InputAdornment>
                  }
                >
                  <MenuItem value={10}>Стационарный</MenuItem>
                  <MenuItem value={20}>Мобильный</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={classes.rowStatus} align={'left'}>
              Статус
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRowData
              key={`TableRowData-${row.id}`}
              _row={JSON.stringify(row)}
              isSelected={selected.includes(row.id)}
              onChangeSelect={handleSelectOneSensor.bind(this, row.id)}
            />
          ))}
        </TableBody>
      </Table>
    </PerfectScrollbar>
  );
};
const TableRowData = React.memo((props) => {
  const {
    _row,
    isSelected,

    onChangeSelect
  } = props;
  const row = JSON.parse(_row);
  const classes = useStyles();

  const _renderType = () => {
    if (row.type === 10) {
      return (<Box className={classes.rowTypeInfo}>
        <img src={StationaryTypeIcon}/>
        <span>Стационарный</span>
      </Box>)
    }
    if (row.type === 20) {
      return (<Box className={classes.rowTypeInfo}>
        <img src={MobileTypeIcon}/>
        <span>Мобильный</span>
      </Box>)
    }
    if (row.type === 0) {
      return null
    }

    switch (row.type) {
      case 10: {
        return (
          <Tooltip title="Стационарное устройство слежения">
            <div className={classes.cellType}>
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.75702 9.82978L12.7975 3.78934L9.84266 0.834507C9.61931 0.611121 9.25734 0.610816 9.03361 0.833668L0 9.82978H6.75702Z"
                  fill="white"/>
                <path
                  d="M18.6389 9.5242H17.3027C16.3553 9.5242 15.5846 10.2949 15.5846 11.2423V13.1894H14.1923C13.8433 12.1564 12.6626 11.6731 11.695 12.1551L10.7058 11.1659L12.6981 9.17357C13.3679 8.50375 13.3679 7.41393 12.6981 6.74411L12.0802 6.12622L7.39926 10.8073C7.29187 10.9146 7.14621 10.975 6.99434 10.975H3.71143L6.95524 14.2188C7.18344 14.3913 7.50258 14.3692 7.7047 14.167L9.89595 11.9758L10.8851 12.9649C10.2943 14.1511 11.1615 15.5565 12.4921 15.5565C13.2813 15.5565 13.9527 15.0441 14.1923 14.3347H15.5846V16.2819C15.5846 17.2292 16.3554 17.9999 17.3027 17.9999H18.639C18.9553 17.9999 19.2117 17.7435 19.2117 17.4272V10.0969C19.2116 9.78061 18.9552 9.5242 18.6389 9.5242Z"
                  fill="white"/>
                <path
                  d="M2.62967 11.5134L2.00758 12.1355C1.78393 12.3591 1.78393 12.7218 2.00758 12.9454L4.14562 15.0834C4.36927 15.3071 4.73186 15.3071 4.95551 15.0834L5.5776 14.4614L2.62967 11.5134Z"
                  fill="white"/>
              </svg>
            </div>
          </Tooltip>
        )
      }
      case 20: {
        return (
          <Tooltip title="Мобильное устройство слежения">
            <div className={classes.cellType}>
              <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.0029 2.06638H5.23595V1.07007C5.23595 0.887631 5.08807 0.739746 4.90563 0.739746H2.07403C1.8916 0.739746 1.7437 0.887631 1.7437 1.07007V2.06638H0.517338C0.334896 2.06638 0.187012 2.21426 0.187012 2.3967V10.3378C0.187012 10.5202 0.334896 10.6681 0.517338 10.6681H4.24765H10.2726H14.0029C14.1854 10.6681 14.3332 10.5202 14.3332 10.3378V2.3967C14.3332 2.21426 14.1854 2.06638 14.0029 2.06638ZM7.23986 9.18736C6.08785 9.18736 5.09851 8.49139 4.66899 7.49705C4.52206 7.15695 4.4405 6.78203 4.4405 6.388C4.4405 4.84196 5.69381 3.58863 7.23984 3.58863C8.7859 3.58863 10.0392 4.84196 10.0392 6.388C10.0392 6.76893 9.96301 7.13203 9.82518 7.46306C9.4037 8.47551 8.40497 9.18736 7.23986 9.18736Z"
                  fill="white"/>
                <path
                  d="M9.76343 12.1899C9.87202 12.0586 9.97039 11.9187 10.0576 11.7712H4.46393C4.55109 11.9186 4.64944 12.0586 4.75805 12.1899L0.598338 17.8892C0.51412 18.0045 0.59654 18.1667 0.739378 18.1667H1.78482C1.89684 18.1667 2.00206 18.113 2.06774 18.0222L5.7177 12.9787C5.99675 13.1296 6.30041 13.2408 6.62109 13.3049V17.7995C6.62109 17.9924 6.77745 18.1487 6.97033 18.1487H7.51055C7.70345 18.1487 7.85979 17.9924 7.85979 17.7995V13.3127C8.19546 13.2501 8.51297 13.136 8.80372 12.9786L12.4537 18.0223C12.5194 18.113 12.6246 18.1667 12.7366 18.1667H13.7821C13.9249 18.1667 14.0073 18.0046 13.9231 17.8892L9.76343 12.1899Z"
                  fill="white"/>
              </svg>
            </div>
          </Tooltip>
        )
      }
      default: {
        return (
          <Tooltip title="Тип не получен">
            <div className={classes.cellType} style={{backgroundColor: '#F86C6C'}}><span
              style={{color: 'white'}}>!</span></div>
          </Tooltip>
        )
      }
    }
  }

  return (
    <TableRow
      selected={isSelected}
      onClick={onChangeSelect}
      hover
    >
      <TableCell>
        <CustomCheckbox checked={isSelected} type="circle"/>
      </TableCell>
      <TableCell>{row.address}</TableCell>
      <TableCell>{row.direction}</TableCell>
      <TableCell>{row.vendor}</TableCell>
      <TableCell>{row.serial}</TableCell>
      <TableCell>{_renderType()}</TableCell>
      <TableCell>
        <div className={clsx({
          "tm-round-info": true,
          "--on": Boolean(row.status === 10),
          "--off": Boolean(row.status === 0),
          "--error": Boolean(row.status === 20),
        })}/>
      </TableCell>
    </TableRow>
  )
})
const useStyles = makeStyles(() => ({
  tableRowHeader: {
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 5,
      paddingBottom: 5,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgb(233, 238, 244)',

      '&:hover': {
        backgroundColor: 'rgb(245, 245, 245)'
      }
    },
    '& .Mui-focused': {
      backgroundColor: 'rgb(245, 245, 245)'
    },

    '& .MuiIconButton-root': {
      padding: 0
    }
  },

  cellStatus: {
    width: 22,
    height: 22,
    borderRadius: '100%',
    border: '1px solid rgba(0, 0, 0, 0.1)'
  },
  cellStatusAccess: {
    backgroundColor: '#6FF86C'
  },
  cellStatusError: {
    backgroundColor: '#F86C6C'
  },
  cellStatusDanger: {
    backgroundColor: '#bfbfbf'
  },

  tableBodyAddress: {
    maxWidth: 300
  },

  rowCheckBox: {
    width: 45,

    '& .MuiIconButton-root': {
      padding: 0
    }
  },
  rowAddress: {
    width: '25%'
  },
  rowDirection: {
    width: '20%'
  },
  rowTitle: {
    width: '15%'
  },
  rowType: {
    width: '15%',
    maxWidth: 180
  },
  rowVendor: {
    width: '15%'
  },
  rowSerial: {
    width: '15%'
  },
  rowStatus: {
    width: 60
  },

  filterSelect: {
    width: '100%',
    maxWidth: '100%',

    '& .MuiInputBase-root': {
      paddingRight: '30px!important'
    },
    '& .MuiSelect-root': {
      padding: 5,
      paddingLeft: 15
    }
  },
  cellType: {
    borderRadius: '100%',
    backgroundColor: '#2979FF',
    border: '1px solid rgba(21, 36, 53, 0.1)',
    padding: 5,
    boxSizing: 'border-box',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: 35,
    height: 35
  },

  rowTypeInfo: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    flexWrap: "nowrap",

    "& img": {
      width: 26,
      height: 26,
      borderRadius: "100%",
      background: palette.primary.main,
      marginRight: 6
    }
  },
}));

TableComponents.propTypes = {
  rows: PropTypes.array,
  selected: PropTypes.array,
  isSelectRowTable: PropTypes.func
};

export default React.memo(TableComponents);
