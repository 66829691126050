export default [
	{label: "Отчет по проездам", value: "report_full"},
	{label: "Отчет по количеству потока по часам", value: "sensor_hour"},
	{label: "Отчет по количеству потока за сутки", value: "sensor_day"},
	{label: "Отчет по времени поступления данных", value: "sensor_delay"},
	{label: "Отчет по потоку по загрузке данных в разрезе суток", value: "total_day"},
	{label: "Отчет по отфильтрованному потоку", value: "filter_error"},
	{label: "Отчет по отфильтрованному потоку (агрегированный)", value: "filter_error_aggregated"},
	{label: "Отчет по количеству нарушений за сутки", value: "violation_day"},
	{label: "Отчет о принятых нарушениях (расширенный)", value: "violation_extended"},
	{label: "Отчет по дублям проездов", value: "duplicates"},
]
