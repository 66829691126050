import React, {Component} from 'react';
import {
  Box,
  Grid,
  Divider,
  Typography,
  Collapse,
  Paper,
  Button
} from '@material-ui/core';
import {
  Map as MapIcon
} from "@material-ui/icons";
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,

} from 'react-feather';
import queryString from 'query-string';
import {
  Table,
  Filter,
  ModalDelete,
  ModalInformationEdit,
} from './components/index';
import axios from '../../../plugins/axios';
import {
  Page,
  ModalSensorMap,
  AddNewFavorites,
  PageFilterTableContent,
  ModalSensorCameraStream
} from '../../../components/index';
import {store} from 'react-notifications-component';
import moment from 'moment';
import {checkPointFromPoligon} from '../../../helpers/checkPointPoligon';
import urls from "../../../constant/urls";
import numberFormat from "../../../helpers/numberFormat";

const initFilter = {
  serial: '',
  title: '',
  vendor: '',
  address: '',
  last_report_fix: null,
  last_report: null,
  report_count: '',
  with_cameras: null,

  status: null,
  type: null,
  groups: [],
  page: 1,
  limit: 20
}

class SensorList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          label: 'Серийный',
          field: 'serial',
          align: 'left',
        },
        {
          label: 'Идентификатор',
          field: 'title',
          align: 'left',
        },
        {
          label: 'Модель',
          field: 'vendor',
          align: 'left',
        },
        {
          label: 'Местоположение',
          field: 'address',
          align: 'left',
          width: 300
        },
        {
          label: 'Последняя фиксация',
          field: 'last_report_fix',
          align: 'left',
          type: 'date'
        },
        {
          label: 'Последняя загрузка',
          field: 'last_report',
          align: 'left',
          type: 'date'
        },
        {
          label: 'Статус',
          field: 'status',
          align: 'left',
          type: 'status',
          width: 50
        },
        {
          label: 'Тип устройства',
          field: 'type',
          type: 'type',
          align: 'left',
          width: 60
        },
        {
          label: 'Группа',
          type: 'group_name',
          field: 'group_id',
          align: 'left',
        },
        {
          label: '',
          field: 'action',
          align: 'left',
          type: 'action'
        },
      ],
      selected: [],
      groupList: [],
      showColumns: ['serial', 'title', 'vendor', 'address', 'last_report_fix', 'last_report', 'status', 'group_id', 'type', 'report_count', 'action'],
      devicesSensor: [],
      devicesSensorActive: [],
      sensorMap: [],
      mapPolygons: [],
      sensorCamerasStream: [],

      pagination: {
        rowsPerPage: 20,
        page: 1,
        orderBy: null,
        order: 'asc',
        totalCount: 20
      },
      filters: {...initFilter},
      editInformation: {
        openModal: false,
        url: '',
        title: '',
        description: '',
        count: 0,
        sensors: []
      },

      showCardSensor: false,
      cardSensor: {},
      sensorCameraStream: null,
      sensorsCameraIds: {},

      showModalDelete: false,
      itemModalDelete: {},

      showFilter: false,
      isOpenMap: false,
      isShowOnlyCameras: false,

      filterString: 'sensor'
    }

    this.handleGetListFilter = null;
    this.keySensorCameraStream = 0;
  }

  // инициализация страницы
  componentDidMount = () => {
    let params = queryString.parse(this.props.location.search);

    if (Object.keys(params).length > 0) {
      let filters = this.state.filters

      for (let key in params) {
        filters[key] = params[key]

        if (key === 'groups') {
          filters[key] = params[key].split(',')
        }
        if (key === 'limit') {
          filters[key] = Number(params[key])
        }
        if (key === 'page') {
          filters[key] = Number(params[key])
        }
        if (key === 'last_report_fix' || key === 'last_report') {
          let date = new Date();
          date.setTime(params[key]);

          filters[key] = date
        }
      }

      this.setState({
        filters
      })
    }

    this.getListSensorCamera();
    this.loadSensorList()
    this.loadSensorMap()
    this.loadGroupList()
  }
  componentDidUpdate = (prevProps) => {
    let prevSearch = queryString.parse(prevProps.location.search);
    let currentSearch = queryString.parse(this.props.location.search);

    if (JSON.stringify(prevSearch) !== JSON.stringify(currentSearch)) {
      let params = currentSearch;

      let filters = {
        serial: '',
        title: '',
        vendor: '',
        address: '',
        last_report_fix: null,
        last_report: null,
        report_count: '',

        status: null,
        type: null,
        groups: [],
        page: 1,
        limit: 20
      }
      for (let key in params) {
        filters[key] = params[key]

        if (key === 'groups') {
          filters[key] = params[key].split(',')
        }
        if (key === 'limit') {
          filters[key] = Number(params[key])
        }
        if (key === 'page') {
          filters[key] = Number(params[key])
        }
        if (key === 'last_report_fix' || key === 'last_report') {
          let date = moment(params[key]).unix();

          filters[key] = date
        }
      }
      this.setState({
        filters
      })
      setTimeout(() => {
        this.loadSensorList();
      }, 500)
    }
  }

  // получение списка камер (потоковые)
  getListSensorCamera = () => {
    axios("post", urls["sensor-find-cameras"], {
      page: 1,
      limit: 5000
    }).then((response) => {
      let sensorsCameraIds = {};

      Object.keys(response.data).map((key) => {
        const item = response.data[key];

        if (!sensorsCameraIds[item.sensor_id]) {
          sensorsCameraIds[item.sensor_id] = {
            key: item.sensor_id,
            items: []
          };
        }

        sensorsCameraIds[item.sensor_id]['items'].push(item);
      })
      Object.keys(sensorsCameraIds).map((key) => {
        let item = sensorsCameraIds[key];
        item.items =  item.items.sort((a, b) => {
          if (a.direction < b.direction) { return -1 }
          if (a.direction > b.direction) { return 1 }

          return 0
        })
      });

      this.setState({
        sensorsCameraIds
      })
    });
  }

  // конвертация фильтров со фронта на бек
  getFilter = () => {
    let filter = {};

    for (let key in this.state.filters) {
      let item = this.state.filters[key];

      if (item && key !== 'with_cameras') {
        filter[key] = item;
      }
      if ((key === 'status' || key === 'type') && item != null) {
        filter[key] = Number(item)
      }
      if ((key === 'with_cameras') && item != null) {
        filter[key] = Boolean(item)
      }

    }
    if (this.state.pagination.orderBy) {
      filter['sort_field'] = this.state.pagination.orderBy;
      filter['sort_direction'] = this.state.pagination.order;
    }

    return filter
  }

  // Получение списка устройств
  loadSensorList = () => {
    let filter = this.getFilter()

    axios('post', '/operator/sensor/find', filter).then((response) => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;

      this.setState({
        rows,
        pagination
      })
      this.setDevicesSensor()
    }).catch((error) => {
    })
  }
  //Получение списка груп устройств
  loadGroupList = () => {
    let filter = {
      limit: 999,
      page: 1
    };

    axios('post', '/operator/sensor-group/find', filter).then((response) => {
      let groupList = response.data;

      if (!groupList) {
        groupList = []
      }

      this.setState({
        groupList
      })
    })
  }
  // Получение списка устройств для отображения на карте
  loadSensorMap = () => {
    let filter = {
      limit: 999,
      page: 1
    }

    axios('post', '/operator/sensor/find', filter).then((response) => {
      let rows = response.data;
      if (!rows) {
        rows = []
      }
      this.setState({
        sensorMap: rows
      })
    }).catch((error) => {
    })
  }

  //
  setDevicesSensor = () => {
    let devicesSensor = []

    this.state.rows.map(row => {
      devicesSensor.push({
        id: row.id,
        title: row.title,
        gps: row.gps,
      })
    })

    this.setState({
      devicesSensor
    })
  }

  // Логика работый с фильтрами
  setFilterToUrl = () => {
    let filter = []

    for (let key in this.state.filters) {
      let item = this.state.filters[key];

      if (key === 'groups') {
        if (item.length <= 0) {
          item = null
        }
      }
      if (item && item !== '' && key !== 'status' && key !== 'type') {
        filter.push(key + '=' + item);
      }

      if ((key === 'status' || key === 'type') && item != null) {
        filter.push(key + '=' + item)
      }
    }

    this.setState({
      filterString: 'sensor?' + filter.join('&')
    })

    this.props.history.replace('sensor?' + filter.join('&'))
  }
  changeFilters = (filters) => {
    clearTimeout(this.handleGetListFilter)

    filters['page'] = 1;

    this.setState({filters})

    this.handleGetListFilter = setTimeout(() => {
      this.setFilterToUrl()
    }, 500)
  }
  changeFiltersTable = (filters) => {
    clearTimeout(this.handleGetListFilter)

    this.setState({filters})

    this.handleGetListFilter = setTimeout(() => {
      this.loadSensorList()
      this.setFilterToUrl()
    }, 500)
  }
  changeShowOnlyCameras = (isShowOnlyCameras) => {
    this.setState({ isShowOnlyCameras }, () => {

    });
  }
  resetFilter = async () => {
    clearTimeout(this.handleGetListFilter);
    await this.setState({ filters: {...initFilter} });
    this.setFilterToUrl();
  }

  changePage = async (newPage) => {
    clearTimeout(this.handleGetListFilter);
    let filters = {...this.state.filters};
    filters.page = newPage;
    await this.setState({ filters });
    this.setFilterToUrl();
  }
  // -----------------------

  // Логика работы с таблицей (выбор, удаление, закрыти)
  isSelectRowTable = (selected) => {
    this.setState({
      selected
    })
  }
  isDeleteRowTable = (props) => {
    let {consent, item} = props;

    if (!consent) {
      this.setState({
        showModalDelete: true,
        itemModalDelete: item
      })

      return null
    }

    this.setState({
      showModalDelete: false
    })

    store.addNotification({
      title: 'Успешно',
      message: 'Запись удалена',
      type: 'success',
      insert: 'top',
      container: 'bottom-left',
      dismiss: {
        duration: 3000,
        onScreen: false,
        pauseOnHover: true,
        delay: 0
      }
    });
  }
  isCloseDeleteModal = () => {
    this.setState({
      showModalDelete: false,
      itemModalDelete: {}
    })
  }
  isChangeRowPage = (pagination) => {
    this.setState({
      pagination
    })

    this.loadSensorList()
  }
  // ------------------

  isChangeSettings = (showColumns) => {
    this.setState({
      showColumns
    })
  }

  // Локига работы с устройствами на карте
  getSensorsMap = () => {
    let enableFilter = false;
    let filters = {...this.state.filters};

    delete filters['limit']
    delete filters['page']

    for (let key in filters) {
      let item = filters[key];

      if (item) {
        if (typeof item == 'object' && Object.keys(item).length > 0) {
          enableFilter = true
        }
      }
      if (key === 'with_cameras' && item) {
        enableFilter = true;
      }
    }

    if (enableFilter) {
      return this.state.rows
    } else {
      return this.state.sensorMap
    }
  }
  clickSensorMap = (sensor) => {
    this.setState({
      cardSensor: sensor,
      showCardSensor: true
    })
  }
  sensorCardEdit = (sensor) => {
    this.props.history.push('/sensor/edit/' + sensor.id)
  }
  // ------------------------


  onRouteMultiEdit = (selected = this.state.selected) => {
    let selectedGroupEmpty = true;
    let countSelectedGroup = 0;
    let selectedGroupList = [];

    let url = '/sensor/edit/' + selected.join(', ');

    if (selected.length === 1) {
      this.props.history.push(url)
    }

    selected.map(deviceId => {
      let sensor = this.onCheckDeviceGroup(deviceId);
      if (sensor) {
        selectedGroupEmpty = false;
        countSelectedGroup++;
        selectedGroupList.push(sensor)
      }
    })
    if (!selectedGroupEmpty) {
      this.setState({
        editInformation: {
          openModal: true,
          url: url,
          title: 'Предупреждение',
          description: `Вы точно хотите изменить группы, у выбранных вами ${countSelectedGroup} устройств уже имеется группа. При редактировании данные перезапишутся`,
          count: countSelectedGroup,
          sensors: [...selectedGroupList]
        },
      })
    } else {
      url = '/sensor/edit/' + selected.join(', ')
      this.props.history.push(url)
    }
  }
  onCheckDeviceGroup = (deviceId) => {
    let device = this.state.sensorMap.find(device => device.id === deviceId);

    if (
      device.group_id &&
      device.group_id != '' &&
      device.group_id != '000000000000000000000000'
    ) {
      let group = this.state.groupList.find(group => group.id === device.group_id);
      device['group_title'] = group.Title

      return device
    }

    return null
  }
  onOpenInformationEditSensor = () => {
  }
  onCloseInformationEditSensor = () => {
    this.setState({
      editInformation: {
        openModal: false,
        url: '',
        title: '',
        description: '',
        count: 0,
        sensors: []
      },
    })
  }
  onChangeInformation = (selected) => {
    this.setState({selected})

    this.onRouteMultiEdit(selected)
  }

  // Изменение границы выбора камер на карте
  onChangeMapPolygons = (mapPolygons) => {
    let listPolygon = mapPolygons;
    let listSensor = this.state.sensorMap;
    let newSelected = [];

    listPolygon.map(polygon => {
      let list = [];

      polygon.map(point => {
        list.push(point)
      })

      listSensor.map(point => {
        point['lat'] = point['gps']['lat'];
        point['lng'] = point['gps']['lon'];

        let checkPointPoligon = checkPointFromPoligon(list, point);

        if (checkPointPoligon) {
          newSelected.push(point.id)
        }
      })
    })

    this.setState({
      mapPolygons,
      selected: newSelected
    });
  }


  onAddCameraDashboard = (props) => {}

  // Запуск камеры
  onOpenCamera = (sensorCameraStream) => {
    let newSensorCamerasStream = [...this.state.sensorCamerasStream];
    newSensorCamerasStream.push({
      ...sensorCameraStream,
      key: this.keySensorCameraStream
    });
    this.setState({
      sensorCamerasStream: newSensorCamerasStream
    })
    this.keySensorCameraStream++
  }
  onCloseCamera = (key) => {
    let newSensorCamerasStream = [...this.state.sensorCamerasStream];
    newSensorCamerasStream.splice(newSensorCamerasStream.findIndex(item => item.key === key), 1);

    this.setState({
      sensorCamerasStream: newSensorCamerasStream
    })
  }
  // ---------------


  openDialogMap = () => {
    this.setState({ isOpenMap: true });
  }

  render() {
    const { sensorCameraStream, sensorCamerasStream, sensorsCameraIds, pagination, filters } = this.state;
    const { app } = this.props;
    const { user } = app;
    const roleSensorStream = user.roles.admin || user.roles['sensor.stream'];

    return (
      <>
        <PageFilterTableContent
          currentPage={Number.parseFloat(filters?.page)}
          totalPage={Math.ceil(Number.parseFloat(pagination?.totalCount || '1')/pagination.rowsPerPage)}

          onChangePage={this.changePage}
          onResetFilter={this.resetFilter}
        >
          <slot name="head">
            <ComponentHead
              totalCount={pagination.totalCount}
              onOpenMap={this.openDialogMap}
            />
          </slot>
          <slot name="body">
            <Table
              columns={this.state.columns}
              rows={this.state.rows}
              showColumns={this.state.showColumns}
              selected={this.state.selected}
              pagination={this.state.pagination}
              filters={this.state.filters}
              roleSensorStream={roleSensorStream}
              groupList={this.state.groupList}
              sensorsCameraIds={sensorsCameraIds}

              onRouteMultiEdit={this.onRouteMultiEdit}

              isChangeFilter={this.changeFiltersTable}
              isSelectRowTable={this.isSelectRowTable}
              isDeleteRowTable={this.isDeleteRowTable}
              isChangeRowPage={this.isChangeRowPage}
              isChangeSettings={this.isChangeSettings}

              onOpenSensorCameraStream={this.onOpenCamera}
            />
          </slot>
          <slot name="filter">
            <Filter
              filters={this.state.filters}
              groupList={this.state.groupList}
              isShowOnlyCameras={this.state.isShowOnlyCameras}
              roleSensorStream={roleSensorStream}
              onChange={this.changeFilters}
              changeShowOnlyCameras={this.changeShowOnlyCameras}
            />
          </slot>
        </PageFilterTableContent>

        <ModalDelete
          item={this.state.itemModalDelete}
          open={this.state.showModalDelete}
          isDelete={this.isDeleteRowTable}
          isClose={() => this.isCloseDeleteModal()}
        />

        <ModalSensorMap
          isOpen={this.state.isOpenMap}
          onClose={() => {
            this.setState({isOpenMap: false})
          }}
          onClick={(sensor) => this.clickSensorMap(sensor)}
          sensors={this.getSensorsMap()}
          selected={this.state.selected}
          sensorCameraStream={sensorCameraStream}
          sensorsCameraIds={sensorsCameraIds}
          toEditFromMap={() => this.onRouteMultiEdit()}

          onCloseCard={() => this.setState({ showCardSensor: false })}
          showCardSensor={this.state.showCardSensor}
          cardSensor={this.state.cardSensor}
          onCardEdit={this.sensorCardEdit}
          roleSensorStream={roleSensorStream}

          mapPolygons={this.state.mapPolygons}
          onChangeMapPolygons={this.onChangeMapPolygons}
          onOpenSensorCameraStream={this.onOpenCamera}
        />

        <ModalInformationEdit
          open={this.state.editInformation.openModal}
          selected={this.state.selected}
          onClose={this.onCloseInformationEditSensor}
          information={this.state.editInformation}
          onChangeInformation={this.onChangeInformation}
          onChangeSelected={selected => this.setState({selected})}
        />

        {
          sensorCamerasStream.map((sensorCameraStream, idx) => (
            <ModalSensorCameraStream
              key={idx}
              isOpen={true}
              sensor={sensorCameraStream}

              onClose={() => this.onCloseCamera(sensorCameraStream.key)}
              onAddCameraDashboard={this.onAddCameraDashboard}
            />
          ))
        }
      </>
    )
  }
};
const ComponentHead = React.memo((props) => {
  const {
    totalCount,

    onOpenMap
  } = props;

  return (
    <Grid container justify="space-between" style={{ marginBottom: "12px" }}>
      <Grid item>
        <Typography variant="h1">Комплексы: {numberFormat(totalCount)}</Typography>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="contained"
          startIcon={<MapIcon/>}
          onClick={onOpenMap}
        >
          Показать на карте
        </Button>
      </Grid>
    </Grid>
  )
});

export default SensorList
