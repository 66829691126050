import palette from "../palette";

export default {
  root: {
    padding: "16px",
    borderRadius: "16px",
  },
  standardInfo: {
    backgroundColor: "#1881E1",
  },
  standardError: {
    backgroundColor: "#E13118"
  },
  standardWarning: {
    backgroundColor: "#F57C00"
  },
  standardSuccess: {
    backgroundColor: "#62AF3E"
  },

  icon: {
    "& .MuiSvgIcon-root": {
      color: "white"
    }
  },
  message: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "120%",
    letterSpacing: "-0.02em",
    color: "#FFFFFF",

    "& .MuiTypography-root": {
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "120%",
      letterSpacing: "-0.02em",
      color: "#FFFFFF",
    }
  }
}
