import React, {useRef, useState} from 'react';
import {
  CircularProgress
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import './index.scss';


const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: '100%'
  },

  loader: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 5,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  image: {
    maxWidth: '100%',
    cursor: 'zoom-in'
  }
}));

const Image = (props) => {
  const {
    src,
    alt,
    classContainer,
    classLoader,
    classImage,

    name,

    images,
    activeIndex
  } = props;
  const imageRef = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(activeIndex);

  const classes = useStyles();

  const handleErrorImage = () => {
    imageRef.current.hidden = true
  }
  const handleSuccessImage = () => {
    imageRef.current.hidden = false
    setShowLoader(false)
  }

  const openLightbox = () => {

    if ((images || []).length <= 0) {
      setIsOpen(true);

      return null
    }


    const activeIndex = images.findIndex((t) => t === src);
    setImageIndex(activeIndex);
    setIsOpen(true);
  }

  return (
    <div
      ref={imageRef}
      className={classNames(classes.container, classContainer)}
      filterName="image"
    >
      <img
        src={src}
        alt={alt}
        className={classNames(classes.image, classImage)}
        onLoad={handleSuccessImage}
        onError={handleErrorImage}
        onClick={openLightbox}
        name={name}
      />

      {
        ( showLoader ) && (
          <div className={classNames(classes.loader, classLoader)}>
            <CircularProgress color="inherit" />
          </div>
        )
      }


      {Boolean(isOpen) && (
          <>
            {Boolean((images || []).length > 0) ? (
                <Lightbox
                    mainSrc={images[imageIndex]}
                    nextSrc={images[(imageIndex + 1)]}
                    prevSrc={images[(imageIndex - 1)]}

                    onCloseRequest={() => { setIsOpen(false) }}
                    onMovePrevRequest={() => setImageIndex(imageIndex - 1)}
                    onMoveNextRequest={() => setImageIndex(imageIndex + 1)}
                />
            ) : (
                <Lightbox
                    mainSrc={src}
                    onCloseRequest={() => { setIsOpen(false) }}
                />
            )}
          </>
      )}
    </div>
  )
}

export default Image
