import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Typography,
	IconButton,
	Box,
	Grid, Tooltip
} from "@material-ui/core";
import {
	Skeleton,
	Pagination
} from "@material-ui/lab";
import {
	Download as DownloadIcon

} from "react-feather";
import exportTypes from "../../../../../constant/exportTypes";
import moment from "moment";
import {formatMoney} from "../../../../../helpers/format";

const statusTask = {
	"0": "В очереди",
	"-1": "Завершена с ошибкой",
	"10": "Выполняется",
	"20": "Готова",
};

const TableComponent = (props) => {
	const {rows, filter, pagination, isLoading, onChangeFilter, onDownloadFile} = props;

	const handleChangePagination = (event, page) => {
		let newFilter = {...filter};

		newFilter.page = page;

		onChangeFilter(newFilter, true);
	}

	return (
		<>
			<Box>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Тип файла</TableCell>
							<TableCell>Статус задачи</TableCell>
							<TableCell>Дата создания</TableCell>
							<TableCell>Дата начала</TableCell>
							<TableCell>Дата завершения</TableCell>
							<TableCell width={50} align="right"></TableCell>
						</TableRow>
					</TableHead>

					{
						isLoading ? (
							<TableBody>
								<TableRow style={{borderBottom: "1px solid #e5ebf1"}}>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton width={30} height={30}/></TableCell>
								</TableRow>
								<TableRow style={{borderBottom: "1px solid #e5ebf1"}}>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton width={30} height={30}/></TableCell>
								</TableRow>
								<TableRow style={{borderBottom: "1px solid #e5ebf1"}}>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton width={30} height={30}/></TableCell>
								</TableRow>
								<TableRow style={{borderBottom: "1px solid #e5ebf1"}}>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton width={30} height={30}/></TableCell>
								</TableRow>
								<TableRow style={{borderBottom: "1px solid #e5ebf1"}}>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton/></TableCell>
									<TableCell><Skeleton width={30} height={30}/></TableCell>
								</TableRow>
							</TableBody>
						) : (
							<TableBody>
								{
									rows.map((row, idx) => (
										<TableRow key={`export-task-${idx}`} style={{borderBottom: "1px solid #e5ebf1"}}>
											<TableCell>{exportTypes[row.type]}</TableCell>
											<TableCell>{statusTask[row.status]}</TableCell>
											<TableCell>
												{
													Boolean(row.created) ? (
														<>
															{moment(row.created).format("DD.MM.YYYY")}<br/>
															{moment(row.created).format("HH:mm:ss")}
														</>
													) : "—"
												}
											</TableCell>
											<TableCell>
												{
													Boolean(row.started) ? (
														<>
															{moment(row.started).format("DD.MM.YYYY")}<br/>
															{moment(row.started).format("HH:mm:ss")}
														</>
													) : "—"
												}
											</TableCell>
											<TableCell>
												{
													Boolean(row.completed) ? (
														<>
															{moment(row.completed).format("DD.MM.YYYY")}<br/>
															{moment(row.completed).format("HH:mm:ss")}
														</>
													) : "—"
												}
											</TableCell>
											<TableCell>
												<Tooltip title="Скачать файл">
													<IconButton
														disabled={row.status !== 20}
														onClick={() => onDownloadFile(row)}
													>
														<DownloadIcon/>
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))
								}
							</TableBody>
						)
					}
				</Table>
			</Box>
			<Box mt={2}>
				<Grid container justify="flex-end">
					<Grid item>
						<Pagination
							page={filter.page}
							count={pagination.page}

							onChange={handleChangePagination}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	)
};

export default TableComponent
