import React from 'react';
import MomentUtils from '@date-io/moment';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import 'moment/locale/ru';
import moment from 'moment';

moment.locale("ru");

const DateTimePicker = (props) => {
  const {value, label, onChange, ...other} = props

  const handleDateChange = (date) => {
    if ( date ){
      if( date._isValid ){
        onChange(date)
      }
    }else{
      onChange(date)
    }
  }

  return (
    <MuiPickersUtilsProvider
      locale={'ru'}
      libInstance={moment}
      utils={MomentUtils}
    >
      <KeyboardDateTimePicker
        {...other}

        autoOk
        ampm={false}
        variant="inline"
        inputVariant="outlined"
        label={label}
        placeholder='01.01.2000 00:00'
        format="DD.MM.YYYY HH:MM"
        mask={'__.__.____ __:__'}
        value={value}
        InputAdornmentProps={{position: 'end'}}
        onChange={date => handleDateChange(date)}
        size={'small'}
        fullWidth
        minDateMessage={'Введена дата меньше разрешимой'}
        maxDateMessage={'Введена дата больше разрешимой'}
        invalidDateMessage={'Введена недопустимая дата'}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DateTimePicker
