import React, { useState } from 'react';
import {
  Button,
  Tooltip
} from '@material-ui/core';
import Modal from './Modal';
import {SaveAltRounded as SaveAltRoundedIcon} from '@material-ui/icons';

const ExportCreate = (props) => {
  const { onExportFile, children } = props;
  const [isOpenModal, setOpenModal] = useState(false);

  const handleExportFile = function (body) {
    setOpenModal(false)

    onExportFile(body)
  }

  return (
    <div>
      <Tooltip title="Постановка задач на экспорт материалов">
        <div onClick={() => { setOpenModal(true) }}>
          {children}
        </div>
      </Tooltip>

      <Modal
        open={isOpenModal}
        onClose={() => { setOpenModal(false) }}
        onExportFile={handleExportFile}
      />
    </div>
  )
}

export default ExportCreate
