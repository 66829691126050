import palette from '../palette';

export default {
  root: {
    "& .MuiIconButton-label": {
      width: 24,
      height: 24,
      backgroundColor: "#FFF",
      border: "1px solid #E0E2E8",
      borderRadius: "100%",
      overflow: "hidden",

      "& svg": {
        opacity: 0
      }
    },
    "&.Mui-checked .MuiIconButton-label": {
      borderColor: palette.primary.main,
      backgroundColor: palette.primary.main,
      "&:after": {
        content: "''",
        width: "80%",
        height: "80%",
        position: "absolute",
        top: '50%', left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundImage: `url(${require('../../assets/icons/checkbox-checked.svg')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "50%",
        filter: "invert(1)"
      }
    }
  },
  _root: {
    '& .MuiIconButton-label': {
      '& svg': {
        backgroundColor: 'rgba(194, 207, 224, 0.2)',
        borderRadius: 4,
      },
      '& path': {
        display: 'none'
      }
    },
    '&$checked': {
      '& .MuiIconButton-label': {
        '& svg': {
          backgroundColor: palette.primary.main,
          stroke: palette.white,
          padding: 3
        },
        '& path': {
          display: 'block'
        }
      },
    }
  },
};
