import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import {} from "@material-ui/styles";

const initStage = {
	title: "",
	message: "",
	labelSuccess: "",
	labelCancel: "",
	onSuccess: null,
	onCancel: null,

	isOpen: false
}

class DialogRefConfirmation extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {...initStage};
	};

	open = ({ title, message, labelSuccess, labelCancel, onSuccess, onCancel }) => {
		this.setState({
			title,
			message,
			labelCancel,
			labelSuccess,

			onCancel,
			onSuccess,

			isOpen: true
		})
	}
	close = () => {
		this.setState({...initStage});
	}

	cancel = () => {
		const { onCancel } = this.state;
		this.close();
		if (!onCancel) {
			return
		}
		onCancel();
	}
	success = () => {
		const { onSuccess } = this.state;
		this.close();
		if (!onSuccess) {
			return
		}
		onSuccess();
	}

	render() {
		const {
			title,
			message,
			labelCancel,
			labelSuccess,

			isOpen
		} = this.state;

		return (
      <Dialog
	      open={isOpen}
	      fullWidth
	      maxWidth="sm"
      >
	      <DialogTitle>
		      <Typography variant="h3">
			      { title || "Подтверждение" }
		      </Typography>
	      </DialogTitle>
	      <DialogContent>
		      <Typography
			      variant="h5"
			      dangerouslySetInnerHTML={{
							__html: message || ""
			      }}
		      />
	      </DialogContent>
	      <DialogActions>
		      <Box px={2} pb={1}>
			      <Grid container justify="flex-end" spacing={1}>
				      <Grid item>
					      <Button
						      size="small"
						      variant="outlined"
						      onClick={this.cancel}
					      >{labelCancel || 'Отменить'}</Button>
				      </Grid>
				      <Grid item>
					      <Button
						      size="small"
						      variant="contained"
						      onClick={this.success}
					      >{labelSuccess || 'Подтвердить'}</Button>
				      </Grid>
			      </Grid>
		      </Box>
	      </DialogActions>
      </Dialog>
		)
	}
}

export default DialogRefConfirmation
