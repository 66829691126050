import React, { Component } from 'react';
import {getLocalStorage} from '../../helpers/localStorage';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {
  setUser
} from '../account/Account/AccountState';

class Home extends Component{
  constructor(props) {
    super(props);

  }

  componentDidMount = () => {
    let urlRedirect = '/authorization';
    let sessionId = getLocalStorage('session_id');

    if (sessionId){
      urlRedirect = '/dashboard'
    }


    this.props.history.replace(urlRedirect);
  }

  render() {
    return (
      <div/>
    );
  }
}

export default compose(
  connect(
    state => ({
      dashboard: state.dashboard
    }),
    dispatch => ({
      setUser: (user) => dispatch(setUser(user))
    }),
  ),
)(Home);
