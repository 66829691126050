export default {
    "admin": "Администратор",
    "user.base": "Просмотр списка пользователей",
    "user.edit": "Создание/редактирование пользователей",
    "sensor.base": "Доступ к разделу устройств, создание\\редактирование устройств, групп устройств",
    "notifications.base": "Получение уведомлений",
    "new_1": "Доступ к разделу \"Логи нарушений\"",
    "new_2": "Доступ к разделу \"Отчеты\"",
    "new_3": "Доступ к разделу \"Точки назначения\"",
}
