import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	Typography, Backdrop, CircularProgress, makeStyles
} from "@material-ui/core";
import {
	Page,
	DialogConfirmation, WithoutAccess, PageFilterTableContent
} from "../../../components";
import {getPageFromCount} from "../../../common/pagination";
import {
	Filter,
	Table
} from "./components";
import axios from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {sendMessages} from "../../../common/messages";

const initialFilter = {
	email: "",
	username: "",
	full_name: "",
	roles: [],

	page: 1,
	limit: 20
};

class UsersList extends Component {
	constructor(props) {
		super(props);

		const isPermissions = props.app.user.roles.admin || props.app.user.roles['user.base'];

		this.state = {
			rows: [],

			filter: {...initialFilter},
			pagination: {
				page: 1
			},
			deleteUser: null,

			isLoading: true,
			isShowBackdrop: false,
			isPermissions: isPermissions,
			isOpenDialogConfirmation: false
		};

		this.refFilter = React.createRef();
	}

	// инициализация страницы
	componentDidMount = () => {
		this.getListUsers();
	}

	// получение списка пользователя
	getListUsers = () => {
		this.setState({isLoading: true});

		const filter = this.getFilter();

		axios('post', urls["user-get-list"], filter).then((response) => {
			const {data, headers} = response;

			let pagination = {...this.state.pagination};
			pagination.page = getPageFromCount(headers['x-pagination-total-count'], this.state.filter.limit);

			this.setState({
				rows: data || [],
				pagination: pagination,

				isLoading: false
			})
		});
	}

	// конвертация фильтров со фронта на бекенд
	getFilter = () => {
		return this.state.filter
	}
	resetFilter = async () => {
		await this.setState({ filter: {...initialFilter} });
		this.getListUsers();
	}

	// изменение фильтра
	onChangeFilter = (filter, isFastStart) => {
		this.setState({filter}, () => {
			if (isFastStart) {
				this.getListUsers();
			}
		});
	}
	changePage = async (newPage) => {
		let filter = {...this.state.filter};
		filter.page = newPage;
		await this.setState({ filter });

		this.getListUsers();
	}

	// функционал удаления пользователя (удаляется после подтверждения)
	onDeleteUser = (user = this.state.deleteUser, isConfirm) => {
		if (!isConfirm) {
			this.setState({
				deleteUser: user,
				isOpenDialogConfirmation: true
			});

			return null
		}

		this.setState({isOpenDialogConfirmation: false, deleteUser: null, isShowBackdrop: true});

		axios('get', `${urls['user-delete']}/${user.id}`).then(() => {
			this.getListUsers();
			sendMessages({
				message: "Пользователь успешно удален",
				type: "success"
			});

			this.setState({isShowBackdrop: false})
		})
	}

	// функция перехода на страницы создания пользователя
	_routeCreateUser = () => {
		this.props.history.push('/users/created')
	}

	render() {
		const {
			rows, filter, pagination, isLoading,
			isOpenDialogConfirmation, deleteUser,
			isPermissions
		} = this.state;

		if (!isPermissions) {
			return (
				<WithoutAccess/>
			)
		}

		console.log('this.state: ', this.state);

		return (
			<>

				<PageFilterTableContent
					page={filter.page}
					totalPage={pagination.page}
					onResetFilter={this.resetFilter}
					onChangePage={this.changePage}
				>
					<slot name="head">
						<HeadElement
							onCreateUser={this._routeCreateUser}
						/>
					</slot>
					<slot name="body">
						<Table
							rows={rows}
							filter={filter}
							pagination={pagination}
							isLoading={isLoading}

							onChangeFilter={this.onChangeFilter}
							onDeleteUser={this.onDeleteUser}
						/>
					</slot>
					<slot name="filter">
						<Filter
							filter={filter}
							initialFilter={initialFilter}

							onChange={this.onChangeFilter}
							onSearch={this.getListUsers}
						/>
					</slot>
				</PageFilterTableContent>

				<DialogConfirmation
					title={`Удаление пользователя`}
					comment={`Вы действительно хотите удалить пользователя "${deleteUser?.full_name}"? После удаления результат необратим`}
					buttonConfirmation="Удалить"

					isOpen={isOpenDialogConfirmation}

					onClose={() => this.setState({isOpenDialogConfirmation: false})}
					onConfirmation={() => this.onDeleteUser(deleteUser, true)}
				/>

				<Backdrop open={this.state.isShowBackdrop} invisible={this.state.isShowBackdrop}>
					<CircularProgress size={80} style={{color: 'white'}}/>
				</Backdrop>

			</>
		)
	}
};
const HeadElement = (props) => {
	const {
		onCreateUser
	} = props;
	const classes = useStyles();
	return (
		<Box className={classes.headElement}>
			<Grid container alignItems="center" justify="space-between">
				<Grid item>
					<Typography variant="h1">Пользователи</Typography>
				</Grid>
				<Grid item>
					<Button
						size="small"
						color="primary"
						variant="outlined"
						className={classes.headElementButton}
						onClick={onCreateUser}
					>Добавить пользователя</Button>
				</Grid>
			</Grid>
		</Box>
	)
};

const useStyles = makeStyles(() => ({
	headElement: {
		marginBottom: "16px"
	},
	headElementButton: {
		borderRadius: "8px",

    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: "-0.02em",
    color: "#152435",
  }
}));

export default UsersList
