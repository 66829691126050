import React from "react";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography
} from "@material-ui/core";
import {
  Pagination
} from "@material-ui/lab";
import {
  makeStyles
} from "@material-ui/styles";
import {
  ChevronLeft as ChevronLeftIcon
} from "@material-ui/icons";
import PropTypes from 'prop-types';
import clsx from "clsx";
import palette from "../../theme/palette";

const FilterTemplate = (props) => {
  const {
    title,
    filter,
    currentPage,
    totalPages,
    onReset,

    onChange,
    onChangePage,
    onChangeClose
  } = props;
  const classes = useStyles();
  const [isFullWidth, setFullWidth] = React.useState(true);

  const handleChangePage = (event, page) => {
    let newFilter = {...filter};
    newFilter.page = page;

    if (onChange) {
      onChange(newFilter, true);
    }
    if (onChangePage) {
      onChangePage(page);
    }
  }
  const handleChangeFullWidth = (val) => {
    setFullWidth(val);
    if (onChangeClose) {
      onChangeClose(val);
    }
  }

  return (
    <Box
      className={clsx({
        [classes.root]: true,
        'close': !isFullWidth,
      })}
    >
      <VisibleContent visible={isFullWidth}>
        <Typography className={classes.title}>{ title }</Typography>
        <Grid className={classes.actions} container spacing={2}>
          <Grid item>
            <Button variant="text" size="small" onClick={onReset}>Сбросить всё</Button>
          </Grid>
        </Grid>
        <Box className={classes.content}>
          { props.children }
        </Box>
        <IconButton className={classes.buttonClose} color="primary" onClick={() => handleChangeFullWidth(false)}>
          <ChevronLeftIcon/>
        </IconButton>

        <VisibleContent visible={Boolean((totalPages || 1) > 1)}>
          <Box className={classes.pagination}>
            <Pagination
              page={filter?.page || currentPage}
              count={totalPages}
              siblingCount={1}
              shape="rounded"
              onChange={handleChangePage}
            />
          </Box>
        </VisibleContent>
      </VisibleContent>
      <VisibleContent visible={!isFullWidth}>
        <div
          className={classes.buttonOpenFull}
          onClick={() => handleChangeFullWidth(true)}
        >
          <ChevronLeftIcon/>
          <span>{title || 'Фильтр'}</span>
          <ChevronLeftIcon/>
        </div>
      </VisibleContent>
    </Box>
  )
}
const VisibleContent = React.memo((props) => (
  <>
    {(props.visible) ? props?.children || null : null}
  </>
));

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 64px - 32px - 38px - 16px)",
    width: "100vw",
    maxWidth: "400px",
    borderRadius: '10px',
    background: '#F4F6F8',
    padding: '20px',
    boxSizing: 'border-box',
    position: "sticky",
    overflowY: "auto",
    top: '74px',

    "&.close": {
      display: "flex",
      alignItems: "center",
      maxWidth: 40,
      padding: 0
    }
  },
  title: {
    marginBottom: 8,

    color: '#000',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '120%',
    letterSpacing: '-0.36px',
    textTransform: 'uppercase',
  },
  actions: {
    marginBottom: 24
  },
  content: {},
  buttonClose: {
    position: "absolute",
    top: 7, right: 0
  },
  buttonOpenFull: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    cursor: "pointer",
    padding: "10px 0",

    "& span": {
      verticalAlign: 'top',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(-90deg)',

      color: '#8698B1',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '100%',
      letterSpacing: '1.28px',
      textTransform: 'uppercase',
      whiteSpace: "nowrap"
    },
    "& svg": {
      color: palette.primary.main,
      transform: "rotate(180deg)"
    }
  },
  pagination: {
    background: 'white',
    margin: "-20px",
    marginTop: "auto",
    padding: "10px"
  },
}));

FilterTemplate.propTypes = {
  title: PropTypes.string,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,

  onReset: PropTypes.func,
  onChangePage: PropTypes.func,
};

export default React.memo(FilterTemplate)
