import React, { useState } from 'react';
import {
  Button,
  Tooltip
} from '@material-ui/core';
import Modal from './Modal';
import {
  GetAppOutlined as GetAppOutlinedIcon
} from '@material-ui/icons';

const ExportCreate = (props) => {
  const {
    disabled,
    onExportFile
  } = props;
  const [isOpenModal, setOpenModal] = useState(false);

  // event об запуске создания файла экспорта
  const handleExportFile = function (body) {
    setOpenModal(false)

    onExportFile(body)
  }

  return (
    <div>
      <Tooltip arrow title="Постановка задач на экспорт материалов">
        <Button variant="outlined" color="primary" size="small" onClick={() => setOpenModal(true)}>
          <GetAppOutlinedIcon style={{ marginRight: 8 }}/>
          Экспорт
        </Button>
      </Tooltip>

      <Modal
        open={isOpenModal}
        onClose={() => { setOpenModal(false) }}
        onExportFile={handleExportFile}
      />
    </div>
  )
}

export default ExportCreate
