import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Checkbox,
	Typography,
	IconButton,
	FormControlLabel
} from "@material-ui/core";
import {
	withStyles,
	makeStyles
} from "@material-ui/styles";
import {
	Close as CloseIcon
} from "@material-ui/icons";
import palette from "../../../../theme/palette";
import clsx from "clsx";
import {Map, Table} from "../index";
import {trim} from "core-js/internals/string-trim";
import {checkPointFromPoligon} from "../../../../helpers/checkPointPoligon";

class ModalDevices extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			fullItems: [],
			selected: [],
			polygons: [],

			filter: {},

			view: "table",

			isOpen: false,
			isVisibleSelected: false
		}
	}

	componentDidUpdate = async (prevProps) => {
		if (this.props.open && prevProps.open !== this.props.open) {
			this.initFullProps();
		}
		if (!this.props.open && prevProps.open !== this.props.open) {
			this.setState({ isOpen: false });
		}
		if (this.props.open && JSON.stringify(prevProps.devicesSensor) !== JSON.stringify(this.props.devicesSensor)) {
			this.initFullProps();
		}
		if (this.props.open && JSON.stringify(prevProps.devicesSensorActive) !== JSON.stringify(this.props.devicesSensorActive)) {
			this.setState({ selected: this.props.devicesSensorActive });
		}
	}

	initFullProps = () => {
		let items = this.props.devicesSensor || [];
		if (this.state.isVisibleSelected) {
			const selected = this.state.selected;
			items = items.filter((t) => selected.includes(t.id));
		}
		this.setState({
			fullItems: this.props.devicesSensor || [],
			items: items,
			selected: this.props.devicesSensorActive || [],
			filter: this.props.filter || [],
			isOpen: true
		});
	}

	changeView = (view) => {
		this.setState({ view });
	}
	changeVisibleSelected = (isVisibleSelected) => {
		let items = [...(this.state.fullItems || [])];
		if (!!isVisibleSelected) {
			const selected = this.state.selected;
			items = items.filter((t) => selected.includes(t.id));
		}

		this.setState({
			items,
			isVisibleSelected
		});
	}
	changeSelected = (selected) => {
		this.setState({ selected });
		this.props.isChangeDevicesSensor(selected);
	}
	changePoligons = async (polygons) => {
		await this.setState({polygons})

		const { fullItems, selected } = this.state;
		let newSelected = [...selected];
		polygons.map(polygon => {
			console.log('polygon: ', polygon);
			let list = [...polygon];
			fullItems.map(point => {
				point['lat'] = point['gps']['lat'];
				point['lng'] = point['gps']['lon'];
				let checkPointPoligon = checkPointFromPoligon(list, point);
				if (checkPointPoligon && !newSelected.includes(point.id)) {
					newSelected.push(point.id)
				}
			})
		});

		this.setState({ selected: newSelected })
		this.props.isChangeDevicesSensor(newSelected);
	}

	submitFilter = () => {
		this.props.onStartFilter();
		this.props.isClose();
	}

	render () {
		const {
			filter,
			classes,
			isClose
		} = this.props;
		const {
			items,
			selected,
			polygons,

			view,

			isOpen,
			isVisibleSelected
		} = this.state;

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="lg"
				classes={{ root: classes.root }}
			>
				<CustomDialogTitle
					view={view}
					isVisibleSelected={isVisibleSelected}

					onClose={isClose}
					onSubmit={this.submitFilter}
					onChangeView={this.changeView}
					onChangeVisibleSelected={this.changeVisibleSelected}
				/>

				<DialogContent>
					<VisibleContent visible={Boolean(view === "map")}>
						<Map
							// setRef={refMap}
							// setRefEditControl={refEditControl}

							position={[56.8519000, 60.6122000]}
							devicesSensor={items}
							selected={selected}
							isSelectRowTable={this.changeSelected}

							polygons={polygons}
							setPolygons={this.changePoligons}
						/>
					</VisibleContent>
					<VisibleContent visible={Boolean(view === "table")}>
						<Table
							rows={[...(items || [])].splice(0, 50)}
							selected={selected}
							filter={filter}
							onFilter={(filter) => this.props.onFilter(filter)}
							onChangeSelected={this.changeSelected}
						/>
					</VisibleContent>
				</DialogContent>
			</Dialog>
		)
	}
}
const VisibleContent = React.memo(({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
});
const CustomDialogTitle = React.memo((props) => {
	const {
		view,
		isVisibleSelected,

		onClose,
		onSubmit,
		onChangeView,
		onChangeVisibleSelected
	} = props;
	const classes = useStyles();
	return (
		<DialogTitle className={classes.dialogTitle}>
			<Box className={classes.dialogTitleRoot}>
				<Typography className={classes.dialogTitleTitle}>Выбор комплекса</Typography>
				<FormControlLabel
					control={<Checkbox />}
					checked={isVisibleSelected}
					className={classes.dialogTitleCheckbox}
					label="Показать только выбранные"
					onChange={onChangeVisibleSelected.bind(this, !isVisibleSelected)}
				/>
				<Box className={classes.dialogTitleViews}>
					<Button
						variant="contained"
						className={clsx({'active': true})}
						onClick={onSubmit}
					>Применить</Button>
					<Button
						variant="contained"
						className={clsx({'active': Boolean(view === 'map')})}
						onClick={onChangeView.bind(this, 'map')}
					>На карте</Button>
					<Button
						variant="contained"
						className={clsx({'active': Boolean(view === 'table')})}
						onClick={onChangeView.bind(this, 'table')}
					>Списком</Button>
				</Box>
				<IconButton className={classes.dialogTitleClose} onClick={onClose}>
					<CloseIcon/>
				</IconButton>
			</Box>
		</DialogTitle>
	)
});

const useStyles = makeStyles(() => ({
	dialogTitle: {
		backgroundColor: "transparent!important"
	},
	dialogTitleRoot: {
		display: "flex",
		alignItems: "center",
		position: "relative",
		width: "100%"
	},
	dialogTitleTitle: {
		marginRight: 32,

		color: `${palette.black}!important`,
		fontSize: "24px",
		fontWeight: "600",
		lineHeight: "100%",
		letterSpacing: "-0.48px",
	},
	dialogTitleCheckbox: {
		"& .MuiIconButton-label": {
			width: 20,
			height: 20,
			position: "relative",
		},
		"& .MuiIconButton-label:after": {
			width: "120%!important",
			height: "120%!important",
		},
		"& .MuiTypography-root": {
			color: "#647083!important",
			fontSize: "15px",
			fontWeight: "600",
			lineHeight: "100%",
			letterSpacing: "-0.3px",
		}
	},
	dialogTitleViews: {
		display: "flex",
		alignItems: "center",
		marginLeft: "auto",

		"& > button": {
			display: "flex",
			alignItems: "center",
			marginLeft: 24,
			padding: "8px 16px",
			height: "auto",
			minHeight: "initial",
			color: "#8698B1",
			fontSize: "16px",
			fontWeight: "600",
			lineHeight: "100%",
			letterSpacing: "-0.32px",
			borderRadius: "8px",
			background: "rgba(134, 152, 177, 0.20)",
			border: "none",

			"&:first-child": {
				marginLeft: 0,
			}
		},
		"& > button:hover": {
			color: "#152435"
		},
		"& > button.active": {
			backgroundColor: "#FFB200",
			color: "#152435"
		},
	},
	dialogTitleClose: {
		marginLeft: 18,
		"& svg": {
			color: "#8698B1"
		}
	}
}));

const styles = {
	root: {
		"& .MuiPaper-root": {
			borderRadius: "10px",
			background: "#F4F6F8",
		},
		"& .MuiDialogContent-root": {
			padding: 0
		}
	}
};
ModalDevices = withStyles(styles)(ModalDevices);

export default ModalDevices
