import React, {Component} from 'react';
import {
	Backdrop, Button,
	Box, CircularProgress,
	Grid, makeStyles,
	Typography,
} from '@material-ui/core';
import {
	GetAppOutlined as GetAppOutlinedIcon
} from "@material-ui/icons";
import {
	Map as MapComponent,
	List as ListCompoent,
	ExportCreate
} from "./components";
import {Page} from "../../../components";
import axiosAgent from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {sendMessage} from "../../../utils/message";
import {generateColor} from "../../../helpers/colors";
import colorPalettes from "../../../constant/colorPalettes";
import palette from "../../../theme/palette";
import {withStyles} from "@material-ui/styles";

class FrequentRoutes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			frequentRoutes: [],
			shows: [],

			isEmpty: false,
			isLoading: true,
			isPermission: true,
			isShowBackdrop: false
		};

		this.licensePlate = props.match.params.license_plate;
		this.refMap = React.createRef(null);
	}

	// инициализация страницы
	componentDidMount = () => {
		this.getFrequentRoutes();
	}

	// Получение списка частых маршрутов
	getFrequentRoutes = () => {
		axiosAgent('post', `${urls["frequent-routes-get"]}`, {
			license_plate: this.licensePlate
		}).then((response) => {
			let frequentRoutes = response?.data?.route_list || [];
			frequentRoutes.map((route, idx) => {
				route.index = idx;
				route.color = colorPalettes[idx];
			})

			this.setState({
				frequentRoutes: frequentRoutes,

				isEmpty: Boolean(frequentRoutes.length <= 0),
				isLoading: false,
			})
		});
	}

	// изменение отобажения маршрутов
	// вкл / выкл частого маршрута
	onChangeShows = (routeIndex) => {
		let newShows = [...this.state.shows];

		if (newShows.includes(routeIndex)) {
			newShows.splice(newShows.indexOf(routeIndex), 1);
		} else {
			newShows.push(routeIndex);
		}

		// const map = this.refMap.current;
		// if (map) {
		//     const _layers = map.contextValue?.map?._layers || {};
		//     const list = Object.keys(_layers);
		//
		//     await Promise.all(list.map(async (id) => {
		//         if (_layers[id]._path !== undefined) {
		//             const layer = _layers[id];
		//             await map.contextValue.map.removeLayer(layer);
		//         }
		//     }));
		// }

		this.setState({
			shows: newShows
		});
	}

	// создание запроса на экспорт частых маршрутов
	onExportFile = (props) => {
		this.setState({isShowBackdrop: true});

		let filter = {
			license_plate: this.licensePlate,
			type: props.type,
			encoding: Number(props.encoding),
		};

		axiosAgent('post', '/export/create', filter).then((response) => {
			this.setState({isShowBackdrop: false});

			sendMessage({
				type: "success",
				message: "Задача на выгрузку успешно создана"
			})

		}).catch((error) => {
			this.setState({isShowBackdrop: false});

			sendMessage({
				type: "danger",
				message: error?.response?.data?.error
			})

		})
	}

	render() {
		const {
			frequentRoutes,
			shows,

			isEmpty,
			isLoading,
			isPermission,
			isShowBackdrop
		} = this.state;
		const {
			classes
		} = this.props;

		if (isLoading) {
			return (
				<Page>

					<Box mb={3}>
						<Typography variant="h1">Частые маршруты ГРЗ "<Typography variant="h1" component="span"
						                                                          color="primary">{this.licensePlate}</Typography>"</Typography>
					</Box>

					{
						isLoading && (
							<Typography variant="h3">Загружаем частые маршруты ГРЗ</Typography>
						)
					}

				</Page>
			)
		}

		return (
			<>
				<HeadElement
					grz={this.licensePlate}
					onExport={this.onExportFile}
				/>
        <Box className={classes.content}>
	        <div className={classes.contentLeft}>
		        <ListCompoent
			        routes={frequentRoutes}
			        shows={shows}
			        onChange={this.onChangeShows}
		        />
	        </div>
	        <div className={classes.contentRight}>
		        <MapComponent
			        refMap={this.refMap}
			        routes={frequentRoutes}
			        shows={shows}
		        />
	        </div>
        </Box>

				<Backdrop open={isShowBackdrop} invisible={isShowBackdrop} style={{position: "absolute"}}>
					<CircularProgress size={60} style={{color: 'white'}}/>
				</Backdrop>
			</>
		)
	}

}
const HeadElement = (props) => {
	const { grz, onExport } = props;
	const classes = useStyles();

	return (
		<Box className={classes.headElement}>
			<Typography variant="h1" className={classes.headElementTitle}>Частые маршруты ГРЗ "<span>{grz}</span>"</Typography>

			<ExportCreate onExportFile={onExport}>
				<Button
					size="small"
					color="primary"
					variant="outlined"
					className={classes.headElementExport}
				>
					<GetAppOutlinedIcon/>
					Экспорт
				</Button>
			</ExportCreate>
		</Box>
	)
};
const useStyles = makeStyles(() => ({
	headElement: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "16px"
	},
	headElementTitle: {
		"& span": {
			color: palette.primary.main
		}
	},
	headElementExport: {
		display: "flex",
		alignItems: "center",
		padding: "8px 24px",
		boxSizing: "border-box",
		borderRadius: "8px",
    height: "auto",

    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: "-0.02em",
    color: "#152435",

		"& svg": {
			marginRight: 8
		}
  }
}));

const styles = {
	content: {
		display: "flex",
	},
	contentLeft: {
		width: "100%",
		maxWidth: "390px",
	},
	contentRight: {
		display: "flex",
		flex: 1,
		marginLeft: "8px",
		height: "calc(100vh - 154px)",
		borderRadius: "10px",
		position: "relative",
		overflow: "hidden"
	},
};
FrequentRoutes = withStyles(styles)(FrequentRoutes);

export default FrequentRoutes
