import React from "react";

const TransportLinks = (props) => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9999 4.92892L9.87859 7.05024C9.48968 7.43915 9.48968 8.07555 9.87859 8.46445C10.2675 8.85336 10.9039 8.85336 11.2928 8.46445L13.4141 6.34313C14.5809 5.17641 16.49 5.17641 17.6568 6.34313C18.8235 7.50986 18.8235 9.41905 17.6568 10.5858L15.5354 12.7071C15.1465 13.096 15.1465 13.7324 15.5354 14.1213C15.9244 14.5102 16.5608 14.5102 16.9497 14.1213L19.071 12C21.0226 10.0484 21.0226 6.88054 19.071 4.92892C17.1194 2.97731 13.9515 2.97731 11.9999 4.92892ZM9.17149 14.8284C9.56039 15.2173 10.1968 15.2173 10.5857 14.8284L14.8283 10.5858C15.2172 10.1969 15.2172 9.56047 14.8283 9.17156C14.4394 8.78265 13.803 8.78265 13.4141 9.17156L9.17149 13.4142C8.78258 13.8031 8.78258 14.4395 9.17149 14.8284ZM12.707 15.5355L10.5857 17.6568C9.41897 18.8236 7.50978 18.8236 6.34306 17.6568C5.17633 16.4901 5.17633 14.5809 6.34306 13.4142L8.46438 11.2929C8.85329 10.904 8.85329 10.2676 8.46438 9.87867C8.07547 9.48976 7.43907 9.48976 7.05016 9.87867L4.92884 12C2.97723 13.9516 2.97723 17.1194 4.92884 19.0711C6.88046 21.0227 10.0483 21.0227 11.9999 19.0711L14.1212 16.9497C14.5101 16.5608 14.5101 15.9244 14.1212 15.5355C13.7323 15.1466 13.0959 15.1466 12.707 15.5355Z"
                fill={ props.color }/>
        </svg>
    )
}

TransportLinks.defaultProps = {
    color: 'white'
};

export default TransportLinks
