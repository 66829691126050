import palette from '../palette';

export default {
  root: {
    position: 'relative',

    '&$formTitle': {
      display: 'block',
      marginBottom: 8,

      color: '#647083',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '100%',
      letterSpacing: '-0.28px',
    },

    '& sup': {
      color: palette.primary.main,
      fontSize: 16,
      position: 'absolute',
      top: -5
    }
  },

  gutterBottom: {
    marginBottom: 8
  },
  formTitle: {}
};
