import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {RouteWithLayout} from '../components';
import {Main as MainLayout} from '../layouts';

import {
	Home as HomeView,

	Dashboard as DashboardView,
	DashboardRoute as DashboardRouteView,
	FrequentRoutes as FrequentRoutesView,

	Account as AccountView,

	SensorList as SensorListView,
	SensorEdit as SensorEditView,
	SensorView as SensorViewView,

	SensorGroupCreate as SensorGroupCreateView,
	SensorGroupEdit as SensorGroupEditView,
	SensorGroupList as SensorGroupListView,
	SensorGroupView as SensorGroupViewView,

	LoadersList as LoadersListView,

	SystemSettings as SystemSettingsView,

	UsersList as UsersListView,
	UserCreate as UserCreateView,
	UserEdit as UserEditView,

	Logging as LoggingView,
	UnloadingTasks as UnloadingTasksView,
	FeedbackList as FeedbackListView,
	SystemNotifications as SystemNotificationsView,

	SensorsDuplo as SensorsDuploView,
	SensorDuploForm as SensorDuploFormView,
	Reports as ReportsView,
	LogsViolations as LogsViolationsView,
	TargetConfig as TargetConfigView,
	TargetConfigForm as TargetConfigFormView
} from '../views';

const pagesMain = [
	{
		path: '/',
		component: HomeView,
		exact: true,
		layout: MainLayout,
	},

	{
		path: '/dashboard',
		component: DashboardView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/dashboard/route/:license_plate',
		component: DashboardRouteView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/dashboard/frequent-routes/:license_plate',
		component: FrequentRoutesView,
		exact: true,
		layout: MainLayout,
	},

	{
		path: '/account',
		component: AccountView,
		exact: true,
		layout: MainLayout,
	},

	{
		path: '/sensor',
		component: SensorsDuploView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/sensor/create',
		component: SensorDuploFormView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/sensor/edit/:id',
		component: SensorDuploFormView,
		exact: true,
		layout: MainLayout,
	},

	// {
	// 	path: '/sensor',
	// 	component: SensorListView,
	// 	exact: true,
	// 	layout: MainLayout,
	// },
	{
		path: '/sensor/edit/:id',
		component: SensorEditView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/sensor/view/:id',
		component: SensorViewView,
		exact: true,
		layout: MainLayout,
	},


	{
		path: '/sensor-group',
		component: SensorGroupListView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/sensor-group/create',
		component: SensorGroupCreateView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/sensor-group/edit/:id',
		component: SensorGroupEditView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/sensor-group/view/:id',
		component: SensorGroupViewView,
		exact: true,
		layout: MainLayout,
	},


	{
		path: '/loaders',
		component: LoadersListView,
		exact: true,
		layout: MainLayout,
	},

	{
		path: '/system-settings',
		component: SystemSettingsView,
		exact: true,
		layout: MainLayout,
	},


	{
		path: '/users',
		component: UsersListView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/users/created',
		component: UserCreateView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/users/edit/:id',
		component: UserEditView,
		exact: true,
		layout: MainLayout,
	},


	{
		path: '/logging',
		component: LoggingView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/unloading-tasks',
		component: UnloadingTasksView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/feedback-list',
		component: FeedbackListView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/system-notifications',
		component: SystemNotificationsView,
		exact: true,
		layout: MainLayout,
	},

	{
		path: '/reports',
		component: ReportsView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/logs-violations',
		component: LogsViolationsView,
		exact: true,
		layout: MainLayout,
	},

	{
		path: '/target-config',
		component: TargetConfigView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/target-config/create',
		component: TargetConfigFormView,
		exact: true,
		layout: MainLayout,
	},
	{
		path: '/target-config/:id',
		component: TargetConfigFormView,
		exact: true,
		layout: MainLayout,
	},
];

const Main = (props) => {
	return (
		<Switch>
			{
				pagesMain.map((page, idx) => (
					<RouteWithLayout
						key={'page-' + idx}
						{...page}
					/>
				))
			}

			<Redirect to="/not-found"/>
			<Redirect from="/" to="/dashboard"/>
		</Switch>
	);
};

export default Main
