export default [
	{label: "АВТОДОРИЯ", value: "АВТОДОРИЯ"},
	{label: "АРЕНА", value: "АРЕНА"},
	{label: "ДЕКАРТ", value: "ДЕКАРТ"},
	{label: "ИНТЕГРА-КДД", value: "ИНТЕГРА-КДД"},
	{label: "КОРДОН", value: "КОРДОН"},
	{label: "КОРДОН-М 2", value: "КОРДОН-М 2"},
	{label: "КОРДОН-ТЕМП", value: "КОРДОН-ТЕМП"},
	{label: "КРИС-П М", value: "КРИС-П М"},
	{label: "Кордон.Про М", value: "Кордон.Про М"},
	{label: "ПАРКОН", value: "ПАРКОН"},
	{label: "ПАРКОН-А", value: "ПАРКОН-А"},
	{label: "ПРИЗМА", value: "ПРИЗМА"},
	{label: "МАНГУСТ", value: "МАНГУСТ"},
	{label: "МАНГУСТ-С", value: "МАНГУСТ-С"},
	{label: "МАНГУСТ-М", value: "МАНГУСТ-М"},
	{label: "ШАТЛ", value: "ШАТЛ"}
]
