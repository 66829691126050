// @flow
import {compose} from 'recompose';
import {connect} from 'react-redux';
import Dashboard from './Dashboard';
import { setFavorites } from '../../../AppState';

export default compose(
  connect(
    state => ({
      openSidebarLeft: state.app.openSidebarLeft,
      dashboard: state.dashboard
    }),
    dispatch => ({
      setFavorites: (favorites) => dispatch(setFavorites(favorites)),
    }),
  ),
)(Dashboard);
