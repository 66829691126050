import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTable from './MuiTable';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiTypography from './MuiTypography';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiCheckbox from './MuiCheckbox';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiSvgIcon from './MuiSvgIcon';
import MuiBackdrop from './MuiBackdrop';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiSwitch from './MuiSwitch';
import MuiListItem from './MuiListItem';
import MuiDialog from './MuiDialog';
import MuiPagination from './MuiPagination';
import MuiTooltip from './MuiTooltip';
import MuiAlert from './MuiAlert';

export default {
  MuiAlert,
  MuiButton,
  MuiDialog,
  MuiIconButton,
  MuiPaper,
  MuiTable,
  MuiTableCell,
  // MuiTableRow,
  // MuiTableHead,
  MuiTableSortLabel,
  MuiPagination,
  MuiTooltip,
  MuiTypography,
  MuiInputBase,
  MuiInputLabel,
  MuiCheckbox,
  MuiFormControlLabel,
  MuiSvgIcon,
  MuiBackdrop,
  MuiTab,
  MuiTabs,
  MuiSwitch,
  MuiListItem
};
