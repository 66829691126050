import {combineReducers} from 'redux';

import app from '../AppState';
import account from '../views/account/Account/AccountState';
import login from '../views/auth/Login/LoginState';
import dashboard from '../views/dashboard/Dashboard/DashboardState';

const rootReducer = combineReducers({
  app,
  login,
  dashboard,
  account
});

export default rootReducer
