import React, { useState } from 'react';
import {
  makeStyles,
  IconButton
} from '@material-ui/core';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorage';
import {
  X as XIcon
} from 'react-feather';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    zIndex: 9999,
    top: 10,
    left: 10,
    right: 10
  },

  alert: {
    width: '100%',
    padding: 16,
    background: '#F3F5F9',
    borderRadius: 4
  },
  alertTitle: {
    fontSize: 18,
    fontWeight: '500',
    marginBottom: 8
  },
  alertDescription: {
    marginBottom: 8
  },
  alertLink: {
    color: '#152435'
  },

  alertClose: {
    position: 'absolute',
    right: 10,
    top: 10
  },

  alertBackground: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    backgroundColor: '#181F48',
    opacity: 0.5
  }
}));

const AlertChangeBrowser = () => {
  let initialShowAlerts = false;
  initialShowAlerts = (getLocalStorage('show-alert-browser')) ? (getLocalStorage('show-alert-browser') == 'true') ? true : false : true;

  let userAgent = navigator.userAgent.toLowerCase();
  let InternetExplorer = false;
  if((/mozilla/.test(userAgent) && !/firefox/.test(userAgent) && !/chrome/.test(userAgent) && !/safari/.test(userAgent) && !/opera/.test(userAgent)) || /msie/.test(userAgent)){
    InternetExplorer = true;
  }

  if (!InternetExplorer){
    initialShowAlerts = false;
  }

  const classes = useStyles();
  const [showAlerts, setShowAlerts] = useState(initialShowAlerts);

  const handleCloseAlerts = () => {
    setLocalStorage('show-alert-browser', false)
    setShowAlerts(false)
  }


  if (!showAlerts){
    return <></>
  }

  return (
    <div className={classes.root}>
      <div className={classes.alert}>
        <div className={classes.alertTitle}>Предупреждение</div>
        <div className={classes.alertDescription}>Вы используете старый браузер. Для полноценной работы платформы, рекомендуем использовать новый браузер <b>Microsoft Edge</b></div>

        <a
          href="https://www.microsoft.com/ru-ru/edge?form=MA13DE&OCID=MA13DE"
          target={'_blank'}
          className={classes.alertLink}
        >Скачать</a>

        <IconButton
          className={classes.alertClose}
          onClick={() => handleCloseAlerts()}
        >
          <XIcon/>
        </IconButton>
      </div>


      <div className={classes.alertBackground}></div>
    </div>
  )
}

export default AlertChangeBrowser
