import React from "react";
import {
	Box
} from "@material-ui/core";
import {
	withStyles
} from "@material-ui/styles";
import {
	Header as HeaderComponent,
	Notifications as NotificationsComponent
} from "./components";
import {
	ModalFormFeedback
} from "../../components";
import {
	NewHeaderHome as NewHeaderHomeIcon,
	NewHeaderSystem as NewHeaderSystemIcon,
	NewHeaderDevices as NewHeaderDevicesIcon,
	NewHeaderAssessment as NewHeaderAssessmentIcon
} from "../../icons";
import {compose} from "recompose";
import {connect} from "react-redux";
import {clearLocalStorage} from "../../helpers/localStorage";

const mainMenu = [
	{
		label: "Логи проездов",
		path: "/dashboard",
		activePaths: ['/dashboard', '/dashboard/route/:license_plate', '/dashboard/frequent-routes/:license_plate'],
		rules: [],
		icon: NewHeaderHomeIcon
	},
	{
		label: "Логи нарушений",
		path: "/logs-violations",
		activePaths: ['/logs-violations'],
		rules: [],
		icon: NewHeaderHomeIcon
	},
	{
		label: "Комплексы",
		path: "/sensor",
		paths: [
			{label: "Комплексы", path: "/sensor"},
			{label: "Группы комплексов", path: "/sensor-group"},
		],
		activePaths: [
			'/sensor', '/sensor/edit/:id', '/sensor/view/:id',
			'/sensor-group', '/sensor-group/create', '/sensor-group/edit/:id', '/sensor-group/view/:id',
			'/sensor/create'
		],
		rules: [],
		icon: NewHeaderDevicesIcon
	},
	{
		label: "Отчеты",
		path: "/reports",
		activePaths: ['/reports'],
		rules: [],
		icon: NewHeaderAssessmentIcon
	},
	{
		label: "Точки назначения",
		path: "/target-config",
		activePaths: ['/target-config', '/target-config/create', '/target-config/:id'],
		rules: [],
		icon: NewHeaderAssessmentIcon
	},
	{
		label: "Система",
		path: "/charts",
		paths: [
			{label: "Пользователи", path: "/users"},
			{label: "Логирование", path: "/logging"},
			{label: "Системные настройки", path: "/system-settings"},
			{label: "Системные уведомления", path: "/system-notifications"},
		],
		activePaths: [
			'/charts', '/reference/:category?/:level2?/:level3?',
			'/system-settings', '/history-notifications', '/logging', '/unloading-tasks',
			'/system-notifications', '/suppression-offenders', '/system-map-tags', '/frequent-stops',
			'/users', '/users/created', '/users/edit/:id'
		],
		rules: ['admin'],
		icon: NewHeaderSystemIcon
	}
];

class Main extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isNotifications: false,
			isModalFormFeedback: false,
		};
	}

	changeOpenNotifications = (val) => {
		this.setState({ isNotifications: val })
	}
	changeOpenFormFeedback = (val) => {
		this.setState({ isModalFormFeedback: val });
	}

	exitUserSession = async () => {
		clearLocalStorage('session_id');
		window.location.href = "/";
	}

	render () {
		const {
			user,
			classes,
			notifications
		} = this.props;
		const {
			isNotifications,
			isModalFormFeedback
		} = this.state;

		return (
			<Box className={classes.root}>
				<Box className={classes.header}>
					<HeaderComponent
						user={user}
						mainMenu={mainMenu}

						onExitUserSession={this.exitUserSession}
						onOpenFormFeedback={this.changeOpenFormFeedback.bind(this, true)}
						onOpenNotification={this.changeOpenNotifications.bind(this, true)}
					/>
				</Box>
				<Box className={classes.body}>
					{this.props.children}
				</Box>

				<NotificationsComponent
					open={isNotifications}
					notifications={notifications}
					onCloseNotification={this.changeOpenNotifications.bind(this, false)}
				/>
				<ModalFormFeedback
					open={isModalFormFeedback}
					onClose={this.changeOpenFormFeedback.bind(this, false)}
				/>
			</Box>
		)
	}
}

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",
		paddingTop: 64
	},
	header: {
		height: 64,
		position: "fixed",
		top: 0, left: 0, right: 0,
		zIndex: 1000
	},
	body: {
		padding: "20px 8px",
		boxSizing: "border-box"
	},
	bodyLeft: {},
	bodyCenter: {},
	bodyRight: {},
};
Main = withStyles(styles)(Main);

export default compose(
	connect(
		state => ({
			user: state.app.user,
			notifications: state.app.notification,
		}),
		dispatch => ({}),
	),
)(Main)
