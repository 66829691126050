import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Backdrop,
	Typography,
	CircularProgress
} from '@material-ui/core';
import {
	withStyles
} from "@material-ui/styles";
import {
	Table as TableComponent
} from "./components";
import {
	DialogRefConfirmation
} from "../../../components";
import {
	Link
} from "react-router-dom";
import axiosAgent from "../../../plugins/axios";
import {sendMessages} from "../../../common/messages";

class TargetConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			isLoading: true,
			isBackdrop: false
		};
		this.refDialogRefConfirmation = React.createRef();
	}

	componentDidMount = async () => {
		await this.getItems();
	}

	getItems = async () => {
		const items = await axiosAgent('post', '/target-config/list', {
			page: 1, limit: 200
		}).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});
		await this.setState({
			items,
			isLoading: false
		})
	}
	removeItem = async (item, isConfirm) => {
		if (!isConfirm) {
			this.refDialogRefConfirmation.current.open({
				title: "Подтверждении",
				message: "Вы точно хотите удалить точку назначения?",
				onSuccess: this.removeItem.bind(this, item, true)
			});
			return
		}
		await this.setState({ isBackdrop: true });
		const res = await axiosAgent('get', `/target-config/delete/${ item.id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			sendMessages({
				type: 'danger',
				message: 'Возникла ошибка при удалении, повторите попытку позднее.'
			})
			return
		}
		await this.getItems();
		await this.setState({ isBackdrop: false });
		sendMessages({
			type: 'success',
			message: 'Точка назначения успешно удалена'
		})
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			items,
			isLoading,
			isBackdrop
		} = this.state;

		return (
			<>

				<Grid container justify="space-between">
					<Grid item>
						<Typography variant="h1">Точки назначения</Typography>
					</Grid>
					<Grid item>
						<Button
							size="small"
							color="primary"
							variant="outlined"
							to="/target-config/create"
							className={classes.buttonCreate}
							component={Link}
						>
							Добавить точку
						</Button>
					</Grid>
				</Grid>
				<Box mt={2}/>
				<TableComponent
					data={items}
					isLoad={isLoading}

					onRemove={this.removeItem}
				/>

				<DialogRefConfirmation ref={this.refDialogRefConfirmation}/>
				<Backdrop open={isBackdrop} invisible={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

const styles = {
	buttonCreate: {
		height: 36,
		padding: "0 24px",
		borderRadius: 8,

		fontSize: 16,
		lineHeight: 16,
		color: "#152435"
	}
};
TargetConfig = withStyles(styles)(TargetConfig);

export default TargetConfig
