import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Select,
	Backdrop,
	Checkbox,
	MenuItem,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	FormHelperText,
	FormControlLabel,
	CircularProgress,
} from '@material-ui/core';
import {
	withStyles
} from "@material-ui/styles";
import {
	VisibleContent
} from "../../../components";
import {
	Formik
} from "formik";
import * as Yup from "yup";
import axiosAgent from "../../../plugins/axios";
import {sendMessages} from "../../../common/messages";

const initForm = {
	"type": "",
	"title": "",
	"active": "",
	"endpoint": "",
	"settings": {
		"priority_response": false
	},
	"filters": {}
};
const filterItems = [
	{label: "Отсутствует КФВФ в справочнике", value: "missing_sensor"},
	{label: "Отсутствует тег или информация в теге <v_regno>", value: "missing_regno"},
	{label: "Отсутствует тег или информация в теге <v_camera>", value: "missing_camera"},
	{label: "Отсутствует тег или информация в теге <v_time_check>", value: "missing_time"},
	{label: "Отсутствует тег или информация в теге <v_photo_ts>", value: "missing_photo"},
	{label: "Отсутствует тег или информация в теге <v_camera_place>", value: "missing_place"},
	{label: "Отсутствуют теги или информация в тегах <v_gps_x> и <v_gps_y>", value: "missing_gps"},
	{label: "Наличие полностю распознанного ГРЗ", value: "lp_unrecognized"},
	{label: "Закончился срок поверки КВФВ", value: "sensor_verification"},
	{label: "Время фиксации больше серверного", value: "time_after"},
	{label: "Время фиксации меньше серверного на более 120 часов", value: "time_before"}
];

class TargetConfigForm extends Component {
	constructor(props) {
		super(props);

		const targetId = props?.match?.params?.id || "";
		this.state = {
			form: {...initForm},

			targetId: targetId,
			isCreate: !Boolean(targetId),
			isInitForm: false,
			isBackdrop: false,
		};

		this.refFormik = React.createRef();
	}

	componentDidMount = async () => {
		await this.initForm();
	}

	initForm = async () => {
		const {
			isCreate,
			targetId
		} = this.state;

		if (isCreate) {
			await this.setState({ isInitForm: true });
			return
		}

		const item = await axiosAgent('get', `/target-config/get/${ targetId }`).then((res) => {
			return res.data
		}).catch(() => null);
		if (!item) {
			sendMessages({
				type: 'danger',
				message: 'Точка назначения не найден'
			});
			this.props.history.replace('/target-config')
			return
		}

		await this.setState({
			form: {...initForm, ...item},
			isInitForm: true
		});
	}

	changeForm = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}
	changeFormFilters = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values['filters'][name] = value;
		this.refFormik.current.setValues(values);
	}
	changeFormSettings = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values['settings'][name] = value;
		this.refFormik.current.setValues(values);
	}

	submitForm = async (form) => {
		const {
			targetId,
			isCreate,
		} = this.state;

		await this.setState({ isBackdrop: true });

		const actionUrl = Boolean(isCreate) ? "/target-config/create" : `/target-config/edit/${ targetId }`;
		const res = await axiosAgent('post', actionUrl, form).then((res) => {
			return res.data
		}).catch((err) => {
			let _msg = "Возникла ошибка в процессе создания точки назначения, повторите попытку позднее.";
			if (!isCreate) {
				_msg = "Возникла ошибка в процессе изменения точки назначения, повторите попытку позднее."
			}

			return {error: _msg}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			sendMessages({
				type: 'danger',
				message: res?.error,
			})
			return
		}

		await this.setState({ isBackdrop: false });

		let _msg = 'Точка назначения успешно создана';
		if (!isCreate) {
			_msg = 'Точка назначения успешно изменена';
		}

		sendMessages({
			type: 'success',
			message: _msg
		});

		this.props.history.push('/target-config')
	}

	routeGoBack = () => {
		this.props.history.goBack();
	}

	render() {
		const {
			form,
			isCreate,
			isBackdrop,
			isInitForm
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<>
				<Box maxWidth="860" margin="0 auto">
					<Typography variant="h1">
						{isCreate ? "Добавление точки назначения" : "Редактирование точки назначения"}
					</Typography>
					<VisibleContent visible={!isCreate}>
						<Box mt={1}/>
						<Typography variant="caption" style={{ fontSize: 16 }}>{form.title}</Typography>
					</VisibleContent>
					<Box mb={2}/>
					<VisibleContent visible={isInitForm}>
						<Box className={classes.section}>
							<Formik
								innerRef={this.refFormik}
								initialValues={form}
								validationSchema={validationSchema}
								onSubmit={this.submitForm}
							>{(params) => {
								const {
									values,
									errors,
									touched,
									handleSubmit
								} = params;

								return (
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												value={values.title}
												name="title"
												label="Название"
												placeholder="Введите название"
												variant="outlined"
												fullWidth={true}
												error={Boolean(touched.title && errors.title)}
												helperText={touched.title && errors.title}
												onChange={this.changeForm}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControl fullWidth error={Boolean(touched.type && errors.type)}>
												<InputLabel>Тип</InputLabel>
												<Select
													value={values.type}
													name="type"
													label="Тип"
													variant="outlined"
													onChange={this.changeForm}
												>
													<MenuItem value="rsync">Rsync</MenuItem>
													<MenuItem value="endpoint">Endpoint</MenuItem>
												</Select>
												{Boolean(touched.type && errors.type) && (
													<FormHelperText>{touched.type && errors.type}</FormHelperText>
												)}
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<TextField
												value={values.endpoint}
												name="endpoint"
												label="URL / Path"
												placeholder="URL..."
												variant="outlined"
												fullWidth={true}
												error={Boolean(touched.endpoint && errors.endpoint)}
												helperText={touched.endpoint && errors.endpoint}
												onChange={this.changeForm}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												value={Boolean(values['settings']['priority_response'])}
												checked={Boolean(values['settings']['priority_response'])}
												name="priority_response"
												label="Приоритет ответа"
												control={<Checkbox />}
												onChange={(event, value) => this.changeFormSettings({target: {name: 'priority_response', value}})}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												value={Boolean(values['active'])}
												checked={Boolean(values['active'])}
												name="active"
												label="Активна"
												control={<Checkbox />}
												onChange={(event, value) => this.changeForm({target: {name: 'active', value}})}
											/>
										</Grid>
										<Grid item xs={12}>
											<InputLabel>Фильтры</InputLabel>
											<Grid container>
												{filterItems.map((filterItem) => (
													<Grid
														key={`filterItem-${ filterItem.value }`}
														item
														xs={12}
													>
														<FormControlLabel
															value={Boolean(values['filters'][filterItem.value])}
															checked={Boolean(values['filters'][filterItem.value])}
															name={filterItem.value}
															label={filterItem.label}
															control={<Checkbox />}
															onChange={(event, value) => this.changeFormFilters({target: {name: filterItem.value, value}})}
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container justify="flex-end" spacing={1}>
												<Grid item>
													<Button
														size="small"
														variant="outlined"
														onClick={this.routeGoBack}
													>Отменить</Button>
												</Grid>
												<Grid item>
													<Button
														size="small"
														variant="contained"
														onClick={handleSubmit}
													>Сохранить</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								)
							}}</Formik>
						</Box>
					</VisibleContent>
				</Box>

				<Backdrop open={isBackdrop} invisible={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

const styles = {
	section: {
		background: "#F4F6F8",
		padding: 24,
		boxSizing: "border-box",
		borderRadius: 10
	}
};
TargetConfigForm = withStyles(styles)(TargetConfigForm);

const validationSchema = Yup.object().shape({
	type: Yup.string().required('Заполните поле'),
	title: Yup.string().required('Заполните поле'),
	endpoint: Yup.string().required('Заполните поле'),
});

export default TargetConfigForm
