import palette from "../palette";

export default {
  indicator: {
    backgroundColor: palette.primary.main,
    height: 3
  },
  flexContainer: {
    marginLeft: -32
  },
}
