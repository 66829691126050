import React, {Component} from 'react';
import {
	Grid,
	Button,
	Typography
} from '@material-ui/core';
import {
	Table as TableComponent,
	Filter as FilterComponent
} from "./components";
import {
	DialogRefConfirmation,
	PageFilterTableContent
} from "../../../components";
import numberFormat from "../../../helpers/numberFormat";
import staticData from "./static-data.json";
import {Link} from "react-router-dom";
import axiosAgent from "../../../plugins/axios";

const initFilter = {
	serial: '',
	title: '',
	vendor: '',
	address: '',
	type: null,
	page: 1,
	limit: 20
};

class SensorsDuplo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			filter: {...initFilter},

			totalCount: 0,
			totalPages: 1,
			isLoading: true
		};
		this.refDialogRefConfirmation = React.createRef();
	}

	componentDidMount = async () => {
		await this.getItems()
	}

	getItems = async () => {
		await this.setState({ isLoading: true });
		const filter = await this.getFilte();
		const res = await axiosAgent('post', '/operator/sensor/find', filter).then((res) => {
			return res
		}).catch(() => {
			return {}
		});

		const totalCount = Number.parseFloat(res?.headers?.['x-pagination-total-count'] || '0');
		const totalPages = Math.ceil(totalCount / 20);

		this.setState({
			items: res?.data || [],
			totalCount,
			totalPages,
			isLoading: false
		})
	}
	removeItem = async (item, isConfirm) => {
		if (!isConfirm) {
			this.refDialogRefConfirmation.current.open({
				title: "Подтверждение",
				message: `Вы действительно хотите удалить комплекс по адресу "${item.address}"?`
			});
			return
		}
	}

	getFilte = async () => {
		const { filter } = this.state;
		let _objectFilter = {};

		Object.keys(filter).map((filterKey) => {
			let _value = filter[filterKey];

			if (['type'].includes(filterKey) && _value) {
				_value = Number.parseFloat(_value);
			}
			if (_value) {
				_objectFilter[filterKey] = _value;
			}
		});

		return _objectFilter
	}
	resetFilter = async () => {
		await this.setState({filter: {...initFilter}});
		await this.getItems();
	}
	changeFilter = async (filter) => {
		await this.setState({ filter });
	}
	changePage = async (newPage) => {
		let filter = {...this.state.filter};
		filter.page = newPage;
		await this.setState({ filter });
		await this.getItems();
	}

	render() {
		const {
			items,
			filter,
			totalCount,
			totalPages,
			isLoading
		} = this.state;

		return (
			<>
				<PageFilterTableContent
					currentPage={filter.page}
					totalPage={totalPages}
					onResetFilter={this.resetFilter}
					onChangePage={this.changePage}
				>
					<slot name="head">
						<HeadElement
							totalCount={totalCount}
						/>
					</slot>
					<slot name="body">
						<TableComponent
							data={items}
							isLoad={isLoading}

							onRemove={this.removeItem}
						/>
					</slot>
					<slot name="filter">
						<FilterComponent
							filter={filter}

							onSearch={this.getItems}
							onChange={this.changeFilter}
						/>
					</slot>
				</PageFilterTableContent>

				<DialogRefConfirmation
					ref={this.refDialogRefConfirmation}
				/>
			</>
		);
	}
};
const HeadElement = ({ totalCount }) => {
	return (
		<Grid container justify="space-between" style={{ marginBottom: "12px" }}>
			<Grid item>
				<Typography variant="h1">Список комплексов: {numberFormat(totalCount)}</Typography>
			</Grid>
			<Grid item>
				<Button
					size="small"
					variant="contained"
					component={Link}
					to="/sensor/create"
				>Добавить комплекс</Button>
			</Grid>
		</Grid>
	)
}

export default SensorsDuplo
