import React from 'react';
import {
  FormControl,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: {
    '& .MuiSelect-select': {
      display: 'flex',
      flexWrap: 'wrap',

      '& > *': {
        marginLeft: 10,

        '&:first-child': {
          marginLeft: 0,
        }
      }
    }
  },

  option: {
    display: 'flex',
    alignItems: 'center'
  },
  optionColor: {
    width: 20,
    height: 20,
    borderRadius: '100%',
    marginRight: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(25, 42, 62, 0.2)'
  }
}));

const SelectColor = (props) => {
  const {className, variant, options, ...other} = props;
  const classes = useStyles();

  const handleRenderValue = (value) => {
    let valueBlock = [];
    let count = 0;

    value.map(item => {
      let option = options.find(option => option.value === item);

      if (option){
        if (valueBlock.length === 0){
          valueBlock.push((
            <div className={classes.option}>
              <div
                className={classes.optionColor}
                style={{ backgroundColor: option.color }}
              ></div>
              { option.label }
            </div>
          ))
        }else{
          count++
        }
      }
    })

    if (count > 0){
      valueBlock.push((
        <div className={classes.option}>
          скрыто {count}
        </div>
      ))
    }

    if (valueBlock.length <= 0){
      valueBlock.push((<div>&nbsp;</div>))
    }

    return valueBlock
  }

  return (
    <FormControl
      className={className}
      variant={variant}
    >
      <Select
        {...other}
        className={classes.select}
        renderValue={handleRenderValue}
      >
        {
          options.map((option, idx) => (
            <MenuItem key={'option-color-' + idx} value={option.value} className={classes.option}>
              <div
                className={classes.optionColor}
                style={{ backgroundColor: option.color }}
              ></div>
              { option.label }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default SelectColor
