import palette from "../palette";

export default {
  root: {
    "& .MuiDialogTitle-root": {
      backgroundColor: palette.primary.main,

      "& > *": {
        color: "white"
      },
      "& .MuiTypography-root": {
        color: "white"
      },
    }
  }
}
