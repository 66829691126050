import React, {useState} from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	TextField,
	Typography,
	IconButton,
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import {
	Eye as EyeIcon,
	EyeOff as EyeOffIcon
} from "react-feather";
import {Formik} from 'formik';
import {CustomCheckbox} from "../../../../../components";
import {useHistory} from "react-router-dom";

import * as Yup from 'yup';
import userRoles from "../../../../../constant/userRoles";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import regexp from "../../../../../constant/regexp";

const Form = (props) => {
	const {innerRef, initialValues, onChange, onSubmit} = props;

	const classes = useStyles();
	const history = useHistory();

	const [isShowPassword, setShowPassword] = useState(false);

	const handleChange = ({target}) => {
		const {name, value} = target;
		let form = {...initialValues};

		form[name] = value;

		onChange(form);
	}
	const handleChangeBoolean = ({target}, value) => {
		const {name} = target;
		let form = {...initialValues};

		form[name] = value;

		onChange(form);
	}
	const handleChangeRoles = (role) => {
		let form = {...initialValues};
		let roles = form.roles;
		const index = roles.indexOf(role);

		if (index > -1) {
			roles.splice(index, 1)
		} else {
			roles.push(role)
		}
		;

		onChange(form);
	}
	const handleChangePassword = ({target}) => {
		const {name, value} = target;
		let form = {...initialValues};

		form[name] = value.replace(/\n/g, '');

		onChange(form);
	}

	const _routeGoBack = () => {
		history.goBack();
	}

	return (
		<form autoComplete="off" action="javascript:void(0);">
			<Formik
				innerRef={innerRef}
				initialValues={initialValues}
				validationSchema={validationSchema}

				onSubmit={onSubmit}
			>
				{(props) => {
					const {values, errors, touched, isValid, submitForm} = props;

					return (
						<>

							<Grid container wrap="nowrap">
								<Grid item xs={4}>
									<Box pr={4}>

										<Box mb={3}>
											<Typography variant="formTitle">Логин</Typography>

											<TextField
												value={values.username}
												error={Boolean(touched.username && errors.username)}
												helperText={touched.username && errors.username}

												name="username"
												placeholder="ivanov"
												variant="outlined"
												fullWidth

												autocomplete="off"

												onChange={handleChange}
											/>

										</Box>
										<Box mb={3}>
											<Typography variant="formTitle">ФИО</Typography>

											<TextField
												value={values.full_name}
												error={Boolean(touched.full_name && errors.full_name)}
												helperText={touched.full_name && errors.full_name}

												name="full_name"
												placeholder="Иванов И.И."
												variant="outlined"
												fullWidth

												autocomplete="off"

												onChange={handleChange}
											/>

										</Box>
										<Box mb={3}>
											<Typography variant="formTitle">Email</Typography>

											<TextField
												value={values.email}
												error={Boolean(touched.email && errors.email)}
												helperText={touched.email && errors.email}

												name="email"
												placeholder="invanov@mail.ru"
												variant="outlined"
												fullWidth

												autocomplete="off"

												onChange={handleChange}
											/>

										</Box>
										<Box mb={3}>
											<Typography variant="formTitle">Номер телефона</Typography>

											<InputMask
												mask="+7 (999) 999 - 99 - 99"
												name="phone"
												error={Boolean(touched.phone && errors.phone)}
												helperText={touched.phone && errors.phone}
												value={values.phone}
												onChange={handleChange}
											>
												{(inputProps) => (
													<TextField
														{...inputProps}
														placeholder="+7 (___) ___ - __ - __"
														variant="outlined"
														fullWidth

														autocomplete="off"
													/>
												)}
											</InputMask>

										</Box>
										<Box mb={3}>
											<Typography variant="formTitle">Пароль</Typography>

											<TextField
												value={values.password}
												type={isShowPassword ? 'text' : 'password'}
												error={Boolean(touched.password && errors.password)}
												helperText={touched.password && errors.password}

												name="password"
												placeholder="********"
												variant="outlined"
												fullWidth

												multiline
												rows={1}
												rowsMax={1}

												InputProps={{
													endAdornment: (
														<Tooltip
															title={isShowPassword ? 'Скрыть пароль' : 'Показать пароль'}>
															<IconButton
																onClick={() => setShowPassword(!isShowPassword)}>
																{isShowPassword ? <EyeOffIcon/> : <EyeIcon/>}
															</IconButton>
														</Tooltip>
													),
												}}

												onChange={handleChangePassword}
											/>

										</Box>

									</Box>
								</Grid>
								<div className={classes.separateVertical}/>
								<Grid item xs={4}>
									<Box px={4}>

										<Box mb={3}>
											<Typography variant="formTitle">Разрешённый IP</Typography>

											<TextField
												value={values.allowed_ips}
												error={Boolean(touched.allowed_ips && errors.allowed_ips)}

												name="allowed_ips"
												placeholder="155.155.155.155"
												variant="outlined"
												fullWidth
												autocomplete="off"

												onChange={handleChange}
											/>

											<Typography
												className={clsx({
													[classes.hint]: true,
													[classes.hintError]: Boolean(touched.allowed_ips && errors.allowed_ips),
												})}
											>
												{Boolean(touched.allowed_ips && errors.allowed_ips) ? (touched.allowed_ips && errors.allowed_ips) : "Список адресов или подсетей через запятую: 192.168.1.1, 192.158.1.0/24"}
											</Typography>

										</Box>

									</Box>
								</Grid>
								<div className={classes.separateVertical}/>
								<Grid item xs={4}>
									<Box pl={4}>
										<Box mb={3}>
											<Typography variant="formTitle">Настройки пользователя</Typography>

											<Box>
												<CustomCheckbox
													label="Активен"
													name="status"
													fullWidth

													checked={values.status}
													onChange={(event) => handleChangeBoolean(event, !values.status)}
												/>
											</Box>
											<Box>
												<CustomCheckbox
													label="Email уведомления по розыску"
													name="send_notifications"
													fullWidth

													checked={values.send_notifications}
													onChange={(event) => handleChangeBoolean(event, !values.send_notifications)}
												/>
											</Box>
											<Box>
												<CustomCheckbox
													label="Включить 2х факторную авторизацию"
													name="enable_2fa"
													fullWidth

													checked={values.enable_2fa}
													onChange={(event) => handleChangeBoolean(event, !values.enable_2fa)}
												/>
											</Box>

										</Box>

										<Box>
											<Typography variant="formTitle">Роли</Typography>

											{
												Object.keys(userRoles).map((key) => {
													const label = userRoles[key];

													return (
														<Box mb={1}>
															<CustomCheckbox
																key={'role-' + key}
																label={label}
																fullWidth

																checked={values.roles.indexOf(key) > -1}
																onChange={() => handleChangeRoles(key)}
																value={key}
															/>
														</Box>
													)
												})
											}

										</Box>
									</Box>
								</Grid>
							</Grid>

							<Box mt={4} mx={4}>
								<div className={classes.separateHorizontal}/>
							</Box>

							<Box px={4} pt={4} pb={0}>
								<Grid container spacing={2}>
									<Grid item>
										<Tooltip title="Изменить пользователь">
											<Button
												size="small"
												variant="contained"
												onClick={submitForm}
											>Сохранить</Button>
										</Tooltip>
									</Grid>
									<Grid item>
										<Tooltip title="Выход на страницу пользователей">
											<Button size="small" onClick={_routeGoBack} variant="outlined">Отменить</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Box>

						</>
					)
				}}
			</Formik>
		</form>
	)
};

const useStyles = makeStyles((theme) => ({
	separateHorizontal: {
		width: '100%',
		height: 1,
		backgroundColor: '#8698B1',
		opacity: 0.2
	},
	separateVertical: {
		width: 1,
		height: 'auto',
		backgroundColor: '#8698B1',
		opacity: 0.2
	},

	hint: {
		fontSize: 14,
		lineHeight: '16px',
		color: '#8698B1',
		marginTop: 4
	},
	hintError: {
		color: "#e53935"
	},
}));

const validationSchema = Yup.object().shape({
	username: Yup.string().max(255).required('Заполните поле'),
	full_name: Yup.string().max(255).required('Заполните поле'),
	email: Yup.string().max(255).email('Неправильно заполнен Email').required('Заполните поле'),
	password: Yup.string().max(255).min(8, 'Минимум 8 символов'),
	status: Yup.boolean().required('Заполните поле'),
	allowed_ips: Yup
		.string()
		.test(
			'Присутствует запрещенный IP адрес (0.0.0.0)',
			'Присутствует запрещенный IP адрес (0.0.0.0)',
			function (item) {
				const addresses = (item || "").split(",").map((address) => address.split(" ").join(""));

				let isValid = true;
				addresses.map((address) => {
					if (address === "0.0.0.0") {
						isValid = false
					}
				})

				return isValid
			}
		)
		.test(
			'Неверный формат адреса/адресов',
			'Неверный формат адреса/адресов',
			function (item) {
				const addresses = (item || "").split(",").map((address) => address.split(" ").join(""));

				let isValid = true;
				addresses.map((address) => {
					const isValidAdd = Boolean(regexp.ipAddress.test(address) || regexp.ipAddressV4.test(address));

					if (!isValidAdd) {
						isValid = false
					}
				})

				return isValid
			}
		)
		// .when('roles', {
		//     is: (roles) => (roles || []).includes("admin"),
		//     then: Yup.string(),
		//     otherwise: Yup.string().test(
		//         'Адрес (0.0.0.0/0) доступен только для администратора',
		//         'Адрес (0.0.0.0/0) доступен только для администратора',
		//         function (item) {
		//             const addresses = (item || "").split(",").map((address) => address.split(" ").join(""));
		//
		//             let isValid = true;
		//             addresses.map((address) => {
		//                 if (address === "0.0.0.0/0") {
		//                     isValid = false
		//                 }
		//             })
		//
		//             return isValid
		//         }
		//     )
		// })
		.required('Заполните поле'),
	phone: Yup.string().when('enable_2fa', {
		is: (enable_2fa) => Boolean(enable_2fa),
		then: Yup.string().matches(/^\+7 \(\d{3}\)\s\d{3} - \d{2} - \d{2}$/, 'Не правильно набран номер').required('Обазательно к заполнению'),
		otherwise: Yup.string().matches(/^\+7 \(\d{3}\)\s\d{3} - \d{2} - \d{2}$/, 'Не правильно набран номер')
	})
}, [['enable_2fa', 'phone']]);

export default Form
