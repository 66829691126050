import React from 'react';
import {Map, TileLayer, Marker, Popup} from 'react-leaflet';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: 250
  },
}));


const MapSensor = (props) => {
  let { sendorGps, title } = props;
  const classes = useStyles();

  if (!sendorGps){
    return null
  }
  const position = [sendorGps.lat, sendorGps.lon];

  return (
    <>
      <Map
        className={classes.leafletContainer}
        center={position}
        length={4}
        zoom={12}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />

        <Marker position={position}>
          <Popup>{ title }</Popup>
        </Marker>
      </Map>
    </>
  )
};

export default MapSensor
