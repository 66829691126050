import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
	IconButton,
	SvgIcon,
	TableSortLabel,
	Box,
	Grid,
	Menu,
	Tooltip,
	MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
	GitPullRequest as GitPullRequestIcon,
	Coffee as CoffeeIcon
} from 'react-feather';
import {
	ArrowHeaderTable as ArrowHeaderTableIcon,
	Pin as PinIcon
} from '../../../../../icons/index'
import {
	Image,
	CustomMoment as Moment,
	CustomTablePagination
} from "../../../../../components";
import moment from 'moment';
import {openNewTab} from '../../../../../common/openUrls';
import varibles from "../../../../../varibles";

const useStyles = makeStyles(theme => ({
	form: {
		marginBottom: 30
	},
	gridLines: {
		alignItems: 'flex-end'
	},

	tableSortLabel: {
		'& .MuiTableSortLabel-icon': {
			opacity: 0.5
		}
	},
	tableCellAction: {
		display: 'flex',
		alignItems: 'center'
	},
	tableRow: {
		cursor: 'pointer'
	},

	bulkOperations: {
		position: 'relative'
	},
	bulkActions: {
		paddingLeft: 4,
		paddingRight: 4,
		marginTop: 6,
		position: 'absolute',
		width: '100%',
		zIndex: 2,
		backgroundColor: '#fafafa'
	},
	bulkAction: {
		marginLeft: theme.spacing(2)
	},

	image: {
		width: 65,
		minWidth: 65,
		height: 65,
		marginBottom: -10,

		position: 'relative',
		zIndex: 555,
		marginTop: -5,
		marginLeft: -10
	},

	stickyActionsColumn: {
		'& table:first-child': {
			'& tr': {
				'&:hover td:last-child': {
					backgroundColor: theme.palette.background.default,
				},
				'& td:last-child, th:last-child': {
					backgroundColor: 'white',
					position: 'sticky',
					right: 0,
					zIndex: 999
				},
				'& th:last-child': {
					backgroundColor: 'rgb(229, 235, 241)',
				},
				'& th:first-child': {
					zIndex: 9999
				}
			}
		}
	},

	contextMenu: {
		backgroundColor: 'white',
		position: 'absolute',
		padding: '8px 0',
		boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
		borderRadius: 4,

		'& .MuiMenuItem-root': {}
	}
}));

const initialState = {
	rightValue: '',
	rightFilterName: '',

	contextMenuX: null,
	contextMenuY: null,
	contextMenuOpen: false,
}

const TableComponents = props => {
	const {
		rows, columns, showColumns,
		filter, selected, loading,
		isChangeRowPage, isChangeFilter,
		isOpenModalInformation, onLoadMore, isInfiniteScrollLoading
	} = props;
	let {pagination} = props;
	const classes = useStyles();
	const refTable = useRef(null);
	const [state, setState] = useState(initialState);

	// Подготовка URL и переход на страницу проезда
	const toRoute = (row) => {
		let urlFilter = [];

		for (let key in filter) {
			let item = filter[key];

			if (key === 'sensor_ids') {
				if (item.length <= 0) {
					item = null
				}
			}
			if (key === 'datetime_start' || key === 'datetime_end') {
				item = new Date(item).getTime()
			}
			if (key === 'license_plate_list') {
				item = null
			}
			if (key === 'limit') {
				item = 40
			}
			if (key === 'page') {
				item = null
			}
			if (key === 'license_plate_empty' || key === 'license_plate_invalid') {
				item = Boolean(item);
			}

			if (item) {
				urlFilter.push(key + '=' + item);
			}
		}

		let url = '/dashboard/route/' + row.license_plate_id + '?' + urlFilter.join('&');
		openNewTab(url)
	}
	// Подготовка URL и переход на страницу частые проезды
	const toFrequentRoutes = (row) => {
		let url = `/dashboard/frequent-routes/${row.license_plate}`;
		openNewTab(url)
	}

	const routeFrequentStops = (row) => {
		let url = `/frequent-stops?grz=${row.license_plate}`;
		openNewTab(url)
	}

	// Функция изменения сортировки
	const createSortHandler = (field) => {

		const isAsc = pagination.orderBy === field && pagination.order === 'desc';
		pagination.order = isAsc ? 'asc' : 'desc';
		pagination.orderBy = field;

		isChangeRowPage(pagination);
	};


	const rightClickRow = (event) => {
		setState({
			contextMenuOpen: false
		})

		event.preventDefault();

		let item = event.target;
		let value = item.getAttribute('value');
		let filterName = item.getAttribute('filter-name');

		if (filterName) {
			let posTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

			setState({
				rightValue: value,
				rightFilterName: filterName,

				contextMenuX: event.clientX - 2,
				contextMenuY: event.clientY - 4 + posTop,
				contextMenuOpen: true
			})
		}
	}
	const rightClickClose = (event) => {
		let item = event.target;
		let filterName = item.getAttribute('filter-name');

		if (filterName) {
			setState({
				contextMenuOpen: false
			})
			rightClickRow(event)
		} else {
			setState({
				contextMenuOpen: false
			})
		}
	}
	const handleCloseMenuContext = () => {
		setState({
			contextMenuX: null,
			contextMenuY: null,
			contextMenuOpen: false,
		})
	}
	const rightClickChangeFilter = () => {
		let filterName = state.rightFilterName;
		let value = state.rightValue;

		if (filterName === 'sensor_ids') {
			if (filter.sensor_ids.indexOf(value) <= -1) {
				filter.sensor_ids.push(value);
			} else {
				return null
			}
		}
		if (filterName === 'datetime') {
			filter.datetime_start = moment(value).subtract(5, 'hours');
			filter.datetime_end = moment(value).subtract(5, 'hours');
		}
		if (filterName === 'license_plate_list') {
			filter.license_plate_list.push(value);
		}

		handleCloseMenuContext()

		isChangeFilter(filter)
	}

	// Фунция запуска детального просмотра сработки
	const handleOpenModalInformation = (event, row) => {
		event.preventDefault();
		let item = event.target;
		let name = item.getAttribute('name');
		let className = item.getAttribute('class');
		let filterName = item.getAttribute('filter-name');

		if (
			filterName === 'license_plate_list' ||
			filterName === 'image' ||
			Boolean((className || "").indexOf("ril__") > -1)
		) {
			return null
		}
		if (!className) {
			className = ''
		}

		if (
			name === 'image' ||
			className === 'ril-inner ril__inner' ||
			className.indexOf('ril-close') > -1 ||
			className.indexOf('MuiIconButton-label') > -1
		) {
			return null
		}

		isOpenModalInformation(row)
	}

	// Функцил откртия меню для сортировки по ГРЗ сработки
	const handleOpenModalInformationGrz = (event, row) => {
		event.preventDefault();
		let item = event.target;
		let name = item.getAttribute('name');
		let className = item.getAttribute('class');

		let selected_text = '';
		if (window.getSelection) {
			selected_text = window.getSelection().toString();
		} else if (document.selection && document.selection.type != 'Control') {
			selected_text = document.selection.createRange().text;
		}
		if (selected_text == '') {
			if (!className) {
				className = ''
			}

			if (name === 'image' || className === 'ril-inner ril__inner' || className.indexOf('ril-close') > -1) {
				return null
			}

			isOpenModalInformation(row)
		}
	}

	const _imagesPage = () => {
		return rows.map((row) => {
			return `${varibles.IMAGE_URL + (row?.images?.main || "")}`
		})
	}

	return (
		<div onClick={rightClickClose} onContextMenu={rightClickClose}>
			<PerfectScrollbar>
				<Box minWidth={1400} className={classes.stickyActionsColumn}>
					<Table
						id={'basic-drive-table'}
						ref={refTable}
						className={'MuiTable-list'}
						onContextMenu={rightClickClose}
					>
						<TableHead className={'MuiTableHead-list'}>
							<TableRow>
								{
									columns.map((column, idx) => {
										if (showColumns.indexOf(column.field) > -1) {
											let titleSort = column.label + ' по ' + (pagination.orderBy === column.field ? (pagination.order === 'desc') ? 'возрастанию' : 'убыванию' : 'возрастанию');

											if (column.sort) {
												return (
													<TableCell
														key={'table-cell-' + idx}
														align={'left'}
													>
														<Tooltip title={titleSort}>
															<TableSortLabel
																IconComponent={ArrowHeaderTableIcon}
																className={classes.tableSortLabel}
																active={pagination.orderBy === column.field}
																direction={pagination.orderBy === column.field ? pagination.order : 'desc'}
																onClick={() => createSortHandler(column.field)}
															>
																{column.label}
															</TableSortLabel>
														</Tooltip>
													</TableCell>
												)
											} else {
												return (
													<TableCell key={'table-cell-' + idx} align={'left'}
													           className={classes.tableSortLabel}>
														{column.label}
													</TableCell>
												)
											}
										}
									})
								}
							</TableRow>
						</TableHead>

						{
							(!loading || rows.length > 0) ?
								(
									<TableBody>
										{rows.map((row, idx) => {
											const isSensorSelected = selected.indexOf(row.id) > -1;

											return (
												<TableRow
													key={row.name + '-' + idx}
													selected={isSensorSelected}
													className={'MuiTableRow-list'}
													onClick={(event) => handleOpenModalInformation(event, row)}
													hover
												>
													{
														columns.map((item, idx) => {
															if (showColumns.indexOf(item.field) === -1) {
																return null
															}

															if (item.type === 'image') {
																return (
																	<TableCell
																		key={item.field + '-' + idx}
																		name={'image'}
																		filterName="image"
																	>
																		<Image
																			src={varibles.IMAGE_URL + row.images.main}
																			classImage={classes.image}
																			name={'image'}
																			filterName="image"

																			images={_imagesPage()}
																			activeIndex={idx}
																		/>
																	</TableCell>
																)
															}
															if (item.type === 'date') {
																return (
																	<TableCell key={item.field + '-' + idx}
																	           onContextMenu={item.filterName ? rightClickRow : ''}>
																		<div>
																			<p>
																				<Moment
																					format="DD.MM.YYYY"
																					value={row[item.field]}
																					filter-name={item.filterName}
																					style={item.filterName ? {cursor: 'context-menu'} : {}}
																				>
																					{row[item.field]}
																				</Moment>
																			</p>
																			<p>
																				<Moment
																					format="HH:mm:ss"
																					value={row[item.field]}
																					filter-name={item.filterName}
																					style={item.filterName ? {cursor: 'context-menu'} : {}}
																				>
																					{row[item.field]}
																				</Moment>
																			</p>
																		</div>
																	</TableCell>
																)
															}
															if (item.type === 'speed') {
																return (
																	<TableCell key={item.field + '-' + idx}>
																		{row[item.field]} км/ч
																	</TableCell>
																)
															}
															if (item.type === 'action') {
																return (
																	<TableCell key={item.field + '-' + idx}>
																		{
																			(row.license_plate) && (
																				<Grid
																					container
																					wrap={'nowrap'}
																				>
																					<Grid item>
																						<Tooltip
																							title="Открыть карту передвижения">
																							<IconButton
																								onClick={() => toRoute(row)}
																								className={'ril-inner ril__inner'}
																							>
																								<SvgIcon
																									style={{pointerEvents: 'none'}}>
																									<PinIcon
																										color={'#8698B1'}/>
																								</SvgIcon>
																							</IconButton>
																						</Tooltip>
																					</Grid>
																				</Grid>
																			)
																		}
																	</TableCell>
																)
															}
															if (item.type === 'grz') {
																let grz = row[item.field];

																return (
																	<TableCell
																		key={item.field + '-' + idx}
																		value={grz}
																		filter-name={item.filterName}
																		onContextMenu={rightClickRow}
																		onClick={(event) => handleOpenModalInformationGrz(event, row)}
																	>
																		<div style={{
																			whiteSpace: 'nowrap',
																			pointerEvents: 'none'
																		}}>{grz}</div>
																	</TableCell>
																)
															}

															if (typeof row[item.field] === 'object') {
																return (
																	<TableCell
																		key={item.field + '-' + idx}>1</TableCell>
																)
															}

															return (
																<TableCell
																	key={item.field + '-' + idx}
																	value={row[item.field]}
																	filter-name={item.filterName}
																	style={item.filterName ? {cursor: 'context-menu'} : {}}
																	onContextMenu={item.filterName ? rightClickRow : ''}
																>{row[item.field]}</TableCell>
															)
														})
													}
												</TableRow>
											)
										})}
									</TableBody>
								)
								:
								(
									<TableBody>
										<TableRow className={'MuiTableRow-list'}>
											{
												columns.map((item, idx) => {
													if (showColumns.indexOf(item.field) === -1) {
														return null
													}

													if (item.type === 'image') {
														return (
															<TableCell
																key={item.field + '-' + idx}
																name={'image'}
															>
																<Skeleton variant="rect" width={65} height={65}/>
															</TableCell>
														)
													}
													if (item.type === 'date') {
														return (
															<TableCell key={item.field + '-' + idx}>
																<Skeleton variant="rect"/>
															</TableCell>
														)
													}
													if (item.type === 'action') {
														return (
															<TableCell key={item.field + '-' + idx}/>
														)
													}
													if (item.type === 'grz') {
														return (
															<TableCell key={item.field + '-' + idx}>
																<div style={{whiteSpace: 'nowrap'}}><Skeleton
																	variant="rect"/></div>
															</TableCell>
														)
													}

													return (
														<TableCell key={item.field + '-' + idx}><Skeleton
															variant="rect"/></TableCell>
													)
												})
											}
										</TableRow>
									</TableBody>
								)
						}

						{
							(isInfiniteScrollLoading) && (
								<TableBody>
									<TableRow className={'MuiTableRow-list'}>
										{
											columns.map((item, idx) => {
												if (showColumns.indexOf(item.field) === -1) {
													return null
												}

												if (item.type === 'image') {
													return (
														<TableCell
															key={item.field + '-' + idx}
															name={'image'}
														>
															<Skeleton variant="rect" width={65} height={65}/>
														</TableCell>
													)
												}
												if (item.type === 'date') {
													return (
														<TableCell key={item.field + '-' + idx}>
															<Skeleton variant="rect"/>
														</TableCell>
													)
												}
												if (item.type === 'action') {
													return (
														<TableCell key={item.field + '-' + idx}/>
													)
												}
												if (item.type === 'grz') {
													return (
														<TableCell key={item.field + '-' + idx}>
															<div style={{whiteSpace: 'nowrap'}}><Skeleton
																variant="rect"/></div>
														</TableCell>
													)
												}

												return (
													<TableCell key={item.field + '-' + idx}><Skeleton
														variant="rect"/></TableCell>
												)
											})
										}
									</TableRow>
								</TableBody>
							)
						}
					</Table>
				</Box>
			</PerfectScrollbar>

			{
				(state.contextMenuOpen) && (
					<div
						className={classes.contextMenu}
						style={{
							left: state.contextMenuX,
							top: state.contextMenuY
						}}
					>
						<MenuItem onClick={rightClickChangeFilter}>Фильтровать</MenuItem>
					</div>
				)
			}

		</div>
	);
};

TableComponents.propTypes = {
	columns: PropTypes.array,
	showColumns: PropTypes.array,
	rows: PropTypes.array,
	selected: PropTypes.array,
	pagination: PropTypes.object,
	isSelectRowTable: PropTypes.func,
	isDeleteRowTable: PropTypes.func,
	isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
	showColumns: []
};

export default TableComponents;
