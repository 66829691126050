import React from "react";
import {
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@material-ui/core";
import {
	Skeleton
} from "@material-ui/lab";
import {

} from "@material-ui/styles";
import {
	VisibleContent
} from "../../../../../components";
import systemNotificationsTypes from "../../../../../constant/systemNotificationsTypes";
import moment from "moment";

const TableCustom = (props) => {
	const {
		items,
		isLoading
	} = props;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell width="120px">Дата фиксации</TableCell>
					<TableCell width="260px">Тип операции</TableCell>
					<TableCell>Информация</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<VisibleContent visible={Boolean(!isLoading)}>
					{items.map((item) => (
						<RowItem
							key={`RowItem-${item.id}`}
							item={item}
						/>
					))}
				</VisibleContent>
				<VisibleContent visible={Boolean(isLoading)}>
					<RowsLoading/>
				</VisibleContent>
			</TableBody>
		</Table>
	)
};
const RowItem = (props) => {
	const {
		item
	} = props;

	const _message = () => {
		if (item.type === "sensorGPSEmpty") {
			return `Устройство ${ item.object.sensor_serial } (${ item.object.address }) отправилоа ГЕО позицию с нулями "${ JSON.stringify(item.object.gps) }"`
		}
		if (item.type === "sensorAddressEmpty") {
			return `Устройство ${ item.object.sensor_serial }"`
		}
		if (item.type === "sensorSerialEmpty") {
			return ""
		}
		if (item.type === "sensorImage") {
			return `Устройство ${ item.object.sensor_serial } (${ item.object.address }) не отправило изображение транспортного средства ГРЗ "${ item.object.license_plate }"`
		}
		if (item.type === "sensorDataDate") {
			return `Устройство ${ item.object.sensor_serial }`
		}
		return "Н/Д"
	}

	return (
		<TableRow>
			<TableCell>
				<div
					dangerouslySetInnerHTML={{
						__html: moment(item.created).format("DD.MM.YYYY<br/>HH:mm:ss")
					}}
				/>
			</TableCell>
			<TableCell>
				{systemNotificationsTypes[item.type] || "Н/Д"}
			</TableCell>
			<TableCell>
				<div
					dangerouslySetInnerHTML={{
						__html: _message()
					}}
				/>
			</TableCell>
		</TableRow>
	)
}
const RowsLoading = () => {
	return new Array(10).fill(1).map((t, i) => (
		<TableRow key={`RowsLoading-${ i }`}>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
		</TableRow>
	))
}

export default TableCustom
