import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';

const CustomMoment = (props) => {
  const { children, ...other } = props;

  let date = moment(children);

  return (
    <Moment
      { ...other }
    >
      { date }
    </Moment>
  )
}

export default CustomMoment
