import React from "react";
import {
	Box,
	Grid,
	Switch
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";

const ListComponent = (props) => {
	const {routes, shows, onChange} = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<div className={classes.title}>Маршруты</div>
			<Grid container spacing={2}>
				{routes.map((route) => (
					<Grid item xs={12}>
						<ItemRoute
							item={route}
							checked={Boolean(shows.includes(route.index))}
							onChange={onChange.bind(this, route.index)}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	)
};
const ItemRoute = (props) => {
	const {
		item,
		checked,
		onChange
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.itemRoute} onClick={onChange}>
			<Box className={classes.itemRouteIcon} style={{ background: item.color }}/>
			<Box className={classes.itemRouteName}>
				<div>Показать только</div>
				<span>{item.length} фиксации</span>
			</Box>
			<Box className={classes.itemRouteSwitch}>
				<Switch
					value={checked}
					checked={checked}
					className="switch-ios"
				/>
			</Box>
		</Box>
	)
}
const useStyles = makeStyles(() => ({
	root: {
		background: "#F4F6F8",
		borderRadius: "10px",
		padding: "20px",
		boxSizing: "border-box"
	},
	title: {
		marginBottom: "16px",

		fontWeight: "600",
		fontSize: "18px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		textTransform: "uppercase",
		color: "#000000",
	},

	itemRoute: {
		display: "flex",
		alignItems: "center"
	},
	itemRouteIcon: {
		width: 20, height: 20,
		borderRadius: "100%",
		marginRight: "8px"
	},
	itemRouteName: {
		marginRight: "16px",

    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "100%",
    letterSpacing: "-0.02em",
    color: "#647083",

		"& span": {
			fontWeight: "400",
			fontSize: 12
		}
  },
	itemRouteSwitch: {
		marginLeft: "auto"
	},
}));

export default ListComponent
