import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/styles";
import {
	Close as CloseIcon,
	ZoomOutMap as ZoomOutMapIcon
} from "@material-ui/icons";
import { Map, TileLayer } from 'react-leaflet';
import { Image, VisibleContent } from '../../../../../components';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import Draggable from 'react-draggable';
import PolylineDecorator from "./PolylineDecorator";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import moment from "moment";
import 'leaflet-polylinedecorator';
import clsx from "clsx";

class MapComponent extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			zoomMap: 14,
			centerMap: (process.env.REACT_APP_MAP_CENTER || '').split(','),

			bounds: null,
		};

		this.refMap = React.createRef();
	};

	setCenterFromSchedule = (item) => {
		this.setState({
			zoomMap: 18,
			centerMap: [item.gps?.lat,  item.gps?.lon]
		})
	}
	setCenterMapFromPoints = () => {
		let bounds = [...(this.props?.items || [])].map((point) => {
			return [
				point.gps?.lat,
				point.gps?.lon
			]
		});
		if (bounds.length <= 0) {
			bounds = null;
		}

		this.setState({ bounds });
	}

	pointClick = (point) => {
		this.props.onClickPoint(point);
	}

	render () {
		const {
			items,
			classes,
			mapPoints,
			openedCardsPoint,
			isConnectDotsLines
		} = this.props;
		const {
			bounds,
			zoomMap,
			centerMap
		} = this.state;

		return (
			<Box className={classes.root}>
				<Map
					ref={this.refMap}
					length={4}
					zoom={zoomMap}
					center={centerMap}
					className={classes.map}
					bounds={bounds}
				>
					<TileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					/>

					{mapPoints.map(( item, index ) => (
						<MapPoints
							key={`MapPoints-${ index }`}
							item={item}
							onPointClick={this.pointClick}
						/>
					))}

					<VisibleContent visible={isConnectDotsLines}>
						<PolylineDecorator items={items}/>
					</VisibleContent>
				</Map>

				<ParentOpenedCards
					elements={openedCardsPoint}
					onClose={this.pointClick}
				/>
			</Box>
		)
	}
}

const MapPoints = ({ item, onPointClick }) => {
	let points = [...(item?.points || [])];
	// if (points.length <= 1) {
	// 	points.push({
	// 		...points[0],
	// 		disabled: true
	// 	})
	// }

	return (
		<MarkerClusterGroup>
			{points.map((point) => (
				<MapPoint
					item={point}
					onClick={onPointClick}
				/>
			))}
		</MarkerClusterGroup>
	)
}
const MapPoint = (props) => {
	const { item, children, onClick } = props;
	const { gps, indexNumber } = item;
	const position = [gps.lat, gps.lon];

	let className = [];
	if (item.start) {
		className.push('--start-point')
	}
	if (item.end) {
		className.push('--end-point')
	}

	const customMarkerIcon = divIcon({
		html: renderToStaticMarkup(<div><span>{ indexNumber }</span></div>),
		className: className.join(' '),
		iconSize: [30, 30]
	});

	const handleClickPoint = () => {
		onClick(item);
	}

	return (
		<Marker
			position={position}
			icon={customMarkerIcon}
			onClick={handleClickPoint}
		>{ children }</Marker>
	)
}

const ParentOpenedCards = (props) => {
	const { elements, onClose } = props;

	if (elements.length <= 0) {
		return null
	}
	return elements.map((element) => (
		<ElementOpenedCard
			key={`ElementOpenedCard-${ element.indexNumber }`}
			item={element}
			onClose={onClose}
		/>
	))
};
const ElementOpenedCard = ({ item, onClose }) => {
	const classes = useStyles();
	const title = `${item.indexNumber}) ${moment(item.datetime).format("DD.MM.YYYY HH:mm:ss")}`;

	const handleClose = () => {
		onClose(item);
	}

	return (
		<Draggable bounds="parent" handle=".elementOpenedCardDrag">
			<Box className={classes.elementOpenedCard}>
				<Box className={classes.elementOpenedCardHead}>
					<div className={clsx([classes.elementOpenedCardDrag, 'elementOpenedCardDrag'])}>
						<ZoomOutMapIcon/>
					</div>
					<span>{ title }</span>
					<IconButton size="small" className={classes.elementOpenedCardClose} onClick={handleClose}>
						<CloseIcon/>
					</IconButton>
				</Box>
				<Box className={classes.elementOpenedCardBody}>
					<Image
						src={[process.env.REACT_APP_IMAGE_URL, item?.images?.main].join('')}
						classImage={classes.elementOpenedCardImage}
					/>

					<div className={classes.elementOpenedCardTitle}>Проезд зафиксирован</div>
					<div className={classes.elementOpenedCardValue}>{ item.address }</div>

					<div className={classes.elementOpenedCardTitle}>Код устройства</div>
					<div className={classes.elementOpenedCardValue}>{ item.sensor_serial }</div>

				</Box>
			</Box>
		</Draggable>
	)
}

const useStyles = makeStyles(() => ({
	elementOpenedCard: {
		zIndex: "999",
		top: 20, left: 20,

		background: "#F4F6F8",
		borderRadius: "10px",
		padding: "16px 20px",
		boxSizing: "border-box",
		width: 320,
		position: "absolute",
		fontFamily: 'Lato',
	},
	elementOpenedCardHead: {
		display: "flex",
		alignItems: "center",
		marginBottom: 16,

		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	elementOpenedCardBody: {},
	elementOpenedCardClose: {
		position: "absolute",
		top: 0, right: 0,
		width: 40, height: 40,
		borderRadius: 0
	},
	elementOpenedCardImage: {
		width: "100%",
		height: "140px",
		objectFit: "cover",
		borderRadius: "4px"
},
	elementOpenedCardTitle: {
		marginTop: 10,
		marginBottom: 4,

		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#647083",
	},
	elementOpenedCardValue: {
		padding: "8px 12px",
		boxSizing: "border-box",
		background: "#FFFFFF",
		border: "1px solid rgba(21, 36, 53, 0.1)",
		borderRadius: "8px",

		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	elementOpenedCardDrag: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 16, height: 16,
		marginRight: 4,
		cursor: "all-scroll",

		"& svg": {
			color: "#152435",
			width: "100%", height: "100%"
		}
	}
}));

const styles = {
	root: {
		display: "flex",
		flex: 1,
		backgroundColor: "#F4F6F8",
		borderRadius: "10px",
		position: "relative",
		overflow: "hidden"
	},

	map: {
		width: "100%",
		height: "100%",

		"& .leaflet-marker-icon:not(.marker-cluster)": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "100%",
			border: "2px solid white",
			backgroundColor: "#2979FF",
			boxShadow: "0px 4px 4px 0px #1146A033",

			fontFamily: 'Lato',
			fontWeight: "600",
			fontSize: "12px",
			lineHeight: "100%",
			color: "white",

			"&.--start-point": {
				backgroundColor: "#46D32F",
			},
			"&.--end-point": {
				backgroundColor: "#F57600",
			},
		},
		"& .leaflet-marker-icon.marker-cluster": {
			background: "transparent",
			"& div": {
				background: "transparent",
			},
			"& span": {
				fontFamily: "Lato",
				fontWeight: "600",
				fontSize: "24px",
				lineHeight: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				letterSpacing: "-0.02em",
				color: "#FFFFFF",
			},
			"&:after": {
				content: "''",
				position: "absolute",
				top: "50%", left: "50%",
				transform: "translate(-50%, -50%)",
				zIndex: -1,
				width: "80px", height: "80px",
				background: "#2979FF",
				filter: "blur(16px)",
				pointerEvents: "none"
			}
		},
	}
};
MapComponent = withStyles(styles)(MapComponent);

export default MapComponent
