import palette from '../palette';

export default {
  root: {
    '& .MuiPaginationItem-page': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 32,
      minWidth: 32,
      background: '#F3F5F9',
      borderRadius: 8,

      color: '#152435',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '100%',
      letterSpacing: '-0.32px',

      "&.Mui-selected": {
        background: "#FFB200"
      }
    }
  }
};
