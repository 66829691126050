import React from "react";
import {
	Box,
	Grid,
	Select,
	MenuItem,
	Typography,
	FormControl
} from "@material-ui/core";
import {
	DateRange
} from "../../../../../components";
import systemNotificationsTypes from "../../../../../constant/systemNotificationsTypes";

const Filter = (props) => {
	const {
		filter,
		onChange
	} = props;

	const handleChange = ({ target }, value) => {
		const { name } = target;

		let _filter = {...filter};
		_filter[name] = value?.props?.value || '';

		onChange(_filter);
	}
	const handleChangeDate = (item) => {
		let _filter = {...filter};
		_filter.datetime_from = Boolean(item?.startDate) ?  item?.startDate * 1000 : null;
		_filter.datetime_to = Boolean(item?.endDate) ?  item?.endDate * 1000 : null;
		onChange(_filter);
	}

	return (
		<Grid container spacing={2}>
			<Grid xs={12} item>
				<Typography variant="formTitle">Временной промежуток</Typography>
				<DateRange
					name='datetime_start'
					dateStart={filter.datetime_from}
					dateEnd={filter.datetime_to}
					onChange={handleChangeDate}
				/>
			</Grid>
			<Grid xs={12} item>
				<Typography variant="formTitle">Тип</Typography>

				<FormControl fullWidth>
					<Select
						name="type"
						variant="outlined"
						value={filter.type}
						onChange={handleChange}
					>
						<MenuItem value="">Сбросить</MenuItem>

						{
							Object.keys(systemNotificationsTypes).map((key) => {
								const value = key;
								const title = systemNotificationsTypes[key];

								return (
									<MenuItem value={value}>{title}</MenuItem>
								)
							})
						}
					</Select>
				</FormControl>

			</Grid>
		</Grid>
	)
}

export default Filter
