export default [
    {width: 1280, height: 960},
    {width: 1024, height: 768},
    {width: 800, height: 600},
    {width: 640, height: 480},
    {width: 480, height: 360},
    {width: 320, height: 240},
    {width: 240, height: 180},
    {width: 160, height: 120},
]
