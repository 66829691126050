import React from "react";
import {
	Box,
	Grid,
	Divider,
	Typography
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import {
	VisibleContent
} from "../../../../../components";
import moment from "moment";

const FixationSchedule = (props) => {
	const {
		items,
		onClick
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography className={classes.title}>График фиксаций</Typography>
			<Box className={classes.list}>
				{items.map((item, index) => (
					<ItemElement
						key={`ItemElement-${ item.id }`}
						item={item}
						index={index + 1}
						isDivider={Boolean(index > 0)}

						onClick={onClick}
					/>
				))}
			</Box>
		</Box>
	)
};
const ItemElement = React.memo((props) => {
	const {
		item,
		index,
		isDivider,
		onClick
	} = props;
	const classes = useStyles();

	const handleClick = () => {
		onClick(item);
	}

	return (
		<>
			<VisibleContent visible={isDivider}>
				<Divider className={classes.divider}/>
			</VisibleContent>

			<Box className={classes.itemElement} onClick={handleClick}>
				<div className="--number">{ index }</div>
				<div className="--date">{ moment(item.datetime).format('DD.MM.YYYY') }</div>
				<div className="--time">{ moment(item.datetime).format('HH:mm:ss') }</div>
			</Box>
		</>
	)
});

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		padding: "16px 0 0",
		boxSizing: "border-box",
		backgroundColor: "#F4F6F8",
		borderRadius: "10px",
		flex: 1,
		position: "relative",
		overflow: "hidden"
	},
	title: {
		marginBottom: "12px",
		padding: "0 20px",

		fontWeight: "600",
		fontSize: "18px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	list: {
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1
	},
	divider: {
		margin: "0 20px",
	},

	itemElement: {
		display: "flex",
		alignItems: "center",
		padding: "5px 10px",
		margin: "0 10px",
		cursor: "pointer",

		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "160%",
		color: "#152435",

		"& .--number": {
			width: "48px"
		},
		"& .--date": {
			width: "calc((100% - 48px) / 2)"
		},
		"& .--time": {
			width: "calc((100% - 48px) / 2)"
		},

		"&:hover": {
			backgroundColor: "white",
			borderRadius: "4px"
		}
	}
}));

export default FixationSchedule
