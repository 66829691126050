// @flow
import {compose} from 'recompose';
import {connect} from 'react-redux';

import FeedbackList from './FeedbackList';

export default compose(
  connect(
    state => ({
      user: state.app.user
    }),
    dispatch => ({}),
  ),
)(FeedbackList);
