import React from 'react';
import {SvgIcon} from '@material-ui/core';

export default function Home(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7.49995 14.25V10.5H10.5V14.25C10.5 14.6625 10.8375 15 11.25 15H13.5C13.9125 15 14.25 14.6625 14.25 14.25V9.00004H15.525C15.87 9.00004 16.035 8.57254 15.7725 8.34754L9.50245 2.70004C9.21745 2.44504 8.78245 2.44504 8.49745 2.70004L2.22745 8.34754C1.97245 8.57254 2.12995 9.00004 2.47495 9.00004H3.74995V14.25C3.74995 14.6625 4.08745 15 4.49995 15H6.74995C7.16245 15 7.49995 14.6625 7.49995 14.25Z" fill="#FFB200"/>
			</svg>
		</SvgIcon>
	);
}
