import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  makeStyles
} from '@material-ui/core';
import {
  Check as CheckIcon,
  Minus as MinusIcon
} from 'react-feather';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  checkbox: {},

  defaulted: {},
  circle: {
    '&.Mui-checked svg': {
      backgroundColor: theme.palette.primary.main + '!important',
    },
    '&.MuiCheckbox-indeterminate svg': {
      backgroundColor: theme.palette.primary.main + '!important',
      color: 'white'
    },
    '& svg': {
      borderRadius: '100%!important',
      border: '1px solid rgba(100, 112, 131, 0.25)',
      backgroundColor: 'white!important'
    },

    '&:hover svg': {
      border: '1px solid ' + theme.palette.primary.main,
    }
  }
}));

const typesIcon = {
  defaulted: (<CheckIcon/>),
  circle: (<CheckIcon/>)
};

const CustomCheckbox = (props) => {
  const {label, type, className, ...other} = props;
  const classes = useStyles();
  const checkedIcon = typesIcon[type];
  const classesIcon = classes[type];


  if (!label || label === '') {
    return (
      <Checkbox
        {...other}
        checkedIcon={checkedIcon}
        className={clsx([className, classes.checkbox, classesIcon])}
        indeterminateIcon={<MinusIcon/>}
      />
    )
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...other}
          checkedIcon={checkedIcon}
          className={clsx([className, classes.checkbox, classesIcon])}
          indeterminateIcon={<MinusIcon/>}
        />
      }
      label={label}
    />
  )
}

CustomCheckbox.defaultProps = {
  type: 'defaulted'
};

export default CustomCheckbox
