import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Map,
  TileLayer,
  FeatureGroup,
  Marker,
  Popup
} from 'react-leaflet'
import 'leaflet-polylinedecorator';
import {EditControl} from 'react-leaflet-draw';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {MapTags} from "../../../../../components";

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },
}));

const MapObject = (props) => {
  const classes = useStyles();
  const {position, devicesSensor} = props;

  const _onEditPath = function (e) {
    e.layers.eachLayer(a => {
    });
  }
  const _onCreate = function (event) {
    let polygonList = event.layer._latlngs;
    polygonList.map(polygon => {
      polygon.map(point => {
      })
    })
  }
  const _onDeleted = function () {
  }

  return (
    <Map
      className={classes.leafletContainer}
      center={position}
      length={4}
      zoom={12}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      <MarkerClusterGroup>
        {
          devicesSensor.map((device, idx) => {
            let position = [device.gps.lat, device.gps.lon]

            return (
              <Marker position={position} key={'device-point' + idx}>
                <Popup>{ device.title }</Popup>
              </Marker>
            )
          })
        }
      </MarkerClusterGroup>

      <FeatureGroup>
        <EditControl
          position='topright'
          onEdited={_onEditPath}
          onCreated={_onCreate}
          onDeleted={_onDeleted}
          draw={{
            polyline: false,
            rectangle: true,
            circle: false,
            marker: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>

      <MapTags/>

    </Map>
  );
};

export default MapObject;
