import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    display: 'flex',
    alignItems: 'center',

    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 'normal',
    color: palette.gray.default,

    '&$active': {
      color: palette.gray.dark,
    }
  }
};
