import React from 'react';
import {SvgIcon} from '@material-ui/core';

export default function Home(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.25 15H9M12.75 15H9M9 15V12.75M9 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12V4.5C2.25 4.08579 2.58579 3.75 3 3.75H15C15.4142 3.75 15.75 4.08579 15.75 4.5V12C15.75 12.4142 15.4142 12.75 15 12.75H9Z" stroke="#FFB200" stroke-width="1.5"/>
				<path d="M4.5 6.75H13.5M4.5 9.75H12" stroke="#FFB200" strokeWidth="1.5"/>
			</svg>
		</SvgIcon>
	);
}
