// @flow
import {compose} from 'recompose';
import {connect} from 'react-redux';

import LoadersList from './LoadersList';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(LoadersList);
