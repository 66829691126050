// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Reports from './Reports';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Reports);
