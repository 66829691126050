import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Paper,
  Box,
  IconButton,
  Button,
  Tooltip
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  X as XIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  fileLabel: {
    flex: 1,
    display: 'flex'
  },
  fileTextField: {
    flex: 1
  },

  sesnorsList: {
    maxHeight: 350
  },
  itemSensor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
    borderRadius: 4,
    padding: '8px 16px',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2)',
    border: '1px solid rgba(0,0,0,0.2)'
  },
  itemSensorTitle: {
    fontSize: 18,
    fontWeight: '500',
    marginBottom: 4
  },
  itemSensorDescription: {
    marginBottom: 8
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  }
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalInformationEdit = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    open,
    onClose,
    selected,
    information,
    onChangeInformation,
    onChangeSelected
  } = props;

  const handleToEdit = () => {
    let {url} = information;

    history.push(url)
  }
  const handleDeleteItem = (sensorId) => {
    let newSelected = [...selected];

    newSelected.splice(newSelected.indexOf(sensorId), 1);

    onChangeInformation(newSelected)
  }

  const renderSensor = (props) => {
    let { id, group_title, serial, title, address } = props;

    return (
      <div className={classes.itemSensor}>
        <div>
          <div className={classes.itemSensorTitle}>{ title } { serial }</div>
          <div className={classes.itemSensorDescription}>Текущая группа: <b>{ group_title }</b></div>
          { address }
        </div>
        <div>
          <Tooltip title={'Удалить устройство'}>
            <IconButton onClick={() => handleDeleteItem(id)}>
              <XIcon/>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'md'}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={onClose}
      >
        <Grid>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant="h3">
                {information.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MyDialogTitle>
      <DialogContent style={{padding: 0, paddingBottom: 26}}>
        <Box pl={3} pr={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {information.description}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <PerfectScrollbar className={classes.sesnorsList}>
                { information.sensors.map(renderSensor) }
              </PerfectScrollbar>
            </Grid>

            <Grid justify={'flex-end'} style={{display: 'flex', marginTop: 16, width: '100%'}}>
              <Button
                size="large"
                style={{marginRight: 10}}
                variant="formCancel"
                onClick={() => onClose()}
              >
                Отменить
              </Button>
              <Button
                size="large"
                type="submit"
                variant="formSubmit"
                onClick={handleToEdit}
              >
                Редактировать
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalInformationEdit
