import React from 'react';
import {useHistory} from 'react-router-dom';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	SvgIcon,
	TableSortLabel,
	Box,
	Button,
	Grid,
	Menu,
	MenuItem,
	Typography,
	FormControlLabel,
	Radio,
	Switch,
	Tooltip, Popover
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
	Edit2 as Edit2Icon,
	MoreVertical as MoreVerticalIcon,
	ChevronLeft as ChevronLeftIcon,
	Check as CheckIcon,
	Video as VideoIcon
} from 'react-feather';
import PopupState, {bindMenu, bindTrigger, bindPopover} from 'material-ui-popup-state';
import {
	ArrowHeaderTable as ArrowHeaderTableIcon
} from '../../../../../icons/index'
import {
	CustomMoment as Moment,
	CustomCheckbox as Checkbox, CustomTablePagination, StatusCheckbox, DeviceType
} from "../../../../../components";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
	form: {
		marginBottom: 30
	},
	gridLines: {
		alignItems: 'flex-end'
	},

	tableSortLabel: {
		fontSize: 12,
		lineHeight: 1.1
	},
	tableCellAction: {
		display: 'flex',
		alignItems: 'center'
	},
	tableRow: {
		cursor: 'pointer'
	},

	bulkOperations: {
		position: 'relative'
	},
	bulkActions: {
		padding: '8px 4px',
		borderRadius: 4,
		marginTop: 6,
		position: 'absolute',
		width: '100%',
		zIndex: 1000,
		backgroundColor: '#fafafa'
	},
	bulkAction: {
		marginLeft: theme.spacing(2)
	},

	checkboxRow: {
		'&.Mui-checked svg': {
			backgroundColor: theme.palette.primary.main + '!important',
		},
		'& svg': {
			borderRadius: '100%!important',
			border: '1px solid rgba(100, 112, 131, 0.25)',
			backgroundColor: 'white!important'
		}
	},

	stickyActionsColumn: {
		'& table:first-child': {
			'& tr': {
				'& td:last-child, th:last-child': {
					backgroundColor: 'white',
					position: 'sticky',
					right: 0,
					zIndex: 0
				},
				'& th:last-child': {
					backgroundColor: 'rgb(229, 235, 241)',
				},
				'& th:first-child': {
					zIndex: 9999
				},
				'&.Mui-selected td:last-child': {
					backgroundColor: '#F4F6F8'
				},
				'&.Mui-selected:hover td:last-child': {
					backgroundColor: 'rgb(227, 236, 248)'
				},
			}
		}
	},
}));

const MenuTable = (props) => {
	const {filters, isChangeFilter, showColumns, columns, isChangeSettings} = props;

	const handleChangeStatus = function (event) {
		let item = event.target;

		let filter = filters;
		filter['status'] = item.name;

		isChangeFilter(filter);
	}
	const handleChange = (event) => {
		let newShowColumns = showColumns;
		let name = event.target.name;

		if (newShowColumns.indexOf(name) > -1) {
			newShowColumns.splice(newShowColumns.indexOf(name), 1)
		} else {
			newShowColumns.push(name)
		}

		isChangeSettings(newShowColumns)
	};

	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{(popupState) => (
				<React.Fragment>
					<IconButton
						aria-controls="simple-menu"
						aria-haspopup="true"
						{...bindTrigger(popupState)}
					>
						<SvgIcon fontSize="small">
							<MoreVerticalIcon/>
						</SvgIcon>
					</IconButton>
					<Menu
						variant='menu'
						anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
						getContentAnchorEl={null}
						PaperProps={{
							style: {
								width: 250,
							},
						}}
						{...bindMenu(popupState)}
					>
						<PopupState variant="popover" popupId="demo-popup-menu">
							{(popupState) => (
								<React.Fragment>
									<MenuItem
										aria-controls="simple-menu"
										aria-haspopup="true"
										{...bindTrigger(popupState)}
									>
										<ChevronLeftIcon size={15}/>
										Комплексы для отображения
									</MenuItem>
									<Menu
										variant='menu'
										anchorOrigin={{vertical: -8, horizontal: -253}}
										getContentAnchorEl={null}
										PaperProps={{
											style: {
												width: 250,
											},
										}}
										{...bindMenu(popupState)}
									>
										<Box p={1}>
											<Grid container>
												<Grid item xs={12}>
													<Typography variant={'formTitle'}>Отображать</Typography>
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel
														control={<Radio checked={filters.status === '10'} name="10"
														                size={'small'}
														                onChange={handleChangeStatus}
														                style={{padding: '0 8px'}}/>}
														label="Активные устройства"
													/>
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel
														control={<Radio checked={filters.status === '20'} name="20"
														                size={'small'}
														                onChange={handleChangeStatus}
														                style={{padding: '0 8px'}}/>}
														label="Ошибка устройства"
													/>
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel
														control={<Radio checked={filters.status === '0'} name="0"
														                size={'small'}
														                onChange={handleChangeStatus}
														                style={{padding: '0 8px'}}/>}
														label="Неактивный"
													/>
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel
														control={<Radio
															checked={!filters.status || filters.status === 'null'}
															name="null"
															size={'small'} onChange={handleChangeStatus}
															style={{padding: '0 8px'}}/>}
														label="Все"
													/>
												</Grid>
											</Grid>
										</Box>
									</Menu>
								</React.Fragment>
							)}
						</PopupState>
						<PopupState variant="popover" popupId="demo-popup-menu">
							{(popupState) => (
								<React.Fragment>
									<MenuItem
										aria-controls="simple-menu"
										aria-haspopup="true"
										{...bindTrigger(popupState)}
									>
										<ChevronLeftIcon size={15}/>
										Столбцы для отображения
									</MenuItem>
									<Menu
										variant='menu'
										anchorOrigin={{vertical: -8, horizontal: -253}}
										getContentAnchorEl={null}
										PaperProps={{
											style: {
												width: 250,
											},
										}}
										{...bindMenu(popupState)}
									>
										<Box p={1}>
											<Grid container>
												<Grid item xs={12}>
													<Typography variant={'formTitle'}>Отображать</Typography>
												</Grid>
												<Grid container spacing={1}>
													{
														columns.map((item, idx) => {
															let checked = showColumns.indexOf(item.field) > -1;

															if (item.field === 'action') {
																return ''
															}

															return (
																<Grid item xs={12}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={checked}
																				onChange={handleChange}
																				name={item.field}
																				style={{
																					padding: 0,
																					marginLeft: 8,
																					marginRight: 5
																				}}
																				size={'small'}
																				checkedIcon={<CheckIcon/>}
																			/>
																		}
																		label={item.label}
																	/>
																</Grid>
															)
														})
													}
												</Grid>
											</Grid>
										</Box>
									</Menu>
								</React.Fragment>
							)}
						</PopupState>
					</Menu>
				</React.Fragment>
			)}
		</PopupState>
	)
}

const TableComponents = props => {
	const {
		rows,
		columns,
		showColumns,
		selected,
		filters,
		isSelectRowTable,
		isChangeRowPage,
		isChangeFilter,
		isChangeSettings,
		groupList,
		onRouteMultiEdit,
		sensorsCameraIds,
		roleSensorStream,
		onOpenSensorCameraStream
	} = props;
	let {pagination} = props;
	const classes = useStyles();
	const history = useHistory();

	const handleSelectAllCustomers = () => {
		let newSelected = []

		if (selected.length !== rows.length) {
			rows.map(item => {
				newSelected.push(item.id)
			})
		}

		isSelectRowTable(newSelected)
	};
	const handleSelectOneSensor = (id) => {
		let newSelected = selected

		if (newSelected.indexOf(id) > -1) {
			newSelected.splice(newSelected.indexOf(id), 1)
		} else {
			newSelected.push(id)
		}

		isSelectRowTable(newSelected)
	};
	const handleChangePage = (event, page) => {
		let newFilter = filters;
		newFilter['page'] = page;

		isChangeFilter(newFilter);
	}
	const toEdit = (id) => {
		history.push('/sensor/edit/' + id)
	};
	const toMultiEdit = () => {
		onRouteMultiEdit()
	}

	const handleChangeRowPage = (event) => {
		pagination.rowsPerPage = event.target.value;

		isChangeRowPage(pagination)
	}
	const createSortHandler = (field) => {
		const isAsc = pagination.orderBy === field && pagination.order === 'asc';
		pagination.order = isAsc ? 'desc' : 'asc';
		pagination.orderBy = field;

		isChangeRowPage(pagination);
	};

	const handleGetGroupName = (id) => {
		let name = '';
		let group = groupList.find(group => group.id === id);

		if (group) {
			name = group.Title
		}

		return name
	}

	const handleOpenStream = (row, camera) => {
		onOpenSensorCameraStream({...row, camera})
	}
	const handleCheckCamera = (camera) => {
		if (!camera) {
			return false
		}
		if (!camera?.items || camera.items.length <= 0) {
			return false
		}

		let bool = false;
		camera.items.map((item) => {
			if (!!item.video_url) {
				bool = true
			}
		});

		return bool
	}

	let selectedSomeSensor = selected.length > 0 && selected.length < rows.length;
	let selectedAllSensor = selected.length === rows.length;

	return (
		<div>
			{selected.length > 0 && (
				<div className={classes.bulkOperations}>
					<div className={classes.bulkActions}>
						<Checkbox
							checked={selectedAllSensor}
							indeterminate={selectedSomeSensor}
							onChange={handleSelectAllCustomers}
							checkedIcon={<CheckIcon/>}
							className={classes.checkboxRow}
						/>
						<Button
							variant="outlined"
							className={classes.bulkAction}
							onClick={() => toMultiEdit()}
						>
							Редактировать
						</Button>
					</div>
				</div>
			)}
			<PerfectScrollbar>
				<Box minWidth={1400} className={classes.stickyActionsColumn}>
					<Table className={'MuiTable-list'}>
						<TableHead className={'MuiTableHead-list'}>
							<TableRow>
								<TableCell style={{padding: '0 10px'}}>
									<Checkbox
										checked={selectedAllSensor}
										indeterminate={selectedSomeSensor}
										onChange={handleSelectAllCustomers}
										checkedIcon={<CheckIcon/>}
										className={classes.checkboxRow}
									/>
								</TableCell>
								{
									columns.map((column, idx) => {
										let titleSort = column.label + ' по ' + (pagination.orderBy === column.field ? (pagination.order === 'desc') ? 'возрастанию' : 'убыванию' : 'возрастанию');

										if (showColumns.indexOf(column.field) <= -1) {
											return null
										}

										if (column.field === 'action') {
											return (
												<TableCell align={'right'} style={{padding: 0}}>
													{
														false && (
															<MenuTable
																filters={filters}
																isChangeFilter={isChangeFilter}
																showColumns={showColumns}
																columns={columns}
																isChangeSettings={isChangeSettings}
															/>
														)
													}
												</TableCell>
											)
										}

										if (column.textTooltip) {
											titleSort = column.textTooltip;
										}

										return (
											<TableCell
												key={'table-cell-' + idx}
												align={'left'}
												width={(column.width) ? column.width : ''}
											>
												<Tooltip title={titleSort}>
													<TableSortLabel
														IconComponent={ArrowHeaderTableIcon}
														className={classes.tableSortLabel}
														active={pagination.orderBy === column.field}
														direction={pagination.orderBy === column.field ? pagination.order : 'asc'}
														onClick={() => createSortHandler(column.field)}
													>
														{column.label}
													</TableSortLabel>
												</Tooltip>
											</TableCell>
										)
									})
								}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, idx) => {
								const camera = sensorsCameraIds[row.id];
								const isSensorSelected = selected.indexOf(row.id) > -1;
								const isShowCameras = handleCheckCamera(camera);

								return (
									<TableRow
										key={row.name + '-' + idx}
										selected={isSensorSelected}
										className={'MuiTableRow-list'}
									>
										<TableCell padding="checkbox">
											<Checkbox
												checked={selected.indexOf(row.id) > -1}
												onChange={() => handleSelectOneSensor(row.id)}
												value={isSensorSelected}
												checkedIcon={<CheckIcon/>}
												className={classes.checkboxRow}
											/>
										</TableCell>

										{
											columns.map((item, idx) => {
												if (showColumns.indexOf(item.field) === -1) {
													return null
												}

												if (item.type === 'group_name') {
													return (
														<TableCell key={item.field + '-' + idx}>
															{handleGetGroupName(row[item.field])}
														</TableCell>
													)
												}
												if (item.type === 'checkbox') {
													return (
														<TableCell key={item.field + '-' + idx}>
															<SvgIcon fontSize='small'>
																{
																	row[item.field] ? item.true : item.false
																}
															</SvgIcon>
														</TableCell>
													)
												}
												if (item.type === 'date') {
													return (
														<TableCell key={item.field + '-' + idx}>
															<Moment format="DD.MM.YYYY">
																{row[item.field]}
															</Moment>
															<br/>
															<Moment format="HH:mm:ss">
																{row[item.field]}
															</Moment>
														</TableCell>
													)
												}
												if (item.type === 'action') {
													return (
														<TableCell key={item.field + '-' + idx}>
															<Grid container wrap={'nowrap'} justify="flex-end">
																{
																	Boolean(camera && roleSensorStream && isShowCameras) && (
																		<Grid item style={{position: "relative"}}>

																			<PopupState
																				variant="popover"
																				popupId="demo-popup-popover"
																			>
																				{(popupState) => (
																					<div>
																						<Tooltip
																							title="Запуск видео с камеры"
																						>
																							<IconButton {...bindTrigger(popupState)}>
																								<VideoIcon/>
																							</IconButton>
																						</Tooltip>
																						<Popover
																							{...bindPopover(popupState)}
																							anchorOrigin={{
																								vertical: 'bottom',
																								horizontal: 'right',
																							}}
																							transformOrigin={{
																								vertical: 'bottom',
																								horizontal: 'right',
																							}}
																						>

																							<Menu {...bindMenu(popupState)}>
																								{
																									camera.items.map((camera, idx) => {
																										if (!camera.video_url) {
																											return null
																										}

																										return (
																											<MenuItem
																												onClick={() => handleOpenStream(row, camera)}
																											>
																												{camera?.direction || camera?.uid}
																											</MenuItem>
																										)
																									})
																								}
																							</Menu>
																						</Popover>
																					</div>
																				)}
																			</PopupState>
																		</Grid>
																	)
																}

																<Grid item>
																	<Tooltip title="Редактирование">
																		<IconButton onClick={() => toEdit(row.id)}>
																			<SvgIcon fontSize="small">
																				<Edit2Icon/>
																			</SvgIcon>
																		</IconButton>
																	</Tooltip>
																</Grid>
															</Grid>
														</TableCell>
													)
												}
												if (item.type === 'status') {
                            let _labelTooltip = '';

                            if (row[item.field] === 10) {
                                _labelTooltip = 'Активный';
                            }
                            if (row[item.field] === 20) {
                                _labelTooltip = 'Ошибка';
                            }
                            if (row[item.field] === 0) {
                                _labelTooltip = 'Неактивный';
                            }

                            return (
                              <TableCell key={item.field + '-' + idx}>
                                  <Tooltip title={_labelTooltip} arrow>
	                                  <div>
		                                  <StatusCheckbox
			                                  on={Boolean(row[item.field] === 10)}
			                                  off={row[item.field] === 20}
		                                  />
	                                  </div>
                                  </Tooltip>
                              </TableCell>
                            )
												}
												if (item.type === 'type') {
													return (
														<TableCell key={item.field + '-' + idx}>
															<DeviceType type={row[item.field]}/>
														</TableCell>
													)
												}

												if (typeof row[item.field] === 'object') {
													return (
														<TableCell key={item.field + '-' + idx}>Не валидное
															поле</TableCell>
													)
												}

												return (
													<TableCell
														key={item.field + '-' + idx}>{row[item.field]}</TableCell>
												)
											})
										}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</Box>
			</PerfectScrollbar>
		</div>
	);
};

TableComponents.propTypes = {
	columns: PropTypes.array,
	rows: PropTypes.array,
	selected: PropTypes.array,
	pagination: PropTypes.object,
	isSelectRowTable: PropTypes.func,
	isDeleteRowTable: PropTypes.func,
	isChangeRowPage: PropTypes.func,
};

export default TableComponents;
