import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Paper,
  Box,
  IconButton,
  Button,
  TextField
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
  X as XIcon
} from 'react-feather';
import {
  ColorPicker
} from '../../../../../components';
import * as Yup from 'yup';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import {Formik} from 'formik';
import axios from '../../../../../plugins/axios';

const useStyles = makeStyles((theme) => ({
  fileLabel: {
    flex: 1,
    display: 'flex'
  },
  fileTextField: {
    flex: 1
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  }
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalCreateGroup = (props) => {
  const classes = useStyles();
  const {onClose, open, onCreateGroup} = props;
  let { form } = props;
  const refFormik = useRef(null);


  const handleSubmit = (values, setStatus, setErrors) => {
    axios('post', '/operator/sensor-group/create', values).then(response => {
      onCreateGroup()
    }).catch(error => {
      const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

      setStatus({ success: false });
      setErrors({ submit: message });
    })
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'md'}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={onClose}
      >
        <Grid>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant='h3'>
                Добавление группы устройств
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MyDialogTitle>
      <DialogContent>
        <Box>
          <Formik
            initialValues={form}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Заполните поле')
            })}
            onSubmit={async (values, {
              setErrors,
              setStatus
            }) => {
              try {
                handleSubmit(values, setStatus, setErrors)
              } catch (error) {
                const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

                setStatus({success: false});
                setErrors({submit: message});
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form
                ref={refFormik}
                noValidate
                className={clsx(classes.root)}
                onSubmit={handleSubmit}
                autoComplete={'off'}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant={'formTitle'}>Наименование<sup>*</sup></Typography>
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      autoFocus
                      placeholder={'Наименование группы'}
                      helperText={touched.title && errors.title}
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Box mt={3}>
                  <Grid justify={'flex-end'} style={{display: 'flex'}}>
                    <Button
                      size="small"
                      style={{ marginRight: 10 }}
                      variant="formCancel"
                      onClick={() => onClose()}
                    >
                      Отменить
                    </Button>
                    <Button
                      size="small"
                      type="submit"
                      variant="formSubmit"
                      onClick={handleSubmit}
                    >
                      Создать
                    </Button>
                  </Grid>

                  {errors.submit && (
                    <Box mt={2}>
                      <Alert severity="error">{errors.submit}</Alert>
                    </Box>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalCreateGroup
