import React, {Component} from 'react';
import {
  Typography,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  Box,
  Collapse,
  Paper
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpIcon from '@material-ui/icons/Help';

import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';

import './index.scss';

import {
  Filter,
  Table,
  TargetInfoHeader
} from './components/index'

class TargetInfoList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        grz: '',
        dateTimeStart: null,
        dateTimeEnd: null,
        devices: [],
      },

      tableRows: [
        {
          date: '23.04.2020 10:13:11',
          date1: '23.04.2020 10:13:11',
          date2: '23.04.2020 10:13:11',
          date3: '23.04.2020 10:13:11',
          date4: '23.04.2020 10:13:11',
          date5: '23.04.2020 10:13:11',
          date6: '23.04.2020 10:13:11',
          date7: '23.04.2020 10:13:11',
          date8: '23.04.2020 10:13:11',
          date9: '23.04.2020 10:13:11',
        }
      ],
      tableColumns: [
        {
          title: 'Дата загрузки'
        },
        {
          title: 'Дата фиксации'
        },
        {
          title: 'ГРЗ'
        },
        {
          title: 'Цвет'
        },
        {
          title: 'Марка/Модель'
        },
        {
          title: 'Тип'
        },
        {
          title: 'Скорость'
        },
        {
          title: 'Местоположения'
        },
        {
          title: 'Направление'
        },
        {
          title: 'Серийный номер устройства'
        },
        {
          title: ''
        }
      ],

      exportMenu: null,
      activeTableRow: null,

      showInfoHeader: false
    }
  }

  setTargetHeader = (idx) => {
    if (idx === this.state.activeTableRow){
      this.setState({
        activeTableRow: null,
        showInfoHeader: false
      })
    }else{
      this.setState({
        activeTableRow: idx,
        showInfoHeader: true
      })
    }
  }

  render() {
    return (
      <div className={'container'}>
        <div className={'section'}>
          <div className={'container container--small'}>
            <Filter
              params={this.state.params}
              update={(params) => {
                this.setState({params})
              }}
            />

            <div className={'target-info-list__information'}>
              <Typography
                className={'target-info-list__information-title'}
                variant={'h5'}
                align={'center'}
              >
                Всего найдено 408. Затрачено времени 0,311с
              </Typography>

              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button
                      startIcon={<GetAppIcon/>}
                      endIcon={
                        <Tooltip
                          title="Тут будет большой вспомогательный текст, я не знаю что именено, но думаю что очень большой">
                          <HelpIcon color={'black'}/>
                        </Tooltip>
                      }
                      {...bindTrigger(popupState)}
                    >
                      Экспортировать
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={popupState.close}>Cake</MenuItem>
                      <MenuItem onClick={popupState.close}>Death</MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </div>
          </div>

          <Box mb={3}>
            <Collapse in={this.state.showInfoHeader}>
              <Paper elevation={0}>
                <TargetInfoHeader/>
              </Paper>
            </Collapse>
          </Box>

          <div>
            <Table
              columns={this.state.tableColumns}
              rows={this.state.tableRows}

              activeTableRow={this.state.activeTableRow}

              setActiveRow={(idx) => this.setTargetHeader(idx)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default TargetInfoList
