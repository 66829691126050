import React from 'react';
import {SvgIcon} from '@material-ui/core';

export default function Devices(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evendd" clipRule="evenodd" d="M12.75 6H5.25C4.425 6 3.75 6.675 3.75 7.5V15C3.75 15.825 4.425 16.5 5.25 16.5H12.75C13.575 16.5 14.25 15.825 14.25 15V7.5C14.25 6.675 13.575 6 12.75 6ZM13.5 4.875H4.5C4.5 4.2525 5.0025 3.75 5.625 3.75H12.375C12.9975 3.75 13.5 4.2525 13.5 4.875ZM12.75 2.625H5.25C5.25 2.0025 5.7525 1.5 6.375 1.5H11.625C12.2475 1.5 12.75 2.0025 12.75 2.625ZM12 11.25C12 12.9069 10.6569 14.25 9 14.25C7.34315 14.25 6 12.9069 6 11.25C6 9.59315 7.34315 8.25 9 8.25C10.6569 8.25 12 9.59315 12 11.25ZM9.75 11.25C10.1642 11.25 10.5 10.9142 10.5 10.5C10.5 10.0858 10.1642 9.75 9.75 9.75C9.33579 9.75 9 10.0858 9 10.5C9 10.9142 9.33579 11.25 9.75 11.25Z" fill="#FFB200"/>
			</svg>
		</SvgIcon>
	);
}
