import React from "react";
import {
	Grid,
	Tooltip,
	IconButton,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@material-ui/core";
import {
	Skeleton
} from "@material-ui/lab";
import {

} from "@material-ui/styles";
import {
	List as ListIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Warning as WarningIcon,
} from "@material-ui/icons";
import {
	DeviceType,
	VisibleContent
} from "../../../../../components";
import violationProcessing from "../../constants/violationProcessing";
import moment from "moment";
import {useHistory} from "react-router-dom";

const TableCustom = (props) => {
	const {
		data,
		isLoad,

		onRemove
	} = props;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Серийный номер</TableCell>
					<TableCell>Производитель</TableCell>
					<TableCell>Тип</TableCell>
					<TableCell>Местоположение</TableCell>
					<TableCell>Обработка нарушений</TableCell>
					<TableCell>Дата поверки</TableCell>
					<TableCell align="right">Действия</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<VisibleContent visible={!isLoad}>
					{data.map((item) => (
						<RowItem
							key={`RowItem-${ item.id }`}
							item={item}
							onRemove={onRemove.bind(this, item, false)}
						/>
					))}
				</VisibleContent>
				<VisibleContent visible={isLoad}>
					<RowsLoading/>
				</VisibleContent>
			</TableBody>
		</Table>
	)
};
const RowItem = (props) => {
	const {
		item,
		onRemove
	} = props;
	const history = useHistory();

	const routeEdit = () => {
		history.push(`/sensor/edit/${ item.id }`);
	};
	const routeDashboard = () => {
		history.push(`/dashboard?sensor_ids=${ item.id }`);
	};

	return (
		<TableRow>
			<TableCell>
				{item.serial}
			</TableCell>
			<TableCell>
				{item.vendor || "Н/Д"}
			</TableCell>
			<TableCell>
				<DeviceType type={item.type}/>
			</TableCell>
			<TableCell>
				{ item.address }
			</TableCell>
			<TableCell>
				{violationProcessing[item.violation_processing] || "Н/Д"}
			</TableCell>
			<TableCell>
				{Boolean(item.verification) ? moment(item.verification).format("DD.MM.YYYY") : "Н/Д"}
			</TableCell>
			<TableCell align="right">
				<Grid container wrap="nowrap" alignItems="center" justify="flex-end" spacing={1}>
					<Grid item>
						<Tooltip title="Логи проездов" arrow>
							<IconButton size="small" onClick={routeDashboard}>
								<ListIcon style={{ fontSize: 20 }}/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title="Логи нарушений" arrow>
							<IconButton size="small">
								<WarningIcon style={{ fontSize: 20 }}/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title="Редактировать" arrow>
							<IconButton size="small" onClick={routeEdit}>
								<EditIcon style={{ fontSize: 20 }}/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title="Удалить" arrow>
							<IconButton size="small" color="secondary" onClick={onRemove}>
								<DeleteIcon style={{ fontSize: 20 }}/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</TableCell>
		</TableRow>
	)
}
const RowsLoading = () => {
	return new Array(10).fill(1).map((t, i) => (
		<TableRow key={`RowsLoading-${ i }`}>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell align="right"><Skeleton/></TableCell>
		</TableRow>
	))
}

export default TableCustom
