import React from "react";
import {
	Box,
	Grid,
	Switch,
	Divider,
	Typography
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import {
	Check as CheckIcon
} from "@material-ui/icons";
import {
	VisibleContent
} from "../../../../../components";
import clsx from "clsx";

const Annotation = (props) => {
	const {
		isVisibleLegend,
		isConnectDotsLines,

		onChange
	} = props;
	const classes = useStyles();

	const handleChangeVal = (name, value) => {
		onChange({ name, value })
	}

	return (
		<Box className={classes.root}>

			<label className={classes.switchItem}>
				<span>Соединять точки линиями</span>
				<Switch
					checked={isConnectDotsLines}
					className={clsx(['switch-ios'])}
					onChange={handleChangeVal.bind(this, 'isConnectDotsLines', !isConnectDotsLines)}
				/>
			</label>

			<Divider className={classes.divider}/>

			<label className={classes.switchItem}>
				<span>Показать легенду</span>
				<Switch
					checked={isVisibleLegend}
					className={clsx(['switch-ios'])}
					onChange={handleChangeVal.bind(this, 'isVisibleLegend', !isVisibleLegend)}
				/>
			</label>

			<VisibleContent visible={isVisibleLegend}>
				<Divider className={classes.divider}/>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<HelpItem color="#46D32F" text="Начальная точка маршрута"/>
					</Grid>
					<Grid item xs={12}>
						<HelpItem color="#2979FF" text="Промежуточные точки"/>
					</Grid>
					<Grid item xs={12}>
						<HelpItem color="#F57600" text="Конечная точка маршрута"/>
					</Grid>
				</Grid>
			</VisibleContent>

		</Box>
	)
};
const HelpItem = ({ color, text }) => {
	const classes = useStyles();
	return (
		<Box className={classes.helpItem}>
			<Box className={classes.helpItemIcon} style={{ backgroundColor: color }}>
				<CheckIcon/>
			</Box>
			<Typography className={classes.helpItemText}>{ text }</Typography>
		</Box>
	)
};

const useStyles = makeStyles(() => ({
	root: {
		padding: "20px",
		boxSizing: "border-box",
		backgroundColor: "#F4F6F8",
		borderRadius: "10px"
	},
	divider: {
		margin: "10px 0",
		backgroundColor: "#152435",
		opacity: "0.1"
},

	switchItem: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",

		"& span": {
			fontWeight: "600",
			fontSize: "15px",
			lineHeight: "100%",
			letterSpacing: "-0.02em",
			color: "#647083",
		}
	},

	helpItem: {
		display: "flex",
		alignItems: "center",
	},
	helpItemIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 20,
		height: 20,
		borderRadius: "100%",
		marginRight: "8px",

		"& .MuiSvgIcon-root": {
			width: 16,
			height: 16,
			color: "white"
		}
	},
	helpItemText: {
		flex: 1,

		fontWeight: "600",
		fontSize: "15px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#647083",
	},
}));

export default Annotation
