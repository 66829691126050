import React from "react";
import {
  Box
} from "@material-ui/core";
import {
  withStyles
} from "@material-ui/styles";
import {
  Map as MapComponent,
  Filter as FilterComponent,
  Annotation as AnnotationComponent,
  FixationSchedule as FixationScheduleComponent
} from "./components";
import queryString from "query-string";
import axiosAgent from "../../../plugins/axios";
import moment from "moment";

class DashboardRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      mapPoints: [],
      openedCardsPoint: [],

      filter: {},

      numberId: props?.match?.params?.license_plate,
      numberString: '',

      perPage: 40,
      totalCount: 0,


      isInitLoad: true,
      isVisibleLegend: true,
      isConnectDotsLines: true,
    };
    this.refMap = React.createRef();
  }

  componentDidMount = async () => {
    await this.initFilter();
    await this.getItems();
  }

  // Логика работы с элементами
  getItems = async () => {
    const filter = await this.getFilterApi();
    const res = await axiosAgent('post', '/operator/report/find', filter).then((res) => {
      return res
    }).catch((err) => {
      return { error: err.response }
    });

    // Сортировка и настройка всех элементов
    let items = (res?.data || []);
    items = items.sort((a, b) => {
      const aDatetime = moment(a.datetime);
      const bDatetime = moment(b.datetime);

      if (aDatetime > bDatetime) {
        return 1
      }
      if (aDatetime < bDatetime) {
        return -1
      }
      return 0
    });
    items = items.map((item, index) => {
      return {
        ...item,
        indexNumber: index + 1
      }
    });

    // Подготова списка для карты
    const mapPoints = await this._getItemsMapPoints(items);

    await this.setState({
      items: items,
      mapPoints,
      isInitLoad: false,
      numberString: items?.[0]?.license_plate || '',
      totalCount: res?.headers?.['x-pagination-total-count'],
    });

    this.refMap.current.setCenterMapFromPoints();
  }
  _getItemsMapPoints = async (pointsApi) => {
    const totalCountItems = pointsApi.length;

    let objectPoints = {};
    pointsApi.map((point, idx) => {
      // Создание элемента если его нет в списке
      const key = [point.gps.lat, point.gps.lon].join('_');
      if (!objectPoints[key]) {
        objectPoints[key] = {
          position: [point.gps.lat, point.gps.lon],
          points: [],
          start: false,
          end: false
        }
      }

      // Настройка
      const isFirst = Boolean(point.indexNumber === 1);
      const isLast = Boolean(point.indexNumber === totalCountItems);

      point['start'] = isFirst;
      point['end'] = isLast;

      objectPoints[key]['start'] = isFirst;
      objectPoints[key]['end'] = isLast;

      objectPoints[key]['points'].push(point)
    });

    let arrayPoint = [];
    Object.keys(objectPoints).map((key) => {
      arrayPoint.push(objectPoints[key]);
    })

    return arrayPoint
  }
  clickScheduleItem = async (item) => {
    this.refMap.current.setCenterFromSchedule(item);
  }

  // Фильтр
  initFilter = async () => {

    const paramsSearch = queryString.parse(this.props.location.search);

    let filter = {
      license_plate_id: this.state.numberId,
    };
    Object.keys(paramsSearch || {}).map((filterKey) => {
      let value = paramsSearch[filterKey];
      if (['sensor_ids', 'license_plate_list'].includes(filterKey)) {
        value = value.split(',')
      }
      if (['limit', 'speed'].includes(filterKey)) {
        value = Number.parseFloat(value);
      }
      if (['datetime_start', 'datetime_end'].includes(filterKey)) {
        const date = new Date();
        date.setTime(value);
        value = date;
      }
      if (['license_plate_empty', 'license_plate_invalid'].includes(filterKey)) {
        value = Boolean(value);
      }
      filter[filterKey] = value;
    });

    await this.setState({
      filter,
      perPage: filter?.limit || 40
    });
  }
  changeFilter = async (filter) => {
    await this.setState({ filter });
    await this.getItems();
  }
  getFilterApi = async () => {
    const { filter, perPage } = this.state;

    let filterObject = {};
    Object.keys(filter).map((filterKey) => {
      const value = filter[filterKey];
      if (value) {
        filterObject[filterKey] = value;
      }
    });
    filterObject = {
      ...filterObject,
      limit: perPage,
      sort_field: 'datetime',
      sort_direction: 'desc',
    }

    return filterObject
  }

  // Логика работы с точками на карте
  clickPointMap = (point) => {
    let openedCardsPoint = [...this.state.openedCardsPoint];
    const findIndex = openedCardsPoint.findIndex((t) => t.indexNumber === point.indexNumber);
    if (findIndex === -1) {
      openedCardsPoint.push({...point})
    } else {
      openedCardsPoint.splice(findIndex, 1)
    }
    this.setState({
      openedCardsPoint
    });
  }

  changeCommonState = async ({ name, value }, { isStartSearch } = {}) => {
    await this.setState({
      [name]: value
    });

    if (!isStartSearch) {
      return
    }
    await this.getItems();
  }

  render () {
    const {
      classes
    } = this.props;
    const {
      items,
      filter,
      perPage,
      mapPoints,
      totalCount,
      numberString,
      openedCardsPoint,

      isInitLoad,
      isVisibleLegend,
      isConnectDotsLines
    } = this.state;

    if (isInitLoad) {
      return null
    }
    return (
      <Box className={classes.root}>
        <Box className={classes.left}>
          <FilterComponent
            filter={filter}
            perPage={perPage}
            number={numberString}
            totalCount={totalCount}

            onChangeFilter={this.changeFilter}
            onChangeCommonState={this.changeCommonState}
          />
          <AnnotationComponent
            isVisibleLegend={isVisibleLegend}
            isConnectDotsLines={isConnectDotsLines}
            onChange={this.changeCommonState}
          />
          <FixationScheduleComponent
            items={items}
            onClick={this.clickScheduleItem}
          />
        </Box>
        <Box className={classes.right}>
          <MapComponent
            ref={this.refMap}

            items={items}
            mapPoints={mapPoints}
            openedCardsPoint={openedCardsPoint}
            isConnectDotsLines={isConnectDotsLines}

            onClickPoint={this.clickPointMap}
          />
        </Box>
      </Box>
    )
  }
}

const styles = {
  root: {
    display: "flex",
    height: "calc(100vh - 80px)",
    margin: "-12px 0",
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "392px",

    "& > *": {
      marginTop: 8,
      "&:first-child": {
        marginTop: 0
      }
    }
  },
  right: {
    display: "flex",
    flex: 1,
    marginLeft: "8px"
  },
};
DashboardRoute = withStyles(styles)(DashboardRoute);

export default DashboardRoute
