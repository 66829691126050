import React, {Component} from 'react';
import {
  Box,
  Grid,
} from '@material-ui/core';
import {
  Filter,
  Table,
  ModalInformation,
  ExportCreate
} from './components';
import {
  PageFilterTableContent
} from '../../../components';
import queryString from 'query-string';
import axios from '../../../plugins/axios';
import moment from 'moment';
import {withStyles} from '@material-ui/styles';
import {store} from 'react-notifications-component';

import {
  HeadInformation,
  BlockNotification
} from "./new-components";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          label: '',
          field: 'images',
          align: 'left',
          type: 'image'
        },
        {
          label: 'Время фиксации',
          field: 'datetime',
          align: 'left',
          sort: true,
          type: 'date'
        },
        {
          label: 'Время загрузки',
          field: 'created',
          align: 'left',
          type: 'date',
          sort: true,
          filterName: 'datetime'
        },
        {
          label: 'ГРЗ',
          field: 'license_plate',
          align: 'left',
          sort: false,
          filterName: 'license_plate_list',
          type: 'grz'
        },
        {
          label: 'Марка',
          field: 'mark',
          align: 'left',
          sort: true,
          filterName: 'mark'
        },
        {
          label: 'Скорость',
          field: 'speed',
          type: 'speed',
          align: 'left',
          sort: false,
        },
        {
          label: 'Модель',
          field: 'model',
          align: 'left',
          filterName: 'model',
          sort: true,
        },
        {
          label: 'Цвет',
          field: 'color',
          align: 'left',
          sort: true,
        },
        {
          label: 'Тип',
          field: 'type',
          align: 'left',
          sort: true,
        },

        {
          label: 'Местоположение',
          field: 'address',
          align: 'left',
        },
        {
          label: 'Направление',
          field: 'direction',
          align: 'left',
        },
        {
          label: 'Код устройства',
          field: 'sensor_serial',
          align: 'left',
          sort: true,
        },
        {
          label: '',
          field: 'action',
          type: 'action',
          align: 'left',
        },
      ],
      selected: [],
      groupList: [],
      sensorList: [],
      showColumns: ['license_plate', 'created', 'speed', 'datetime', 'images', 'sensor_serial', 'direction', 'address', 'action'],

      pagination: {
        rowsPerPage: 40,
        page: 1,
        orderBy: 'datetime',
        order: 'desc',
        totalCount: 0
      },
      filter: {
        sensor_ids: [],
        license_plate_list: [],
        colors: [],
        license_plate_text: '',
        mark: '',
        model: '',
        datetime_start: null,
        datetime_end: null,
        sort_field: '',
        sort_direction: '',
        license_plate_empty: false,
        license_plate_foreign: false,
        license_plate_invalid: false,

        limit: 40,
        page: 1,
      },
      modalInformation: {},
      sensorFilter: {
        address: '',
        direction: '',
        vendor: '',
        serial: ''
      },

      dateTimeRequest: moment(),
      filterString: 'dashboard',

      tableLoader: true,
      isLoadingTable: true,
      showModalInformation: false,
      useGrzMask: false,
      isInfiniteScroll: false,
      isInfiniteScrollLoading: false,


      workFile: false,
      workFileName: '',

      errorWorkFile: false,
      errorWorkFileText: ''
    }

    this.handleGetSensorList = null;
    this.handleInfiniteScroll = null;
  }

  // Начала жизненого цикла главного экрана (проезды)
  componentDidMount = () => {
    let params = queryString.parse(this.props.location.search);
    if (Object.keys(params).length > 0) {
      let filter = this.state.filter;
      let useGrzMask = this.state.useGrzMask;

      for (let key in params) {
        filter[key] = params[key];

        if (key === 'sensor_ids' || key === 'license_plate_list' || key === 'colors') {
          filter[key] = params[key].split(',')
        }
        if (key === 'limit') {
          filter[key] = Number(params[key])
        }
        if (key === 'page') {
          filter[key] = Number(params[key])
        }
        if (key === 'datetime_start' || key === 'datetime_end') {
          let date = new Date();
          date.setTime(params[key]);
          filter[key] = date
        }
        if (key === 'license_plate_empty' || key === 'license_plate_invalid' || key === 'license_plate_foreign') {
          filter[key] = Boolean(filter[key]);
        }
      }

      this.setState({
        filter,
        useGrzMask
      })
    }
    this.getDriveList()
    this.getSensorList()
  }
  componentDidUpdate = (prevProps) => {
    let prevSearch = queryString.parse(prevProps.location.search);
    let currentSearch = queryString.parse(this.props.location.search);

    if (JSON.stringify(prevSearch) !== JSON.stringify(currentSearch)) {
      let params = currentSearch;
      if (Object.keys(params).length > 0) {
        let filter = {
          sensor_ids: [],
          license_plate_text: '',
          license_plate_list: [],
          mark: '',
          model: '',
          datetime_start: null,
          datetime_end: null,
          sort_field: '',
          sort_direction: '',
          license_plate_empty: false,
          license_plate_foreign: false,
          license_plate_invalid: false,

          limit: 40
        }

        for (let key in params) {
          filter[key] = params[key]

          if (key === 'sensor_ids' || key === 'license_plate_list' || key === 'colors') {
            filter[key] = params[key].split(',')
          }
          if (key === 'limit') {
            filter[key] = Number(params[key])
          }
          if (key === 'datetime_start' || key === 'datetime_end') {
            let date = new Date();
            date.setTime(params[key]);
            filter[key] = date
          }
          if (key === 'license_plate_empty' || key === 'license_plate_invalid' || key === 'license_plate_foreign') {
            filter[key] = Boolean(params[key]);
          }
          if (key === 'page') {
            filter[key] = Number(params[key])
          }
        }

        this.setState({
          filter
        })

        setTimeout(() => {
          if (this.state.workFile) {
            this.specifyReportRearch()
          } else {
            this.getDriveList();
          }
        }, 500)
      }
    }
  }

  // Конвертация фильтра на фронте для фильтра бекенда (проезды)
  getFilter = () => {
    let filter = {}

    for (let key in this.state.filter) {
      let item = this.state.filter[key];
      if (item) {
        filter[key] = item;

        if (key === 'license_plate_empty' || key === 'license_plate_invalid' || key === 'license_plate_foreign') {
          filter[key] = Boolean(item);
        }
        if (key === 'speed' || key === "speed_direction") {
          filter[key] = parseFloat(item);
        }
      }
    }
    if (this.state.pagination.orderBy) {
      filter['sort_direction'] = this.state.pagination.order;
      filter['sort_field'] = this.state.pagination.orderBy;
    }

    return filter
  }

  // Конвертация фильтра на фронте для фильтра бекенда (устройства)
  getFilterSensor = () => {
    let filter = {}

    for (let key in this.state.sensorFilter) {
      let item = this.state.sensorFilter[key];

      if (item) {
        filter[key] = item;
      }
    }

    return filter
  }

  // Метод получения списка устройств (камеры)
  getSensorList = () => {
    let filter = this.getFilterSensor();
    filter['page'] = 1;
    filter['limit'] = 999;

    axios('post', '/operator/sensor/find', filter).then((response) => {
      let sensorList = response.data

      if (!sensorList) {
        sensorList = []
      }

      this.setState({
        sensorList,
        totalMetricaSensor: response.headers['x-pagination-total-count']
      })
    }).catch((error) => {
    })
  }

  // Функция изменения фильтра устройств
  // реализованно с отложенным стартом, что бы
  // не грузить данные пока пользователь вводит фильтр
  onFilterSensor = (sensorFilter) => {
    clearTimeout(this.handleGetSensorList)

    this.setState({sensorFilter});

    this.handleGetSensorList = setTimeout(() => {
      this.getSensorList()
    }, 500)
  }

  // Метод получения списка проездов
  getDriveList = (infiniteScroll = false) => {
    this.setState({ isLoadingTable: true })
    let filter = this.getFilter();
    axios('post', '/operator/report/find', filter).then((response) => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;

      if (infiniteScroll){
        rows = [...rows, ...this.state.rows]
      }

      const totalPages = Math.ceil(Number.parseFloat(String(pagination.totalCount || 0).replace(/\D+/g,""))/pagination.rowsPerPage);
      console.log('totalPages: ', totalPages, pagination.limit);
      console.log('totalPages: ', Number.parseFloat(String(pagination.totalCount || 0).replace(/\D+/g,""))/pagination.rowsPerPage);

      this.setState({
        rows,
        pagination,
        tableLoader: false,
        isInfiniteScrollLoading: false,
        dateTimeRequest: moment(),
        isInfiniteScroll: rows.length > 0,
        isLoadingTable: false,
        totalPages,
      })
    }).catch((error) => {
      this.setState({
        isLoadingTable: false
      })
    })
  }

  // Метод загрузки списка при дохождения до конца списка
  // Пока не работает
  onLoadMore = () => {
    let filter = { ...this.state.filter };
    filter['page']++;

    this.setState({
      filter
    });

    this.onLoadContentEager()
  }
  onLoadContentEager = () => {
    let filter = this.getFilter();
    axios('post', '/operator/report/find', filter).then((response) => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;


      this.setState({
        rows: [...this.state.rows, ...rows],
        pagination,
        tableLoader: false,
        dateTimeRequest: moment(),
      })
    }).catch((error) => {
    })
  }

  // Фунциоанл запуска подробоного изучения сработки
  // модальное окно
  isOpenModalInformation = (modalInformation) => {
    this.setState({
      modalInformation,
      showModalInformation: true
    })
  }

  // Функционал конвертации и устоновки фильтра в URL браузера
  setFilterToUrl = () => {
    let filter = []

    for (let key in this.state.filter) {
      let item = this.state.filter[key];

      if (key === 'sensor_ids') {
        if (item.length <= 0) {
          item = null
        }
      } else if (key === 'datetime_start' || key === 'datetime_end') {
        item = new Date(item).getTime()
      } else if (key === 'license_plate_list') {
        item = item.join(',')
      }

      if (item) {
        filter.push(key + '=' + item);
      }
    }

    this.setState({
      filterString: 'dashboard?' + filter.join('&')
    })
    this.props.history.replace('dashboard?' + filter.join('&'))
  }

  // Фунционал изменений фильтра на странице
  changeFilters = (filter, offUpdate) => {
    clearTimeout(this.handleGetListFilter)

    filter['page'] = 1;

    this.setState({filter})

    if (!offUpdate) {
      this.handleGetListFilter = setTimeout(() => {
        this.setFilterToUrl()
      }, 500)
    }
  }

  // Фунционал изменения фильтра в таблице (меняем страницу)
  changeFiltersTable = (filter, offUpdate) => {
    clearTimeout(this.handleGetListFilter)

    this.setState({filter})

    if (!offUpdate) {
      this.handleGetListFilter = setTimeout(() => {
        this.setFilterToUrl()
      }, 500)
    }
  }
  isChangeRowPage = (pagination) => {
    this.setState({
      pagination
    })
    if (this.state.workFile) {
      this.specifyReportRearch()
    } else {
      this.getDriveList();
    }
  }

  // Метод поиска проездов через файл
  bulkReportSearch = (file) => {
    let data = new FormData();

    data.append('file', file);
    data.append('data', JSON.stringify(this.getFilter()));

    axios('post', '/operator/report/bulk-report-search', data).then(response => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;


      this.setState({
        rows,
        pagination,
        tableLoader: false,
        dateTimeRequest: moment(),

        workFile: true,
        workFileName: file.name
      })
    }).catch(error => {
      this.setState({
        errorWorkFile: true,
        errorWorkFileText: error.response.data.error
      })
    })
  }
  // Метод уточнения списка проездов если мы работаем с файлом
  specifyReportRearch = (infiniteScroll = false) => {
    let filter = this.getFilter();

    axios('post', '/operator/report/specify-report-search', filter).then((response) => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;

      if (infiniteScroll){
        rows = [...rows, ...this.state.rows]
      }

      this.setState({
        rows,
        pagination,
        tableLoader: false,
        isInfiniteScrollLoading: false,
        dateTimeRequest: moment(),
      })
    }).catch((error) => {
    })
  }
  // Удаление фкционала поиска по файлу (удаляем выбрный файл и запрашиваем простой список)
  removeFilterFile = () => {
    this.getDriveList();

    this.setState({
      workFile: false,
      workFileName: ''
    })
  }

  // infinite scroll table
  onInfiniteScroll = () => {
    // if (!this.state.isInfiniteScroll){
    //   return null
    // }
    //
    // let filter = this.state.filter;
    // filter['page'] = filter['page'] + 1;
    //
    // this.setState({
    //   filter,
    //   isInfiniteScroll: false,
    //   isInfiniteScrollLoading: true
    // })
    //
    // if (this.state.workFile) {
    //   this.specifyReportRearch(true)
    // } else {
    //   this.getDriveList(true);
    // }
  }
  // ---

  // Функционал экспорта документов
  exportDocument = (body) => {
    let filter = this.getFilter();

    filter['type'] = body.type;
    filter['encoding'] = Number(body.encoding);
    filter['use_bulk_result'] = this.state.workFile;

    axios('post', '/export/create', filter).then(response => {
      store.addNotification({
        title: 'Успешно',
        message: 'Задача на выгрузку успешно создана',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    }).catch(error => {

    })
  }

  hideGraph = (name) => {
    let widgets = this.props.dashboard.widgets;
    widgets[name] = false;
    this.props.setWidgets(widgets);
  }

  render() {
    const {classes} = this.props;
    const {
      pagination,
      dateTimeRequest,
      isLoadingTable
    } = this.state;

    return (
      <>
        <Box className={classes.root}>
          <HeadInformation
            totalCount={String(pagination.totalCount || 0).replace(/\D+/g,"")}
            searchTimeStamp={dateTimeRequest}
            onExportRows={this.exportDocument}
          />
          <Box mt={2}/>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item>
              <Filter
                pagination={pagination}
                filters={this.state.filter}
                totalCount={String(pagination.totalCount || 0).replace(/\D+/g,"")}
                onChange={(filter, offUpdate) => this.changeFilters(filter, offUpdate)}
                onChangeFile={this.bulkReportSearch}
                sensorList={this.state.sensorList}
                sensorFilter={this.state.sensorFilter}
                useGrzMask={this.state.useGrzMask}

                onChangeUseGrzMask={() => {
                  this.setState({useGrzMask: !this.state.useGrzMask})
                }}
                onFilterSensor={(filter) => this.onFilterSensor(filter)}
                onStartFilter={this.setFilterToUrl}
                onChangePagination={this.changeFiltersTable}
              />
            </Grid>
            <Grid item style={{flex: 1}}>
              <VisibleContent visible={Boolean(this.state.workFile)}>
                <BlockNotification
                  type="info"
                  title={`Ведется работа с файлом "${this.state.workFileName}"`}

                  onCancel={this.removeFilterFile}
                />
              </VisibleContent>
              <VisibleContent visible={Boolean(this.state.errorWorkFile)}>
                <BlockNotification
                  type="error"
                  title="Ошибка"
                  message={this.state.errorWorkFileText}
                />
              </VisibleContent>

              <Table
                columns={this.state.columns}
                showColumns={this.state.showColumns}
                rows={this.state.rows}
                selected={this.state.selected}
                pagination={this.state.pagination}
                filter={this.state.filter}
                loading={this.state.tableLoader || isLoadingTable}

                isInfiniteScrollLoading={this.state.isInfiniteScrollLoading}
                isSelectRowTable={this.isSelectRowTable}
                isDeleteRowTable={this.isDeleteRowTable}
                isChangeRowPage={this.isChangeRowPage}
                isChangeFilter={this.changeFiltersTable}
                isOpenModalInformation={this.isOpenModalInformation}

                onLoadMore={this.onLoadMore}
                onInfiniteScroll={this.onInfiniteScroll}
              />
            </Grid>
          </Grid>
        </Box>

        <ModalInformation
          open={this.state.showModalInformation}
          item={this.state.modalInformation}
          isClose={() => {
            this.setState({showModalInformation: false})
          }}
        />
      </>
    )
  }
}
const VisibleContent = React.memo(({ visible, children }) => {
  if (!visible) {
    return null
  }
  return children
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
};

export default withStyles(styles)(Dashboard)
