import React from "react";
import { Polyline } from "react-leaflet";
import L from "leaflet";

class PolylineDecorator extends React.Component {
    constructor(props) {
        super(props);

        this.polylineRef = React.createRef();
        this.polylineDecoratorRef = null;
    }

    componentDidMount() {
        const polyline = this.polylineRef.leafletElement;
        const { map } = this.polylineRef.props.leaflet;

        this.polylineDecoratorRef = L.polylineDecorator(polyline, {
            patterns : this.props.patterns
        }).addTo(map);
    }

    componentWillUnmount() {
        const polyline = this.polylineRef.leafletElement;

        this.polylineDecoratorRef.remove(polyline);
    }

    render() {
        return (
            <Polyline
                {...this.props}
                ref={polylineRef => this.polylineRef = polylineRef}
            />
        )
    }

}

export default PolylineDecorator
