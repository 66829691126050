import React, {Component} from 'react';
import {} from '@material-ui/core';

class SensorGroupView extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <div>

      </div>
    );
  }
}

export default SensorGroupView
