import React, {useState, useRef} from 'react';
import {useHistory} from 'react-router';
import {
	Avatar,
	Box,
	Container,
	Card,
	CardContent,
	CardMedia,
	Typography,
	colors,
	Grid,
	Button,
	Link,
	IconButton,
	makeStyles,

	Dialog,
	DialogTitle,
	DialogContent, TextField, Backdrop, CircularProgress
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LoginForm from './LoginForm';
import {
	X as XIcon
} from "react-feather";
import {setLocalStorage} from '../../../helpers/localStorage';
import InputMask from "react-input-mask";
import axios from "../../../plugins/axios";
import {sendMessages} from "../../../common/messages";
import moment from "moment";

let timeIntervalUpdateTime = null;
let currentTime = null;

function LoginView(props) {
	const classes = useStyles();
	const history = useHistory();

	const refTime = useRef();

	const [isOpenDialogVerification, setOpenDialogVerification] = useState(false);
	const [sendCodeRepeat, setSendCodeRepeat] = useState(false);
	const [user, setUser] = useState({});
	const [sessionId, setSessionId] = useState("");
	const [verificationCode, setVerificationCode] = useState("");
	const [isShowBackdrop, setShowBackdrop] = useState(false);

	// Функционал успешной авторизации пользователь
	const handleSubmitSuccess = (data) => {
		if (data['2fa_required']) {
			timeIntervalUpdateTime = setInterval(() => {
				startTime();
			}, 1000);
			currentTime = moment().add(5, 'm');

			setOpenDialogVerification(true);
			setUser(data.user);
			setSessionId(data.session_id);

			return null
		}

		setLocalStorage('session_id', data.session_id);
		props.setUser(data.user)
		history.replace('/')
	};

	// Функционал пожтверждение двух факторной авторизации кода (если у пользователя стоит что он должен)
	// авторизовываться через код
	const handleSendVification = async () => {
		setShowBackdrop(true);

		const response = await axios('post', '/auth/2fa', {
			session_id: sessionId,
			code: verificationCode.replace(/[^\d]/g, '')
		}).catch((error) => {
			return null
		});

		if (!response) {
			setShowBackdrop(false);

			sendMessages({
				message: "Не правильный код верификации",
				type: "danger"
			})

			return null
		}

		setLocalStorage('session_id', sessionId);
		props.setUser(user)
		history.replace('/')
	}

	// Функционал повторной отправки кода пользователя
	// переодичной отправки 5 минут
	const handleRepeatCode = async () => {
		await axios('post', '/auth/2fa-resend', {
			session_id: sessionId
		}).then((res) => {
			if (res?.data?.error) {
				sendMessages({message: res?.data?.error_text || "Систменая ошибка", type: "danger"});

				return null
			}

			sendMessages({message: "Код успешно отправлен", type: "success"});
		}).catch((error) => {
			return null
		});
		setSendCodeRepeat(false);
		timeIntervalUpdateTime = setInterval(() => {
			startTime();
		}, 1000);
		currentTime = moment().add(5, 'm');
	}

	// Функционал запуска обратного отчета времени
	const startTime = () => {
		const timeDifference = moment(moment(currentTime) - moment()).subtract(5, 'h').format('mm:ss');
		const isSendCodeRepeat = (moment(currentTime) - moment()) <= 0;
		setSendCodeRepeat(isSendCodeRepeat);

		if (isSendCodeRepeat) {
			clearInterval(timeIntervalUpdateTime)
		}

		if (!refTime.current) {
			return null
		}
		refTime.current.innerHTML = timeDifference;
	}

	return (
		<div className={classes.root}>
			<LoginForm onSubmitSuccess={handleSubmitSuccess}/>

			<Dialog
				open={isOpenDialogVerification}
				onClose={() => setOpenDialogVerification(false)}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>
					<Grid container justify="space-between" alignItems="center">
						<Grid item>
							<Typography variant="h3">Верификация</Typography>
						</Grid>
						<Grid item>
							<IconButton onClick={() => setOpenDialogVerification(false)} style={{padding: 0}}>
								<XIcon/>
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Box mb={1}>
						<Typography variant="formTitle">Введите код из СМС</Typography>

						<InputMask
							mask="999-999"
							value={verificationCode}
							onChange={({target}) => setVerificationCode(target.value)}
						>
							{(inputProps) => (
								<TextField
									{...inputProps}
									placeholder="___-___"
									variant="outlined"
									fullWidth
								/>
							)}
						</InputMask>
					</Box>
					<Box mb={2}>
						<Typography variant="caption">Не пришла СМС? {(sendCodeRepeat) ? (
							<Link onClick={handleRepeatCode} style={{cursor: "pointer"}}>Запросить СМС с кодом</Link>) : (
							<Typography variant="caption">Вы можете запросить новую через <span
								ref={refTime}>05:00</span></Typography>)}</Typography>
					</Box>
					<Box mb={1}>
						<Grid container spacing={1}>
							<Grid item>
								<Button onClick={handleSendVification}
								        disabled={verificationCode.replace(/[^\d]/g, '').length !== 6}
								        variant="contained">Отправить</Button>
							</Grid>
							<Grid item>
								<Button onClick={() => setOpenDialogVerification(false)}
								        variant="outlined">Закрыть</Button>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
			</Dialog>

			<Backdrop open={isShowBackdrop} invisible={isShowBackdrop}>
				<CircularProgress size={80} style={{color: 'white'}}/>
			</Backdrop>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100vw",
		height: "100vh"
	}
}));

export default LoginView;
