import React from 'react';
import {
    Link,
    TableCell,
    TableRow
} from '@material-ui/core';
import {
    CustomMoment as Moment
} from '../../../../../components';
import varibles from "../../../../../varibles";

const LoggingElementsSearchListUpload = (props) => {
    const {row, classes} = props;
    const {payload, username, user_id, ip} = row;

    const handleGetDownloadList = (url) => {
        let urlDownload = varibles.DOWNLOAD_URL + url;

        return urlDownload
    }

    let {status, license_plate, mark, model} = payload;

    status = (status === 10) ? 'активный' : 'неактивный';
    license_plate = (license_plate) ? license_plate : 'не заполнено';
    mark = (mark) ? mark : 'не заполнено';
    model = (model) ? model : 'не заполнено';

    return (
        <TableRow key={props.key} className={classes.className}>
            <TableCell width={120}>
                <div className={classes.cellPayload}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Moment
                            format="DD.MM.YYYY"
                            value={row['created']}
                        >
                            {row['created']}
                        </Moment>
                        <Moment
                            format="HH:mm:ss"
                            value={props.row['created']}
                        >
                            {row['created']}
                        </Moment>
                    </div>
                </div>
            </TableCell>
            <TableCell width={250}>
                <span style={{fontWeight: 500}}>Загрузка списка элементов розыска</span>
            </TableCell>
            <TableCell width={800}>
                {`Загружен элемент списка розыска. ГРЗ — ${license_plate}. Марка автомобиля: ${mark}. Модель автомобиля: ${model}.`}

                <Link style={{marginLeft: 8}} href={handleGetDownloadList(row.url)} target="_blank">Скачать файл</Link>
            </TableCell>
            <TableCell>{username}</TableCell>
            <TableCell>{ip}</TableCell>
        </TableRow>
    )
}

export default LoggingElementsSearchListUpload
