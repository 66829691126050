import React from "react";
import {
	Box,
	Grid,
	Tooltip,
	Popover,
	MenuList,
	MenuItem,
	IconButton,
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import {
	Person as PersonIcon,
	Message as MessageIcon,
	ExitToApp as ExitToAppIcon,
	Notifications as NotificationsIcon,
} from "@material-ui/icons";
import {
	Link,
	useRouteMatch
} from "react-router-dom";
import PopupState, {
	bindPopover
} from 'material-ui-popup-state';
import palette from "../../../../theme/palette";
import clsx from "clsx";

const Header = (props) => {
	const {
		user,
		mainMenu: _mainMenu,

		onExitUserSession,
		onOpenFormFeedback,
		onOpenNotification
	} = props;
	const classes = useStyles();
	const [mainMenu, setMainMenu] = React.useState([]);

	React.useEffect(() => {
		initMainMenu();
	}, []);

	const initMainMenu = () => {
		let list = [];
		_mainMenu.map((itemMenu) => {
			const paths = (itemMenu.paths || []).filter((path) => {
				if ((path.rules || []).length <= 0) {
					return true
				}
				return Boolean((path.rules || []).find((rule) => user?.roles?.[rule]))
			});
			const rules = itemMenu?.rules || [];
			if (rules.length <= 0) {
				list.push({
					...itemMenu,
					paths
				})
			}
			if (rules.find((rule) => user?.roles?.[rule])) {
				list.push({
					...itemMenu,
					paths
				})
			}
		});
		setMainMenu(list);
	}

	return (
		<Box className={classes.root}>
			<Link to="/" className={classes.logo}>
				<img src={require("../../../../assets/image/new-logo.png")}/>
				<div>
					<div>Цифровой путь</div>
					<Box className={classes.cityName}>{process.env.REACT_APP_CITY_NAME}</Box>
				</div>
			</Link>
			<Box className={classes.mainMenu}>
				{mainMenu.map((item, index) => (
					<ElementMainMenu
						key={`ElementMainMenu-${index}`}
						item={item}
					/>
				))}
			</Box>
			<Box className={classes.controls}>
				<Grid container spacing={1} alignItems="center" wrap="nowrap">
					<Grid item>
						<IconButton className={classes.controlButton} onClick={onOpenFormFeedback}>
							<MessageIcon/>
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton to="/account" className={classes.controlButton} component={Link}>
							<PersonIcon/>
						</IconButton>
					</Grid>
					<Grid item>
						<Tooltip title="Выход из системы" arrow>
							<IconButton className={classes.controlButton} onClick={onExitUserSession}>
								<ExitToAppIcon/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}
const ElementMainMenu = React.memo((props) => {
	const {
		item
	} = props;
	const classes = useStyles();
	const routeMatch = useRouteMatch();

	const handleClickLink = (popupState, event) => {
		if ((item.paths || []).length <= 1) {
			return
		}

		popupState.open(event);
		event.preventDefault();
		event.stopPropagation();
	}

	return (
		<PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<Box className={classes.elementMainMenu}>
					<Link
						to={item.path}
						className={clsx({
							[classes.elementMainMenuButton]: true,
							'active': (item.activePaths || []).includes(routeMatch.path)
						})}
						onClick={handleClickLink.bind(this, popupState)}
					>
						{Boolean(item.icon) && (<item.icon/>)}
						<span dangerouslySetInnerHTML={{ __html: item.label }}/>
					</Link>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
						transformOrigin={{vertical: 'top', horizontal: 'left'}}
					>
						<Box className={classes.elementMainMenuDropdown}>
							<MenuList>
								{(item?.paths || []).map((children) => (
									<MenuItem to={children.path} component={Link}>{ children.label }</MenuItem>
								))}
							</MenuList>
						</Box>
					</Popover>
				</Box>
			)}
		</PopupState>
	)
})

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		height: 64,
		backgroundColor: "black",
		padding: "0 20px",
		boxSizing: "border-box"
	},
	logo: {
		display: "flex",
		alignItems: "center",
		color: '#FFF',
		fontSize: '24px',
		fontWeight: '700',
		lineHeight: '105%',
		letterSpacing: '-0.8px',
		marginRight: 40,

		"& img": {
			height: 24,

			maxWidth: "100%",
			maxHeight: "100%",
			objectFit: "contain",
			objectPosition: "center",

			marginRight: 10
		},
		"&:hover": {
			color: palette.primary.main
		}
	},
	cityName: {
		marginTop: 4,
		opacity: 0.5,

		color: "#FFF",
		fontSize: "14px",
		fontWeight: "700",
		lineHeight: "105%",
		letterSpacing: "-0.9px",
	},
	mainMenu: {
		display: "flex",
		flex: 1,

		"& > *": {
			marginLeft: 32,
			"&:first-child": {
				marginLeft: 0
			}
		}
	},

	controls: {
		display: "flex",
		alignItems: "center",
		marginLeft: 40
	},
	controlButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 30,
		height: 30,
		padding: 6,
		backgroundColor: `${palette.primary.main}!important`,

		"& svg": {
			width: "100%",
			height: "100%",
			color: palette.black
		}
	},

	elementMainMenu: {},
	elementMainMenuButton: {
		display: "flex",
		alignItems: "center",
		height: 64,
		opacity: 0.7,

		color: "#FFF",
		fontFeatureSettings: "'ss01' on",
		fontSize: "13px",
		fontWeight: "600",
		lineHeight: "100%",
		letterSpacing: "0.65px",
		textTransform: "uppercase",

		"& > svg": {
			width: 18,
			height: 18,
			marginRight: 6
		},
		"&:hover": {
			opacity: 1
		},
		"&.active": {
			fontWeight: "800",
			position: "relative",
			opacity: 1,

			"&:after": {
				content: "''",
				position: "absolute",
				left: 0, right: 0, bottom: 0,
				height: 5,
				backgroundColor: palette.primary.main
			}
		}
	},
	elementMainMenuDropdown: {},
}));

export default React.memo(Header)
