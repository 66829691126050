import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Paper,
  Box,
  IconButton,

  FormControl,
  RadioGroup,
  FormControlLabel,

  Radio, Button
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
  X as XIcon
} from 'react-feather';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingBottom: 16,
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  },
  image: {
    maxHeight: 325,
    width: '100%',
    height: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },

  marker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    backgroundColor: '#2979FF',
    border: '1px solid rgba(21, 36, 53, 0.1)',
    boxShadow: '0px 4px 4px rgba(72, 93, 210, 0.4)',

    fontWeight: '600',
    fontSize: 16,
    lineHeight: '16px',
    color: 'white',

    '&:hover': {
      zIndex: 999 + '!important',
      boxShadow: '0 0 0 5px #FF4B4B'
    }
  },
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Modal = (props) => {
  const {onClose, open, onExportFile} = props;
  const [type, setType] = useState(null);
  const [composition, setComposition] = useState(null)
  const [encoding, setEncoding] = useState(null)

  // функция устоновки типа экспортного файла (PDF, CSV)
  const handleChangeType = function (event, name) {
    setType(name)
  }

  // функция устоновки состав файла (с фото, без фото)
  const handleChangeComposition = function (event, name) {
    setComposition(name)
  }

  // функция устоновки типа кодировки файла
  // UTF-8 - UBUNTU
  // Windows-1251 - Windows
  const handleChangeEncoding = function (event, name) {
    setEncoding(name)
  }

  // Экспорт файла
  const handleExportDocument = function () {
    let typeFile = type + '' + composition;

    let body = {
      type: typeFile,
      encoding: encoding
    }

    setType(null);
    setComposition(null);
    setEncoding(null);

    onExportFile(body)
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'md'}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={onClose}
      >
        <Typography variant="h3">
          Экспорт документа
        </Typography>
      </MyDialogTitle>
      <DialogContent>
        <Box bgcolor={'#f3f5f9'} borderRadius={4} p={2} mb={2}>
          <Typography variant={'formTitle'}>Тип файла</Typography>

          <FormControl component="fieldset">
            <RadioGroup
              row
              value={type}
              onChange={handleChangeType}
            >
              <FormControlLabel value="export_pdf" control={<Radio/>} label="PDF"/>
              <FormControlLabel value="export_csv" control={<Radio/>} label="CSV"/>
            </RadioGroup>
          </FormControl>
        </Box>

        <Box bgcolor={'#f3f5f9'} borderRadius={4} p={2} mb={2}>
          <Typography variant={'formTitle'}>Состав файла</Typography>

          <FormControl component="fieldset">
            <RadioGroup
              row
              value={composition}
              onChange={handleChangeComposition}
            >
              <FormControlLabel value="_photo" control={<Radio/>} label="С фото"/>
              <FormControlLabel value="" control={<Radio/>} label="Без фото"/>
            </RadioGroup>
          </FormControl>
        </Box>

        <Box bgcolor={'#f3f5f9'} borderRadius={4} p={2} >
          <Typography variant={'formTitle'}>Кодировка файла</Typography>

          <FormControl component="fieldset">
            <RadioGroup
              row
              value={encoding}
              onChange={handleChangeEncoding}
            >
              <FormControlLabel value="0" control={<Radio/>} label="UTF-8"/>
              <FormControlLabel value="10" control={<Radio/>} label="Windows-1251"/>
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogContent>
        <Grid container spacing={2} justify={'flex-end'}>
          <Grid item>
            <Button
              onClick={handleExportDocument}
              disabled={type == null || composition == null || encoding == null}
              variant="formSubmit"
            >
              Экспортировать
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default Modal
