import React from "react";
import {
	Grid,
	Button,
	Select,
	MenuItem,
	TextField,
	InputLabel,
	FormControl,
} from "@material-ui/core";
import violationProcessing from "../../constants/violationProcessing";
import vendors from "../../../../../constant/vendors";

const Filter = (props) => {
	const {
		filter,

		onChange,
		onSearch
	} = props;

	const handleChange = ({ target }) => {
		const { name, value } =  target;
		let _filter = {...filter};
		_filter[name] = value;
		_filter.page = 1;
		onChange(_filter);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextField
					label="Произвольный поиск"
					placeholder="Произвольный поиск..."
					variant="outlined"
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					value={filter.serial}
					name="serial"
					label="Серийный номер"
					placeholder="Введите серийный номер..."
					variant="outlined"
					fullWidth
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<InputLabel>Производитель</InputLabel>
					<Select
						value={filter.vendor}
						name="vendor"
						label="Производитель"
						variant="outlined"
						onChange={handleChange}
					>
						<MenuItem value="">Сбросить</MenuItem>
						{vendors.map((vendor) => (
							<MenuItem
								key={`vendors-${ vendor.value }`}
								value={vendor.value}
							>{ vendor.label }</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<InputLabel>Тип</InputLabel>
					<Select
						value={filter.type}
						name="type"
						label="Тип"
						variant="outlined"
						onChange={handleChange}
					>
						<MenuItem value={null}>Сбросить</MenuItem>
						<MenuItem value="10">Стационарный</MenuItem>
						<MenuItem value="20">Передвижной</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<TextField
					value={filter.address}
					name="address"
					label="Местоположение"
					placeholder="Введите местоположение..."
					variant="outlined"
					fullWidth
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<InputLabel>Обработка нарушений</InputLabel>
					<Select
						label="Обработка нарушений"
						variant="outlined"
						onChange={handleChange}
					>
						<MenuItem value="">Сбросить</MenuItem>
						{Object.keys(violationProcessing).map((key) => (
							<MenuItem value={key}>{violationProcessing[key]}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<Button
					size="small"
					variant="contained"
					onClick={onSearch}
				>Поиск</Button>
			</Grid>
		</Grid>
	)
}

export default Filter
