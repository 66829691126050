import React from 'react';
import {
  useTheme,
  Grid,
  Typography
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import './index.scss';

const PieGraph = (props) => {
  const theme = useTheme();
  const {series, labels} = props;

  let sensorWorkByHour = 0;
  let sensorErrorByHour = 0;
  let sensorOffByHour = 0;

  if (series.length > 0){
    sensorWorkByHour = series[0].data[series[0].data.length - 1];
    sensorErrorByHour = series[1].data[series[1].data.length - 1];
    sensorOffByHour = series[2].data[series[2].data.length - 1];
  }

  let seriesData = [sensorWorkByHour, sensorErrorByHour, sensorOffByHour];
  const options = {
    chart: {
      type: 'donut',
    },
    labels: [...labels],
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    colors: ['#6FF86C', '#F86C6C', '#bfbfbf']
  };

  return (
    <>
      <Chart
        className={'sensor-pie-graph'}
        type="donut"
        height="150"
        options={options}
        series={seriesData}
      />

      <Grid container justify={'center'} spacing={1}>
        <Grid item>
          <Typography variant={'h4'} style={{color: '#6FF86C'}}>{ sensorWorkByHour }</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'}>/</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'} style={{color: '#F86C6C'}}>{ sensorErrorByHour }</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'}>/</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'} style={{color: '#bfbfbf'}}>{ sensorOffByHour }</Typography>
        </Grid>
      </Grid>
      <Grid container justify={'center'} spacing={1}>
        <Grid item>
          <Typography variant={'body1'} style={{color: '#6FF86C'}}>работает</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body1'}>/</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body1'} style={{color: '#F86C6C'}}>в ошибке</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body1'}>/</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body1'} style={{color: '#bfbfbf'}}>выключен</Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default PieGraph
