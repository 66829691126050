import React, {Component} from 'react';
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import {
  Table
} from './components';
import {Page} from '../../../components';
import axios from '../../../plugins/axios';

class FeedbackList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],


      filter: {
        page: 1,
        limit: 20
      },
      pagination: {
        rowsPerPage: 20,
        page: 0,
        orderBy: null,
        order: 'asc',
        totalCount: 0
      },

      urlFeedback: '',

      isLoading: true
    }
  }

  componentDidMount = () => {
    if(Object.keys(this.props.user).length > 0){
      this.onCheckUser()
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if(!this.state.urlFeedback && Object.keys(this.props.user).length > 0){
      this.onCheckUser()
    }
    if (this.state.urlFeedback != prevState.urlFeedback){
      this.onLoadList()
    }
  }

  onCheckUser = () => {
    let { roles } = this.props.user;
    let urlFeedback = '/feedback/get-user';

    if (roles.admin){
      urlFeedback = '/feedback/get'
    }

    this.setState({
      urlFeedback
    })
  }

  onLoadList = (filter = this.state.filter) => {
    if (this.state.urlFeedback){
      axios('post', this.state.urlFeedback, filter).then(response => {
        let rows = response.data;
        let totalCount = response.headers['x-pagination-total-count'];
        let pagination = this.state.pagination;

        if (!rows) {
          rows = []
        }

        pagination.totalCount = totalCount;

        this.setState({
          pagination,
          rows: rows
        })
      }).catch(error => {

      })
    }
  }

  onChangeFilter = (filter) => {
    this.setState({ filter })

    this.onLoadList(filter)
  }

  render() {
    return (
      <Page title={'Пользователи'}>
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Grid container alignItems={'center'}>
                <Grid item style={{marginRight: 16}}>
                  <Typography variant={'h3'}>История обращений</Typography>
                </Grid>
              </Grid>

            </Box>

            <Box mb={3}>
              <Table
                rows={this.state.rows}
                filter={this.state.filter}
                pagination={this.state.pagination}
                loading={this.state.isLoading}

                onChangeFilter={this.onChangeFilter}
              />
            </Box>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default FeedbackList
