import React from 'react';
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Tooltip,
  TableSortLabel
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },

  tableSortLabel: {
    fontSize: 12,
    lineHeight: 1.1
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    cursor: 'pointer'
  }
}));

const TableComponents = props => {
  const classes = useStyles();
  const history = useHistory();

  let {rows, columns, activeTableRow, setActiveRow} = props;

  let pagination = {
    sort: null
  }

  const toRoute = (params) => {
    history.push("/routes/a570ap69/121212")
  }

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                {
                  columns.map((column, idx) => (
                    <TableCell
                      key={'table-cell-' + idx}
                      align={'center'}
                      // sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        className={classes.tableSortLabel}
                        // active={orderBy === headCell.id}
                        // direction={orderBy === headCell.id ? order : 'asc'}
                        // onClick={createSortHandler(headCell.id)}
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <TableRow
                  key={row.name}
                  onClick={() => { setActiveRow(idx) }}
                  role="checkbox"
                  tabIndex={-1}
                  selected={activeTableRow == idx}
                  aria-checked={activeTableRow == idx}
                  className={classes.tableRow}
                >
                  {
                    Object.keys(row).map((key, idx) => (
                      <TableCell
                        key={'table-cell-' + idx}
                      >{row[key]}</TableCell>
                    ))
                  }

                  <TableCell
                    align={'right'}
                    className={classes.tableCellAction}
                  >
                    <Tooltip title="Просмотреть маршрут">
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={() => toRoute(row)}
                      >
                        <MoreVertIcon/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Подсказка 1">
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                      >
                        <UnfoldMoreIcon/>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={3}
          page={1}
          onChangePage={() => {}}
          onChangeRowsPerPage={() => {}}
        />
      </Paper>
    </div>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
};

export default TableComponents;
