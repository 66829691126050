import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Map,
  TileLayer,
  FeatureGroup
} from 'react-leaflet'
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import {EditControl} from 'react-leaflet-draw';
import PolylineDecorator from './PolylineDecorator';
import Points from "./Points";

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: 'calc(100vh - 108px)'
  },
}));

const MapObject = (props) => {
  const arrow = [{
    offset: '95%',
    repeat: 0,
    symbol: L.Symbol.arrowHead({
      pixelSize: 10,
      pathOptions: {
        stroke: true,
        weight: 5,
        fill: true,
        fillColor: 'red'
      }
    })
  }];
  const classes = useStyles();
  const {position, points, apiPoints, showCluster, connectDots, openInfo} = props;

  const _onEditPath = function (e) {
    e.layers.eachLayer(a => {
    });
  }
  const _onCreate = function (event) {
    let polygonList = event.layer._latlngs;
    polygonList.map(polygon => {
      polygon.map(point => {
      })
    })
  }
  const _onDeleted = function () {
  }

  const getListPolyline = function () {
    let list = []

    apiPoints.map((point, idx) => {
      if (idx < apiPoints.length - 1) {
        list.push([point.position, apiPoints[idx + 1].position])
      }
    })

    return list
  }

  return (
    <Map
      className={classes.leafletContainer}
      center={position}
      length={4}
      zoom={10}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      <Points
        points={points}
        showCluster={showCluster}
        openInfo={() => openInfo()}
      />

      {
        getListPolyline().map((polyline, idx) => (
          <PolylineDecorator
            key={'polyline-' + idx}
            patterns={arrow}
            interactive={false}
            positions={polyline}

          />
        ))
      }

      <FeatureGroup>
        <EditControl
          position='topright'
          onEdited={_onEditPath}
          onCreated={_onCreate}
          onDeleted={_onDeleted}
          draw={{
            polyline: false,
            rectangle: true,
            circle: false,
            marker: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
    </Map>
  );
};

export default MapObject;
