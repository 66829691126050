import React from "react";
import {
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@material-ui/core";
import {
	Skeleton
} from "@material-ui/lab";
import {
	VisibleContent
} from "../../../../../components";
import moment from "moment";

const TableCustom = (props) => {
	const {
		data
	} = props;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Дата и время запроса</TableCell>
					<TableCell>Дата и время нарушения</TableCell>
					<TableCell>Комплекс</TableCell>
					<TableCell>Местоположение</TableCell>
					<TableCell>ГРЗ</TableCell>
					<TableCell>Код нарушения</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<VisibleContent visible={Boolean(true)}>
					{data.map((item) => (
						<RowData
							key={`RowData-${item.id}`}
							item={item}
						/>
					))}
				</VisibleContent>
				<VisibleContent visible={Boolean(false)}>
					<RowsLoading/>
				</VisibleContent>
			</TableBody>
		</Table>
	)
};
const RowData = (props) => {
	const {
		item
	} = props;
	console.log('item: ', item);

	return (
		<TableRow>
			<TableCell>{ moment(item.request_time).format("DD.MM.YYYY HH:mm:ss") }</TableCell>
			<TableCell>{ moment(item.datetime).format("DD.MM.YYYY HH:mm:ss") }</TableCell>
			<TableCell>
				<span dangerouslySetInnerHTML={{__html: [item.sensor_vendor, item.sensor_serial].filter((t) => !!t).join("<br/>") || 'Н/Д'}}/>
			</TableCell>
			<TableCell>
				{item.sensor_location || 'Н/Д'}
			</TableCell>
			<TableCell>{item.license_plate}</TableCell>
			<TableCell>{item?.violations?.[0]}</TableCell>
		</TableRow>
	)
}
const RowsLoading = () => {
	return new Array(10).fill(1).map((t, i) => (
		<TableRow key={`RowsLoading-${ i }`}>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
		</TableRow>
	))
}

export default TableCustom
