import React from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import clsx from 'clsx';
import {CustomMoment as Moment} from '../../../../../components';

const LoggingElementDefault = (props) => {

  return (
    <TableRow
      key={props.key}
      className={props.className}
    >
      {
        props.columns.map((item, idx) => {
          if (props.showColumns.indexOf(item.field) === -1) {
            return null
          }

          if (item.type === 'type') {
            switch (props.row[item.field]) {
              case 'logLogin': {
                return (
                  <TableCell key={item.field + '-' + idx} width={250}>
                    <span style={{ fontWeight: 500 }}>авторизация пользователя</span>
                  </TableCell>
                )
              }
              case 'logReportSearch': {
                return (
                  <TableCell key={item.field + '-' + idx}  width={250}>
                    <span style={{ fontWeight: 500 }}>применение фильтра проездов</span>
                  </TableCell>
                )
              }
              case 'logBulkReportSearch': {
                return (
                  <TableCell key={item.field + '-' + idx}  width={250}>
                    <span style={{ fontWeight: 500 }}>применение фильтра проездов</span>
                  </TableCell>
                )
              }
              case 'logSearchItemCreate': {
                return (
                  <TableCell key={item.field + '-' + idx}  width={250}>
                    <span style={{ fontWeight: 500 }}>создание элемента розыска</span>
                  </TableCell>
                )
              }
              case 'logSearchListUpload': {
                return (
                  <TableCell key={item.field + '-' + idx}  width={250}>
                    <span style={{ fontWeight: 500 }}>загрузка списка элементов розыска</span>
                  </TableCell>
                )
              }
            }
          }
          if (item.type === 'payload') {
            let type = props.row['type'];
            let payload = props.row['payload'];

            let string = [];

            if (type === 'logReportSearch') {
              string.push('Получение списка фиксаций')

              if (payload.license_plate_mask) {
                string.push('Поиск по маске: ' + payload.license_plate_mask)
              }
              if (payload.license_plate_list && payload.license_plate_list.length > 0) {
                string.push('Поиск по множественному поиску: ' + payload.license_plate_list.join(','))
              }
            }

            return (
              <TableCell key={item.field + '-' + idx}  width={800}>
                <div className={props.className.cellPayload}>{string.join(' ')}</div>
              </TableCell>
            )
          }
          if (item.type === 'dateTime') {
            return (
              <TableCell key={item.field + '-' + idx} width={120}>
                <div className={props.className.cellPayload}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Moment
                      format="DD.MM.YYYY"
                      value={props.row[item.field]}
                      filter-name={item.filterName}
                      style={item.filterName ? {cursor: 'context-menu'} : {}}
                    >
                      {props.row[item.field]}
                    </Moment>
                    <Moment
                      format="HH:mm:ss"
                      value={props.row[item.field]}
                      filter-name={item.filterName}
                      style={item.filterName ? {cursor: 'context-menu'} : {}}
                    >
                      {props.row[item.field]}
                    </Moment>
                  </div>
                </div>
              </TableCell>
            )
          }
          if (typeof props.row[item.field] === 'object') {
            return (
              <TableCell key={item.field + '-' + idx}>1</TableCell>
            )
          }

          return (
            <TableCell
              key={item.field + '-' + idx}
              value={props.row[item.field]}
              filter-name={item.filterName}
              style={item.filterName ? {cursor: 'context-menu'} : {}}
            >{props.row[item.field]}</TableCell>
          )
        })
      }
    </TableRow>
  )
}

export default LoggingElementDefault
