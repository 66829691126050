import React, { PureComponent } from "react";
import {
    Box,
    Grid,
    Typography,
    CircularProgress
} from "@material-ui/core";

class PageLoading extends PureComponent {
    render() {
        return (
            <Box bgcolor="#f4f6f8" height="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">

                <Box mb={5}>
                    <CircularProgress size={160} thickness={2} style={{color: '#192A3E'}}/>
                </Box>

                <Typography variant="h4">Идет загрузка, ожидайте...</Typography>

            </Box>
        )
    }
}

export default PageLoading
