import React from 'react';
import {useHistory} from 'react-router';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {
  Box,
  Grid,
  Button,
  TextField,
  makeStyles, Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function Form({className, onSubmitSuccess, initialValues, ...rest}) {
  const history = useHistory();
  const classes = useStyles();
  const handleSendForm = (values) => {
    onSubmitSuccess(values)
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required('Заполните поле'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus
      }) => {
        try {
          handleSendForm(values)
        } catch (error) {
          const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

          setStatus({success: false});
          setErrors({submit: message});
        }
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          autoComplete={'off'}
          {...rest}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant={'formTitle'}>Наименование</Typography>
              <TextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                placeholder={'Наименование группы'}
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="title"
                value={values.title}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={2} style={{ width: '100%', display: 'grid' }}>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    size="small"
                    variant="formCancel"
                    style={{ marginRight: 10 }}
                    onClick={() => handleCancel()}
                  >
                    Отменить
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    size="small"
                    type="submit"
                    variant="formSubmit"
                  >
                    Применить
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>

          {errors.submit && (
            <Box mt={2}>
              <Alert severity="error">{errors.submit}</Alert>
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
}

Form.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

Form.defaultProps = {
  onSubmitSuccess: () => {
  }
};

export default Form;
