const SET_USER = 'account/SET_USER';
const REMOVE_USER = 'account/REMOVE_USER';

const initialState = {
  user: null
};

export function setUser(user) {
  return {
    type: SET_USER,
    user
  }
}
export function removeUser() {
  let user = null;

  return {
    type: REMOVE_USER,
    user
  }
}

export default function UserInfo(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER: {
      let user = action.user

      return {
        ...state,
        user
      }
    }
    case REMOVE_USER: {
      let user = action.user

      return {
        ...state,
        user
      }
    }
    default:
      return state;
  }
}
