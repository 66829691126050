import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Paper,
  Box,
  IconButton,

  FormControl,
  RadioGroup,
  FormControlLabel,

  Radio, Button, DialogActions
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
  X as XIcon
} from 'react-feather';

const Modal = (props) => {
  const {onClose, open, onExportFile} = props;
  const [type, setType] = useState(null);
  const [composition, setComposition] = useState(null)
  const [encoding, setEncoding] = useState(null)

  const handleChangeType = function (event, name) {
    setType(name)
  }
  const handleChangeComposition = function (event, name) {
    setComposition(name)
  }
  const handleChangeEncoding = function (event, name) {
    setEncoding(name)
  }

  const handleExportDocument = function () {
    let typeFile = type;

    let body = {
      type: typeFile,
      encoding: encoding
    }

    setType(null);
    setComposition(null);
    setEncoding(null);

    onExportFile(body)
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={onClose}
    >
      <DialogTitle>
        <Typography variant="h3">
          Экспорт документа
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box bgcolor={'#f3f5f9'} borderRadius={4} p={2} mb={2}>
          <Typography variant={'formTitle'}>Тип файла</Typography>

          <FormControl component="fieldset">
            <RadioGroup
              row
              value={type}
              onChange={handleChangeType}
            >
              <FormControlLabel value="routes_csv" control={<Radio/>} label="CSV без фото"/>
              <FormControlLabel value="routes_pdf" control={<Radio/>} label="PDF без фото"/>
            </RadioGroup>
          </FormControl>
        </Box>
        <Box bgcolor={'#f3f5f9'} borderRadius={4} p={2} >
          <Typography variant={'formTitle'}>Кодировка файла</Typography>

          <FormControl component="fieldset">
            <RadioGroup
              row
              value={encoding}
              onChange={handleChangeEncoding}
            >
              <FormControlLabel value="0" control={<Radio/>} label="UTF-8"/>
              <FormControlLabel value="10" control={<Radio/>} label="Windows-1251"/>
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box px={2} pb={1}>
          <Grid container justify={'flex-end'}>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                disabled={type == null || encoding == null}
                onClick={handleExportDocument}
              >
                Экспортировать
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default Modal
