import React, { useState, useEffect } from "react";
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {getLocalStorage} from "../helpers/localStorage";
import {setUser} from "../AppState";
import { withRouter } from 'react-router-dom'
import {
    PageLoading
} from "../components";

import Main from "./Main";
import Authorization from "./Authorization";
import axios from "../plugins/axios";
import {win} from "leaflet/src/core/Browser";

const queryString = require('query-string');

const Routes = (props) => {
    const { app, history } = props;
    const { user } = app;
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        ( async () => {
            await handleSetAccount();
        })();
    }, []);
    useEffect(() => {
        window.addEventListener('user_clear', handleChangeLocalStorage);
    }, []);
    useEffect(() => history.listen(() => {
        const isAccount = Boolean(localStorage.getItem('session_id'));
        if (isAccount) {
            return null
        }
        props.setUser({});
    }), [])

    const handleSetAccount = async () => {
        const sessionId = getLocalStorage('session_id');

        if (!sessionId) {
            setLoading(false);
            return false
        }

        const user = await axios('post', '/auth/session', {session_id: sessionId}).then((response) => {
            const data = response.data;
            if (!data.status) {
                return null
            }

            return data.user;
        }).catch((error) => {
            return null
        });

        props.setUser(user);
        setLoading(false);
    }
    const handleChangeLocalStorage = () => {
        console.log('handleChangeLocalStorage')
    }

    if (isLoading) {
        return (
            <PageLoading/>
        )
    }

    let Navigation = Main;
    if (!user || !user?.id) {
        Navigation = Authorization;
    }

    return (
        <Navigation/>
    )
}

const RoutesRouter = withRouter(Routes);

export default compose(
    connect(
        state => ({
            app: state.app
        }),
        dispatch => ({
            setUser: (user) => dispatch(setUser(user))
        }),
    ),
)(RoutesRouter);
