import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Paper,
  makeStyles,
  Grid,
  IconButton
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
  X as XIcon
} from 'react-feather';
import {DropzoneArea} from 'material-ui-dropzone'

const useStyles = makeStyles((theme) => ({
  dropzoneArea: {
    '& .MuiDropzoneArea-invalid': {
      borderColor: '#152435',
      backgroundImage: 'initial'
    }
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  },
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size={'small'}
        >
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalUpload = (props) => {
  const classes = useStyles();
  const {isClose, open, isLoadFile} = props;
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    setFiles(event);
  }
  const handleFileSend = () => {
    isLoadFile(files);
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'md'}
      onClose={isClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={isClose}
      >
        <Typography variant='h4'>
          Загрузить файл
        </Typography>
      </MyDialogTitle>
      <DialogContent dividers>
        <Grid container className={classes.gridHeight} spacing={1}>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant={'body1'}>
                К загрузке возможны следующие файлы (txt, csv)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'}>
                Общее количество загружаемых ГРЗ не должно превышать 500 штук
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.dropzoneArea}>
            <DropzoneArea
              acceptedFiles={['.txt', '.csv']}
              onChange={handleFileChange}
              filesLimit={1}
              dropzoneText={'Перетащите файл или нажмите'}
              showPreviews={false}
              showAlerts={false}
              showFileNames={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleFileSend} color="primary" variant={'contained'}>
          Загрузить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalUpload
