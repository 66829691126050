// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Account from './Account';

export default compose(
  connect(
    state => ({
      user: state.app.user
    }),
    dispatch => ({}),
  ),
)(Account);
