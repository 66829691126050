import React from "react";
import {
	Grid,
} from "@material-ui/core";
import {
	DateRange,
	ModalSensorFilterV2
} from "../../../../../components";

const Filter = () => {

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<DateRange
						label="Временной промежуток"
					/>
				</Grid>
				<Grid item xs={12}>
					<ModalSensorFilterV2/>
				</Grid>
			</Grid>
		</>
	)
}

export default Filter
