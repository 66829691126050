import React from "react";
import {
	makeStyles
} from "@material-ui/styles";
import palette from "../../theme/palette";

// 10 - Стационарное устройство слежения
// 20 - Мобильное устройство слежения
// 0 - Тип не получен

const labels = {
	"0": "Неизвестный",
	"10": "Стационарный",
	"20": "Мобильный",
};
const icons = {
	"0": require("../../assets/icons/sensor/error-type.svg"),
	"10": require("../../assets/icons/sensor/stationary-type.svg"),
	"20": require("../../assets/icons/sensor/mobile-type.svg"),
}

const DeviceType = ({ type: _type }) => {
	const type = String(_type);
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.icon}>
				<img src={icons[type]}/>
			</div>
			<div className={classes.name}>
				{ labels[type] }
			</div>
		</div>
	)
};

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center"
	},
	icon: {
		width: 24,
		height: 24,
		borderRadius: "100%",
		backgroundColor: palette.primary.main,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginRight: 6,
		padding: 2,
		boxSizing: "border-box",

		"& img": {
			width: "100%",
			height: "100%",
			objectFit: "contain"
		}
	},
	name: {
		fontSize: "12px",
		lineHeight: "130%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
}));

DeviceType.defaultProps = {
	type: 0
}

export default React.memo(DeviceType)
