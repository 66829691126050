export default [
    '#0000FF',
    '#00008B',
    '#4B0082',
    '#800080',
    '#006400',
    '#008080',
    '#800000',
    '#8B4513',
    '#C71585',
    '#FF0000',
    '#000000',
    '#4682B4',
    '#9966FF',
    '#00FF00',
    '#0000FF',
    '#00008B',
    '#4B0082',
    '#800080',
    '#006400',
    '#008080',
    '#800000',
    '#8B4513',
    '#C71585',
    '#FF0000',
    '#000000',
    '#4682B4',
    '#9966FF',
    '#00FF00',
]
