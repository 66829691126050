import React, {useState} from 'react';
import {
	makeStyles,
	Button,
	Grid,
	RadioGroup,
	FormControlLabel,
	Radio, Typography
} from '@material-ui/core';
import {
	CheckCircle as CheckCircleIcon
} from '@material-ui/icons';
import {
	ModalDevices,
} from './components/';
import {
	Check as CheckIcon
} from 'react-feather';
import './index.scss'
import axiosAgent from "../../plugins/axios";

const ModalSensorFilter = (props) => {
	const {value, onChange, onFilter, renderButton, onStartFilter, onClose} = props;
	const [open, setOpen] = useState(false);

  const [sensorList, setSensorList] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      await getSensorList();
    })();
  }, []);
  const getSensorList = async () => {
    const _filter = {
      page: 1,
      limit: 999999,
    }
    const res = await axiosAgent('post', '/operator/sensor/find', _filter).then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });
	  setSensorList(res);
  }

  const [sensorFilter, setSensorFilter] = React.useState({
    address: "",
    vendor: "",
    serial: "",
    type: ""
  });
	const [sensorFilterItems, setSensorFilterItems] = React.useState(null);
  const handleChangeSensorFilter = (_filter) => {
	  setSensorFilter(_filter);

		const address = (_filter.address || '').toLocaleLowerCase();
		const serial = (_filter.serial || '').toLocaleLowerCase();
		const vendor = (_filter.vendor || '').toLocaleLowerCase();
		const type = (_filter.type || '').toLocaleLowerCase();
		if (!address && !serial && !vendor && !type) {
			setSensorFilterItems(null)
			return
		}

		let _sensorFilterItems = [...sensorList].filter((item) => {
			const _serial = (item.serial || '').toLocaleLowerCase();
			const _address = (item.address || '').toLocaleLowerCase();

			return false
		});
		console.log('_filter: ', _filter);
		console.log('sensorList: ', sensorList);
  };

	const handleOpenModal = () => {
		setOpen(true)
	}
	const handleCloseModal = () => {
		setOpen(false);

		if (onClose) {
			onClose();
		}
		handleStartFilter();
	}
	const handleChangeSensor = (list) => {
		onChange(list)
	}

	const handleStartFilter = () => {
		if (onStartFilter) {
			onStartFilter()
		}
	}

	const _sensorList = () => {
		return sensorList
	}

	return (
		<>
			<SelectComponent
				value={value}
				label={'Комплексы'}
				onClick={handleOpenModal}
				renderButton={renderButton}
			/>

			<ModalDevices
				open={open}
				isClose={handleCloseModal}

				devicesSensor={_sensorList()}
				devicesSensorActive={value}
				isChangeDevicesSensor={handleChangeSensor}

				filter={sensorFilter}
				onFilter={handleChangeSensorFilter}
				onStartFilter={handleStartFilter}
			/>
		</>
	)
}
const SelectComponent = React.memo((props) => {
	const {value, onClick, renderButton} = props;
	const [type, setType] = useState(null);
	const classes = stylesSelectComponents();
	const selectedCount = (value || []).length;

	const handleClearFilter = () => {
	}
	const handleChangeType = (event) => {
		let item = event.target;
		let name = item.name;
		let value = item.value;

		if (value == type) {
			value = null
		}

		setType(value);
	}

	return (
		<Button
			fullWidth
			size="small"
			variant="contained"
			onClick={onClick}
		>
			{Boolean(selectedCount > 0) ? `Комплексы (${selectedCount})` : 'Комплексы'}
		</Button>
	)
})
const stylesSelectComponents = makeStyles((theme) => ({
	selectGrid: {
		flex: 1,

		'& .MuiInputBase-root': {
			backgroundColor: 'transparent'
		},
		'& .MuiInputBase-root.Mui-focused': {
			boxShadow: 'none',
			outline: 'none'
		},
	},
	rightGrid: {
		display: 'flex',
		padding: '8px 0',

		'& .MuiFormGroup-root': {
			marginLeft: -14
		},
		'& .MuiFormControlLabel-root': {
			marginLeft: 14,
			marginRight: 0,
			display: 'flex',
			alignItems: 'center'
		},
		'& .MuiIconButton-root': {
			marginRight: 6
		}
	},
	rightGridContent: {
		display: 'flex',
		paddingLeft: 20,
		borderLeft: '1px solid rgba(100, 112, 131, 0.2)'
	},
	button: {
		fontSize: 13,
		lineHeight: '120%',
		fontWeight: '500',

		'& svg': {
			marginLeft: 5
		}
	},

	placeholder: {},
	value: {},

	buttonOpen: {
		cursor: 'pointer',
	},

	radioGroup: {
		'& .MuiRadio-root': {
			color: '#D6DAE1'
		},
		'& .MuiFormControlLabel-label': {
			color: 'rgba(100, 112, 131, 0.8)',
			fontSize: 15,
			lineHeight: '24px',
			fontWeight: 'normal'
		},
		'& .Mui-checked': {
			color: theme.palette.primary.main,

			'&~.MuiFormControlLabel-label': {
				color: theme.palette.primary.main
			},
		}
	}
}));

export default React.memo(ModalSensorFilter)
