import React from 'react';
import {
  Grid,
  Button,
  TablePagination
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const CustomTablePagination = (props) => {
  const {
    rows,
    pagination,
    filter,
    onChangeRowsPerPage,
    onChangePage,

    useEagerLoading,
    onLoadMore
  } = props;

  const _renderLabelDisplayedRows = (props) => {
    return `Страница ${props.page + 1}. Показаны с ${props.from} по ${props.to} из ${props.count}.`
  }
  const handleChangePage = (event, page) => {
    let table = document.querySelector('.MuiTable-root');
    if (table) {
      let posTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      let { top } = table.getBoundingClientRect();
      let scrollTop = posTop + (top - 100);

      window.scrollTo(0, scrollTop)
    }

    page--

    onChangePage(event, page)
  }
  const handleLoadMore = () => {
    let posTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

    setTimeout(() => {
      window.scrollTo(0, posTop)
    }, 2000)

    onLoadMore()
  }

  let count = pagination.totalCount;
  let countPage = Math.ceil(pagination.totalCount / ((filter.limit) ? filter.limit : 40));

  if ( countPage >= 999 ){
    countPage = 999
  }

  return (
    <>
      <Grid container alignItems={'center'} justify={'flex-end'}>
        <Grid item>
          <Pagination
            count={countPage}
            page={(filter.page) ? filter.page: 1}
            onChange={handleChangePage}
          />
        </Grid>

        {
          false && (
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={Number(count)}
                rowsPerPage={(filter.limit) ? filter.limit : 1}
                page={(filter.page) ? filter.page - 1 : 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}

                labelDisplayedRows={_renderLabelDisplayedRows}

                nextIconButtonText={'Следующая страница'}
                backIconButtonText={'Предыдущая страница'}
              />
            </Grid>
          )
        }
      </Grid>
    </>
  )
}

export default CustomTablePagination
