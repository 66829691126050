import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,

    Typography,
    Box,
    Grid,
    Tooltip,
    Button
} from "@material-ui/core";

const DialogConfirmation = (props) => {
    const { title, comment, buttonConfirmation, onConfirmation, isOpen, onClose } = props;

    return (
        <Dialog
            open={isOpen}
            maxWidth="md"

            fullWidth

            onClose={onClose}
        >
            <DialogTitle>
                <Typography variant="h3">{ title || "Уведомление" }</Typography>
            </DialogTitle>

            <DialogContent>

                <Box mb={2}>
                    <Typography variant="subtitle1">{ comment }</Typography>
                </Box>

                <Box>
                    <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                            <Button variant="outlined" onClick={onClose}>Отмена</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={onConfirmation}>{ buttonConfirmation || 'Подтвердить' }</Button>
                        </Grid>
                    </Grid>
                </Box>

            </DialogContent>
        </Dialog>
    )
};

export default DialogConfirmation
