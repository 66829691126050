import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Grid,
  Button,
  TextField,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axios from '../../../plugins/axios';

function LoginForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();

  const login = (form, setStatus, setErrors) => {
    axios('post', '/auth/login', form).then((response => {
      let data = response.data

      if (data.error){
        setStatus({ success: false });
        setErrors({ submit: data.error_text });

        return null
      }

      onSubmitSuccess(data)
    })).catch((error => {
      setStatus({ success: false });
      setErrors({ submit: error.response.data.message });
    }))
  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required('Заполните поле'),
        password: Yup.string().max(255).required('Заполните поле')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus
      }) => {
        try {
          login(values, setStatus, setErrors)
        } catch (error) {
          const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

          setStatus({ success: false });
          setErrors({ submit: message });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          autoComplete={'off'}
          {...rest}
        >
          <Box className={classes.left}>
            <Box className={classes.logoImage}>
              <img src={require("../../../assets/image/new-logo.png")}/>
            </Box>
            <Box className={classes.cityName}>
              {process.env.REACT_APP_CITY_NAME}
            </Box>
            <Typography className={classes.logoText}>
              Цифровой<br/>путь
            </Typography>
            <Box className={classes.formAuth}>
              <Typography variant="h1" align="center">Авторизация</Typography>
              <Box mt={5}/>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    autoFocus
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                    label="Логин"
                    margin="normal"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="username"
                    value={values.username}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    name="password"
                    label="Пароль"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="small"
                    type="submit"
                    variant="contained"
                  >
                    Авторизоваться
                  </Button>
                  {errors.submit && (
                    <Box mt={2}>
                      <Alert severity="error">{errors.submit}</Alert>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classes.right}>
            <img src={require("../../../assets/image/auth-bg.jpg")}/>
          </Box>
        </form>
      )}
    </Formik>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    background: "#152435"
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "560px",
    padding: "60px 20px",
    boxSizing: "border-box"
  },
  right: {
    flex: 1,
    position: "relative",

    "& img": {
      position: "absolute",
      top: 0, left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "bottom",
      mixBlendMode: 'luminosity',
    }
  },
  logoImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& img": {
      height: 54
    }
  },
  cityName: {
    marginTop: 20,
    color: "#FFF",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "105%",
    textAlign: "center",
    letterSpacing: "-0.9px",
  },
  logoText: {
    marginTop: "auto",
    color: '#FFF',
    textAlign: 'center',
    fontSize: '52px',
    fontWeight: '700',
    lineHeight: '105%',
    letterSpacing: '-2.86px',
  },
  formAuth: {
    borderRadius: '10px',
    background: '#F4F6F8',
    margin: "0 auto",
    marginTop: "auto",
    padding: 32,
    boxSizing: "border-box",
    width: "100%",
    maxWidth: "420px",
  },
}));

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};
LoginForm.defaultProps = {
  onSubmitSuccess: () => {}
};

export default LoginForm;
