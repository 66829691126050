import palette from '../palette';

export default {
  root: {
    padding: "8px",
    borderColor: "rgba(21, 36, 53, 0.1)",
    background: "transparent",
    backgroundColor: "#F4F6F8!important"
  },
  head: {
    borderBottom: "none",

    fontSize: 12,
    lineHeight: "100%",
    color: "#647083",
    letterSpacing: "-0.24px"
  },
  body: {
    fontSize: 16,
    lineHeight: "130%",
    color: palette.black,
    letterSpacing: "-0.32px"
  }
};
