// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import SensorList from './SensorList';
import {setFavorites} from "../../../AppState";

export default compose(
  connect(
    state => ({
        app: state.app
    }),
    dispatch => ({
      setFavorites: (favorites) => dispatch(setFavorites(favorites)),
    }),
  ),
)(SensorList);
