import React from "react";
import {
	Grid,
	Tooltip,
	IconButton,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import {
	Skeleton
} from "@material-ui/lab";
import {
	Edit as EditIcon,
	Delete as DeleteIcon
} from "@material-ui/icons";
import {
	Link
} from "react-router-dom";
import {
	VisibleContent
} from "../../../../../components";
import clsx from "clsx";

const TableCustom = (props) => {
	const {
		data,
		isLoad,

		onRemove
	} = props;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Название</TableCell>
					<TableCell>Тип</TableCell>
					<TableCell>URL / Path</TableCell>
					<TableCell>Статус</TableCell>
					<TableCell align="right">Действия</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<VisibleContent visible={!isLoad}>
					{data.map((item) => (
						<RowItem
							key={`RowItem-${ item.id }`}
							item={item}
							onRemove={onRemove}
						/>
					))}
				</VisibleContent>
				<VisibleContent visible={isLoad}>
					<RowsLoading/>
				</VisibleContent>
			</TableBody>
		</Table>
	)
};
const RowItem = (props) => {
	const {
		item,
		onRemove
	} = props;
	const classes = useStyles();

	return (
		<TableRow>
			<TableCell>{ item.title }</TableCell>
			<TableCell>{ item.type }</TableCell>
			<TableCell>{ item.endpoint }</TableCell>
			<TableCell>
				<Grid container>
					<Grid item>
						<div className={clsx({
							[classes.status]: true,
							'--on': item.active,
							'--off': !item.active,
						})}>
							{item.active ? 'Активна' : 'Отключена'}
						</div>
					</Grid>
				</Grid>
			</TableCell>
			<TableCell>
				<Grid container justify="flex-end">
					<Grid item>
						<Tooltip title="Редактировать" arrow>
							<IconButton to={`/target-config/${ item.id }`} size="small" component={Link}>
								<EditIcon/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title="Удалить" arrow>
							<IconButton
								size="small"
								color="secondary"
								onClick={onRemove.bind(this, item, false)}
							>
								<DeleteIcon/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</TableCell>
		</TableRow>
	)
};
const RowsLoading = () => {
	return new Array(5).fill(1).map((t, i) => (
		<TableRow>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell align="right"><Skeleton/></TableCell>
		</TableRow>
	))
}

const useStyles = makeStyles(() => ({
	status: {
		borderRadius: 8,
		padding: "4px 8px",
		boxSizing: "border-box",

		fontSize: 15,
		lineHeight: "15px",
		color: "white",

		"&.--on": {
			backgroundColor: "#62AF3E",
		},
		"&.--off": {
			backgroundColor: "#E13118",
		},
	}
}));

export default TableCustom
