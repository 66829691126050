import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Tabs,
  Tab,
  Box,
  IconButton,
  TableHead,
  Button
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Map, TileLayer, Marker} from 'react-leaflet';
import ReactPlayer from 'react-player/lazy'
import {withStyles} from '@material-ui/core/styles';
import {
  X as XIcon,
  Copy as CopyIcon
} from 'react-feather';
import {
  Image,
  CustomMoment as Moment
} from '../../../../../components';
import {store} from 'react-notifications-component';
import {renderToStaticMarkup} from "react-dom/server";
import {divIcon} from "leaflet";
import varibles from "../../../../../varibles";

const useStyles = makeStyles((theme) => ({
  buttonDelete: {
    color: 'rgba(0, 0, 0, 0.4)'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },

  image: {
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },

  tabsContent: {
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  headerTime: {
    color: theme.palette.gray.dark,
    marginLeft: 20
  },

  tabPanel: {
    '& .MuiBox-root': {
      padding: 0,
      paddingTop: 15,
    },
    '& .MuiGrid-item > *': {
      borderRadius: 4,
      overflow: 'hidden'
    }
  },


  table: {
    backgroundColor: '#f3f5f9',
    borderRadius: 4,
    overflow: 'hidden',

    '& tr th, td': {
      borderRight: '1px solid #cdd9ec',
      '&:last-child': {
        borderRight: 'none'
      }
    }
  },
  tableHeader: {},
  tableHeaderRow: {
    backgroundColor: '#f3f5f9!important'
  },
  tableBodyRow: {
    backgroundColor: '#f3f5f9',

    '& .MuiTableCell-root': {
      fontSize: 16,
      lineHeight: '21px!important',
      fontWeight: '500!important'
    }
  },
  tableHeaderCell: {}
}));

const styles = (theme) => ({
  root: {
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  },
  image: {
    maxHeight: 325,
    width: '100%',
    height: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },

  marker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    backgroundColor: '#2979FF',
    border: '1px solid rgba(21, 36, 53, 0.1)',
    boxShadow: '0px 4px 4px rgba(72, 93, 210, 0.4)',

    fontWeight: '600',
    fontSize: 16,
    lineHeight: '16px',
    color: 'white',

    '&:hover': {
      zIndex: 999 + '!important',
      boxShadow: '0 0 0 5px #FF4B4B'
    }
  },
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{flex: 1}}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ImageMapInformation = withStyles(styles)((props) => {
  const {images, mapPosition, classes} = props;

  if (Object.keys(images).length == 1) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Image
            src={varibles.IMAGE_URL + images.main}
            classImage={classes.image}
          />
        </Grid>
        <Grid item xs={8}>
          <Map
            className={classes.leafletContainer}
            center={mapPosition}
            length={4}
            zoom={14}
            style={{width: '100%', minHeight: 325, height: '100%'}}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />

            <Marker
              position={mapPosition}
            />

          </Map>
        </Grid>
      </Grid>
    )
  }
  if (Object.keys(images).length == 2) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Image
            src={varibles.IMAGE_URL + images.main}
            classImage={classes.image}
          />
        </Grid>
        <Grid item xs={6}>
          <Map
            className={classes.leafletContainer}
            center={mapPosition}
            length={4}
            zoom={14}
            style={{width: '100%', minHeight: 325, height: '100%'}}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />

            <Marker
              position={mapPosition}
            />

          </Map>
        </Grid>
        <Grid item xs={3}>
          <Image
            src={varibles.IMAGE_URL + images.img3}
            classImage={classes.image}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Map
          className={classes.leafletContainer}
          center={mapPosition}
          length={4}
          zoom={14}
          style={{width: '100%', minHeight: 325, height: '100%'}}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />

          <Marker position={mapPosition}/>

        </Map>
      </Grid>
    </Grid>
  )
})

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <XIcon style={{color: "white"}}/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalInformation = (props) => {
  const classes = useStyles();
  const {isClose, open, item} = props;
  const [tab, setTab] = React.useState(0);

  if (!item || Object.keys(item).length <= 0) {
    return null
  }

  const mapPosition = [item.gps.lat, item.gps.lon];

  let grz = item.license_plate;

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  // Фунционал копирования ГРЗ в буфер обмена пользователя
  const handleCopyGrz = () => {
    navigator.clipboard.writeText(item.license_plate)
      .then(() => {
        store.addNotification({
          message: 'ГРЗ скопирован в буфер обмена',
          type: 'info',
          insert: 'top',
          container: 'bottom-left',
          dismiss: {
            duration: 3000,
            onScreen: false,
            pauseOnHover: true,
            delay: 0
          }
        });
      })
      .catch(err => {
      });
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'lg'}
      onClose={isClose}
      PaperComponent={PaperComponent}
    >
      <MyDialogTitle onClose={isClose}>
        <Grid container alignItems={'center'}>
          <Grid item>
            <Typography variant='h3'>
              { grz }
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleCopyGrz}>
              <CopyIcon color="white"/>
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant={'subtitle1'} className={classes.headerTime}>
              Время фиксации: <Moment format={'DD.MM.YYYY HH:mm:ss'}>{item.datetime}</Moment>
            </Typography>
          </Grid>
        </Grid>
      </MyDialogTitle>
      <DialogContent>
        <Grid container className={classes.gridHeight}>
          <Grid item xs={12} className={classes.tabsContent}>
            <Tabs
              orientation={'horizontal'}
              variant="scrollable"
              value={tab}
              onChange={handleChangeTab}
              className={classes.tabs}
            >
              <Tab label="Фотофиксация"/>
            </Tabs>
            <TabPanel value={tab} index={0} className={classes.tabPanel}>
              <ImageMapInformation
                images={item.images}
                mapPosition={mapPosition}
              />
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <Box mt={3} mb={3}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableHeaderRow}>
                    <TableCell align={'left'} className={classes.tableHeaderCell} width={150}>
                      Время фиксации
                    </TableCell>
                    <TableCell align={'left'} className={classes.tableHeaderCell} width={150}>
                      Время загрузки
                    </TableCell>
                    {
                      false && (
                        <>
                          <TableCell align={'left'} className={classes.tableHeaderCell}>
                            Марка
                          </TableCell>
                          <TableCell align={'left'} className={classes.tableHeaderCell}>
                            Модель
                          </TableCell>
                          <TableCell align={'left'} className={classes.tableHeaderCell}>
                            Цвет
                          </TableCell>
                          <TableCell align={'left'} className={classes.tableHeaderCell}>
                            Тип
                          </TableCell>
                        </>
                      )
                    }
                    <TableCell align={'left'} className={classes.tableHeaderCell}>
                      Скорость
                    </TableCell>
                    <TableCell align={'left'} className={classes.tableHeaderCell} width={250}>
                      Местоположение
                    </TableCell>
                    <TableCell align={'left'} className={classes.tableHeaderCell}>
                      Направление
                    </TableCell>
                    <TableCell align={'left'} className={classes.tableHeaderCell}>
                      Код устройства
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.tableBodyRow}>
                    <TableCell>
                      <Moment format="DD.MM.YYYY HH:mm">
                        {item.datetime}
                      </Moment>
                    </TableCell>
                    <TableCell>
                      <Moment format="DD.MM.YYYY HH:mm">
                        {item.created}
                      </Moment>
                    </TableCell>
                    {
                      false && (
                        <>
                          <TableCell>
                            <div>{item.mark}</div>
                          </TableCell>
                          <TableCell>
                            <div>{item.model}</div>
                          </TableCell>
                          <TableCell>
                            <div>{item.color}</div>
                          </TableCell>
                          <TableCell>
                            <div>{item.type}</div>
                          </TableCell>
                        </>
                      )
                    }
                    <TableCell>
                      <div>{item.speed} км/ч</div>
                    </TableCell>
                    <TableCell>
                      <div style={{ maxWidth: 250 }}>{item.address}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.direction}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.device_code}</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            {
              false && (
                <div>
                  <Box mb={2}>
                    <Typography variant={'subtitle1'}>
                      Равным образом дальнейшее развитие различных форм деятельности позволяет оценить значение
                      соответствующий условий активизации. Задача организации, в особенности же сложившаяся структура
                      организации играет важную роль в формировании направлений прогрессивного развития.
                    </Typography>
                  </Box>

                  <Button variant={'contained'}>Скачать материалы фиксации</Button>
                </div>
              )
            }

          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ModalInformation
