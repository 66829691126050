import React from "react";
import {
	Box,
	Grid,
} from "@material-ui/core";
import {
	Pagination
} from "@material-ui/lab";
import PropTypes from "prop-types";
import FilterTemplate from "../FilterTemplate";

const PageFilterTableContent = (props) => {
	const {
		children,

		totalPage,
		currentPage,
		filterTitle,

		onChangePage,
		onResetFilter
	} = props;
	const [isOpenFilter, setOpenFilter] = React.useState(true);

	const ChildrenHead = children.find((t) => t?.props?.name === "head");
	const ChildrenBody = children.find((t) => t?.props?.name === "body");
	const ChildrenFilter = children.find((t) => t?.props?.name === "filter");


	const handleChangeOpenFilter = (_val) => {
		setOpenFilter(_val);
	};


	return (
		<>

			<VisibleContent visible={Boolean(ChildrenHead)}>
				{ChildrenHead}
			</VisibleContent>

			<Grid container spacing={1} wrap="nowrap">
				<VisibleContent visible={Boolean(ChildrenFilter)}>
					<Grid item>
						<FilterTemplate
							title={filterTitle}
							totalPages={totalPage}
							currentPage={currentPage}

							onReset={onResetFilter}
							onChangePage={onChangePage}
							onChangeClose={handleChangeOpenFilter}
						>
							{ChildrenFilter}
						</FilterTemplate>
					</Grid>
				</VisibleContent>
				<VisibleContent visible={Boolean(ChildrenBody)}>
					<Grid item style={{flex: 1}}>
						{ChildrenBody}
					</Grid>
				</VisibleContent>
			</Grid>

			<VisibleContent visible={Boolean(!isOpenFilter && totalPage > 1)}>
				<BottomPagePagination
					page={currentPage}
					total={totalPage}
					onChange={onChangePage}
				/>
			</VisibleContent>

		</>
	)
};
const VisibleContent = React.memo(({visible, children}) => {
	if (!visible) return
	return children
});
const BottomPagePagination = ({ page, total, onChange }) => {

	const handleChangePage = (event, newPage) => {
		onChange(newPage);
	}

	return (
		<Grid container justify="flex-end" style={{ marginTop: "20px" }}>
			<Grid item>
				<Pagination
					page={page}
					count={total}
					onChange={handleChangePage}
				/>
			</Grid>
		</Grid>
	)
};

PageFilterTableContent.propTypes = {
	totalPage: PropTypes.number,
	currentPage: PropTypes.number,

	filterTitle: PropTypes.string,

	onChangePage: PropTypes.func,
	onResetFilter: PropTypes.func,
	onChangeFilter: PropTypes.func,
};
PageFilterTableContent.defaultFilter = {
	totalPage: 1,
	currentPage: 1,

	onResetFilter: () => {},
	onChangeFilter: () => {},

	onChangePage: () => {},
};

export default PageFilterTableContent
