import React, {Component} from 'react';
import {
  Grid,
  Switch,
  FormControlLabel
} from '@material-ui/core';

import './index.scss';

import {
  Map
} from './components';

class RouteMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      points: [],
      apiPoints: [],

      map: {
        connectDots: true,
        showPointNumbers: true,
        showLegend: true,
        showCluster: true,
      }
    }
  }

  componentDidMount = () => {
    this.setMapPoints()
  }

  setMapPoints = () => {
    let objectPoints = {}
    let points = []
    let apiPoints = [
      {
        title: 'Точка 2',
        position: [56.8219000, 60.6122000]
      },
      {
        title: 'Точка 1',
        position: [56.8519000, 60.6122000]
      },
      {
        title: 'Точка 3',
        position: [56.8919000, 60.6122000]
      },
      {
        title: 'Точка 1',
        position: [56.8519000, 60.6122000]
      },
      {
        title: 'Точка 2',
        position: [56.8219000, 60.6122000]
      },
      {
        title: 'Точка 3',
        position: [56.8919000, 60.6122000]
      },
      {
        title: 'Точка 1',
        position: [56.8519000, 60.6122000]
      },
      {
        title: 'Точка 2',
        position: [56.8219000, 60.6122000]
      },
    ]

    apiPoints.map((point, idx) => {
      let key = point.position.join('_')
      if (!objectPoints[key]) {
        objectPoints[key] = {
          points: [],
          position: point.position,
          type: ''
        }
      }

      if ( idx === 1 ){
        objectPoints[key]['className'] = 'marker--start'
      }
      if ( idx === apiPoints.length - 1 ){
        objectPoints[key]['className'] = 'marker--end'
      }

      objectPoints[key]['points'].push(point)
    })

    for (let key in objectPoints) {
      let point = objectPoints[key]

      points.push({
        countRecords: point.points.length,
        position: point.position,
        points: point.points,
        className: point.className,
      })
    }

    this.setState({
      points,
      apiPoints
    })
  }
  openInfo = () => {
  }

  render() {
    return (
      <div>
        <div className={'container'}>
          <Grid container spacing={2}>
            <Grid item>
              <FormControlLabel
                control={<Switch
                  checked={this.state.map.connectDots}
                  onChange={() => {
                    this.state.map.connectDots = !this.state.map.connectDots;
                    this.setState({map: this.state.map})
                  }}
                />}
                label="Соединять точки линиями"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch
                  checked={this.state.map.showPointNumbers}
                  onChange={() => {
                    this.state.map.showPointNumbers = !this.state.map.showPointNumbers;
                    this.setState({map: this.state.map})
                  }}
                />}
                label="Показывать номера точек"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch
                  checked={this.state.map.showLegend}
                  onChange={() => {
                    this.state.map.showLegend = !this.state.map.showLegend;
                    this.setState({map: this.state.map})
                  }}
                />}
                label="Показывать легенду"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch
                  checked={this.state.map.showCluster}
                  onChange={() => {
                    this.state.map.showCluster = !this.state.map.showCluster;
                    this.setState({map: this.state.map})
                  }}
                />}
                label="Объединить точки"
              />
            </Grid>
          </Grid>
        </div>

        <Map
          position={[56.8519000, 60.6122000]}
          points={this.state.points}
          apiPoints={this.state.apiPoints}
          showCluster={this.state.map.showCluster}
          connectDots={this.state.map.connectDots}
          openInfo={() => this.openInfo()}
        />

        {
          (this.state.map.showLegend) && (
            <div className={'legend'}>
              <div className={'legend__item'}>
                <div className={'legend__icon legend__icon--green'}/>
                <div> - начальная точка маршрута</div>
              </div>
              <div className={'legend__item'}>
                <div className={'legend__icon legend__icon--yellow'}/>
                <div> - конечтная точка маршрута</div>
              </div>
              <div className={'legend__item'}>
                <div className={'legend__icon legend__icon--blue'}/>
                <div> - Промежуточный точки</div>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default RouteMap
