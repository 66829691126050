import React, {Component} from 'react';
import {
  Box,
  Grid,
  Paper,
  Button,
  Tooltip,
  Divider,
  Collapse,
  Typography
} from '@material-ui/core';
import {
  ChevronUp as ChevronUpIcon,
  ChevronDown as ChevronDownIcon
} from 'react-feather';
import queryString from 'query-string';
import {
  Table,
  Filter,
  ModalDelete
} from './components/index';
import axios from '../../../plugins/axios';
import {
  Page,
  PageFilterTableContent
} from '../../../components/index';
import {store} from "react-notifications-component";
import {Link} from "react-router-dom";

class SensorList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          label: 'Наименование',
          field: 'Title',
          align: 'left',
        },
        {
          label: '',
          field: '',
          align: 'left',
        },
      ],
      selected: [],
      groupList: [],

      pagination: {
        rowsPerPage: 20,
        page: 1,
        orderBy: 'orderBy',
        order: 'desc'
      },
      filters: {
        title: '',

        limit: 20,
        page: 1,
      },

      showModalDelete: false,
      itemModalDelete: {},

      showFilter: false
    };

    this.handleGetListFilter = null;
  }

  componentDidMount = () => {
    let params = queryString.parse(this.props.location.search);

    if (Object.keys(params).length > 0) {
      let filters = this.state.filters

      for (let key in params) {
        filters[key] = params[key]
      }

      this.setState({
        filters
      })
    }

    this.loadSensorList()
  }

  // Конвертация фильтра фронта на бек
  getFilter = () => {
    let filter = {};

    for (let key in this.state.filters) {
      let item = this.state.filters[key];

      if (item) {
        filter[key] = item;
      }

      if (
        key === 'page' ||
        key === 'limit'
      ) {
        filter[key] = Number(item)
      }
    }

    return filter
  }

  // Получение списка груп
  loadSensorList = () => {
    let filter = this.getFilter();

    axios('post', '/operator/sensor-group/find', filter).then((response) => {
      let rows = response.data
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }
      pagination.totalCount = totalCount;

      this.setState({
        rows,
        pagination
      })
    }).catch((error) => {
    })
  }

  // ЛОгика работы с фильтром
  setFilterToUrl = () => {
    let filter = []

    for (let key in this.state.filters) {
      let item = this.state.filters[key];

      if (item) {
        filter.push(key + '=' + item);
      }
    }

    this.props.history.replace('sensor-group?' + filter.join('&'))
  }
  changeFilters = (filters) => {
    clearTimeout(this.handleGetListFilter)

    this.setState({filters})

    this.handleGetListFilter = setTimeout(() => {
      this.loadSensorList()
      this.setFilterToUrl()
    }, 500)
  }

  // Логика работы с тбалицей
  isSelectRowTable = (selected) => {
    this.setState({
      selected
    })
  }
  isDeleteRowTable = (props) => {
    let {consent, item} = props;

    if (!consent) {
      this.setState({
        showModalDelete: true,
        itemModalDelete: item
      })

      return null
    }

    this.setState({
      showModalDelete: false
    })

    axios('get', '/operator/sensor-group/delete/' + item.id).then((response) => {
      store.addNotification({
        title: 'Успешно',
        message: 'Запись удалена',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.loadSensorList()
    }).catch((error) => {

    })
  }
  isCloseDeleteModal = () => {
    this.setState({
      showModalDelete: false,
      itemModalDelete: {}
    })
  }
  isChangeRowPage = (pagination) => {
    this.setState({
      pagination
    })

    this.loadSensorList()
  }

  render() {
    const {
      filter,
      pagination
    } = this.state;
    const totalPage = Math.ceil(Number.parseFloat(pagination.totalCount || '1')/pagination.rowsPerPage);

    return (
      <>
        <PageFilterTableContent
          currentPage={Number.parseFloat(filter?.page || 1)}
          totalPage={totalPage}
        >
          <slot name="head">
            <ComponentHead/>
          </slot>
          <slot name="filter">
            <Filter
              filters={this.state.filters}
              onChange={(filters) => this.changeFilters(filters)}
            />
          </slot>
          <slot name="body">
            <Table
              columns={this.state.columns}
              rows={this.state.rows}
              selected={this.state.selected}
              pagination={this.state.pagination}
              filter={this.state.filters}

              isSelectRowTable={this.isSelectRowTable}
              isDeleteRowTable={this.isDeleteRowTable}
              isChangeRowPage={this.isChangeRowPage}
            />
          </slot>
        </PageFilterTableContent>

        <ModalDelete
          item={this.state.itemModalDelete}
          open={this.state.showModalDelete}
          isDelete={this.isDeleteRowTable}
          isClose={() => this.isCloseDeleteModal()}
        />
      </>
    );
  }
};
const ComponentHead = React.memo(() => {
  return (
    <Grid container justify="space-between" style={{ marginBottom: "8px" }}>
      <Grid item>
        <Typography variant="h1">Группы устройств</Typography>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="contained"
          to="/sensor-group/create"
          component={Link}
        >Добавить группу</Button>
      </Grid>
    </Grid>
  )
});

export default SensorList
