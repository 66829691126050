import React, {Component} from 'react';
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button, SvgIcon, Collapse, Paper
} from '@material-ui/core';
import {
  MapSensor
} from './components';
import axios from '../../../plugins/axios';
import {Check as CheckIcon, XCircle as XCircleIcon} from 'react-feather';
import {
  CustomMoment as Moment
} from "../../../components";
import moment from "moment";

class SensorView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sensor: {},

      showMap: false,
      loading: true,
    }
  }

  componentDidMount = () => {
    this.getSensor(this.props.match.params.id)
  }

  getSensor = (id) => {
    axios('get', '/operator/sensor/get/' + id).then((response) => {
      this.setState({
        loading: false,
        sensor: response.data
      })
    }).catch((error) => {
    })
  }

  render() {
    return (
      <Container maxWidth='lg'>
        <Box
          pt={3}
          pb={3}
        >
          <Typography
            variant="h3"
            color="textPrimary"
          >
            {this.state.sensor.title}
          </Typography>

          <Box
            mt={3}
            bgcolor={'white'}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Серийный</TableCell>
                  <TableCell>
                    <div>{this.state.sensor.serial}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Идентификатор</TableCell>
                  <TableCell>
                    <div>{this.state.sensor.title}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Модель</TableCell>
                  <TableCell>
                    <div>{this.state.sensor.vendor}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Местоположение</TableCell>
                  <TableCell>
                    <div>{this.state.sensor.address}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Последняя фиксация</TableCell>
                  <TableCell>
                    <Moment format="DD.MM.YYYY HH:mm">
                      { this.state.sensor.last_report_fix }
                    </Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Последняя загрузка</TableCell>
                  <TableCell>
                    <Moment format="DD.MM.YYYY HH:mm">
                      { this.state.sensor.last_report }
                    </Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Статус</TableCell>
                  <TableCell>
                    <SvgIcon fontSize='small'>
                      {
                        this.state.sensor.status ? <CheckIcon/>: <XCircleIcon/>
                      }
                    </SvgIcon>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Группа</TableCell>
                  <TableCell>
                    <div>{this.state.sensor.group_id}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Номер точки</TableCell>
                  <TableCell>
                    <div>{this.state.sensor.report_count}</div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {
              (this.state.sensor.gps) && (
                <Box p={2}>
                  <Button
                    variant={'outlined'}
                    fullWidth
                    onClick={() => {this.setState({showMap: !this.state.showMap})}}
                  >
                    { this.state.showMap? 'Скрыть' : 'Показать на карте' }
                  </Button>

                  <Box mt={2} mb={this.state.showMap ? 2 : 0}>
                    <Collapse in={this.state.showMap}>
                      <Paper elevation={0}>
                        <MapSensor
                          sendorGps={this.state.sensor.gps}
                          title={this.state.sensor.title}
                        />
                      </Paper>
                    </Collapse>
                  </Box>
                </Box>
              )
            }
          </Box>
        </Box>
      </Container>
    );
  }
}

export default SensorView
