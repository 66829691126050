// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import SensorView from './SensorView';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(SensorView);
