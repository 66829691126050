import palette from '../palette';

export default {
  tooltip: {
    borderRadius: '8px',
    backgroundColor: palette.primary.main,
    backdropFilter: 'blur(8px)',
    padding: "5px 10px",

    color: '#152435',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '130%',
    letterSpacing: '-0.28px',
  },
  arrow: {
    color: palette.primary.main,
  }
};
