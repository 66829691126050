import React, {Component} from "react";
import {
	Box,
	Backdrop,
	Typography, CircularProgress
} from "@material-ui/core";
import {
	Page
} from "../../../components";
import {
	Form
} from "./components";
import axios from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {sendMessages} from "../../../common/messages";
import {withStyles} from "@material-ui/styles";

class UserCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				username: '',
				full_name: '',
				email: '',
				phone: '',
				password: '',
				allowed_ips: "",
				roles: [],
				status: 0,
				send_notifications: false,
				enable_2fa: false,
			},

			isShowBackdrop: false
		};

		this.refFormik = React.createRef();
	}

	// функция создания пользователя
	onCreateUser = (form) => {
		const body = this.getBody(form);

		axios('post', urls["user-create"], body).then((response) => {
			sendMessages({
				message: "Пользователь успешно создан",
				type: "success"
			});

			this.props.history.goBack();
		}).catch((err) => {
			sendMessages({
				message: err?.response?.data?.details || "Ошибка сервера",
				type: "danger"
			});
		});
	}

	// конвертация тела создания пользователя
	// со фронта на бек
	getBody = (form) => {
		let body = {};

		Object.keys(form).map((key) => {
			let value = form[key];

			if (key === "status") {
				value = !!value ? 1 : 0
			}
			if (key === "send_notifications") {
				value = Boolean(value)
			}
			if (key === "roles") {
				value = value.join(';');
			}
			if (key === "phone" && value) {
				value = `+${value.replace(/[^\d]/g, '')}`;
			}
			if (key === "allowed_ips") {
				value = (value || "").split(",").map((address) => address.split(" ").join("")).join(",")
			}

			if (
				value ||
				key === "status" ||
				key === "enable_2fa" ||
				key === "send_notifications"
			) {
				body[key] = value;
			}
		});

		return body
	}

	// изменение формы
	onChangeForm = (form) => {
		this.refFormik.current.setValues(form);
		this.setState({form});
	}

	render() {
		const {form, isShowBackdrop} = this.state;
		const { classes } = this.props;

		return (
			<Page>

				<Box maxWidth={1200} margin="0 auto">
					<Typography
						variant="h1"
						className={classes.title}
					>Добавление пользователя</Typography>

					<Box className={classes.content}>

						<Form
							innerRef={this.refFormik}
							initialValues={form}

							onChange={this.onChangeForm}
							onSubmit={this.onCreateUser}
						/>

					</Box>
				</Box>


				<Backdrop open={this.state.isShowBackdrop} invisible={this.state.isShowBackdrop}>
					<CircularProgress size={80} style={{color: 'white'}}/>
				</Backdrop>

			</Page>
		)
	}
}

const styles = {
	title: {
		marginBottom: 20,

		fontWeight: "700",
		fontSize: "32px",
		lineHeight: "100%",
		letterSpacing: "-0.04em",
		color: "#152435",
	},
	content: {
		background: "#F4F6F8",
		borderRadius: "16px",
		padding: "30px",
		boxSizing: "border-box"
	}
};
UserCreate = withStyles(styles)(UserCreate);

export default UserCreate
