import React from 'react';
import {
  makeStyles,
  Typography,
  IconButton
} from '@material-ui/core';
import {
  X as XIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    borderRadius: 7,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',

    '& .MuiTypography-root': {
      color: 'white!important'
    }
  },
  cardContent: {
    width: '100%',
    height: '100%'
  },
  buttonClose: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 12
  }
}));

const ContentNotification = (props) => {
  const {click, notification} = props;
  const classes = useStyles();

  const handleClickCard = () => {
    click()
  }

  return (
    <div
      className={classes.card}
    >
      <IconButton className={classes.buttonClose}>
        <XIcon/>
      </IconButton>

      <div className={classes.cardContent} onClick={handleClickCard}>
        <Typography variant={'subtitle1'}>{notification.title}</Typography>
        <Typography variant={'body1'}>{notification.mark} / Гос. знак: {notification.goznak}</Typography>
        <Typography variant={'body1'}>Тип: {notification.type}</Typography>
        <Typography variant={'body2'}>Проезд зафиксирован: {notification.travel}</Typography>
        <Typography variant={'body2'}>Код устройства: {notification.deviceCode}</Typography>
      </div>
    </div>
  )
}

export default ContentNotification
