import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	Checkbox,
	Typography,
	FormControlLabel,
} from "@material-ui/core";
import {} from "@material-ui/lab";
import {
	Check as CheckIcon
} from "react-feather";

const BlockWantedLists = (props) => {
	const {title, items, itemsActive, onChange} = props;

	const handleChangeListWanted = (id, isActive) => {
		let newItemsActive = [...itemsActive];

		if (isActive) {
			newItemsActive.splice(newItemsActive.findIndex(t => t === id), 1);
		} else {
			newItemsActive.push(id)
		}

		onChange(newItemsActive);
	}

	return (
		<Box>
			<Typography variant="h3">{title}</Typography>
			<Grid container spacing={2}>
				{items.map((item, idx) => {
					const isActive = itemsActive.indexOf(item.id) > -1;

					return (
						<Grid key={`wanted-list-${idx}`} item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										name={item.id}
										onChange={() => handleChangeListWanted(item.id, isActive)}
										checked={isActive}
										checkedIcon={<CheckIcon/>}
										style={{backgroundColor: item.color}}
									/>
								}
								label={item.title}
							/>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)
};

BlockWantedLists.defaultProps = {
	title: "Списки розыска",
	items: [],
};

export default BlockWantedLists
