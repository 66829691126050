// @flow
import {compose} from 'recompose';
import {connect} from 'react-redux';

import DashboardRoute from './DashboardRoute';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(DashboardRoute);
