import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Tooltip,
	Typography,

	Tab,
	Tabs
} from '@material-ui/core';
import {
	makeStyles
} from "@material-ui/styles";
import {
	Table as TableComponent
} from "./components";
import axios from "../../../plugins/axios";
import {getPageFromCount} from "../../../common/pagination";
import varibles from "../../../varibles";

class UnloadingTasks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rows: [],

			filter: {
				page: 1,
				limit: 20,
			},
			pagination: {
				page: 1
			},
			statusWork: {},

			activeTab: "digital-patrol",

			isLoading: true
		}
	}

	componentDidMount = () => {
		this.executeRequest();
		this.getStatusWork();
	}

	// получение списка выгрузок в зависимости от выбранного таба
	executeRequest = () => {
		if (this.state.activeTab === "digital-patrol") {
			this.getListDigitalPatrol();
		} else {
			this.getListMobileSearch();
		}
	}
	getListDigitalPatrol = () => {
		this.setState({isLoading: true});

		const filter = this.getFilter();

		axios("post", "/export/get-list", filter).then((response) => {
			const {data, headers} = response;

			let pagination = {...this.state.pagination};
			pagination.page = getPageFromCount(headers['x-pagination-total-count'], this.state.filter.limit);
			pagination.totalItems = headers['x-pagination-total-count'];

			this.setState({
				rows: data || [],
				pagination: pagination,

				isLoading: false
			})
		})
	}
	getListMobileSearch = () => {
		this.setState({isLoading: true});

		const filter = this.getFilter();

		axios("post", "/mobile/export/get-list", filter).then((response) => {
			const {data, headers} = response;

			let pagination = {...this.state.pagination};
			pagination.page = getPageFromCount(headers['x-pagination-total-count'], this.state.filter.limit);
			pagination.totalItems = headers['x-pagination-total-count'];

			this.setState({
				rows: data || [],
				pagination: pagination,

				isLoading: false
			})
		})
	}
	getFilter = () => {
		return this.state.filter
	}

	// получение статусов выгрузок
	getStatusWork = () => {
		axios('get', '/export/queue-status').then((response) => {
			this.setState({
				statusWork: response.data
			})
		})
	}

	onChangeTab = (activeTab) => {
		this.setState({activeTab}, () => {
			this.executeRequest();
		});
	}

	onChangeFilter = (filter, isFastStart) => {
		this.setState({
			filter
		}, () => {
			if (isFastStart) {
				this.executeRequest();
			}
		})
	}
	onDownloadFile = (row) => {
		let url = varibles.DOWNLOAD_URL + row.url;
		window.open(url);
	}

	render() {
		const {
			rows,
			filter,
			pagination,
			isLoading,
			activeTab,
			statusWork
		} = this.state;

		return (
			<>

				<HeadElement
					activeTab={activeTab}
					messageInfo={`Задач на выгрузке: CSV — ${statusWork?.csv || 0}, PDF — ${statusWork?.pdf || 0}`}
					onChangeActiveTab={this.onChangeTab}
				/>

				<TableComponent
					rows={rows}
					filter={filter}
					pagination={pagination}
					isLoading={isLoading}

					onChangeFilter={this.onChangeFilter}
					onDownloadFile={this.onDownloadFile}
				/>
			</>
		)
	}
}

const HeadElement = (props) => {
	const {
		activeTab,
		onChangeActiveTab
	} = props;
	const classes = useStyles();
	return (
		<Box className={classes.headElement}>
			<Typography
				variant="h1"
				className={classes.headElementTitle}
			>Задачи на выгрузку</Typography>
			<div className={classes.headElementInfo}>
				{ props.messageInfo }
			</div>
		</Box>
	)
};
const useStyles = makeStyles(() => ({
	headElement: {
		display: "flex",
		alignItems: "center",
		marginBottom: "16px"
	},
	headElementTitle: {
		marginRight: 32
	},
	headElementButton: {
		"&.MuiButton-containedPrimary": {
			color: "#152435"
		},
		"&.MuiButton-containedSecondary": {
			background: "#F3F5F9",
			borderColor: "#F3F5F9",
			color: "#647083"
		},
	},
	headElementInfo: {
		marginLeft: "auto",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "120%",
    letterSpacing: "-0.02em",
    color: "#8698B1",
  },
}));

export default UnloadingTasks
