import React, {useState, useRef} from 'react';
import {useHistory} from 'react-router';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {
    Box,
    Grid,
    Button,
    TextField,
    makeStyles,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Typography, Collapse, Paper,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {
    CustomCheckbox as Checkbox
} from '../../../../../components';
import Alert from '@material-ui/lab/Alert';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
    Plus as PlusIcon,
    X as XIcon
} from "react-feather";
import {MapSensor} from "../../../SensorView/components";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-marginNormal': {
            marginTop: 0
        }
    },

    formControl: {
        marginBottom: theme.spacing(1)
    },

    divider: {
        width: 'calc(100% + 24px)',
        height: 1,
        backgroundColor: theme.palette.primary.light,
        marginTop: 12,
        marginBottom: 16,
        marginLeft: -12,
        marginRight: -12
    }
}));

function Form({
                  className,
                  onSubmitSuccess,
                  initialValues,
                  multiple,
                  multipleActive,
                  changeMultipleActive,
                  groupList,
                  innerRef,
                  isLoadingCamera,

    onAddCamera,
    onEditCamera,
                  onRemoveCamera,
                  roleSensorCameras,
                  ...rest
              }) {
    const history = useHistory();
    const classes = useStyles();
    const refForm = useRef();
    const [showMap, setShowMap] = useState(false);
    const [isShowErrorCameras, setShowErrorCameras] = useState(false);
    const [showErrorMulti, setShowErrorMulti] = useState(false);
    const handleSendForm = (values) => {
        if (multiple && values.group_id === '') {
            setShowErrorMulti(true)

            return null
        }

        onSubmitSuccess(values)
    }

    const handleChangeMultiple = (event) => {
        let name = event.target.name;

        if (multipleActive.indexOf(name) > -1) {
            multipleActive.splice(multipleActive.indexOf(name), 1)
        } else {
            multipleActive.push(name)
        }

        changeMultipleActive(multipleActive)
    }

    const handleCancel = () => {
        history.goBack()
    }

    const validationSchema = {}

    if (!multiple) {
        validationSchema['address'] = Yup.string().max(255).required('Заполните поле');
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={initialValues}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={async (values, {
                setErrors,
                setStatus
            }) => {
                try {
                    handleSendForm(values)
                } catch (error) {
                    const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

                    setStatus({success: false});
                    setErrors({submit: message});
                }
            }}
        >
            {(
                {
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }
            ) => {
                let isDisabledSave = false;
                if (!values.cameras) {
                    values.cameras = []
                }

                // values.cameras.map((camera) => {
                //     if ((!camera.direction || !camera.video_url) && !camera.isDelete) {
                //         isDisabledSave = true;
                //     }
                // });

                return (
                    <form
                        noValidate
                        className={clsx(classes.root, className)}
                        onSubmit={handleSubmit}
                        autoComplete={'off'}
                        {...rest}
                    >
                        <Grid
                            container
                            spacing={1}
                        >
                            {
                                (!multiple) && (
                                    <>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        error={Boolean(touched.status && errors.status)}
                                                        checked={values.status}
                                                        onChange={handleChange}
                                                        name="status"
                                                    />
                                                }
                                                label="Активное устройство"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={'formTitle'}>Местоположение</Typography>
                                            <TextField
                                                error={Boolean(touched.address && errors.address)}
                                                fullWidth
                                                helperText={touched.address && errors.address}
                                                margin="normal"
                                                name="address"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="address"
                                                value={values.address}
                                                variant="outlined"
                                            />

                                            {
                                                multiple && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={multipleActive.indexOf('address') > -1}
                                                                onChange={handleChangeMultiple}
                                                                name="address"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Применить для всех"
                                                    />
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={'formTitle'}>Направление</Typography>
                                            <TextField
                                                error={Boolean(touched.direction && errors.direction)}
                                                fullWidth
                                                helperText={touched.direction && errors.direction}
                                                margin="normal"
                                                name="direction"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="direction"
                                                value={values.direction}
                                                variant="outlined"
                                            />

                                            {
                                                multiple && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={multipleActive.indexOf('direction') > -1}
                                                                onChange={handleChangeMultiple}
                                                                name="direction"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Применить для всех"
                                                    />
                                                )
                                            }
                                        </Grid>
                                        <Grid item container>
                                            <Grid item xs={12}>
                                                <Typography variant={'formTitle'}>Координаты</Typography>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography variant={'formTitle'}>Широта</Typography>
                                                    <TextField
                                                        error={Boolean(touched.lat && errors.lat)}
                                                        fullWidth
                                                        helperText={touched.lat && errors.lat}
                                                        margin="normal"
                                                        name="lat"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="lat"
                                                        value={values.lat}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant={'formTitle'}>Долгота</Typography>
                                                    <TextField
                                                        error={Boolean(touched.lon && errors.lon)}
                                                        fullWidth
                                                        helperText={touched.lon && errors.lon}
                                                        margin="normal"
                                                        name="lon"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="lon"
                                                        value={values.lon}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>

                                            {
                                                false && (
                                                    <Grid item xs={12}>
                                                        {
                                                            (initialValues.gps) && (
                                                                <Box>
                                                                    <Button
                                                                        variant={'outlined'}
                                                                        fullWidth
                                                                        onClick={() => {
                                                                            setShowMap(!showMap)
                                                                        }}
                                                                    >
                                                                        {showMap ? 'Скрыть' : 'Показать на карте'}
                                                                    </Button>

                                                                    <Box mt={2} mb={showMap ? 2 : 0}>
                                                                        <Collapse in={showMap}>
                                                                            <Paper elevation={0}>
                                                                                <MapSensor
                                                                                    sendorGps={initialValues.gps}
                                                                                    title={initialValues.title}
                                                                                />
                                                                            </Paper>
                                                                        </Collapse>
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        }
                                                    </Grid>
                                                )
                                            }

                                        </Grid>

                                        <div className={classes.divider}></div>

                                    </>
                                )
                            }
                            {
                                (!multiple) && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant={'formTitle'}>Идентификатор</Typography>
                                            <TextField
                                                error={Boolean(touched.serial && errors.serial)}
                                                fullWidth
                                                helperText={touched.serial && errors.serial}
                                                margin="normal"
                                                name="serial"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="serial"
                                                value={values.serial}
                                                variant="outlined"
                                            />

                                            {
                                                multiple && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={multipleActive.indexOf('serial') > -1}
                                                                onChange={handleChangeMultiple}
                                                                name="serial"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Применить для всех"
                                                    />
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={'formTitle'}>Модель</Typography>
                                            <TextField
                                                error={Boolean(touched.model && errors.model)}
                                                fullWidth
                                                helperText={touched.model && errors.model}
                                                margin="normal"
                                                name="model"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="model"
                                                value={values.model}
                                                variant="outlined"
                                            />

                                            {
                                                multiple && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={multipleActive.indexOf('model') > -1}
                                                                onChange={handleChangeMultiple}
                                                                name="model"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Применить для всех"
                                                    />
                                                )
                                            }
                                        </Grid>
                                    </>
                                )
                            }
                            {
                                (!multiple && roleSensorCameras) && (
                                    <>

                                        <div className={classes.divider}></div>

                                        <Grid item xs={12}>

                                            <Box mb={2}>
                                                <Grid container justify="space-between" alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="h5">Камеры</Typography>
                                                    </Grid>
                                                    {
                                                        !isLoadingCamera && (
                                                            <Grid item>
                                                                <Tooltip title="Добавление камеры к устройству">
                                                                    <IconButton
                                                                        onClick={onAddCamera}
                                                                        style={{padding: 0}}
                                                                    >
                                                                        <PlusIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </Box>

                                            {
                                                isLoadingCamera && (
                                                    <Box>
                                                        <Typography variant="subtitle1">Идет загрузка камер...</Typography>
                                                    </Box>
                                                )
                                            }

                                            {
                                                !isLoadingCamera && (
                                                    <Box>
                                                        <Grid container spacing={3}>
                                                            {
                                                                values?.cameras.map((camera, idx) => {
                                                                    if (camera.isDelete) {
                                                                        return null
                                                                    }

                                                                    return (
                                                                        <>
                                                                            <Grid item container spacing={2} wrap="nowrap">
                                                                                <Grid item xs={4}>
                                                                                    <Typography variant={'formTitle'}>Направление</Typography>

                                                                                    <TextField
                                                                                        fullWidth
                                                                                        margin="normal"
                                                                                        name="direction"
                                                                                        onChange={({target}) => onEditCamera(target, idx)}
                                                                                        value={camera.direction}
                                                                                        variant="outlined"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <Typography variant={'formTitle'}>IP камеры</Typography>

                                                                                    <TextField
                                                                                        fullWidth
                                                                                        margin="normal"
                                                                                        name="video_url"
                                                                                        onChange={({target}) => onEditCamera(target, idx)}
                                                                                        value={camera.video_url}
                                                                                        variant="outlined"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography variant={'formTitle'}>&nbsp;</Typography>

                                                                                    <Tooltip title="Удаление камеры у устройства">
                                                                                        <IconButton
                                                                                            onClick={() => onRemoveCamera(camera, idx)}
                                                                                            style={{padding: 0, marginTop: 8}}
                                                                                            disabled={!camera.manual_added}
                                                                                        >
                                                                                            <XIcon/>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid style={{ marginTop: -24 }} item container direction="column">
                                                                                <Grid item>
                                                                                    <Typography variant="caption">UID: { camera?.uid || '' }</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography variant="caption">Адрес: { camera?.address || '' }</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Box>
                                                )
                                            }

                                        </Grid>

                                        <div className={classes.divider}></div>

                                    </>
                                )
                            }

                            <Grid item xs={12}>
                                <Typography variant={'formTitle'}>Группа</Typography>
                                <Select
                                    className={classes.formControl}
                                    error={Boolean(touched.group_id && errors.group_id)}
                                    helperText={touched.group_id && errors.group_id}
                                    name="group_id"
                                    variant='outlined'
                                    fullWidth
                                    value={values.group_id}
                                    onChange={handleChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }
                                    }}
                                >
                                    {
                                        groupList.map((group, idx) => (
                                            <MenuItem key={idx} value={group.id}>{group.Title}</MenuItem>
                                        ))
                                    }
                                </Select>

                                {
                                    (showErrorMulti) && (
                                        <Typography variant={'caption'} color={'error'}>Выберите группу</Typography>
                                    )
                                }
                            </Grid>

                            <div className={classes.divider}></div>

                            <Box mt={2} style={{ width: '100%' }}>

                                <Grid container spacing={1} justify="flex-end">
                                    <Grid item>
                                        <Button
                                            size="large"
                                            variant="formCancel"
                                            style={{marginRight: 10}}
                                            onClick={() => handleCancel()}
                                        >
                                            Отменить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={isDisabledSave ? "Проверьте правильнось заполнения камер" : ""}>
                                            <Box>
                                                <Button
                                                    disabled={isSubmitting || isDisabledSave}
                                                    size="large"
                                                    type="submit"
                                                    variant="formSubmit"
                                                >
                                                    Применить
                                                </Button>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Box mt={2}>
                            {errors.submit && (
                                <Box mt={2}>
                                    <Alert severity="error">{errors.submit}</Alert>
                                </Box>
                            )}
                        </Box>
                    </form>
                )
            }}
        </Formik>
    );
}

Form.propTypes = {
    className: PropTypes.string,
    onSubmitSuccess: PropTypes.func
};

Form.defaultProps = {
    onSubmitSuccess: () => {
    }
};

export default Form;
