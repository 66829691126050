import React, {Component} from 'react';
import {
	Box,
	Grid,
	Container,
	Typography,
	CircularProgress,
	Button,
	Tooltip
} from '@material-ui/core';
import {
	withStyles
} from "@material-ui/styles";
import {
	Form,
	ModalCreateGroup
} from './components';
import axios from '../../../plugins/axios';
import {store} from 'react-notifications-component';
import urls from "../../../constant/urls";

class SensorEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				group_id: '',
			},
			formModalGroup: {
				title: ''
			},

			multipleActive: [],
			groupList: [],

			title: '',

			countEditSensor: 0,

			loading: true,
			multiple: false,
			showMap: false,
			isLoadingCamera: true,

			isShowModalGroup: false
		};

		this.formRef = React.createRef();
	}

	componentDidMount = () => {
		let id = this.props.match.params.id;

		this.getGroupList()

		if (id.indexOf(', ') > -1) {
			let countEditSensor = id.split(', ').length

			this.setState({
				multiple: true,
				loading: false,
				countEditSensor
			})
		} else {
			this.getSensor(id);
		}
	}

	// Получение списка устройства
	getSensor = (id) => {
		axios('get', '/operator/sensor/get/' + id).then((response) => {
			let data = response.data;

			let form = data;
			form.lat = form.gps.lat;
			form.lon = form.gps.lon;
			form.status = (form.status === 10) ? true : false;


			this.setState({
				loading: false,
				title: response.data.title,
				form
			}, () => {
				this.getCameraSensor();
			})
		}).catch((error) => {
		})
	}

	// Получение списка групп устройства
	getGroupList = () => {
		axios('post', '/operator/sensor-group/find', {
			limit: 999,
			page: 1
		}).then((response) => {
			let groupList = response.data;

			if (!groupList) {
				groupList = []
			}

			this.setState({
				groupList
			})
		})
	}

	// Изменение камеры
	saveSensor = (form) => {
		let body = {...form};
		body.gps = {
			lat: body.lat,
			lon: body.lon
		};
		body.status = (form.status) ? 10 : -1;
		delete body.cameras;

		if (this.state.multiple) {
			this.saveSensorMultiple(body)

			return null
		}

		(async () => {
			await this.saveCameras(form.cameras);
		})();

		axios('post', '/operator/sensor/edit/' + this.props.match.params.id, body).then(() => {
			store.addNotification({
				title: 'Успешно',
				message: 'Данные изменены',
				type: 'success',
				insert: 'top',
				container: 'bottom-left',
				dismiss: {
					duration: 3000,
					onScreen: false,
					pauseOnHover: true,
					delay: 0
				}
			});

			this.props.history.push('/sensor');
		}).catch((error) => {
			let message = ''

			if (error.response.data.message) {
				message = error.response.data.message;
			} else {
				message = error.response.data;
			}

			store.addNotification({
				title: 'Ошибка',
				message: message,
				type: 'danger',
				insert: 'top',
				container: 'bottom-left',
				dismiss: {
					duration: 3000,
					onScreen: false,
					pauseOnHover: true,
					delay: 0
				}
			});
		})
	}

	// Сохоранение мультикамеры (когда выбранное 2 и более)
	saveSensorMultiple = (form) => {
		let ids = this.props.match.params.id.split(', ');
		let indexSave = 0;

		ids.map(id => {
			let body = {
				group_id: form.group_id
			}

			axios('post', '/operator/sensor/edit/' + id, body).then(() => {
				indexSave++

				if (indexSave === ids.length) {
					store.addNotification({
						title: 'Успешно',
						message: 'Данные изменены',
						type: 'success',
						insert: 'top',
						container: 'bottom-left',
						dismiss: {
							duration: 3000,
							onScreen: false,
							pauseOnHover: true,
							delay: 0
						}
					});
					this.props.history.push('/sensor');
				}
			}).catch((error) => {
				let message = ''

				if (error.response.data.message) {
					message = error.response.data.message;
				} else {
					message = error.response.data;
				}

				store.addNotification({
					title: 'Ошибка',
					message: message,
					type: 'danger',
					insert: 'top',
					container: 'bottom-left',
					dismiss: {
						duration: 3000,
						onScreen: false,
						pauseOnHover: true,
						delay: 0
					}
				});
			})
		})


	}

	// ЛОгика работы с камерами на устрова (потоковые)
	getCameraSensor = () => {
		let id = this.props.match.params.id;

		axios('get', `${urls['sensor-get-cameras']}/${id}`).then((response) => {
			let form = {...this.state.form};
			let cameras = response.data || [];

			form['cameras'] = cameras;

			this.formRef.current.setValues(form);

			this.setState({
				form,
				isLoadingCamera: false
			})
		});
	}
	saveCameras = async (cameras) => {
		cameras = cameras.map((camera) => {
			const link = process.env.REACT_APP_URL_CAMERA;
			camera.video_url = camera.video_url;

			return camera
		});

		cameras.map(async (camera) => {
			if (camera.isCreated && !camera.isDelete) {
				await axios('post', urls["sensor-create-camera"], camera);
			}
			if (!camera.isCreated && !camera.isDelete) {
				await axios('post', `${urls["sensor-edit-camera"]}/${camera.id}`, camera);
			}
			if (camera.isDelete) {
				await axios('get', `${urls["sensor-delete-camera"]}/${camera.id}`);
			}
		});
	}
	onAddCamera = () => {
		let sensor_id = this.props.match.params.id;
		let form = this.formRef.current?.values;

		if (!form.cameras) {
			form.cameras = [];
		}

		form.cameras.push({
			isCreated: true,
			sensor_id: sensor_id,
			direction: '',
			video_url: '',
		});

		this.formRef.current.setValues(form);
	}
	onEditCamera = (target, idx) => {
		const {name, value} = target;

		let form = this.formRef.current?.values;

		form.cameras[idx][name] = value;

		this.formRef.current.setValues(form);
	}
	onRemoveCamera = (camera, idx) => {
		let form = this.formRef.current?.values;
		form.cameras[idx]['isDelete'] = true;

		this.formRef.current.setValues(form);
	}

	// ЛОгика работы с группыми
	onOpenCreateGroup = () => {
		this.setState({
			isShowModalGroup: true
		})
	}
	onSuccessCreateGroup = () => {
		this.getGroupList();

		this.setState({
			isShowModalGroup: false,
			formModalGroup: {
				title: ''
			}
		})
	}


	geMultipleActive = (multipleActive) => {
		this.setState({multipleActive})
	}

	render() {
		const {multiple, isLoadingCamera} = this.state;
		const {app, classes} = this.props;
		const {user} = app;

		const roleSensorCameras = user.roles.admin || user.roles['sensor.cameras'];
		const titleH1 = Boolean(this.state.multiple) ? `Выбрано ${this.state.countEditSensor} устройств` : `Устройство ${this.state.title}`;


		return (
			<Box className={classes.root}>
				<Container maxWidth='md'>
					<Box className={classes.section}>
						<Box mb={2}>
							<Grid container justify={'space-between'} spacing={2}>
								<Grid item>
									<Typography variant="h1" color="textPrimary">
										{ titleH1 }
									</Typography>
								</Grid>
								{(this.state.multiple) && (
									<Grid item>
											<Tooltip title={'Добавление группы устройств'} arrow>
												<Button
													size="small"
													variant="contained"
													onClick={this.onOpenCreateGroup}
												>
													Добавить группу
												</Button>
											</Tooltip>
										</Grid>
								)}
							</Grid>
						</Box>

						<Box>
							{
								(this.state.loading) ?
									(
										<Grid
											container
											justify={'center'}
										>
											<CircularProgress
												color="secondary"
											/>
										</Grid>
									)
									:
									(
										<Grid>
											<Form
												innerRef={this.formRef}

												initialValues={this.state.form}
												multiple={this.state.multiple}
												multipleActive={this.state.multipleActive}
												groupList={this.state.groupList}
												onSubmitSuccess={this.saveSensor}
												changeMultipleActive={this.changeMultipleActive}
												isLoadingCamera={isLoadingCamera}

												onAddCamera={this.onAddCamera}
												onEditCamera={this.onEditCamera}
												onRemoveCamera={this.onRemoveCamera}
												roleSensorCameras={roleSensorCameras}
											/>
										</Grid>
									)
							}
						</Box>
					</Box>

					<ModalCreateGroup
						form={this.state.formModalGroup}
						open={this.state.isShowModalGroup}
						onCreateGroup={this.onSuccessCreateGroup}
						onClose={() => {
							this.setState({isShowModalGroup: false})
						}}
					/>

				</Container>
			</Box>
		);
	}
}

const styles = {
	root: {
		background: "rgba(177, 177, 177, 0.5)",
		backdropFilter: "blur(8px)",
		margin: "-20px -8px",
		padding: "32px 0",
		boxSizing: "border-box",
		minHeight: "calc(100vh - 64px)"
	},
	section: {
		padding: "28px",
		boxSizing: "border-box",
		background: "#F4F6F8",
		borderRadius: "10px",
	}
};
SensorEdit = withStyles(styles)(SensorEdit);

export default SensorEdit
