import palette from '../palette';

export default {
  root: {
    padding: "9px 12px",
    backgroundColor: "white",

    "& fieldset": {
      top: 0
    },
    "& legend": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(21, 36, 53, 0.10)"
    },
    "& .MuiIconButton-root": {
      padding: 0
    }
  },
  input: {
    height: "auto",

    fontSize: 14,
    lineHeight: "130%",
    letterSpacing: "-0.28px",
    color: palette.black,
    padding: "0!important",
  }
};
