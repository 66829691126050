export default {
  root: {
    position: 'initial!important',
    transform: 'initial!important',
    marginBottom: '8px',

    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '100%',
    color: '#647083',
  }
};
