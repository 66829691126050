import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Backdrop,
	Typography,
	CircularProgress
} from '@material-ui/core';
import {
	withStyles
} from "@material-ui/styles";
import {
	FormCreate as FormCreateComponent,
	Table as TableComponent
} from "./components";
import {
	PageFilterTableContent
} from "../../../components";
import axiosAgent from "../../../plugins/axios";
import {sendMessages} from "../../../common/messages";

const initFilter = {
	page: 1,
	limit: 20
};
const initFormCreate = {
	type: "",
	datetime_start: "",
	datetime_end: "",
	encode_utf: false
};

class Reports extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formCreate: {...initFormCreate},

			items: [],
			filter: {...initFilter},
			totalPages: 1,
			isLoading: true,

			isBackdrop: false
		}
	}

	componentDidMount = async () => {
		await this.getItems();
	}

	getItems = async () => {
		this.setState({ isLoading: true });

		const filter = this.getFilter();
		const res = await axiosAgent('post', '/export/get-list', filter).then((res) => {
			return res || {}
		}).catch(() => {
			return {}
		});

		const items = res?.data || [];
		const totalPages = Math.ceil(Number.parseFloat(res?.headers?.['x-pagination-total-count'] || 0) / filter.limit);

		this.setState({
			items,
			totalPages,
			isLoading: false
		})
	}

	getFilter = () => {
		return this.state.filter
	}

	resetForm = () => {
		this.setState({ formCreate: {...initFormCreate} })
	}
	submitForm = async () => {
		await this.setState({ isBackdrop: true });

		await new Promise(r => setTimeout(r, 3000));

		await this.getItems();
		this.resetForm();
		await this.setState({ isBackdrop: false });

		sendMessages({
			message: "Отчет успешно поставлен в очередь",
			type: "success"
		})
	}
	changeForm = (formCreate) => {
		this.setState({ formCreate });
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			items,
			isLoading,

			formCreate,
			isBackdrop
		} = this.state;

		return (
			<>
				<PageFilterTableContent
					onResetFilter={this.resetForm}
				>
					<slot name="head">
						<Grid container spacing={2}>
							<Grid item>
								<Typography variant="h1">Отчеты</Typography>
							</Grid>
							<Grid item>
								<Button
									size="small"
									variant="contained"
									className={classes.buttonUpdate}
									onClick={this.getItems}
								>Обновить</Button>
							</Grid>
						</Grid>
					</slot>
					<slot name="body">
						<TableComponent
							data={items}
							isLoad={isLoading}
						/>
					</slot>
					<slot name="filter">
						<FormCreateComponent
							form={formCreate}
							onChange={this.changeForm}
							onSubmit={this.submitForm}
						/>
					</slot>
				</PageFilterTableContent>

				<Backdrop open={isBackdrop} invisible={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

const styles = {
	buttonUpdate: {
		height: 36,
		padding: "0 24px",
		borderRadius: 8,

		fontSize: 16,
		lineHeight: 16
	}
};
Reports = withStyles(styles)(Reports);

export default Reports
