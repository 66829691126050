import React, {useState} from 'react';

import {
  TextField,
  makeStyles,
  Grid,
  Paper,
  Collapse,
  Button,
  Box, Typography, InputAdornment, Tooltip, IconButton
} from '@material-ui/core';
import clsx from "clsx";
import {ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon} from "react-feather";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  queryField: {
    width: 500
  },
  categoryField: {
    width: '100%',
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  let {filters, onChange} = props;

  const handleChangeFilter = (event) => {
    const item = event.target;
    const name = item.name;
    let value = item.value;

    filters[name] = value;

    onChange(filters)
  }
  const handleClearFilter = (name, value) => {
    filters[name] = value;
    onChange(filters)
  }


  return (
    <>
      <Grid
        container
        spacing={3}
      >
        <Grid xs={12} item>
          <Typography variant={'formTitle'}>Наименование</Typography>

          <TextField
            className={classes.categoryField}
            name={'title'}
            value={filters.title}
            variant="outlined"
            size={'small'}
            onChange={handleChangeFilter}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {
                      (filters.title) && (
                        <Tooltip title="Очистить">
                          <IconButton onClick={() => handleClearFilter('title', '')}>
                            <HighlightOffIcon/>
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Filter;
