import React from "react";
import {
	Box,
	Grid,
	Button,
	Checkbox,
	Typography,
	FormControlLabel, FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import {
	withStyles
} from "@material-ui/styles";
import violationProcessing from "../../sensor/SensorsDuplo/constants/violationProcessing";

class SystemSettings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			settingsForm: {},
			settingsItems: [],
		};
	}

	componentDidMount = async () => {
		await this.getSettings();
	}

	getSettings = async () => {
		const res = {
			"createNotification": {
				"key": "createNotification",
				"group": "system",
				"title": "Создавать уведомления об ошибках отправки в DUPLO",
				"description": "",
				"type": "boolean",
				"value": true,
				"required": true
			},
			"lpWhitelist": {
				"key": "lpWhitelist",
				"group": "system",
				"title": "Белый список ГРЗ",
				"description": "",
				"type": "string",
				"value": "К518ВВ196 А110МР90 A009AA96 A018AA96 A011AA96 K647KA196 K605KA196 М961ВВ196 Е793НЕ196 К498УХ196 В320ВС196 Н196ТР196 Т921КХ196 Р814МР77 А001АА96 А010АА96 А007АА96 А019АА96 А005АА96 А009АА96 В777СТ196 У412КО96 Р891ХК196",
				"required": true
			},
			"resendFilteredDuplicates": {
				"key": "resendFilteredDuplicates",
				"group": "system",
				"title": "Отправлять отфильтрованные дубликаты повторно (rsync only)",
				"description": "",
				"type": "bool",
				"value": false,
				"required": true
			},
			"saveExtraViolation": {
				"key": "saveExtraViolation",
				"group": "system",
				"title": "Сохранть нарушения для УАД",
				"description": "",
				"type": "bool",
				"value": true,
				"required": false
			},
			"saveQueue": {
				"key": "saveQueue",
				"group": "system",
				"title": "Сохранять очередь при ошибках отправки",
				"description": "",
				"type": "bool",
				"value": true,
				"required": true
			},
			"sendNotificationEmail": {
				"key": "sendNotificationEmail",
				"group": "system",
				"title": "Отправлять уведомления на Email",
				"description": "",
				"type": "bool",
				"value": false,
				"required": true
			},
			"skipPercent": {
				"key": "skipPercent",
				"group": "system",
				"title": "Процент отсечения потока",
				"description": "",
				"type": "int",
				"value": 20,
				"required": false
			},
			"violationProcessing": {
				"key": "violationProcessing",
				"group": "system",
				"title": "Обработка нарушений",
				"description": "",
				"type": "string",
				"value": "duplo",
				"required": true
			}
		}

		let settingsForm = {};
		let settingsItems = [];
		Object.keys(res).map((key) => {
			const _item = res[key];
			if (['bool', 'boolean'].includes(_item.type) && !['saveExtraViolation'].includes(key)) {
				settingsForm[key] = _item.value;
				settingsItems.push({
					key: key,
					label: _item.title,
				});
			}
		});

		this.setState({
			settingsForm,
			settingsItems,
		});
	}

	render () {
		const {
			classes
		} = this.props;
		const {
			settingsForm,
			settingsItems,
		} = this.state;

		return (
			<Box maxWidth="640" margin="0 auto">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box className={classes.section}>
							<Typography className={classes.sectionTitle}>Системные настройки</Typography>
							{settingsItems.map((item) => (
								<FormControlLabel
									value={settingsForm[item.key]}
									checked={settingsForm[item.key]}
									control={<Checkbox />}
									label={ item.label }
								/>
							))}
							<Box mt={1}/>
							<Grid container justify="flex-end">
								<Grid item>
									<Button
										size="small"
										variant="contained"
										className={classes.buttonSubmit}
									>Сохранить</Button>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box className={classes.section}>
							<Typography className={classes.sectionTitle}>Метод обработки нарушений</Typography>
							<Grid container spacing={1}>
								<Grid item style={{ flex: 1 }}>
									<FormControl fullWidth>
										<Select
											variant="outlined"
										>
											<MenuItem value="">Сбросить</MenuItem>
											{Object.keys(violationProcessing).map((key) => (
												<MenuItem value={key}>{violationProcessing[key]}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item>
									<Button
										size="small"
										variant="contained"
										className={classes.buttonSubmit}
									>Сохранить</Button>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
		)
	}
};

const styles = {
	section: {
		background: "#F4F6F8",
		padding: 20,
		boxSizing: "border-box",
		borderRadius: 10,
	},
	sectionTitle: {
		marginBottom: 16,
		fontWeight: "600",
		fontSize: "18px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		textTransform: "uppercase",
		color: "#000000",
	},

	buttonSubmit: {
		height: 36,
		padding: "0 24px",
		borderRadius: 4,
		fontSize: 16,
		lineHeight: 16
	}
};
SystemSettings = withStyles(styles)(SystemSettings);

export default SystemSettings
