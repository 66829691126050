import React from "react";
import {
	Grid,
	Button,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@material-ui/core";
import {
	Skeleton
} from "@material-ui/lab";
import {
	makeStyles
} from "@material-ui/styles";
import {
	 VisibleContent
} from "../../../../../components";
import reportsTypes from "../../../../../constant/reportsTypes";
import moment from "moment";
import reportsStatus from "../../../../../constant/reportsStatus";

const TableCustom = (props) => {
	const {
		data,
		isLoad
	} = props;

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Тип</TableCell>
						<TableCell>Статус</TableCell>
						<TableCell>Создана</TableCell>
						<TableCell>Завершена</TableCell>
						<TableCell>Ссылка</TableCell>
						<TableCell align="right">Действия</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<VisibleContent visible={!isLoad}>
						{data.map((item) => (
							<RowData
								key={`RowData-${item.id}`}
								item={item}
							/>
						))}
					</VisibleContent>
					<VisibleContent visible={isLoad}>
						<RowsLoading/>
					</VisibleContent>
				</TableBody>
			</Table>
		</>
	)
};
const RowData = (props) => {
	const {
		item
	} = props;
	const classes = useStyles();
	const [reportType] = React.useState(() => {
		return reportsTypes.find((t) => t.value === item.type)?.label || "Н/Д"
	});
	const [reportStatus] = React.useState(() => {
		return reportsStatus.find((t) => String(t.value) === String(item.status));
	});

	return (
		<TableRow>
			<TableCell>{reportType}</TableCell>
			<TableCell>
				<Grid container>
					<Grid item>
						<div
							className={classes.reportStatus}
							style={{background: reportStatus.color}}
						>
							{reportStatus.label}
						</div>
					</Grid>
				</Grid>
			</TableCell>
			<TableCell>
				{moment(item.created).format("DD.MM.YYYY HH:mm:ss")}
			</TableCell>
			<TableCell>
			{Boolean(item.completed !== '0001-01-01T00:00:00Z') ? moment(item.completed).format("DD.MM.YYYY HH:mm:ss") : "Н/Д"}
			</TableCell>
			<TableCell>
				{Boolean(item.url) && (
					<Button
						component="a"
						target="_blank"
						size="small"
						href={[process.env.REACT_APP_DOWNLOAD_URL, item.url].join('')}
					>
						Скачать
					</Button>
				)}
			</TableCell>
			<TableCell align="right">
				<Button
					size="small"
					variant="contained"
					className={classes.buttonCancel}
				>
					Отменить
				</Button>
			</TableCell>
		</TableRow>
	)
}
const RowsLoading = () => {
	return new Array(10).fill(1).map((t, i) => (
		<TableRow key={`RowsLoading-${ i }`}>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell align="right"><Skeleton/></TableCell>
		</TableRow>
	))
}

const useStyles = makeStyles(() => ({
	reportStatus: {
		padding: "4px 8px",
		boxSizing: "border-box",
		borderRadius: 4,

		fontSize: 14,
		lineHeight: "14px",
		color: "white",
		fontWeight: "500"
	},
	buttonCancel: {
		borderRadius: 4,
		padding: " 0 12px",
		height: 24,

		fontSize: 14,
		lineHeight: 14
	}
}));

export default TableCustom
