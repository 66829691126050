import React from "react";
import {
  Box,
	Typography,
} from "@material-ui/core";
import {
  makeStyles
} from "@material-ui/styles";
import {
	Table,
	Filter
} from "./components";
import {
	PageFilterTableContent
} from "../../../components";
import axiosAgent from "../../../plugins/axios";
import urls from "../../../constant/urls";
import moment from "moment";

const initFilter = {
	type: '',
	datetime_from: null,
	datetime_to: null,

	limit: 20,
	page: 1
};

class SystemNotifications extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
      items: [],

			filter: {...initFilter},

			totalCount: 0,
			datetime: null,

			isLoading: true,
		};
	}

	componentDidMount = async () => {
		await this.getItems();
	}

	getItems = async () => {
		await this.setState({ isLoading: true });
		const filter = this.getFilter();
		const res = await axiosAgent("post", urls["alarms-get-list"], filter).then((res) => res).catch(() => null);
		const totalCount = Number.parseFloat(res?.headers?.['x-pagination-total-count'] || '0');

		this.setState({
			items: res?.data || [],
			totalCount,
			isLoading: false,
			datetime: new Date()
		});
	}

	getFilter = () => {
		let filter = {...(this.state?.filter || {})};
		['datetime_from', 'datetime_to', 'type'].map((key) => {
			if (!filter[key]) {
				delete filter[key];
			}
		});
		['datetime_from', 'datetime_to'].map((key) => {
			if (filter[key]) {
				filter[key] = moment(filter[key]).toISOString()
			}
		});
		return filter
	}
	changeFilter = async (filter) => {
		await this.setState({ filter });
		await this.getItems();
	}
	resetFilter = async () => {
		await this.setState({ filter: {...initFilter} });
		await this.getItems();
	}

	changePage = async (newPage) => {
		let filter = {...this.state.filter};
		filter.page = newPage;
		await this.changeFilter(filter);
	}

	render () {
		const {
			items,
			filter,
			totalCount,
			isLoading,
			datetime
		} = this.state;
		const totalPage = Math.ceil(totalCount / filter.limit);

		return (
			<>
				<PageFilterTableContent
					currentPage={filter.page}
					totalPage={totalPage}
					onChangePage={this.changePage}
					onResetFilter={this.resetFilter}
				>
					<slot name="head">
						<HeadElement
							datetime={datetime}
						/>
					</slot>
					<slot name="body">
						<Table
							items={items}
							isLoading={isLoading}
						/>
					</slot>
					<slot name="filter">
						<Filter
							filter={filter}
							onChange={this.changeFilter}
						/>
					</slot>
				</PageFilterTableContent>
			</>
		)
	}
};
const HeadElement = ({ datetime }) => {
	const classes = useStyles();

	return (
		<Box className={classes.headElement}>
			<Typography variant="h1">Системные уведомления</Typography>
			<div className={classes.headElementDatetime}>
				Данные актуальны<br/>на {moment(datetime).format("DD.MM.YYYY HH:mm:ss")}
			</div>
		</Box>
	)
};
const useStyles = makeStyles(() => ({
	headElement: {
		display: "flex",
		alignItems: "center",
		marginBottom: 16
	},
	headElementDatetime: {
		marginLeft: "20px",

    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "120%",
    letterSpacing: "-0.02em",
    color: "#8698B1",
  },
	headElementButton: {
		display: "flex",
		alignItems: "center",
		marginLeft: "auto",

    fontSeight: "600",
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: "-0.02em",
    color: "#152435",

		"& svg": {
			marginRight: "8px"
		}
	}
}));

export default SystemNotifications
