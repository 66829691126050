import React from "react";
import {
	Box,
	Grid,
	Button,
	Checkbox,
	Typography,

	Select,
	MenuItem,
	InputLabel,
	FormControl,
	FormControlLabel
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import {
	DateRange
} from "../../../../../components";
import reportsTypes from "../../../../../constant/reportsTypes";
import {sendMessages} from "../../../../../common/messages";

const FormCreate = (props) => {
	const {
		form,
		onChange,
		onSubmit
	} = props;
	const classes = useStyles();

	const handleChangeForm = ({ target }) => {
		const { name, value } = target;
		let _form = {...form};
		_form[name] = value;
		onChange(_form);
	}
	const handleChangeFormDate = (params) => {
		const _getDateValue = (_val) => {
			if (!_val) {
				return ""
			}
			return _val * 1000;
		}
		let _form = {...form};
		_form.datetime_start = _getDateValue(params?.startDate || "");
		_form.datetime_end = _getDateValue(params?.endDate || "");
		onChange(_form);
	}
	const handleSubmit = () => {

		let _errors = [];
		if (!form.type) {
			_errors.push('Выберите "Тип запроса"')
		}
		if (!form.datetime_start || !form.datetime_end) {
			_errors.push('Выберите "Временной промежуток"')
		}
		if (_errors.length > 0) {
			_errors.map((t) => {
				sendMessages({ message: t, type: "danger" })
			})
			return
		}


		onSubmit();
	}

	return (
		<>
			<Typography variant="h3">Запрос нового отчета</Typography>
			<Box mb={2}/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel>Тип запроса</InputLabel>
						<Select
							value={form.type}
							name="type"
							label="Тип запроса"
							variant="outlined"
							onChange={handleChangeForm}
						>
							{reportsTypes.map((reportType) => (
								<MenuItem key={`reportsTypes-${reportType.value}`} value={reportType.value}>
									{reportType.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<DateRange
						dateStart={form.datetime_start}
						dateEnd={form.datetime_end}
						label="Временной промежуток"
						onChange={handleChangeFormDate}
					/>
				</Grid>
				<Grid item>
					<FormControlLabel
						value={Boolean(form.encode_utf)}
						checked={Boolean(form.encode_utf)}
						name="encode_utf"
						control={<Checkbox/>}
						label="В формате UTF-8"
						onChange={(event, value) => handleChangeForm({target: {name: 'encode_utf', value}})}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						size="small"
						className={classes.buttonCreate}
						variant="contained"
						onClick={handleSubmit}
					>
						Запросить отчет
					</Button>
				</Grid>
			</Grid>
		</>
	)
}

const useStyles = makeStyles(() => ({
	buttonCreate: {
		borderRadius: 10
	}
}));

export default FormCreate
