import React, {useState} from 'react';
import {
  makeStyles,
  TextField
} from '@material-ui/core';
import {
  CalendarToday
} from '@material-ui/icons';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.scss';

const useStyles = makeStyles((theme) => ({
  dateRange: {
    '& .MuiInputAdornment-root': {
      display: 'none'
    },
  },

  rightContent: {
    padding: '0 15px',
    borderLeft: '1px solid rgba(100, 112, 131, 0.2)'
  },
  iconCalendar: {
    width: 17,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));

function TextMaskCustom(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/]}
      placeholder={'01.01.2000 00:00'}
      placeholderChar={'_'}
    />
  );
}

const DatePicker = (props) => {
  const {
    label,
    onChange,

    cancelMaxDay
  } = props;
  let { value } = props;
  const classes = useStyles();

  const handleChange = (event, range) => {
    let startDate = moment(range.startDate.toISOString()).unix();

    onChange(startDate * 1000)
  }

  let showDateInput = (value) ? true : false;
  let dateString = showDateInput ? moment(value).format('DD.MM.YYYY HH:mm') : '';

  const template = `<div class="daterangepicker" style="display: none">
                <div class="daterangepicker-left">
                  <div class="ranges"></div>
                </div>
                <div class="daterangepicker-right">
                  <div class="daterangepicker-right-top">
                    <div class="drp-calendar left">
                      <div class="calendar-table"></div>
                      <div class="calendar-time">
                        <span class="calendar-time-title">Время</span>
                      </div>
                    </div>
                    <div class="drp-calendar right">
                      <div class="calendar-table"></div>
                      <div class="calendar-time">
                        <span class="calendar-time-title">Время</span>
                      </div>
                    </div>
                  </div>
                  <div class="daterangepicker-right-bottom">
                    <div class="drp-buttons">
                      <span class="drp-selected"></span>
                      <button class="cancelBtn" type="button"></button>
                      <button class="applyBtn" disabled="disabled" type="button"></button>
                    </div>
                  </div>
                </div>
              </div>`;

  const handleGetDate = (date) => {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  if (!value) {
    value = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
  }

  return (
    <div className={classes.dateRange}>
      <TextField
        value={dateString}
        label={label}
        InputProps={{
          inputComponent: TextMaskCustom,
          endAdornment: (
            <DateRangePicker
              startDate={handleGetDate(value)}
              endDate={handleGetDate(value)}
              locale={{
                format: 'DD.MM.YYYY HH:mm',
                applyLabel: 'Фильтровать',
                cancelLabel: 'Сбросить',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 1
              }}
              onApply={handleChange}
              showDropdowns
              timePicker
              timePicker24Hour
              alwaysShowCalendars
              opens={'left'}
              maxDate={cancelMaxDay ? null : moment().add(1, 'days')}
              template={template}
              singleDatePicker
            >
              <div className={classes.rightContent}><CalendarToday className={classes.iconCalendar}/></div>
            </DateRangePicker>
          )
        }}
        variant={'outlined'}
        fullWidth
        inputProps={{
          disabled: true
        }}
      />
    </div>
  )
}


export default DatePicker
