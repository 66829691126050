import React, {useState} from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Box,
	Grid,
	Tooltip,
	IconButton,
	Typography,

	Collapse
} from "@material-ui/core";
import {
	Skeleton,
	Pagination
} from "@material-ui/lab";
import {
	makeStyles
} from "@material-ui/styles";
import {
	ChevronDown as ChevronDownIcon,
	ChevronUp as ChevronUpIcon,
	Edit as EditIcon,
	Trash as TrashIcon
} from "react-feather";
import {
	VisibleContent
} from "../../../../../components";
import moment from "moment";
import clsx from "clsx";
import userRoles from "../../../../../constant/userRoles";
import {useHistory} from "react-router-dom";


const TableUsers = (props) => {
	const {rows, isLoading, onDeleteUser} = props;

	return (
		<Table className="table-collapse">
			<TableHead>
				<TableRow>
					<TableCell width={40}></TableCell>
					<TableCell>Email</TableCell>
					<TableCell>Полное имя</TableCell>
					<TableCell>Логин</TableCell>
					<TableCell>Статус</TableCell>
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{
					(isLoading) ? (
						<RowLoading/>
					) : (
						<>

							{
								rows.map((rows, idx) => (
									<Row key={`user-row-${idx}`} {...rows} onDeleteUser={onDeleteUser}/>
								))
							}

						</>
					)
				}
			</TableBody>
		</Table>
	)
}
const Row = (row) => {
	const classes = useStyles();
	const history = useHistory();

	const [isOpen, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(!isOpen);
	}
	const handleOpenEdit = () => {
		history.push('/users/edit/' + row.id)
	}

	return (
		<>

			<TableRow
				className={clsx({
					'--main': true,
					'--open': isOpen
				})}
			>
				<TableCell width={40}>
					<Tooltip title={isOpen ? 'Свернуть' : 'Развернуть'}>
						<IconButton onClick={handleOpen}>
							{isOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>}
						</IconButton>
					</Tooltip>
				</TableCell>
				<TableCell>{row?.email}</TableCell>
				<TableCell>{row?.full_name}</TableCell>
				<TableCell>{row?.username}</TableCell>
				<TableCell>
					<div className={clsx({
						'tm-round-info': true,
						'--on': row.status === 1,
						'--off': row.status === 0,
					})}/>
				</TableCell>
				<TableCell>
					<Grid container spacing={1} justify="flex-end" wrap="nowrap">
						<Grid item>
							<Tooltip title="Редактировать запись">
								<IconButton onClick={handleOpenEdit}>
									<EditIcon/>
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title="Удалить запись">
								<IconButton onClick={() => row.onDeleteUser(row, false)}>
									<TrashIcon color="#F86C6C"/>
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</TableCell>
			</TableRow>

			<VisibleContent visible={Boolean(isOpen)}>
				<TableCell colSpan={6} style={{padding: 0}}>
					<Box bgcolor="white" py="8px" px="20px" style={{borderRadius: '0 0 10px 10px', marginBottom: 4}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div>
									<div className={classes.tableInfoTitle}>Разрешенные IP адреса:</div>
									<div className={classes.tableInfoMessage}>{(row?.allowed_ips || []).join('; ')}</div>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div>
									<div className={classes.tableInfoTitle}>Роли:</div>
									<div className={classes.tableInfoMessage}>
										{!!row.roles && (
											<>
												{Object.keys(row.roles).map((key) => {
													const title = userRoles[key];
													return (
														<span>{title};</span>
													)
												})}
											</>
										)}
									</div>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div>
									<div className={classes.tableInfoTitle}>Последня дата авторизации:</div>
									<div
										className={classes.tableInfoMessage}>{moment(row?.last_login).format('DD.MM.YYYY HH:mm:ss')}</div>
								</div>
							</Grid>
						</Grid>
					</Box>
				</TableCell>
				<div className={classes.separate}/>
			</VisibleContent>

		</>
	)
}
const RowLoading = (props) => {
	return (
		<>
			<TableRow>
				<TableCell><Skeleton height={20}/></TableCell>
				<TableCell><Skeleton height={20}/></TableCell>
				<TableCell><Skeleton height={20}/></TableCell>
				<TableCell><Skeleton height={20}/></TableCell>
				<TableCell><Skeleton height={20}/></TableCell>
				<TableCell>
					<Grid container spacing={1} justify="flex-end">
						<Grid item><Skeleton height={40} width={40} variant="circle"/></Grid>
						<Grid item><Skeleton height={40} width={40} variant="circle"/></Grid>
					</Grid>
				</TableCell>
			</TableRow>
		</>
	)
}

const useStyles = makeStyles(() => ({
	tableInfoTitle: {
		fontSize: "12px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#647083",
	},
	tableInfoMessage: {
		fontSize: "16px",
		lineHeight: "130%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
}));

export default TableUsers
