import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Paper,
  makeStyles,
  Box
} from '@material-ui/core';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  buttonDelete: {
    color: 'rgba(0, 0, 0, 0.4)'
  }
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ModalDelete = (props) => {
  const classes = useStyles();
  const {
    isDelete,
    item,
    open,
    isClose
  } = props;

  const deleteRow = () => {
    isDelete({ consent: true, item })
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'sm'}
      onClose={isClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <DialogTitle
        id="dialog-confirmation-deletion"
        onClose={isClose}
      >
        <Box>
          <Typography
            variant={'h5'}
          >
            Удаление "{item.serial}"
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Вы действительно хотите удалить запись?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={deleteRow} className={classes.buttonDelete}>
          Удалить
        </Button>
        <Button autoFocus onClick={isClose} color="primary">
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalDelete
