import React from 'react';
import {
  Grid,
  Box,
  Typography
} from '@material-ui/core';

const PageError = (props) => {
  return (
    <Grid container justify={'center'} alignItems={'center'} style={{ flex: 1, height: '100%', backgroundColor: '#152435' }}>
      <Grid item xs={12}>
        <Box mb={2}>

          <Typography variant={'h1'} align={'center'} style={{ color: 'white' }}>Ошибка системы</Typography>
        </Box>

        <Box mb={2}>
          <Typography variant={'h5'} align={'center'} style={{ color: 'white' }}>Мы уже получили сообщение об ошибке системы.</Typography>
          <Typography variant={'h5'} align={'center'} style={{ color: 'white' }}>Приступаем к расмотрению ошибки.</Typography>
        </Box>

        <Box></Box>
      </Grid>
    </Grid>
  )
}

export default PageError
