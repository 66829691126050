import React from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {Popup} from 'react-leaflet';
import Marker from './Marker';

const Points = (props) => {
  const {showCluster, points, openInfo} = props

  if (showCluster) {
    return (
      <MarkerClusterGroup>
        {
          points.map((point, idx) => (
            <Marker
              key={'point-' + idx}
              position={point.position}
              title={point.points.length}
              className={point.className}
            >
              <Popup>
                {
                  point.points.map((item, idx) => (
                    <div key={'popap-items-' + idx}>{item.title}</div>
                  ))
                }
              </Popup>
            </Marker>
          ))
        }
      </MarkerClusterGroup>
    )
  }


  return (
    <div>
      {
        points.map((point, idx) => (
          <Marker
            key={'point-' + idx}
            position={point.position}
            className={'market-first'}
            title={point.points.length}
            className={point.className}
          >
            <Popup>
              {
                point.points.map((item, idx) => (
                  <div
                    key={'popap-items-' + idx}
                    onClick={() => openInfo(item)}
                  >{item.title}</div>
                ))
              }
            </Popup>
          </Marker>
        ))
      }
    </div>
  )
}

export default Points
