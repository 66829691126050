export default {
  root: {
    borderRadius: 8,
    outline: 'solid #F4F6F8 8px',
    margin: '8px',
    width: 'calc(100% - 16px)',

    "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root": {
      border: "none"
    },
    "&.--small": {
      outline: 'none',
      margin: '0',
      width: '100%',

      "& .MuiTableCell-root": {
        padding: "4px"
      }
    }
  },
};
