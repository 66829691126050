import {getLocalStorage} from "./helpers/localStorage";
import axiosAgent from "./plugins/axios";
import urls from "./constant/urls";

const UPDATE_USER = 'app/UPDATE_USER'
const UPDATE_NOTIFICATION = 'app/UPDATE_NOTIFICATION'
const UPDATE_FAVORITES = 'app/UPDATE_FAVORITES'
const UPDATE_OPEN_LEFT_SIDEBAR = 'app/UPDATE_OPEN_LEFT_SIDEBAR'
const UPDATE_COUNT_BADGE_WANTED = 'app/UPDATE_COUNT_BADGE_WANTED'

const UPDATE_REFERENCE_CATEGORIES = 'app/UPDATE_REFERENCE_CATEGORIES';

let openSidebarLeft = localStorage.getItem('openSidebarLeft');
openSidebarLeft = (document.documentElement.clientWidth <= 1399) ? false : openSidebarLeft;

let favoritesList = getLocalStorage('favorites');
if (!favoritesList) {
  favoritesList = '[]';
}
const favoritesListInit = JSON.parse(favoritesList);

const initialState = {
  user: {},
  notification: [],
  favorites: favoritesListInit,
  openSidebarLeft: (openSidebarLeft === 'true') ? true : false,

  countBadgeWanted: 0,


  referenceCategories: [],
};

export function setUser(user) {
  return {
    type: UPDATE_USER,
    user
  }
}

export function setNotification(notification) {
  return {
    type: UPDATE_NOTIFICATION,
    notification
  }
}

export function setFavorites(favorites) {
  return {
    type: UPDATE_FAVORITES,
    favorites
  }
}

export function setOpenLeftSidebar(openSidebarLeft) {

  return {
    type: UPDATE_OPEN_LEFT_SIDEBAR,
    openSidebarLeft
  }
}

export function setCountBadgeWanted(countBadgeWanted) {
  return {
    type: UPDATE_COUNT_BADGE_WANTED,
    countBadgeWanted
  }
}


// Справочник
export function getReferenceCategories () {
  return async function (dispatch) {
    const res = await axiosAgent('get', urls["help-get-list"]).then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });
    const _res = (res || []).map((_item) => {
      return {
        id: _item?.id || '',
        slug: _item?.slug || '',
        rank: _item?.rank || '',
        title: _item?.title || '',
        parent_id: _item?.parent_id || '',
      }
    });
    const _findChildren = (item) => {
      const _items = _res.filter((t) => t.parent_id === item?.id);
      return _items.map((t) => {
        const children = _findChildren(t.id);
        return {
          ...t,
          slug: [item?.slug, t?.slug].join('/'),
          children
        }
      })
    };

    const referenceCategories = _res
      .filter((t) => Boolean(t.parent_id === '000000000000000000000000'))
      .sort((a, b) => {
        if (Number.parseFloat(a?.rank || 0) > Number.parseFloat(b?.rank || 0)) {
          return 1
        }
        if (Number.parseFloat(a?.rank || 0) < Number.parseFloat(b?.rank || 0)) {
          return -1
        }
        return 0
      })
      .map((t) => {
        const children = _findChildren(t);
        return {
          ...t,
          children
        }
      });

    await dispatch({
      type: UPDATE_REFERENCE_CATEGORIES,
      referenceCategories
    });
  }
}

// Reducer
export default function AppState(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_USER: {
      let user = action.user

      return {
        ...state,
        user
      }
    }
    case UPDATE_NOTIFICATION: {
      let notification = action.notification

      return {
        ...state,
        notification
      }
    }
    case UPDATE_FAVORITES: {
      let favorites = action.favorites;

      return Object.assign({}, state, {
        favorites: favorites
      })
    }
    case UPDATE_OPEN_LEFT_SIDEBAR: {
      let openSidebarLeft = action.openSidebarLeft

      return {
        ...state,
        openSidebarLeft
      }
    }
    case UPDATE_COUNT_BADGE_WANTED: {
      let countBadgeWanted = action.countBadgeWanted

      return {
        ...state,
        countBadgeWanted
      }
    }
    case UPDATE_REFERENCE_CATEGORIES: {
      let referenceCategories = action.referenceCategories

      return {
        ...state,
        referenceCategories
      }
    }
    default:
      return state;
  }
}
