import React, {PureComponent, useEffect, useRef} from "react";
import {
    Box,
    Grid,
} from "@material-ui/core";
import {
    withStyles

} from "@material-ui/styles";
import {
    Map,
    Marker,
    // Polyline,
    TileLayer,
    withLeaflet
} from "react-leaflet";
import Polyline from 'react-leaflet-arrowheads'
import {
    PolylineDecorator
} from "../../../../../components";
import {renderToStaticMarkup} from "react-dom/server";
import L, {divIcon} from "leaflet";
import moment from "moment";

class MapComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isReady: false
        }
    }

    getRoutes = (route) => {
        let list = [];

        for (let index = 0; index !== route.length - 1; index++) {
            let currentGps = route.route_item_list[index];
            let nuxtGps = route.route_item_list[index + 1];

            list.push({
                positions: [currentGps.gps, nuxtGps.gps],
                color: route.color
            })
        }

        return list;
    }
    getPatterns = (route) => {
        return [{
            offset: '95%',
            repeat: 0,
            symbol: L.Symbol.arrowHead({
                pixelSize: 20,
                pathOptions: {
                    stroke: false,
                    strokeColor: route.color,
                    weight: 10,
                    fill: true,
                    fillColor: route.color,
                    fillOpacity: 1,
                    fillRule: ''
                }
            })
        }]
    }

    getBounds = () => {
        const routes = this.props.routes;
        let points = [];

        routes.map((route) => {
            route.route_item_list.map((item) => {
                points.push([item.gps.lat, item.gps.lon]);
            })
        })

        if (points.length <= 0){
            return null
        }

        return points
    }

    render() {
        const {
            refMap,

            shows,
            routes,
            classes,
        } = this.props;

        return (
            <Box borderRadius={4} overflow="hidden" bgcolor="white" flex={1} display="flex">

                <Map
                    ref={ref => {
                        refMap.current = ref;
                        this.setState({isReady: true})
                    }}
                    style={{flex: 1}}
                    center={{lat: 56.8519000, lon: 60.6122000}}
                    length={4}
                    zoom={12}
                    bounds={this.getBounds()}
                >

                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />


                    {
                        routes.map((route, idx) => {
                            const routes = this.getRoutes(route);
                            const stylePolyline = this.getPatterns(route);

                            if (shows.length > 0 && !shows.includes(route.index)) {
                                return null
                            }

                            return (
                                <>

                                    {

                                        route.route_item_list.map((point) => {
                                            const iconMarkup = renderToStaticMarkup(
                                                <div style={{width: 30, height: 30, backgroundColor: route.color, borderRadius: 999}}/>
                                            );

                                            const customMarkerIcon = divIcon({
                                                html: iconMarkup,
                                                className: classes.point,
                                                iconSize: [30, 30],
                                            });

                                            return (

                                                <Marker
                                                    position={point.gps}
                                                    icon={customMarkerIcon}
                                                />

                                            )

                                        })

                                    }

                                    {
                                        routes.map((route) => {

                                            if (!refMap.current) {
                                                return null
                                            }

                                            return (
                                                <PolylineComponent
                                                    map={refMap.current.leafletElement}
                                                    color={route.color}
                                                    positions={route.positions}
                                                    patterns={stylePolyline}
                                                />
                                            )
                                        })
                                    }

                                </>
                            )
                        })
                    }


                </Map>

            </Box>
        )
    }
}

const PolylineComponent = withLeaflet((props) => {
    return (
        <PolylineDecorator
            {...props}
        />
    )
})

const styles = {
    point: {
        width: 30,
        height: 30,
        borderRadius: 999
    }
};

export default withStyles(styles)(MapComponent)
