import React from 'react';
import {divIcon} from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import {Marker} from 'react-leaflet';

const marker = (props) => {
  let { position, children, title, className } = props
  let iconClassName = 'marker ' + className

  const iconMarkup = renderToStaticMarkup(
    <div className={'marker-content'}>
      <span>{ title }</span>
    </div>
  );


  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: iconClassName,
    iconSize: [25, 25],
  });

  return (
    <Marker position={position} icon={customMarkerIcon}>
      { children }
    </Marker>
  )
};

export default marker
