import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography
} from "@material-ui/core";
import {
  makeStyles
} from "@material-ui/styles";
import {
  GetAppOutlined as GetAppOutlinedIcon
} from "@material-ui/icons";
import {
  numberFormat
} from "../../../../../helpers";
import {
  ExportCreate
} from "../../components";
import moment from "moment";

const HeadInformation = (props) => {
  const {
    totalCount,
    searchTimeStamp,
    isAllowedExport,
    onExportRows
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.totalCount}>
        Всего найдено: {numberFormat(totalCount)}
      </Typography>
      {Boolean(searchTimeStamp) && (
        <Typography className={classes.actualDate}>
          Данные актуальны<br/>на {moment(searchTimeStamp).format("DD.MM.YYYY HH:mm:ss")}
        </Typography>
      )}

      <Box className={classes.delimiter}/>

      <Grid container spacing={2} style={{flex: 0}}>
        <Grid item>
          <ExportCreate
            disabled={!isAllowedExport}
            onExportFile={onExportRows}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  totalCount: {
    color: '#152435',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '100%',
    letterSpacing: '-0.56px',
  },
  actualDate: {
    marginLeft: 26,

    color: '#8698B1',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '100%',
    letterSpacing: '-0.28px',
  },
  delimiter: {
    flex: 1
  }
}));

export default React.memo(HeadInformation)
