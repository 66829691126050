import React from "react";
import {
	Box,
	Select,
	MenuItem,
	InputLabel,
	Typography,
	FormControl,
	FormControlLabel
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import {
	DateRange
} from "../../../../../components";

const FilterTemplate = (props) => {
	const {
		number,
		filter,
		perPage,
		totalCount,

		onChangeFilter,
		onChangeCommonState
	} = props;
	const classes = useStyles();

	const handleChangeFilterDate = (value) => {
		let _filter = {...filter};

		const getTimeVal = (val) => {
			if (!val) {
				return null
			}

			const date = new Date();
			date.setTime(val * 1000);
			return date
		}
		_filter.datetime_start = getTimeVal(value?.startDate);
		_filter.datetime_end = getTimeVal(value?.endDate);
		onChangeFilter(_filter);
	}
	const handleChangeTotalCount = (event, value) => {
		onChangeCommonState({
			name: 'perPage',
			value: value?.props?.value
		}, { isStartSearch: true });
	}

	return (
		<Box className={classes.root}>

			<Typography className={classes.numberGrz}>ГРЗ: { number }</Typography>
			<Typography className={classes.totalCount}>Всего отображено: { totalCount }</Typography>

			<Box className={classes.dateRange}>
				<DateRange
					dateStart={filter?.datetime_start || null}
					dateEnd={filter?.datetime_end || null}
					label="Временной промежуток"
					onChange={handleChangeFilterDate}
				/>
			</Box>

			<Box className={classes.perPage}>
				<FormControl fullWidth>
					<InputLabel>Показывать по</InputLabel>
					<Select
						value={perPage}
						label="Показывать по"
						variant="outlined"
						onChange={handleChangeTotalCount}
					>
						{[40,60,80,100,120,140,180,200].map((numberCount) => (
							<MenuItem value={numberCount}>
								{numberCount} элементов
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		padding: "20px",
		boxSizing: "border-box",
		backgroundColor: "#F4F6F8",
		borderRadius: "10px"
	},
	numberGrz: {
		fontWeight: "600",
		fontSize: "24px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	totalCount: {
		marginTop: 4,

		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		color: "#000000",
	},
	dateRange: {
		marginTop: 24
	},
	perPage: {
		marginTop: 24
	},
}));

export default FilterTemplate
