import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  CustomTablePagination
} from '../../../../../components';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  username: {},
  email: {},
  description: {
    width: '50%'
  },
  created: {}
}));

const TableComponents = props => {
  const {
    rows, filter, pagination, loading, onChangeFilter
  } = props;
  const classes = useStyles();

  const handleChangePage = (event, page) => {
    let newFilter = {...filter};

    newFilter['page'] = page;

    onChangeFilter(newFilter)
  }
  const handleGetDateTime = (dateTime) => {
    let dateStartDate = moment(dateTime).format('DD.MM.YYYY');
    let dateStartTime = moment(dateTime).format('HH:mm');

    return (
      <div>
        <p>{dateStartDate}</p>
        <p>{dateStartTime}</p>
      </div>
    );
  }

  return (
    <>
      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
      />

      <PerfectScrollbar style={{height: 'auto'}}>
        <Box>
          <Table
            id={'basic-drive-table'}
            className={clsx(['MuiTable-list'])}
          >
            <TableHead className={'MuiTableHead-list'}>
              <TableRow>
                <TableCell align={'left'} className={classes.username}>Пользователь</TableCell>
                <TableCell align={'left'} className={classes.email}>Почта для связи</TableCell>
                <TableCell align={'left'} className={classes.description}>Комментарий</TableCell>
                <TableCell align={'left'} className={classes.created}>Время обращения</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, idx) => {
                return (
                  <TableRow
                    key={row.name + '-' + idx}
                    className={'MuiTableRow-list'}
                  >
                    <TableCell>{row['username']}</TableCell>
                    <TableCell>{row['email']}</TableCell>
                    <TableCell>{row['text']}</TableCell>
                    <TableCell>{handleGetDateTime(row['created'])}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
      />
    </>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  showColumns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
  showColumns: []
};

export default TableComponents;
