import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  SvgIcon,
  TableSortLabel,
  Box,
  Button,
  Grid,
  Tooltip
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  Edit2 as EditIcon,
  Trash as TrashIcon,
} from 'react-feather';
import {
  CustomCheckbox as Checkbox, CustomTablePagination
} from '../../../../../components';

const useStyles = makeStyles(theme => ({
  tableSortLabel: {
    fontSize: 12,
    lineHeight: 1.1
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    cursor: 'pointer'
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '&:hover td:last-child': {
          backgroundColor: 'white',
        },
        '& td:last-child, th:last-child': {
          backgroundColor: 'white',
          position: 'sticky',
          right: 0,
          zIndex: 999
        },
        '& th:last-child': {
          backgroundColor: 'rgb(229, 235, 241)',
        },
        '& th:first-child': {
          zIndex: 9999
        }
      }
    }
  }
}));

const TableComponents = props => {
  const {rows, columns, selected, isSelectRowTable, isDeleteRowTable} = props;
  const classes = useStyles();
  const history = useHistory();

  const toEdit = (id) => {
    history.push('/sensor-group/edit/' + id)
  };
  const deleteRow = (item) => {
    isDeleteRowTable({consent: false, item})
  };
  const handleSelectOneSensor = (id) => {
    let newSelected = selected

    if (newSelected.indexOf(id) > -1) {
      newSelected.splice(newSelected.indexOf(id), 1)
    } else {
      newSelected.push(id)
    }

    isSelectRowTable(newSelected)
  };
  const handleSelectAllCustomers = () => {
    let newSelected = []

    if (selected.length !== rows.length) {
      rows.map(item => {
        newSelected.push(item.id)
      })
    }

    isSelectRowTable(newSelected)
  };

  let selectedSomeSensor = selected.length > 0 && selected.length < rows.length;
  let selectedAllSensor = selected.length === rows.length;

  return (
    <PerfectScrollbar>
      <Box className={classes.stickyActionsColumn}>
        <Table
          className={'MuiTable-list'}
        >
          <TableHead className={'MuiTableHead-list'}>
            <TableRow>
              {
                false && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllSensor}
                      indeterminate={selectedSomeSensor}
                      onChange={handleSelectAllCustomers}
                    />
                  </TableCell>
                )
              }
              {
                columns.map((column, idx) => (
                  <TableCell
                    key={'table-cell-' + idx}
                    align={'left'}
                  >
                    {column.label}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => {
              const isSensorSelected = selected.indexOf(row.id) > -1;

              return (
                <TableRow key={row.name + '-' + idx} selected={isSensorSelected} className={'MuiTableRow-list'}>
                  {
                    false && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected.indexOf(row.id) > -1}
                          onChange={() => handleSelectOneSensor(row.id)}
                          value={isSensorSelected}
                        />
                      </TableCell>
                    )
                  }
                  <TableCell>{row.Title}</TableCell>

                  <TableCell>
                    <Grid
                      container
                      wrap={'nowrap'}
                      justify={'flex-end'}
                    >
                      <Tooltip title="Удаление">
                        <IconButton onClick={() => deleteRow(row)}>
                          <SvgIcon fontSize="small">
                            <TrashIcon/>
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Редактирование">
                        <IconButton onClick={() => toEdit(row.id)}>
                          <SvgIcon fontSize="small">
                            <EditIcon/>
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};

export default TableComponents;
