import React from 'react';
import {
  ModalDevices,
} from './components/';
import './index.scss'

const ModalSensorMap = (props) => {
  const {
    sensors, isOpen, onClose,
    onClick, showCardSensor, cardSensor,
    onCloseCard, onCardEdit, mapPolygons,
    onChangeMapPolygons, selected, toEditFromMap,
    sensorsCameraIds, onOpenSensorCameraStream,
    roleSensorStream
  } = props;
  return (
    <ModalDevices
      open={isOpen}
      isClose={onClose}
      devicesSensor={sensors}
      onClick={onClick}
      showCardSensor={showCardSensor}
      cardSensor={cardSensor}
      onCloseCard={onCloseCard}
      onCardEdit={onCardEdit}
      mapPolygons={mapPolygons}
      onChangeMapPolygons={onChangeMapPolygons}
      selected={selected}
      toEditFromMap={toEditFromMap}
      sensorsCameraIds={sensorsCameraIds}
      roleSensorStream={roleSensorStream}
      onOpenSensorCameraStream={onOpenSensorCameraStream}
    />
  )
}

export default ModalSensorMap
