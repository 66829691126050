import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Typography,
} from '@material-ui/core';
import {
	withStyles
} from "@material-ui/styles";
import {
	PageFilterTableContent
} from "../../../components";
import {
	Table as TableComponent,
	Filter as FilterComponent
} from "./components";

const initFilter = {};

class LogsViolations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [
				{
					"id": "6639c203bd7b77e12cd49a8e",
					"sensor_id": "61c174595ad4c26b138fe218",
					"ip": "10.200.225.34:51898",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11351-56986-11",
					"sensor_location": "Перекресток ул.Московская; ул.Щорса, г. Екатеринбург, Свердловская обл.",
					"license_plate": "Р411КТ196",
					"violations": [
						10
					],
					"datetime": "2024-05-07T05:12:32.331Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:54:11.276Z",
					"response_time": "2024-05-07T05:54:24.162Z"
				},
				{
					"id": "6639c1fbbd7b77e12cd495ef",
					"sensor_id": "61c174595ad4c26b138fe218",
					"ip": "10.200.225.34:51934",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11351-56986-11",
					"sensor_location": "Перекресток ул.Московская; ул.Щорса, г. Екатеринбург, Свердловская обл.",
					"license_plate": "М649РЕ196",
					"violations": [
						10
					],
					"datetime": "2024-05-07T05:12:35.531Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:54:03.218Z",
					"response_time": "2024-05-07T05:54:16.301Z"
				},
				{
					"id": "6639c1f8bd7b77e12cd4946c",
					"sensor_id": "64edb918a8cf1a9907d0a794",
					"ip": "10.200.178.122:44042",
					"sensor_vendor": "ПРИЗМА",
					"sensor_serial": "PSS23029",
					"sensor_location": "г. Екатеринбург, ул. Викулова, д. 28б",
					"license_plate": "Е006ЕТ196",
					"violations": [
						31
					],
					"datetime": "2024-05-07T05:47:08Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:54:00.675Z",
					"response_time": "2024-05-07T05:54:04.743Z"
				},
				{
					"id": "6639c1f6bd7b77e12cd49381",
					"sensor_id": "61c174595ad4c26b138fe0de",
					"ip": "10.7.33.29:58204",
					"sensor_vendor": "ДЕКАРТ",
					"sensor_serial": "DKLCR032011",
					"sensor_location": "г. Екатеринбург, ул. Бебеля, д. 109/1",
					"license_plate": "М440ХУ196",
					"violations": [
						2
					],
					"datetime": "2024-05-07T05:51:11Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:58.686Z",
					"response_time": "2024-05-07T05:54:04.228Z"
				},
				{
					"id": "6639c1edbd7b77e12cd48ec2",
					"sensor_id": "64edb918a8cf1a9907d0a794",
					"ip": "10.200.178.122:44040",
					"sensor_vendor": "ПРИЗМА",
					"sensor_serial": "PSS23029",
					"sensor_location": "г. Екатеринбург, ул. Викулова, д. 28б",
					"license_plate": "Е006ЕТ196",
					"violations": [
						32
					],
					"datetime": "2024-05-07T05:47:08Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:49.51Z",
					"response_time": "2024-05-07T05:53:56.065Z"
				},
				{
					"id": "6639c1eabd7b77e12cd48d12",
					"sensor_id": "61c174595ad4c26b138fe081",
					"ip": "10.8.4.230:59624",
					"sensor_vendor": "КОРДОН-М 2",
					"sensor_serial": "MD0807-KD1254",
					"sensor_location": "Автодорога Подъезд к городу Екатеринбург от а/д М-5 Урал 148.500 км (из Екатеринбурга)",
					"license_plate": "К606СМ96",
					"violations": [
						2
					],
					"datetime": "2024-05-07T05:48:05Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:46.583Z",
					"response_time": "2024-05-07T05:53:53.211Z"
				},
				{
					"id": "6639c1e1bd7b77e12cd48862",
					"sensor_id": "61c1745a5ad4c26b138fe25e",
					"ip": "10.200.231.98:44538",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11526-57092-41",
					"sensor_location": "Перекресток ул.Патриса Лумумбы; ул.Военная, г. Екатеринбург, Свердловская обл.",
					"license_plate": "Р981КВ774",
					"violations": [
						103
					],
					"datetime": "2024-05-07T05:47:26.005Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:37.228Z",
					"response_time": "2024-05-07T05:53:58.777Z"
				},
				{
					"id": "6639c1e0bd7b77e12cd487ef",
					"sensor_id": "61c1745a5ad4c26b138fe2f6",
					"ip": "10.200.235.226:43032",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11521-57104-21",
					"sensor_location": "Перекресток ул.8 Марта; ул.Куйбышева, г. Екатеринбург, Свердловская обл.",
					"license_plate": "Р324ОО196",
					"violations": [
						83
					],
					"datetime": "2024-05-07T05:51:56.91Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:36.58Z",
					"response_time": "2024-05-07T05:53:57.039Z"
				},
				{
					"id": "6639c1dcbd7b77e12cd48636",
					"sensor_id": "64edb918a8cf1a9907d0a794",
					"ip": "10.200.178.122:44038",
					"sensor_vendor": "ПРИЗМА",
					"sensor_serial": "PSS23029",
					"sensor_location": "г. Екатеринбург, ул. Викулова, д. 28б",
					"license_plate": "М414СЕ196",
					"violations": [
						32
					],
					"datetime": "2024-05-07T05:39:34Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:32.878Z",
					"response_time": "2024-05-07T05:53:40.571Z"
				},
				{
					"id": "6639c1d9bd7b77e12cd484a3",
					"sensor_id": "61c1745a5ad4c26b138fe25e",
					"ip": "10.200.231.98:44372",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11526-57092-41",
					"sensor_location": "Перекресток ул.Патриса Лумумбы; ул.Военная, г. Екатеринбург, Свердловская обл.",
					"license_plate": "М548МР196",
					"violations": [
						103
					],
					"datetime": "2024-05-07T05:49:31.715Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:29.564Z",
					"response_time": "2024-05-07T05:53:42.664Z"
				},
				{
					"id": "6639c1d0bd7b77e12cd48035",
					"sensor_id": "64edb918a8cf1a9907d0a794",
					"ip": "10.200.178.122:44036",
					"sensor_vendor": "ПРИЗМА",
					"sensor_serial": "PSS23029",
					"sensor_location": "г. Екатеринбург, ул. Викулова, д. 28б",
					"license_plate": "С719ХР196",
					"violations": [
						31
					],
					"datetime": "2024-05-07T05:39:22Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:20.123Z",
					"response_time": "2024-05-07T05:53:26.017Z"
				},
				{
					"id": "6639c1cabd7b77e12cd47d5f",
					"sensor_id": "61c174595ad4c26b138fe218",
					"ip": "10.200.225.34:44382",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11351-56986-11",
					"sensor_location": "Перекресток ул.Московская; ул.Щорса, г. Екатеринбург, Свердловская обл.",
					"license_plate": "С642КС196",
					"violations": [
						10
					],
					"datetime": "2024-05-07T05:12:28.732Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:14.032Z",
					"response_time": "2024-05-07T05:53:27.888Z"
				},
				{
					"id": "6639c1c6bd7b77e12cd47b44",
					"sensor_id": "64edb918a8cf1a9907d0a794",
					"ip": "10.200.178.122:44034",
					"sensor_vendor": "ПРИЗМА",
					"sensor_serial": "PSS23029",
					"sensor_location": "г. Екатеринбург, ул. Викулова, д. 28б",
					"license_plate": "С139АХ196",
					"violations": [
						31
					],
					"datetime": "2024-05-07T05:36:55Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:10.788Z",
					"response_time": "2024-05-07T05:53:16.508Z"
				},
				{
					"id": "6639c1c3bd7b77e12cd479d8",
					"sensor_id": "61c174595ad4c26b138fe218",
					"ip": "10.200.225.34:44386",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11351-56986-11",
					"sensor_location": "Перекресток ул.Московская; ул.Щорса, г. Екатеринбург, Свердловская обл.",
					"license_plate": "В943МН196",
					"violations": [
						10
					],
					"datetime": "2024-05-07T05:12:26.932Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:07.395Z",
					"response_time": "2024-05-07T05:53:20.061Z"
				},
				{
					"id": "6639c1c2bd7b77e12cd47995",
					"sensor_id": "61c18103c001f5430c8d8fea",
					"ip": "10.66.18.99:34038",
					"sensor_vendor": "АВТОДОРИЯ",
					"sensor_serial": "018-099",
					"sensor_location": "а/д \"Екатеринбург - Кольцово\", 5+220 км, в направлении от г. Екатеринбург",
					"license_plate": "С342АР750",
					"violations": [
						2
					],
					"datetime": "2024-05-07T04:07:25.232Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:06.801Z",
					"response_time": "2024-05-07T05:53:13.105Z"
				},
				{
					"id": "6639c1bfbd7b77e12cd47816",
					"sensor_id": "62a1d7e92a1baffe2df08266",
					"ip": "10.200.237.226:50276",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11557-57259-21",
					"sensor_location": "регулируемый перекресток в районе домов 47, 64 по переулку Базовому, г. Екатеринбург, Свердловская обл.",
					"license_plate": "Х743КМ59",
					"violations": [
						83
					],
					"datetime": "2024-05-07T05:51:24.175Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:03.765Z",
					"response_time": "2024-05-07T05:53:17.648Z"
				},
				{
					"id": "6639c1bebd7b77e12cd4776e",
					"sensor_id": "64edb918a8cf1a9907d0a794",
					"ip": "10.200.178.122:44030",
					"sensor_vendor": "ПРИЗМА",
					"sensor_serial": "PSS23029",
					"sensor_location": "г. Екатеринбург, ул. Викулова, д. 28б",
					"license_plate": "А741ЕХ196",
					"violations": [
						31
					],
					"datetime": "2024-05-07T05:36:30Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:53:02.079Z",
					"response_time": "2024-05-07T05:53:07.8Z"
				},
				{
					"id": "6639c1b5bd7b77e12cd47330",
					"sensor_id": "62c2cad52a1baffe2df087d1",
					"ip": "10.200.238.194:40110",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11555-57257-31",
					"sensor_location": "Перекресток ул.Бакинских Комиссаров; ул.Победы, г. Екатеринбург, Свердловская обл.",
					"license_plate": "О395АН196",
					"violations": [
						10
					],
					"datetime": "2024-05-07T05:51:42.531Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:52:53.417Z",
					"response_time": "2024-05-07T05:53:20.578Z"
				},
				{
					"id": "6639c1b3bd7b77e12cd4720e",
					"sensor_id": "61c1745a5ad4c26b138fe25e",
					"ip": "10.200.231.98:59144",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11526-57092-41",
					"sensor_location": "Перекресток ул.Патриса Лумумбы; ул.Военная, г. Екатеринбург, Свердловская обл.",
					"license_plate": "Т063МН96",
					"violations": [
						103
					],
					"datetime": "2024-05-07T05:47:26.005Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:52:51.096Z",
					"response_time": "2024-05-07T05:53:16.163Z"
				},
				{
					"id": "6639c1b2bd7b77e12cd471cd",
					"sensor_id": "61c174595ad4c26b138fe081",
					"ip": "10.8.4.230:59624",
					"sensor_vendor": "КОРДОН-М 2",
					"sensor_serial": "MD0807-KD1254",
					"sensor_location": "Автодорога Подъезд к городу Екатеринбург от а/д М-5 Урал 148.500 км (из Екатеринбурга)",
					"license_plate": "О909ЕН196",
					"violations": [
						2
					],
					"datetime": "2024-05-07T05:47:37Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:52:50.635Z",
					"response_time": "2024-05-07T05:52:57.331Z"
				},
				{
					"id": "6639c1aebd7b77e12cd46f77",
					"sensor_id": "61c1745a5ad4c26b138fe25e",
					"ip": "10.200.231.98:58990",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "11526-57092-41",
					"sensor_location": "Перекресток ул.Патриса Лумумбы; ул.Военная, г. Екатеринбург, Свердловская обл.",
					"license_plate": "Р352КО196",
					"violations": [
						103
					],
					"datetime": "2024-05-07T05:47:15.127Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:52:46.162Z",
					"response_time": "2024-05-07T05:52:57.942Z"
				},
				{
					"id": "6639c1adbd7b77e12cd46f01",
					"sensor_id": "61c174595ad4c26b138fe084",
					"ip": "10.8.5.194:57394",
					"sensor_vendor": "КОРДОН-М 2",
					"sensor_serial": "MD0828-KD1293",
					"sensor_location": "Автодорога Екатеринбург-Шадринск-Курган 64.500 км (в Екатеринбург)",
					"license_plate": "К593ЕО196",
					"violations": [
						2
					],
					"datetime": "2024-05-07T05:51:14Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:52:45.326Z",
					"response_time": "2024-05-07T05:52:50.054Z"
				},
				{
					"id": "6639c1a9bd7b77e12cd46d3c",
					"sensor_id": "61c174595ad4c26b138fe120",
					"ip": "10.200.214.138:51289",
					"sensor_vendor": "ИНТЕГРА-КДД",
					"sensor_serial": "10158-56209-21",
					"sensor_location": "Проезжая часть ул.Карла Либкнехта, д.5, г. Екатеринбург, Свердловская обл.",
					"license_plate": "Н850ЕР196",
					"violations": [
						5
					],
					"datetime": "2024-05-07T05:50:52.92Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:52:41.639Z",
					"response_time": "2024-05-07T05:52:54.588Z"
				},
				{
					"id": "6639c1a7bd7b77e12cd46c59",
					"sensor_id": "64edb918a8cf1a9907d0a794",
					"ip": "10.200.178.122:44028",
					"sensor_vendor": "ПРИЗМА",
					"sensor_serial": "PSS23029",
					"sensor_location": "г. Екатеринбург, ул. Викулова, д. 28б",
					"license_plate": "С914РО193",
					"violations": [
						31
					],
					"datetime": "2024-05-07T05:34:43Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:52:39.804Z",
					"response_time": "2024-05-07T05:52:44.667Z"
				},
				{
					"id": "6639c1a7bd7b77e12cd46c0f",
					"sensor_id": "628460b42a1baffe2df07d79",
					"ip": "10.200.231.219:44026",
					"sensor_vendor": "МАНГУСТ",
					"sensor_serial": "0033",
					"sensor_location": "а/д Екатеринбургская кольцевая автомобильная дорога 22.970 км",
					"license_plate": "К530НХ196",
					"violations": [
						2
					],
					"datetime": "2024-05-07T05:51:28.777Z",
					"response_success": true,
					"response_code": 200,
					"response_error": "",
					"filter_success": false,
					"filters_result": null,
					"filter_errors": {
						"UAD": [
							"rsync_skip"
						]
					},
					"request_time": "2024-05-07T05:52:39.179Z",
					"response_time": "2024-05-07T05:52:46.989Z"
				}
			],
			filter: {...initFilter},

			isLoading: false,
		}
	}

	componentDidMount = () => {
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			items
		} = this.state;

		return (
			<>
				<PageFilterTableContent>
					<slot name="head">
						<Grid container spacing={2}>
							<Grid item>
								<Typography variant="h1">Логи нарушений</Typography>
							</Grid>
							<Grid item>
								<Button
									size="small"
									variant="contained"
									className={classes.buttonUpdate}
								>Обновить</Button>
							</Grid>
						</Grid>
					</slot>
					<slot name="body">
						<TableComponent
							data={items}
						/>
					</slot>
					<slot name="filter">
						<FilterComponent/>
					</slot>
				</PageFilterTableContent>
			</>
		);
	}
};

const styles = {
	buttonUpdate: {
		height: 36,
		padding: "0 24px",
		borderRadius: 8,
		fontSize: 16,
		lineHeight: "16px"
	}
};
LogsViolations = withStyles(styles)(LogsViolations);

export default LogsViolations
