import React from "react";
import {
	Grid,
	Tooltip,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	ToggleButton,
	ToggleButtonGroup
} from "@material-ui/lab";
import {
	makeStyles
} from "@material-ui/styles";
import {
	DragHandle as DragHandleIcon,
	ChevronLeft as ChevronLeftIcon,
	ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import palette from "../../theme/palette";

const FilterSpeed = (props) => {
	const {
		speed,
		direction,

		onChange,
	} = props;
	const classes = useStyles();

	const handleChangeSpeed = ({ target }) => {
		const { value } = target;

		onChange([
			{name: 'speed', value: value},
			{name: 'speed_direction', value: direction},
		]);
	}
	const handleChangeDirection = (event, value) => {
		if (value === null) {
			onChange([
				{name: 'speed', value: ''},
				{name: 'speed_direction', value: undefined},
			]);
			return
		}

		onChange([
			{name: 'speed', value: speed},
			{name: 'speed_direction', value: value},
		]);
	}

	return (
		<>
			<Typography variant="formTitle">Скорость</Typography>
			<Grid container spacing={2}>
				<Grid item>
					<ToggleButtonGroup
						value={String(direction)}
						color="primary"
						exclusive
						className={classes.toggleButtonGroup}
						onChange={handleChangeDirection}
					>
						<Tooltip title="Меньше" arrow>
							<ToggleButton value="-1" className={clsx({'Mui-selected': Boolean('-1' === String(direction))})}>
								<ChevronLeftIcon/>
							</ToggleButton>
						</Tooltip>
						<Tooltip title="Равно" arrow>
							<ToggleButton value="0"  className={clsx({'Mui-selected': Boolean('0' === String(direction))})}>
								<DragHandleIcon/>
							</ToggleButton>
						</Tooltip>
						<Tooltip title="Больше" arrow>
							<ToggleButton value="1"  className={clsx({'Mui-selected': Boolean('1' === String(direction))})}>
								<ChevronRightIcon/>
							</ToggleButton>
						</Tooltip>
					</ToggleButtonGroup>
				</Grid>
				<Grid item style={{ flex: 1 }}>
					<Tooltip title={Boolean(String((direction || ''))) ? '' : 'Для активации поля выберите направление скорости'} arrow>
						<div>
							<TextField
								type="number"
								value={speed}
								disabled={!Boolean(String((direction || '')))}
								variant="outlined"
								fullWidth
								onChange={handleChangeSpeed}
								className={classes.input}
							/>
						</div>
					</Tooltip>
				</Grid>
			</Grid>
		</>
	)
};

const useStyles = makeStyles(() => ({
	input: {
		"& .MuiInputBase-root": {
			height: 44
		}
	},
	toggleButtonGroup: {
		"& .MuiButtonBase-root": {
			width: 44,
			height: 44
		},
		"& .MuiToggleButton-root.Mui-selected": {
			background: palette.primary.main,
			color: "white"
		}
	}
}));

export default FilterSpeed
