import React, {useState} from 'react';

import {
	TextField,
	makeStyles,
	Grid,
	FormControl,
	Select,
	MenuItem,

	InputAdornment,
	IconButton,
	Typography,
	Tooltip,
	Box,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
	DatePicker
} from '../../../../../components';
import {Check as CheckIcon} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {},
	bulkOperations: {
		position: 'relative'
	},
	bulkActions: {
		paddingLeft: 4,
		paddingRight: 4,
		marginTop: 6,
		position: 'absolute',
		width: '100%',
		zIndex: 2,
		backgroundColor: theme.palette.background.default
	},
	queryField: {
		width: 500
	},
	categoryField: {
		width: '100%',
	},
	imageCell: {
		fontSize: 0,
		width: 68,
		flexBasis: 68,
		flexGrow: 0,
		flexShrink: 0
	},
	image: {
		height: 68,
		width: 68
	},

	filterContainer: {
		'& .MuiGrid-grid-xs-3': {
			[theme.breakpoints.between(0, 1599)]: {
				maxWidth: '33.333333%',
				flexBasis: '33.333333%'
			},
		}
	}
}));

const Filter = (props) => {
	const classes = useStyles();
	let {filters, onChange, groupList, roleSensorStream} = props;

	const handleChangeFilter = (event) => {
		const item = event.target;
		const name = item.name;
		let value = item.value;

		if (value === 'Все') {
			value = ''
		}

		filters[name] = value;
		onChange(filters)
	}
	const handleChangeFilterDate = (date, name) => {
		filters[name] = date;
		onChange(filters)
	}
	const handleClearFilter = (name, value) => {
		filters[name] = value;
		onChange(filters)
	}
	const handleChangeCheckbox = (name, value) => {
		let newFilters = {...filters};

		newFilters[name] = value ? value : null;

		onChange(newFilters);
	}

	return (
		<Box>
			<Grid container spacing={2} className={classes.filterContainer}>
				<Grid xs={12} item>
					<Typography variant={'formTitle'}>Серийный</Typography>
					<TextField
						className={classes.categoryField}
						name={'serial'}
						value={filters.serial}
						variant="outlined"
						onChange={handleChangeFilter}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<>
										{
											(filters.serial) && (
												<Tooltip title="Очистить">
													<IconButton onClick={() => handleClearFilter('serial', '')}>
														<HighlightOffIcon/>
													</IconButton>
												</Tooltip>
											)
										}
									</>
								</InputAdornment>
							)
						}}
					/>
				</Grid>
				<Grid xs={12} item>
					<Typography variant={'formTitle'}>Идентификатор</Typography>
					<TextField
						className={classes.categoryField}
						name={'title'}
						value={filters.title}
						variant="outlined"
						onChange={handleChangeFilter}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<>
										{
											(filters.title) && (
												<Tooltip title="Очистить">
													<IconButton onClick={() => handleClearFilter('title', '')}>
														<HighlightOffIcon/>
													</IconButton>
												</Tooltip>
											)
										}
									</>
								</InputAdornment>
							)
						}}
					/>
				</Grid>
				<Grid xs={12} item>
					<Typography variant={'formTitle'}>Модель</Typography>
					<TextField
						className={classes.categoryField}
						label=""
						name={'vendor'}
						value={filters.vendor}
						variant="outlined"
						onChange={handleChangeFilter}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<>
										{
											(filters.vendor) && (
												<Tooltip title="Очистить">
													<IconButton onClick={() => handleClearFilter('vendor', '')}>
														<HighlightOffIcon/>
													</IconButton>
												</Tooltip>
											)
										}
									</>
								</InputAdornment>
							)
						}}
					/>
				</Grid>
				<Grid xs={12} item>
					<Typography variant={'formTitle'}>Местоположение</Typography>
					<TextField
						className={classes.categoryField}
						name={'address'}
						value={filters.address}
						variant="outlined"
						onChange={handleChangeFilter}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<>
										{
											(filters.address) && (
												<Tooltip title="Очистить">
													<IconButton onClick={() => handleClearFilter('address', '')}>
														<HighlightOffIcon/>
													</IconButton>
												</Tooltip>
											)
										}
									</>
								</InputAdornment>
							)
						}}
					/>
				</Grid>
				<Grid xs={12} item>
					<Typography variant={'formTitle'}>Группа</Typography>
					<FormControl
						className={classes.categoryField}
						variant='outlined'
						size='small'
					>
						<Select
							labelId="demo-mutiple-groups-label"
							id="demo-mutiple-groups"
							multiple
							name="groups"
							value={filters.groups}
							onChange={handleChangeFilter}
							MenuProps={{
								getContentAnchorEl: null,
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									{
										(filters.groups && filters.groups.length > 0) && (
											<Tooltip title="Очистить">
												<IconButton onClick={() => handleClearFilter('groups', [])}>
													<HighlightOffIcon/>
												</IconButton>
											</Tooltip>
										)
									}
								</InputAdornment>
							}
						>
							{groupList.map((group) => (
								<MenuItem key={group.id} value={group.id}>
									{group.Title}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={12} item>
					<Typography variant={'formTitle'}>Статус</Typography>
					<FormControl
						className={classes.categoryField}
						variant='outlined'
						size='small'
					>
						<Select
							labelId="demo-mutiple-status-label"
							id="demo-mutiple-status"
							name="status"
							value={filters.status}
							onChange={handleChangeFilter}
							MenuProps={{
								getContentAnchorEl: null,
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									{
										(filters.status && filters.status.length > 0) && (
											<Tooltip title="Очистить">
												<IconButton onClick={() => handleClearFilter('status', null)}>
													<HighlightOffIcon/>
												</IconButton>
											</Tooltip>
										)
									}
								</InputAdornment>
							}
						>
							<MenuItem value={10}>Активный</MenuItem>
							<MenuItem value={20}>Ошибка</MenuItem>
							<MenuItem value={0}>Неактивный</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={12} item>
					<Typography variant={'formTitle'}>Тип устройства</Typography>
					<FormControl
						className={classes.categoryField}
						variant='outlined'
						size='small'
					>
						<Select
							labelId="demo-mutiple-type-label"
							id="demo-mutiple-type"
							name="type"
							value={filters.type}
							onChange={handleChangeFilter}
							MenuProps={{
								getContentAnchorEl: null,
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									{
										(filters.type && filters.type.length > 0) && (
											<Tooltip title="Очистить">
												<IconButton onClick={() => handleClearFilter('type', null)}>
													<HighlightOffIcon/>
												</IconButton>
											</Tooltip>
										)
									}
								</InputAdornment>
							}
						>
							<MenuItem value={10}>Стационарный</MenuItem>
							<MenuItem value={20}>Мобильный</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>

			{
				roleSensorStream && (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormControlLabel
								control={<Checkbox
									checked={filters.with_cameras}
									onChange={() => handleChangeCheckbox('with_cameras', !filters.with_cameras)}
									checkedIcon={<CheckIcon/>}
								/>}
								label="Только с видео"
							/>
						</Grid>
					</Grid>
				)
			}
		</Box>
	)
}

export default Filter;


// <>
//   <Grid xs={3} item>
//     <Typography variant={'formTitle'}>Тип</Typography>
//     <FormControl
//       className={classes.categoryField}
//       variant='outlined'
//       size='small'
//     >
//       <Select
//         labelId="demo-mutiple-type-label"
//         id="demo-mutiple-type"
//         name="type"
//         value={filters.type || ''}
//         onChange={handleChangeFilter}
//         MenuProps={{
//           getContentAnchorEl: null,
//           anchorOrigin: {
//             vertical: 'bottom',
//             horizontal: 'left',
//           }
//         }}
//         endAdornment={
//           <InputAdornment position="end">
//             {
//               filters.type && (
//                 <IconButton onClick={() => handleClearFilter('type', '')}>
//                   <HighlightOffIcon/>
//                 </IconButton>
//               )
//             }
//           </InputAdornment>
//         }
//       >
//         <MenuItem key={10} value={10}>Стационарный</MenuItem>
//         <MenuItem key={20} value={20}>Мобильный</MenuItem>
//       </Select>
//     </FormControl>
//   </Grid>
// </>
