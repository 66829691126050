// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import FrequentRoutes from './FrequentRoutes';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(FrequentRoutes);
