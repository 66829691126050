import palette from '../palette';

export default {
  root: {
    padding: "12px 28px",
    boxShadow: "none",
    height: "auto",
    borderRadius: 12,

    fontSize: 20,
    fontWeight: "600",
    lineHeight: "100%",
    color: palette.black,
    letterSpacing: "-0.4px",
    textTransform: "initial",

    "& .MuiSvgIcon-root": {
      fontSize: 16
    },
    '&$formSubmit': {
      backgroundColor: '#28a745',
      color: 'white',

      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.26)'
      }
    },
    '&$formCancel': {
      backgroundColor: '#e53b37',
      color: 'white',

      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.26)'
      }
    }
  },
  sizeSmall: {
    padding: "10px 24px",
    fontSize: 16,
  },

  contained: {
    boxShadow: 'none',
    border: '1px solid ' + palette.primary.main,
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    padding: '10px 15px',

    '&:hover': {
      backgroundColor: palette.primary.main,
      boxShadow: 'none',
    }
  },
  text: {
    padding: "0!important",
    borderRadius: 0,
    color: palette.primary.main,
    backgroundColor: "transparent!important",
    borderBottom: "2px dotted",

    "& .MuiTouchRipple-root": {
      display: "none"
    }
  },
  outlined: {
    border: '1px solid ' + palette.gray.light,
    color: palette.gray.dark,
    padding: '10px 15px',
  },

  formSubmit: {},
  formCancel: {}
};
