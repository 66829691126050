import React, {Component} from 'react';
import {
	Box,
	Grid,
	Typography,
} from '@material-ui/core';
import {
  withStyles,
  makeStyles
} from '@material-ui/styles';
import {
  WithoutAccess,
  PageFilterTableContent
} from '../../../components';
import {
	Table,
	Filter
} from './components';
import axios from '../../../plugins/axios';
import {
	CustomMoment as Moment
} from '../../../components';
import moment from 'moment';
import theme from '../../../theme';

const styles = {
	searchContent: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-end'
	},
	searchInput: {
		backgroundColor: 'white',
		width: '100%',
		maxWidth: 350,
		borderRadius: 4,
		overflow: 'hidden',

		"& .MuiOutlinedInput-input": {
			backgroundColor: 'white'
		}
	},

	filterContainer: {
		'& > .MuiGrid-grid-xs-4': {
			[theme.breakpoints.between(0, 1599)]: {
				maxWidth: '50%',
				flexBasis: '50%'
			},
		}
	}
};
const initFilter = {
	limit: 20,
	page: 1,

	license_plate: "",
	username: "",

	datetime_from: null,
	datetime_to: null,

	type: null
};

class Logging extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rows: [],
			columns: [
				{
					label: 'Дата',
					field: 'created',
					type: 'dateTime',
					align: 'left',
				},
				{
					label: 'Тип операции',
					field: 'type',
					type: 'type',
					align: 'left',
				},
				{
					label: 'Действия',
					field: 'payload',
					type: 'payload',
					align: 'left',
				},
				{
					label: 'Пользователь',
					field: 'username',
					type: 'username',
					align: 'left',
				},
				{
					label: 'IP',
					field: 'ip',
					align: 'left',
				},
			],
			showColumns: ['ip', 'type', 'username', 'payload', 'created'],

			pagination: {
				rowsPerPage: 20,
				page: 1,
				orderBy: null,
				order: 'asc',
				totalCount: 0
			},
			filter: {...initFilter},
			createNewList: {
				title: '',
				color: '',
				type: null
			},
			confirmationDeleteList: {},
			editList: {},
			uploadFileList: {},
			fileUploadInfo: {
				failed: 0,
				imported: 0
			},

			dateTimeRequest: null,
			searchTableName: '',

			isLoading: true,
			openModalFileUpload: false,
			openModalCreateList: false,
			openModalConfirmationDelete: false,
			openModalEdit: false,
			openModalNotifications: false,

			isAccess: null
		}

		this.timeoutChangeFilter = null;
	}

	componentDidMount = () => {
		this.onCheckUser()

		this.isLoadSearchList()
	}

	// проверка пользователя на доступ в данные раздел
	// только админ имеет доступ
	onCheckUser = () => {
		let {roles} = this.props.user;
		let isAccess = false;

		if (roles.admin) {
			isAccess = true
		}

		this.setState({
			isAccess
		})
	}

	// конверац
	getFilterSeachList = () => {
		let filter = {};
		let filters = {...this.state.filter};

		for (let key in filters) {
			filter[key] = filters[key];

			if (key === 'datetime_from' || key === 'datetime_to') {
				if (filter[key]) {
					filter[key] = moment(filter[key])
				} else {
					delete filter[key]
				}
			}
			if (key === 'type') {
				filter[key] = (filter[key]) ? filter[key].value : null
			}
		}

		return filter
	}
	isLoadSearchList = () => {
		let filter = this.getFilterSeachList();
		this.setState({
			isLoading: true,
			rows: []
		})

		axios('post', '/userlog/get', filter).then(response => {
			let rows = response.data;
			let totalCount = response.headers['x-pagination-total-count'];
			let pagination = this.state.pagination;

			if (!rows) {
				rows = []
			}

			pagination.totalCount = totalCount;

			this.setState({
				rows,
				pagination,
				isLoading: false,
				dateTimeRequest: moment(),
			})
		}).catch(error => {
		})
	}
	isChangeRowPage = (pagination) => {
		this.setState({
			pagination
		})

		if (this.state.workFile) {
			this.specifyReportRearch()
		} else {
			this.getDriveList();
		}
	}

	onOpenModalInformation = (row) => {
	}

	// функция изменения фильтра
	changeFilters = (filter) => {
		clearTimeout(this.timeoutChangeFilter)

		this.setState({
			filter
		})


		this.timeoutChangeFilter = setTimeout(() => {
			this.isLoadSearchList()
		}, 500)
	}
	resetFilter = async () => {
		await this.setState({filter: {...initFilter}});
		this.isLoadSearchList();
	}

	changePage = async (newPage) => {
		let filter = {...this.state.filter};
		filter.page = newPage;
		this.changeFilters(filter);
	}

	render() {
		const {classes} = this.props;

		if (this.state.isAccess == null) {
			return (<></>)
		}
		if (this.state.isAccess !== null && !this.state.isAccess) {
			return (<WithoutAccess/>)
		}

		const totalPage = Math.ceil(this.state?.pagination?.totalCount / this.state.filter?.limit);

		return (
			<PageFilterTableContent
				currentPage={this.state.filter?.page || 1}
				totalPage={totalPage}
				onChangePage={this.changePage}
				onResetFilter={this.resetFilter}
			>
        <slot name="head">
          <HeadElement
            datetime={this.state.dateTimeRequest}
          />
        </slot>
        <slot name="body">
	        <Table
		        columns={this.state.columns}
		        showColumns={this.state.showColumns}
		        rows={this.state.rows}
		        pagination={this.state.pagination}
		        filter={this.state.filter}
		        isLoading={this.state.isLoading}

		        isChangeRowPage={this.isChangeRowPage}
		        isChangeFilter={this.changeFilters}

		        onOpenModalInformation={this.onOpenModalInformation}
	        />
        </slot>
        <slot name="filter">
          <Filter
            filters={this.state.filter}
            onChange={(filter, offUpdate) => this.changeFilters(filter, offUpdate)}
          />
        </slot>

				<Grid container justify={'center'}>
					<Grid item xs={12}>
						<Box mb={2}>
							<Grid container alignItems={'center'}>
								<Box mr={2}>
									<Typography variant={'h3'}>
										Логирование
									</Typography>
								</Box>

								<Box>
									<Typography style={smallTextGray}>
										Данные актуальны на&nbsp;
										<Moment
											format="DD.MM.YYYY HH:mm:ss"
											value={this.state.dateTimeRequest}
										>
											{this.state.dateTimeRequest}
										</Moment>
									</Typography>
								</Box>
							</Grid>
						</Box>

						<Box
							boxShadow={1}
							bgcolor={'white'}
							borderRadius={4}
							p={2}
							pb={4}
							mb={2}
							className={classes.blockFilter}
						>
							<Box>
								<Filter
									filters={this.state.filter}
									onChange={(filter, offUpdate) => this.changeFilters(filter, offUpdate)}
								/>
							</Box>
						</Box>


					</Grid>
				</Grid>
			</PageFilterTableContent>
		);
	}
};
const HeadElement = (props) => {
  const {
    datetime
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.headElement}>
      <Typography variant="h1">Логирование</Typography>
      <div className={classes.headElementDatetime}>
        Данные актуальны<br/>на {moment(datetime).format("DD.MM.YYYY HH:mm:ss")}
      </div>
    </Box>
  )
}
const useStyles = makeStyles(() => ({
  headElement: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16
  },
  headElementDatetime: {
    marginLeft: 20,
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "120%",
    letterSpacing: "-0.02em",
    color: "#8698B1",
  }
}));

const smallTextGray = {
	color: '#647083',
	fontSize: 16
};

export default withStyles(styles)(Logging)
