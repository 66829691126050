import React from 'react';

function ArrowHeaderTable(props) {
  const { className } = props;

  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle opacity="0.3" cx="7.5" cy="7.5" r="7.5" transform="rotate(-180 7.5 7.5)" fill="#8698B1"/>
      <path d="M7.87217 12.8413L10.8389 9.70686C11.1791 9.34736 10.9452 8.7407 10.4667 8.7407L8.56333 8.7407L8.56333 3.12345C8.56333 2.50555 8.08483 2 7.5 2C6.91517 2 6.43667 2.50555 6.43667 3.12345L6.43667 8.7407L4.5333 8.7407C4.0548 8.7407 3.82086 9.34736 4.16113 9.69563L7.12783 12.8301C7.32986 13.0547 7.67013 13.0547 7.87217 12.8413Z" fill="#F4F6F8"/>
    </svg>
  );
}

export default ArrowHeaderTable;
