import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Select,
	Checkbox,
	MenuItem,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	FormControlLabel,
} from '@material-ui/core';
import {
	withStyles
} from "@material-ui/styles";
import {
	Formik
} from 'formik';
import {
	DatePicker,
	VisibleContent
} from "../../../components";
import * as Yup from 'yup';
import vendors from "../../../constant/vendors";
import violationProcessing from "../SensorsDuplo/constants/violationProcessing";
import axiosAgent from "../../../plugins/axios";
import {sendMessage} from "../../../utils/message";

class SensorDuploForm extends Component {
	constructor(props) {
		super(props);

		const sensorId = props?.match?.params?.id || "";
		this.state = {
			form: {
				vendor: "",
				serial: "",
				type: "",
				address: "",
			},

			sensor: null,
			sensorId,

			isReady: false,
			isCreate: Boolean(!sensorId),
		};
		this.refFormik = React.createRef();
	}

	componentDidMount = async () => {
		await this.initForm();
	}

	initForm = async () => {
		const { sensorId } = this.state;
		if (!sensorId) {
			this.setState({ isReady: true });
			return
		}

		const res = await axiosAgent('get', `/operator/sensor/get/${ sensorId }`).then((res) => {
			return res.data || null
		}).catch(() => {
			return null
		});
		if (!res) {
			sendMessage({
				type: "danger",
				message: "Устройство не найдено"
			})
			this.props.history.goBack();
			return
		}

		let form = {
			vendor: res?.vendor || '',
			serial: "",
			type: "",
			address: "",
		};
		await this.setState({
			sensor: res,
			isReady: true
		})
		console.log('res: ', res);
	}

	render() {
		const {
			form,
			sensor,
			isReady,
			isCreate
		} = this.state;
		const {
			classes
		} = this.props;
		console.log('sensor: ', sensor);

		return (
			<Box maxWidth="860" margin="0 auto">
				<Box mb={2}>
					<Typography variant="h1">
						{isCreate ? "Добавление комплекса" : "Редактирование комплекса"}
					</Typography>
					<VisibleContent visible={!isCreate}>
						<Box mt={1}/>
						<Typography variant="caption" style={{ fontSize: 16 }}>
							{[sensor?.vendor, sensor?.serial].filter((t) => !!t).join(' ')}
						</Typography>
					</VisibleContent>
				</Box>
				<Box className={classes.section}>
					<VisibleContent visible={isReady}>
						<Formik
							innerRef={this.refFormik}
							initialValues={form}
							validationSchema={validationSchema}
						>{(props) => {
							const {} = props;
							return (
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel>Производитель</InputLabel>
											<Select
												label="Производитель"
												variant="outlined"
											>
												{vendors.map((vendor) => (
													<MenuItem key={`vendors-${ vendor.value }`} value={vendor.value}>
														{ vendor.label }
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label="Серийный номер"
											placeholder="Серийный номер"
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel>Тип</InputLabel>
											<Select
												label="Тип"
												variant="outlined"
											>
												<MenuItem value="">Сбросить</MenuItem>
												<MenuItem value="10">Стационарный</MenuItem>
												<MenuItem value="20">Передвижной</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label="Местоположение"
											variant="outlined"
											fullWidth
											multiline
											rows={3}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel>Обработка нарушений</InputLabel>
											<Select
												label="Обработка нарушений"
												variant="outlined"
											>
												{Object.keys(violationProcessing).map((key) => (
													<MenuItem key={`violationProcessing-${ key }`} value={key}>
														{violationProcessing[key]}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<DatePicker
											label="Окончание поверки"
											cancelMaxDay
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label="Норматив фиксаций нарушений"
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label="Список E-mail (разделитель - ',')"
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControlLabel
											control={<Checkbox />}
											label="Отправлять только в ЦП"
										/>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1} justify="flex-end">
											<Grid item>
												<Button
													size="small"
													variant="outlined"
												>Отменить</Button>
											</Grid>
											<Grid item>
												<Button
													size="small"
													variant="contained"
												>Сохранить</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)
						}}</Formik>
					</VisibleContent>
				</Box>
			</Box>
		);
	}
}

const styles = {
	section: {
		padding: 20,
		boxSizing: "border-box",
		borderRadius: 10,
		background: "#F4F6F8"
	}
};
SensorDuploForm = withStyles(styles)(SensorDuploForm);

const validationSchema = Yup.object().shape({

});

export default SensorDuploForm
