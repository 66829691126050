import React, {useRef} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Tooltip
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  ArrowHeaderTable as ArrowHeaderTableIcon,
} from '../../../../../icons/index';
import {useHistory} from 'react-router-dom';
import Skeleton from "@material-ui/lab/Skeleton";
import {
  CustomTablePagination,
  CustomMoment as Moment,
} from "../../../../../components";
import clsx from "clsx";

import {
  LoggingElementDefault,
  LoggingElementsReportSearch,
  LoggingElementsBulkReportSearch,
  LoggingElementsSearchListUpload,
  LoggingElementsSearchItemCreate,
  LoggingElementsForceLogout,

} from '../LoggingElements';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },

  tableSortLabel: {
    '& .MuiTableSortLabel-icon': {
      opacity: 0.5
    }
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    '& td': {
      padding: '10px!important'
    }
  },

  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#fafafa'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },

  image: {
    width: 65,
    height: 65,
    marginBottom: -10,

    position: 'relative',
    zIndex: 555
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '&:hover td:last-child': {
          backgroundColor: theme.palette.background.default,
        },
        '& td:last-child, th:last-child': {
          backgroundColor: 'white',
          position: 'sticky',
          right: 0,
          zIndex: 999
        },
        '& th:last-child': {
          backgroundColor: 'rgb(237, 241, 246)',
        },
        '& th:first-child': {
          zIndex: 9999
        }
      }
    }
  },

  cellPayload: {
    maxWidth: 500
  },
  ceilColor: {
    width: 20,
    height: 45,
    borderRadius: 2
  }
}));

const RowLoading = () => {
  return (
    <>
      {
        [0, 1, 2, 3, 4, 5, 6, 7].map((item) => (
          <TableRow idx={`table-loading-row-${ item }`} className={'MuiTableRow-list'}>
            <TableCell><Skeleton height={40}/></TableCell>
            <TableCell><Skeleton height={40}/></TableCell>
            <TableCell><Skeleton height={40}/></TableCell>
            <TableCell><Skeleton height={40}/></TableCell>
            <TableCell><Skeleton height={40}/></TableCell>
          </TableRow>
        ))
      }
    </>
  )
}
const TableComponents = props => {
  const {
    rows,
    columns,
    showColumns,
    isChangeRowPage,
    isLoading,
    filter,
    isChangeFilter,

    onOpenModalInformation
  } = props;
  let {pagination} = props;
  const classes = useStyles();
  const refTable = useRef(null);
  const history = useHistory();

  const handleChangeRowPage = (event) => {
    pagination.rowsPerPage = event.target.value;

    isChangeRowPage(pagination)
  }
  const handleChangePage = (event, page) => {
    let newFilter = filter;
    newFilter['page'] = page;

    isChangeFilter(newFilter);
  }
  const createSortHandler = (field) => {
    const isAsc = pagination.orderBy === field && pagination.order === 'asc';
    pagination.order = isAsc ? 'desc' : 'asc';
    pagination.orderBy = field;

    isChangeRowPage(pagination);
  }

  const handleOpenInformation = (row) => {
    onOpenModalInformation(row)
  }

  return (
    <Box>
      <Table
        id={'basic-drive-table'}
        ref={refTable}
        className={'MuiTable-list'}
      >
        <TableHead className={'MuiTableHead-list'}>
          <TableRow>
            {
              columns.map((column, idx) => {
                if (showColumns.indexOf(column.field) > -1) {
                  let titleSort = column.label + ' по ' + (pagination.orderBy === column.field ? (pagination.order === 'desc') ? 'возрастанию' : 'убыванию' : 'возрастанию');

                  if (column.sort) {
                    return (
                      <TableCell
                        key={'table-cell-' + idx}
                        align={'left'}
                      >
                        <Tooltip title={titleSort}>
                          <TableSortLabel
                            IconComponent={ArrowHeaderTableIcon}
                            className={classes.tableSortLabel}
                            active={pagination.orderBy === column.field}
                            direction={pagination.orderBy === column.field ? pagination.order : 'asc'}
                            onClick={() => createSortHandler(column.field)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    )
                  } else {
                    return (
                      <TableCell key={'table-cell-' + idx} align={'left'} className={classes.tableSortLabel}>
                        {column.label}
                      </TableCell>
                    )
                  }
                }
              })
            }
          </TableRow>
        </TableHead>

        {
          (!isLoading) ?
            (
              <TableBody>
                {rows.map((row, idx) => {
                  switch (row.type){
                    case ('logReportSearch'): {
                      return (
                        <LoggingElementsReportSearch
                          key={row.name + '-' + idx}
                          columns={columns}
                          classes={classes}
                          showColumns={showColumns}

                          row={row}
                        />
                      )
                    }
                    case ('logBulkReportSearch'): {
                      return (
                        <LoggingElementsBulkReportSearch
                          key={row.name + '-' + idx}
                          columns={columns}
                          classes={classes}
                          showColumns={showColumns}

                          row={row}
                        />
                      )
                    }

                    case ('logSearchItemCreate'): {
                      return (
                        <LoggingElementsSearchItemCreate
                          key={row.name + '-' + idx}
                          columns={columns}
                          classes={classes}
                          showColumns={showColumns}

                          row={row}
                        />
                      )
                    }
                    case ('logSearchListUpload'): {
                      return (
                        <LoggingElementsSearchListUpload
                          key={row.name + '-' + idx}
                          columns={columns}
                          classes={classes}
                          showColumns={showColumns}

                          row={row}
                        />
                      )
                    }
                    case ('forceLogout'): {
                      return (
                        <LoggingElementsForceLogout
                          key={row.name + '-' + idx}
                          columns={columns}
                          classes={classes}
                          showColumns={showColumns}

                          row={row}
                        />
                      )
                    }

                    default: {
                      return (
                        <LoggingElementDefault
                          key={row.name + '-' + idx}
                          columns={columns}
                          className={clsx(['MuiTableRow-list', classes.tableRow])}
                          showColumns={showColumns}

                          row={row}
                        />
                      )
                    }
                  }
                })}
              </TableBody>
            )
            :
            (
              <RowLoading/>
            )
        }
      </Table>
    </Box>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  showColumns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
  showColumns: []
};

export default TableComponents;
