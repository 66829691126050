import React, {Component} from 'react';
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress
} from '@material-ui/core';
import {
  withStyles
} from "@material-ui/styles";
import {
  Form
} from './components';
import axios from '../../../plugins/axios';
import { store } from 'react-notifications-component';

class SensorGroupCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        title: ''
      }
    }
  }


  // Создание группы устроства
  saveSensor = (form) => {
    axios('post', '/operator/sensor-group/create', form).then(() => {
      store.addNotification({
        title: 'Успешно',
        message: 'Группа успешно создана',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.props.history.push('/sensor-group');
    }).catch((error) => {
      let message = ''

      if (error.response.data.message){
        message = error.response.data.message;
      }else{
        message = error.response.data;
      }

      store.addNotification({
        title: 'Ошибка',
        message: message,
        type: 'danger',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    })
  }

  render() {
    const {
      classes
    } = this.props;

    return (
      <Box className={classes.root}>
        <Container maxWidth='sm'>
          <Box className={classes.section}>
            <Typography variant="h1" style={{ marginBottom: "24px" }}>
              Создание
            </Typography>

            <Box>
              {
                (this.state.loading) ?
                  (
                    <Grid
                      container
                      justify={'center'}
                    >
                      <CircularProgress
                        color="secondary"
                      />
                    </Grid>
                  )
                  :
                  (
                    <Form
                      initialValues={this.state.form}
                      onSubmitSuccess={this.saveSensor}
                    />
                  )
              }
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
}

const styles = {
  root: {
    background: "rgba(177, 177, 177, 0.5)",
    backdropFilter: "blur(8px)",
    margin: "-20px -8px",
    padding: "32px 0",
    boxSizing: "border-box",
    minHeight: "calc(100vh - 64px)"
  },
  section: {
    padding: "28px",
    boxSizing: "border-box",
    background: "#F4F6F8",
    borderRadius: "10px",
  }
};
SensorGroupCreate = withStyles(styles)(SensorGroupCreate);

export default SensorGroupCreate
