import React, { PureComponent } from "react";
import {withStyles} from "@material-ui/styles";
import {Marker} from "react-leaflet";
import {divIcon} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";
import urls from "../../constant/urls";
import axios from "../../plugins/axios";

class MapTags extends PureComponent{
    constructor(props) {
        super(props);

        this.state = {
            points: []
        }
    }

    componentDidMount = async () => {

        const points = await axios('get', urls["map-item-get"]).then((res) => {
            return res?.data || []
        })

        this.setState({
            points
        })

    }

    _renderMarker = (point) => {
        const {classes} = this.props;

        return renderToStaticMarkup(
            <>

                <div className={classes.marker}>
                    <img
                        src={`${urls["image-url"]}${point.icon}`}
                        className={classes.markerImage}
                    />
                    <div className={classes.markerTitle}>{point.title}</div>
                </div>


            </>
        )
    }

    render() {
        const { points } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.map}>

                {
                    points.map((point, idx) => {
                        const position = point?.gps || {lat: 0, lon: 0};
                        const customMarkerIcon = divIcon({
                            html: this._renderMarker(point),
                            iconSize: [40, 40],
                            className: classes.marker,
                        });

                        return (
                            <Marker
                                position={position}
                                icon={customMarkerIcon}
                                key={`map-point-${idx}`}
                            />
                        )
                    })
                }

            </div>
        )
    }
}

const styles = {
    map: {
        position: 'relative',

        '& .leaflet-div-icon': {
            backgroundColor: 'transparent!important',
            border: 'none!important'
        }
    },

    marker: {
        width: 40,
        height: 40,
        fontFamily: "Lato",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    markerImage: {
        width: 40,
        marginBottom: 8
    },
    markerTitle: {
        color: '#000000',
        fontWeight: '500',
        textShadow: '0 0 1px white',
        fontSize: 13,
        lineHeight: '13px',
        textAlign: "center",
        letterSpacing: '0.6px',
        position: 'absolute',
        top: '100%'
    }
};

export default withStyles(styles)(MapTags)
