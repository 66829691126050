import React from 'react';
import Marker from './Marker';

const Points = (props) => {
  const {points} = props

  return (
    <div>
      {
        points.map((point, idx) => (
          <Marker
            key={'point-' + idx}
            position={point.position}
          />
        ))
      }
    </div>
  )
}

export default Points
