import React from "react";
import {
  Grid,
} from "@material-ui/core";
import {
  Map
} from '../index';
import {
  Image
} from '../../../../../components/index';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end',
    height: 250
  },

  gridImages: {
    height: '100%'
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
}));

const TargetInfoHeader = props => {
  const classes = useStyles();
  let {params, update} = props
  params = {...params}

  let points = [
    {
      title: 'Точка 2',
      position: [56.8219000, 60.6122000]
    }
  ]

  return (
    <Grid
      className={classes.gridLines}
      container
      spacing={0}
    >
      <Grid
        item
        xs={3}
        className={classes.gridImages}
      >
        <Image
          src={'https://avatars.mds.yandex.net/get-pdb/467185/760b578c-a250-4621-b806-6f44b007a667/s1200'}
          classImage={classes.image}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Map
          position={[56.8519000, 60.6122000]}
          points={points}
        />
      </Grid>
      <Grid
        item
        xs={3}
        className={classes.gridImages}
      >
        <Image
          src={'https://avatars.mds.yandex.net/get-pdb/467185/760b578c-a250-4621-b806-6f44b007a667/s1200'}
          classImage={classes.image}
        />
      </Grid>
    </Grid>
  );
};

TargetInfoHeader.propTypes = {
  params: PropTypes.object
};

export default TargetInfoHeader;
