import React from "react";
import {
	Box,
	Grid,
	Drawer,
	Typography
} from "@material-ui/core";
import {
	makeStyles
} from "@material-ui/styles";
import {
	Close as CloseIcon
} from "@material-ui/icons";
import clsx from "clsx";

const Notifications = (props) => {
	const {
		open,
		notifications,

		onCloseNotification
	} = props;
	const classes = useStyles();

	const removeNotification = (index) => {}

	return (
		<Drawer
			open={open}
			classes={{root: classes.root}}
			anchor="right"

			onClose={onCloseNotification}
		>
			<Typography className={classes.title} variant="h3">Обнаружения — {notifications.length} шт</Typography>
			<Box className={clsx([classes.scrollBody, ''])}>
				<Grid container spacing={1}></Grid>
			</Box>
		</Drawer>
	)
}
const NotificationCard = React.memo((props) => {
	const classes = useStyles();
	return (
		<Box className={classes.notificationCard}>
			<Box className={classes.notificationCardLeft}>
				<img src="https://dp.ugmk-telecom.ru:8080//integra_img10/20231205/14/34/86a30548-20a5-49a6-866f-c144b0804e9f.jpg"/>
			</Box>
			<Box className={classes.notificationCardRight}>
				<div>
					<div className="--title">Гос. знак</div>
					<div className="--value --big">В 888 ВВ 88</div>
				</div>
				<div>
					<div className="--title">Местоположение</div>
					<div className="--value">
						Перекресток ул.Токарей; ул.Кирова, г. Екатеринбург, Свердловская обл.
					</div>
				</div>
				<div>
					<div className="--title">Код устройства</div>
					<div className="--value">
						11512-57108-21
					</div>
				</div>
			</Box>

			<Box className={classes.notificationCardClose}>
				<CloseIcon/>
			</Box>
		</Box>
	)
})

const useStyles = makeStyles(() => ({
	root: {
		"& .MuiPaper-root": {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			maxWidth: "400px",
			borderRadius: "10px 0 0 10px",
			background: "#F4F6F8",
			padding: "32px 36px",
			paddingRight: 0,
			paddingBottom: 0,
			boxSizing: "border-box"
		}
	},
	title: {
		marginBottom: 32,
	},
	scrollBody: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		overflow: "auto",
		paddingBottom: 32,
		boxSizing: "border-box",

		"& .MuiGrid-container": {
			width: "100%"
		}
	},

	notificationCard: {
		display: "flex",
		alignItems: "flex-start",
		padding: 16,
		boxSizing: "border-box",
		borderRadius: "16px",
		background: "#152435",
		position: "relative"
	},
	notificationCardLeft: {
		width: "100%",
		maxWidth: 124,
		position: "relative",
		overflow: "hidden",
		borderRadius: "8px",

		"& img": {
			position: "absolute",
			left: "50%", top: "50%",
			transform: "translate(-50%, -50%)",
		},
		"&:after": {
			content: "''",
			float: "left",
			paddingTop: "70%",
		}
	},
	notificationCardRight: {
		display: "flex",
		flexDirection: "column",
		marginLeft: 16,

		"& > *": {
			marginTop: 14,

			"& .--title": {
				marginBottom: 2,

				color: "#FFF",
				fontSize: "14px",
				fontWeight: "600",
				lineHeight: "100%",
				letterSpacing: "-0.28px",
				opacity: 0.6
			},
			"& .--value": {
				color: "#FFF",
				fontSize: "16px",
				fontWeight: "600",
				lineHeight: "100%",
				letterSpacing: "-0.28px"
			},
			"&:first-child": {
				marginTop: 0
			}
		}
	},
	notificationCardClose: {
		position: "absolute",
		top: 10, right: 10,
		width: 24, height: 24,
		display: "flex", alignItems: "center", justifyContent: "center",
		cursor: "pointer",

		"& svg": {
			color: "white"
		}
	}
}));

export default React.memo(Notifications)
