import React from 'react';
import {
  Grid,
  Tooltip,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import {
  DateRange
} from '../../../../../components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const typesOptions = [
  {
    title: 'Авторизация пользователя',
    value: 'logLogin'
  },
  {
    title: 'Применение фильтра проездов',
    value: 'logReportSearch'
  },
  {
    title: 'Применение фильтра проездов ( список )',
    value: 'logBulkReportSearch'
  },
  {
    title: 'Принудительное заверешение пользовательской сессии.',
    value: 'forceLogout'
  }
]

const Filter = (props) => {
  let {filters, onChange} = props;

  const handleChangeFilter = (event) => {
    let newFilter = filters;
    let item = event.target;
    let name = item.name;
    let value = item.value;

    newFilter[name] = value;

    newFilter.page = 1;

    onChange(newFilter)
  };
  const handleChangeDate = (range) => {
    filters.datetime_from = range.startDate * 1000;
    filters.datetime_to = range.endDate * 1000;

    filters.page = 1;

    onChange(filters)
  }

  const handleChangeAutocomplite = (name, values) => {
    let newFilters = {...filters};

    newFilters[name] = values;

    newFilters.page = 1

    onChange(newFilters)
  }
  const handleRanderOptionLabel = (option) => {
    return option.title
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <Typography variant="formTitle">ГРЗ</Typography>
        <TextField
          name={'license_plate'}
          value={filters.license_plate}
          variant="outlined"
          fullWidth
          onChange={handleChangeFilter}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <>
                  {
                    (filters.license_plate) && (
                      <Tooltip title="Очистить">
                        <IconButton onClick={() => handleChangeAutocomplite('license_plate', '')}>
                          <HighlightOffIcon/>
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography variant="formTitle">Временной промежуток</Typography>
        <DateRange
          name='datetime_start'
          dateStart={filters.datetime_from}
          dateEnd={filters.datetime_to}
          onChange={handleChangeDate}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography variant="formTitle">Тип</Typography>

        <Autocomplete
          openText={'Открыть список'}
          clearText={'Очистить'}
          closeText={'Закрыть'}
          value={filters.type}
          name={'type'}
          options={typesOptions}
          fullWidth
          getOptionLabel={handleRanderOptionLabel}
          noOptionsText={'Не найдено'}
          onChange={(event, values) => handleChangeAutocomplite('type', values)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={'outlined'}
              onChange={handleChangeFilter}
            />
          )}
        />

      </Grid>
      <Grid xs={12} item>
        <Typography variant="formTitle">Имя</Typography>
        <TextField
          name={'username'}
          value={filters.username}
          variant="outlined"
          fullWidth
          onChange={handleChangeFilter}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <>
                  {
                    (filters.username) && (
                      <Tooltip title="Очистить">
                        <IconButton onClick={() => handleChangeAutocomplite('username', '')}>
                          <HighlightOffIcon/>
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </>
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default Filter;
