export default {
  root: {
    background: "rgba(86, 84, 84, 0.5)",
    backdropFilter: "blur(8px)",
},

  invisible: {
    backgroundColor: 'rgba(24, 31, 72, 0.5)',
    zIndex: 99999999
  }
};
