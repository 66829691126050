import palette from '../palette';

export default {
  root: {},
  colorSecondary: {
    '&$checked': {
      color: palette.primary.hover
    }
  }
}
