import React from 'react';
import Polyline from 'react-leaflet-arrowheads'

const arrowheads = {
  yawn: 30,
  size: '24px',
  fill: true,
  color: "#2979FF",
  frequency: 1,
  proportionalToTotal: true,
  offsets: {end: "50%"}
};

const PolylineDecorator = (props) => {
  const { items } = props;
  const [listPolyline, setListPolyline] = React.useState([]);

  React.useEffect(() => {
    handleSetListPolyline();
  }, [items]);
  const handleSetListPolyline = () => {
    let list = [];

    items.map(( item, index ) => {
      const currentItem = items[index];
      const nextItem = items[index + 1];

      if (currentItem && nextItem) {
        list.push([
          [currentItem?.gps?.lat, currentItem?.gps?.lon],
          [nextItem?.gps?.lat, nextItem?.gps?.lon]
        ]);
      }
    });

    setListPolyline(list);
  };

  return listPolyline.map((item, index) => (
    <Polyline
      key={`Polyline-${ index }`}
      fill={true}
      stroke={true}
      color="#2979FF"
      positions={item}
      interactive={false}
      arrowheads={arrowheads}
    />
  ))
}

export default PolylineDecorator;
