import React, {useState} from 'react';

import {
  TextField,
  makeStyles,
  Grid,
  Select,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControl,
  Button,
  Box,
  Collapse,
  Paper,
  Tooltip,
  Typography,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  DateRange,
  MultiSelectText,
  ModalSensorFilter,
  SelectColor,
  CustomCheckbox,
  FilterTemplate,
  FilterSpeed
} from '../../../../../components';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  X as XIcon
} from 'react-feather';
import clsx from 'clsx';
import theme from "../../../../../theme";

const useStyles = makeStyles((theme) => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  queryField: {
    width: 500
  },
  categoryField: {
    width: '100%',
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },

  label: {
    marginBottom: 5
  },

  buttonSpeedArrow: {
    minWidth: 'initial',
    padding: '10px 5px',
    backgroundColor: 'rgba(194, 207, 224, 0.2)'
  },
  buttonSpeedArrowActive: {
    backgroundColor: '#152435',

    '&:hover': {
      backgroundColor: '#1a237e',
    },
    '&:active': {
      backgroundColor: '#1a237e',
    },
  },

  clarificationForm: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.gray.dark,

    '& sup': {
      position: 'relative',
      top: 0,
      color: theme.palette.primary.main,
    }
  },

  formBottom: {
    display: 'flex',
    alignItems: 'center'
  },

  filterContainer: {
    '& > .MuiGrid-grid-xs-4': {
      [theme.breakpoints.between(0, 1599)]: {
        maxWidth: '50%',
        flexBasis: '50%'
      },
    }
  }
}));

const Filter = (props) => {
  const classes = useStyles();
  const [openAdvancedSearch, setAdvancedSearch] = useState(false);
  const [buttonSpeedArrow, setButtonSpeedArrow] = useState(null);
  let {pagination, totalCount, filters, onChange, sensorList, sensorFilter, onFilterSensor, onChangeFile, onStartFilter} = props;
  const pagesCount = Math.ceil(totalCount / pagination.rowsPerPage);


  // Фунция изменения фильтра
  const handleChangeFilter = (event) => {
    const item = event.target;
    const name = item.name;
    let value = item.value;

    if (value === 'Все') {
      value = ''
    }

    filters[name] = value;

    onChange(filters)
  }
  const handelChangeFilterTemplate = (filter) => {
    props.onChangePagination(filter);
  }

  // Фунция изменения фильтра
  const handleChangeFilterName = (date, name) => {
    filters[name] = date;
    onChange(filters)
  }

  // Фунциял удаления занчения фильтра по имени
  const handleClearFilter = (name, value) => {
    filters[name] = value;

    onChange(filters)
  }

  // Очистка всего фильтра
  const handleClearAll = () => {
    filters = {
      sensor_ids: [],
      license_plate_list: [],
      colors: [],
      license_plate_id: '',
      mark: '',
      model: '',
      speed: '',
      speed_direction: '',
      license_plate_mask: '',
      datetime_start: null,
      datetime_end: null,
      sort_field: '',
      sort_direction: '',
      license_plate_empty: false,
      license_plate_foreign: false,
      license_plate_invalid: false,

      limit: 20
    }

    onChange(filters)
  }

  // Открытие дополнительного набора фильтров
  const handleOpenAdvancedSearch = () => {
    setAdvancedSearch(!openAdvancedSearch)
  }

  // Функция изменение даты (подается временной отрезок, возращается разбитый по датам)
  const handleChangeDate = (range) => {
    filters.datetime_start = range.startDate * 1000;
    filters.datetime_end = range.endDate * 1000;

    onChange(filters)
  }

  // Функция изменения набора устройств
  const handleChangeDevices = (name, list, offUpdate) => {
    filters[name] = list;

    onChange(filters, offUpdate)
  }

  // Фунция изменения фильтра скорости
  const handleChangeSpeedArrow = (value) => {
    if (buttonSpeedArrow === value) {
      setButtonSpeedArrow(null)
    } else {
      setButtonSpeedArrow(value)
    }
  }

  // Функциал загрузки фильтра файлом
  const handleChangeFile = (file) => {
    onChangeFile(file);
  }

  const handleChangeMultiValue = (values) => {
    let _filters = {...filters};
    values.map((value) => {
      _filters[value.name] = value.value;
    });
    onChange(_filters);
  }

  let colorOptions = [
    {
      value: '1',
      label: 'Красный',
      color: '#F32222'
    },
    {
      value: '2',
      label: 'Синий',
      color: '#152435'
    },
  ];

  return (
    <FilterTemplate
      title="Поиск по устройствам"
      filter={filters}
      totalPages={pagesCount}
      onReset={handleClearAll}
      onChange={handelChangeFilterTemplate}
    >
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography variant="formTitle">ГРЗ</Typography>
          <MultiSelectText
            className={classes.categoryField}
            name={'license_plate_list'}
            value={filters.license_plate_list}
            variant="outlined"
            onChange={handleChangeFilterName}
            fileUpload={true}
            onChangeFile={handleChangeFile}
          />
        </Grid>
        <Grid xs={12} item>
          <Typography variant="formTitle">Временной промежуток</Typography>
          <DateRange
            name='datetime_start'
            dateStart={filters.datetime_start}
            dateEnd={filters.datetime_end}
            onChange={handleChangeDate}
          />
        </Grid>
        <Grid xs={12} item>
          <FilterSpeed
            speed={filters.speed}
            direction={filters.speed_direction}
            onChange={handleChangeMultiValue}
          />
        </Grid>
        <Grid xs={12} item>
          <Grid container>
            <Grid xs={12} item>
              <CustomCheckbox
                checked={Boolean(filters.license_plate_empty)}
                name="license_plate_empty"
                color="primary"
                label="Пустые ГРЗ"
                onChange={() => handleChangeFilterName(!Boolean(filters.license_plate_empty), 'license_plate_empty')}
              />
            </Grid>
            <Grid xs={12} item>
              <CustomCheckbox
                checked={Boolean(filters.license_plate_foreign)}
                name="license_plate_foreign"
                color="primary"
                label="Иностранные ГРЗ"
                onChange={() => handleChangeFilterName(!Boolean(filters.license_plate_foreign), 'license_plate_foreign')}
              />
            </Grid>
            <Grid xs={12} item>
              <CustomCheckbox
                checked={Boolean(filters.license_plate_invalid)}
                name="license_plate_invalid"
                color="primary"
                label="Недействительные ГРЗ"
                onChange={() => handleChangeFilterName(!Boolean(filters.license_plate_invalid), 'license_plate_invalid')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <ModalSensorFilter
            value={filters.sensor_ids}
            list={sensorList}
            onChange={(list) => handleChangeDevices('sensor_ids', list, true)}
            filter={sensorFilter}
            onFilter={(filter) => onFilterSensor(filter)}
            fullWidth

            onStartFilter={onStartFilter}
          />
        </Grid>
      </Grid>
    </FilterTemplate>
  )
}

export default Filter;
