import React from "react";
import {
  Grid,
  FormControl,
  TextField,
  Button
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from '@material-ui/pickers';
import PropTypes from "prop-types";
import DateFnsUtils from '@date-io/date-fns';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },
}));

const Filter = props => {
  const classes = useStyles();
  let {params, update} = props
  params = {...params}


  return (
    <FormControl className={classes.form}>
      <Grid
        className={classes.gridLines}
        container
        spacing={2}
      >
        <Grid item>
          <TextField
            value={params.grz}
            label="ГРЗ"
            required
            onChange={(e) => {
              params.grz = e.target.value;
              update(params)
            }}
          />
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              label="С"
              format="MM.dd.yyyy HH:mm"
              value={params.dateTimeStart}
              onChange={date => {
                params.dateTimeStart = date;
                update(params)
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              label="По"
              format="MM.dd.yyyy HH:mm"
              value={params.dateTimeEnd}
              onChange={date => {
                params.dateTimeEnd = date;
                update(params)
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <Button>Комплексы</Button>
          <Button>Очистить</Button>
          <Button>Найти</Button>
        </Grid>
      </Grid>
    </FormControl>
  );
};

Filter.propTypes = {
  params: PropTypes.object
};

export default Filter;
